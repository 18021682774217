import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, Form, Input, message, Spin, Tabs } from 'antd';
import CreateProfileTab from './create_cv_tabs/CreateProfileTab';
import CVPreview from './CVPreview';
import CreateProfileTab2 from './create_cv_tabs/CreateProdileTab2';
import CreateEduTab from './create_cv_tabs/CreateEduTab';
import {
	clearCVData,
	fetchApplicantCvs,
	saveApplicantCv,
	setCVData,
} from '../../features/cv/cvSLice';
import { toast } from 'react-hot-toast';
import jsPDF from 'jspdf';
import axios from 'axios';
import CreateExpTab from './create_cv_tabs/createExpTab';
import CreateAchievement from './create_cv_tabs/CreateAchievement';
import CreateSkills from './create_cv_tabs/CreateSkills';
import CreateReference from './create_cv_tabs/CreateReference';
import JumbotronOne from '../../components/JambotronOne';
import BlueTemplate from './templates/BlueTemplate';
import OrangeTemplate from './templates/OrangeTemplate';
import domtoimage from 'dom-to-image-more';
import { formatImgPath } from '../../utils';

const url = process.env.REACT_APP_API_BASE_URL;

export default function CreateCV() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { cvData, cvLoading, cvTempId } = useSelector((state) => state.cv);
	const { user } = useSelector((state) => state.auth);
	const [hasError, sethasError] = useState(false);

	const usrObj = {
		usrId: user?.usrId,
		cvUsrId: user?.usrId,
	};

	const [editingHeader, seteditingHeader] = useState(false);
	const [docTitle, setdocTitle] = useState('Resume Name');
	const [isLoading, setisLoading] = useState(false);

	const [form] = Form.useForm();
	const formRef = useRef(null);
	const view = useRef();

	const docRef = useRef(null);

	const [current, setcurrent] = useState('1');
	const [data, setdata] = useState({
		cvUsrName: user?.usrFirstName,
		cvMail: user?.usrEmail,
		cvTitle: user?.usrOtherTitle,
		cvPhone: user?.usrMobileNumber,
		cvBio: user?.usrBio,
		cvLanguages: user?.usrLanguages,
	});

	function handleCurrent(val) {
		setcurrent(val);
	}

	async function handleDispatch(values) {
		await dispatch(setCVData(values));
	}

	function handleNext() {
		let num = Number(current);
		if (num < 7) {
			setcurrent(String(num + 1));
			view?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'start',
			});
		} else {
			setcurrent(current);
		}
	}

	function handleBack() {
		let num = Number(current);
		if (num > 1) {
			setcurrent(String(num - 1));
			view?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'start',
			});
		} else {
			setcurrent(current);
		}
	}

	const cvTabs = [
		{
			key: '1',
			children: (
				<CreateProfileTab
					cvTempId={cvTempId}
					hasError={hasError}
					form={form}
					data={data}
					docTitle={docTitle}
					handleCurrent={handleCurrent}
				/>
			),
		},
		{
			key: '2',
			children: (
				<CreateProfileTab2 data={data} handleCurrent={handleCurrent} />
			),
		},
		{
			key: '3',
			children: (
				<CreateEduTab data={data} handleCurrent={handleCurrent} />
			),
		},
		{
			key: '4',
			children: (
				<CreateExpTab data={data} handleCurrent={handleCurrent} />
			),
		},
		{
			key: '5',
			children: (
				<CreateAchievement data={data} handleCurrent={handleCurrent} />
			),
		},
		{
			key: '6',
			children: (
				<CreateSkills data={data} handleCurrent={handleCurrent} />
			),
		},
		{
			key: '7',
			children: (
				<CreateReference data={data} handleCurrent={handleCurrent} />
			),
		},
	];

	const handleChange = (values) => {
		setdata((prevData) => ({
			...prevData,
			...values,
		}));
	};

	function handleCVDocName(e) {
		setdocTitle(e.target.value);
		let cvObj = {
			cvDocName: e.target.value,
		};
		handleDispatch(cvObj);
	}

	async function handleSaveDocNameOnEnter(val) {
		let cvObj = {
			cvDocName: val,
		};
		await handleDispatch(cvObj);
		await seteditingHeader(false);
	}

	async function handleFetchCV() {
		await dispatch(fetchApplicantCvs(usrObj));
	}

	// const handleDownloadPDF = async () => {

	// 	if (docRef.current) {
	// 		try {
	// 			setisLoading(true);
	// 			const dataUrl = await domtoimage.toPng(docRef.current, {
	// 				quality: 1,
	// 				scale: 3,
	// 				bgcolor: '#ffffff',
	// 			});

	// 			const img = new Image();
	// 			img.src = dataUrl;

	// 			img.onload = async () => {
	// 				const pdf = new jsPDF('p', 'mm', 'a4');
	// 				const pdfWidth = pdf.internal.pageSize.getWidth();
	// 				const pdfHeight = (img.height * pdfWidth) / img.width;

	// 				pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);

	// 				const file = pdf.output('blob');

	// 				let data = new FormData();
	// 				data.append('file', file);

	// 				const resp = await axios.post(
	// 					`${url}/nea/saveFile.action`,
	// 					data
	// 				);

	// 				if (resp?.status === 200) {
	// 					window.open(
	// 						formatImgPath(resp?.data?.jsonData),
	// 						'_blank'
	// 					);
	// 				} else {
	// 					message.error('Error generating PDF');
	// 				}

	// 				setisLoading(false);
	// 			};
	// 		} catch (error) {
	// 			message.error('Error generating PDF:', error);
	// 			setisLoading(false);
	// 		}
	// 	}
	// };

	// const handlePreview = async () => {
	// 	const doc = new jsPDF('p', 'pt', 'a4');
	// 	document.getElementById('print').style.overflow = 'scroll';

	// 	var pdf = doc.output('blob');

	// 	let data = new FormData();
	// 	data.append('file', pdf);

	// 	const resp = await axios.post(`${url}/nea/saveFile.action`, data);

	// 	const opt = {
	// 		callback: async function (doc) {
	// 			window.open(
	// 				doc.output('bloburl', { filename: resp?.data?.jsonData }),
	// 				'_blank'
	// 			);
	// 		},
	// 		x: 0,
	// 		y: 0,
	// 		margin: [30, 30, 30, 30],
	// 		autoPaging: 'text',
	// 		html2canvas: {
	// 			scale: 0.7,
	// 			scrollY: -window.scrollY,
	// 			width: 900,
	// 			height: window.outerHeight + window.innerHeight,
	// 			windowHeight: window.outerHeight + window.innerHeight,
	// 		},
	// 	};

	// 	doc.html(document.getElementById('print'), opt);
	// };

	const handleDownloadPDF = async () => {
		if (docRef.current) {
			setisLoading(true);
			await domtoimage
				.toPng(docRef.current, {
					quality: 1,
					scale: 3,
					bgcolor: '#ffffff',
				})
				.then((dataUrl) => {
					const img = new Image();
					img.src = dataUrl;
					img.onload = () => {
						const pdf = new jsPDF('p', 'mm', 'a4');
						const pdfWidth = pdf.internal.pageSize.getWidth();
						const pdfHeight = pdf.internal.pageSize.getHeight();
						const imgWidth = img.width;
						const imgHeight = img.height;

						const scaledHeight = (pdfWidth / imgWidth) * imgHeight;

						let yPosition = 0;

						while (yPosition < scaledHeight) {
							pdf.addImage(
								img,
								'PNG',
								0,
								-yPosition,
								pdfWidth,
								scaledHeight
							);

							yPosition += pdfHeight;

							if (yPosition < scaledHeight) {
								pdf.addPage();
							}
						}

						var file = pdf.output('blob');
						let data = new FormData();
						data.append('file', file);

						axios
							.post(`${url}/nea/saveFile.action`, data)
							.then((resp) => {
								if (resp?.status === 200) {
									window.open(
										formatImgPath(resp?.data?.jsonData),
										'_blank'
									);
									setisLoading(false);
								} else {
									message.error('Error generating PDF');
									setisLoading(false);
								}
							})
							.catch(() => {
								message.error('Error uploading PDF');
								setisLoading(false);
							});
					};
				});
		}
	};

	const onFinish = async () => {
		let cvCopy = { ...cvData };

		if (cvCopy?.cvLanguages) {
			cvCopy.cvLanguages = cvCopy?.cvLanguages?.toString();
		}

		if (cvCopy?.cvIconMain) {
			delete cvCopy.cvIconMain;
		}

		cvCopy.cvDocName = cvCopy?.cvDocName ? cvCopy?.cvDocName : docTitle;

		if (docRef.current) {
			setisLoading(true);
			await domtoimage
				.toPng(docRef.current, {
					quality: 1,
					scale: 3,
					bgcolor: '#ffffff',
				})
				.then((dataUrl) => {
					const img = new Image();
					img.src = dataUrl;
					img.onload = () => {
						const pdf = new jsPDF('p', 'mm', 'a4');
						const pdfWidth = pdf.internal.pageSize.getWidth();
						const pdfHeight = pdf.internal.pageSize.getHeight();
						const imgWidth = img.width;
						const imgHeight = img.height;

						const scaledHeight = (pdfWidth / imgWidth) * imgHeight;

						let yPosition = 0;

						while (yPosition < scaledHeight) {
							pdf.addImage(
								img,
								'PNG',
								0,
								-yPosition,
								pdfWidth,
								scaledHeight
							);

							yPosition += pdfHeight;

							if (yPosition < scaledHeight) {
								pdf.addPage();
							}
						}

						var file = pdf.output('blob');
						let data = new FormData();
						data.append('file', file);

						axios
							.post(`${url}/nea/saveFile.action`, data)
							.then(async (resp) => {
								if (resp?.status === 200) {
									cvCopy.cvFile = resp?.data?.jsonData;
									cvCopy.cvUpdatedDate = new Date();
									cvCopy.cvTempId = cvTempId;

									const res = await dispatch(
										saveApplicantCv(cvCopy)
									);

									if (res?.payload?.success) {
										await handleDispatch(
											res?.payload?.jsonData
										);
										await handleFetchCV();
										await setisLoading(true);
										await dispatch(clearCVData());
										await form.resetFields();
										await navigate('/cv-cover-letter');
									} else {
										toast.custom(
											'Could not process your request at the moment. Please try again laster'
										);
									}
									setisLoading(false);
								} else {
									message.error('Error generating PDF');
									setisLoading(false);
								}
							})
							.catch(() => {
								message.error('Error uploading PDF');
								setisLoading(false);
							});
					};
				});
		}
	};

	useEffect(() => {
		handleDispatch(data);
	}, [data]);

	useEffect(() => {}, [cvData]);

	if (window.innerWidth < 1280) {
		return (
			<div className='w-full h-[70vh] flex justify-center items-center text-center flex-col gap-5'>
				<h1>This feature is not available on small screen devices</h1>
				<Link className='btn btn-primary' to='/cv-cover-letter'>
					Go back
				</Link>
			</div>
		);
	}

	return (
		<>
			<div className='p-3 lg:p-0 lg:mr-[38px] lg:ml-[22px] mt-[15px]'>
				<JumbotronOne
					text1={`CV/Cover Letter`}
					text2={''}
					text3={''}
					text4={'A strong CV will help you stand out to employers.'}
				/>

				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: '100%',
					}}
					onFinishFailed={({ errorFields }) => {
						if (errorFields?.length) {
							sethasError(true);
						} else {
							sethasError(false);
						}
					}}
					form={form}
					onValuesChange={(values) => {
						handleChange(values);
					}}
					initialValues={{
						...cvData,
						cvUsrName: user?.usrFirstName,
						cvMail: user?.usrEmail,
						cvTitle: user?.usrOtherTitle,
						cvPhone: user?.usrMobileNumber,
						cvBio: user?.usrBio,
						cvLanguages: user?.usrLanguages,
					}}
					disabled={cvLoading}>
					<div className='bg-white pl-[2.5rem] pr-[1rem] pt-[2.5rem] pb-[7rem]'>
						<div ref={view} className=''>
							<Breadcrumb
								items={[
									{
										title: (
											<Link to='/cv-cover-letter'>
												CV/Cover Letter
											</Link>
										),
									},
									{
										title: 'CV Builder',
									},
								]}
							/>
						</div>

						<div className='w-full flex items-center justify-between mt-[3.38rem] '>
							<div className='w-[27.9375rem]'>
								{editingHeader ? (
									<Input
										value={docTitle}
										onBlur={() => seteditingHeader(false)}
										onChange={(e) => handleCVDocName(e)}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												handleSaveDocNameOnEnter(
													e.target.value
												);
											}
										}}
										placeholder={docTitle}
										className='input'
									/>
								) : (
									<>
										<div className='flex items-center'>
											<span className='heading_heading_4'>
												{docTitle
													? docTitle
													: cvData?.cvDocName
													? cvData?.cvDocName
													: 'Resume 1'}
											</span>
											<button
												className='ml-[1rem]'
												onClick={() =>
													seteditingHeader(true)
												}
												type='button'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'>
													<path
														d='M8.68969 20.2501H4.5C4.30109 20.2501 4.11032 20.1711 3.96967 20.0305C3.82902 19.8898 3.75 19.699 3.75 19.5001V15.3104C3.75009 15.1118 3.82899 14.9213 3.96938 14.7807L15.5306 3.2195C15.6713 3.07895 15.862 3 16.0608 3C16.2596 3 16.4503 3.07895 16.5909 3.2195L20.7806 7.40637C20.9212 7.54701 21.0001 7.7377 21.0001 7.93653C21.0001 8.13535 20.9212 8.32605 20.7806 8.46668L9.21937 20.0307C9.07883 20.1711 8.88834 20.25 8.68969 20.2501Z'
														stroke='#212121'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M12.75 6L18 11.25'
														stroke='#212121'
														strokeWidth='2'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											</button>
										</div>
									</>
								)}
							</div>

							<div className='w-full flex justify-end'>
								<div className='flex items-center w-fit'>
									<Button
										disabled={cvLoading || isLoading}
										onClick={handleDownloadPDF}
										className='blue-outline-btn'
										type='button'>
										{isLoading ? (
											<Spin className='blue-spin' />
										) : (
											<div className='flex items-center gap-2'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='25'
													height='24'
													viewBox='0 0 25 24'
													fill='none'>
													<path
														d='M23.6853 11.6962C23.6525 11.6222 22.8584 9.86062 21.0931 8.09531C18.7409 5.74312 15.77 4.5 12.5 4.5C9.22999 4.5 6.25905 5.74312 3.90687 8.09531C2.14155 9.86062 1.34374 11.625 1.31468 11.6962C1.27203 11.7922 1.25 11.896 1.25 12.0009C1.25 12.1059 1.27203 12.2097 1.31468 12.3056C1.34749 12.3797 2.14155 14.1403 3.90687 15.9056C6.25905 18.2569 9.22999 19.5 12.5 19.5C15.77 19.5 18.7409 18.2569 21.0931 15.9056C22.8584 14.1403 23.6525 12.3797 23.6853 12.3056C23.7279 12.2097 23.75 12.1059 23.75 12.0009C23.75 11.896 23.7279 11.7922 23.6853 11.6962ZM12.5 18C9.61437 18 7.09343 16.9509 5.00655 14.8828C4.15028 14.0313 3.42179 13.0603 2.84374 12C3.42164 10.9396 4.15014 9.9686 5.00655 9.11719C7.09343 7.04906 9.61437 6 12.5 6C15.3856 6 17.9066 7.04906 19.9934 9.11719C20.8514 9.9684 21.5815 10.9394 22.1609 12C21.485 13.2619 18.5403 18 12.5 18ZM12.5 7.5C11.61 7.5 10.7399 7.76392 9.99993 8.25839C9.2599 8.75285 8.68313 9.45566 8.34253 10.2779C8.00194 11.1002 7.91282 12.005 8.08646 12.8779C8.26009 13.7508 8.68867 14.5526 9.31801 15.182C9.94735 15.8113 10.7492 16.2399 11.6221 16.4135C12.495 16.5872 13.3998 16.4981 14.2221 16.1575C15.0443 15.8169 15.7471 15.2401 16.2416 14.5001C16.7361 13.76 17 12.89 17 12C16.9988 10.8069 16.5242 9.66303 15.6806 8.81939C14.837 7.97575 13.6931 7.50124 12.5 7.5ZM12.5 15C11.9066 15 11.3266 14.8241 10.8333 14.4944C10.3399 14.1648 9.95542 13.6962 9.72835 13.1481C9.50129 12.5999 9.44188 11.9967 9.55764 11.4147C9.67339 10.8328 9.95911 10.2982 10.3787 9.87868C10.7982 9.45912 11.3328 9.1734 11.9147 9.05764C12.4967 8.94189 13.0999 9.0013 13.648 9.22836C14.1962 9.45542 14.6648 9.83994 14.9944 10.3333C15.324 10.8266 15.5 11.4067 15.5 12C15.5 12.7956 15.1839 13.5587 14.6213 14.1213C14.0587 14.6839 13.2956 15 12.5 15Z'
														fill='#1B47B4'
													/>
												</svg>
												<span className='ml-[.2rem]'>
													Preview
												</span>
											</div>
										)}
									</Button>

									<Button
										disabled={cvLoading || isLoading}
										// onClick={handleSaveCV}
										className='blue-outline-btn  ml-[.5rem]'
										htmlType='submit'>
										{cvLoading ? <Spin /> : 'Save & exit'}
									</Button>
								</div>
							</div>
						</div>

						<div className='flex items-start gap-x-[2.5rem] w-full mt-[2.06rem] min-h-[100vh] h-auto'>
							<div className='h-full flex flex-shrink-0 2xl:w-[27.9375rem] w-[20rem]'>
								<Tabs
									className='tabless w-full'
									activeKey={current}
									items={cvTabs}
								/>
							</div>

							<div
								ref={docRef}
								className='py-[.88rem] px-[.5rem] h-full w-auto sticky top-0 min-h-[100vh] bg-white'
								style={{
									borderRadius: '0.875rem',
									border: '1px solid var(--Border-secondary, #E0E0E0)',
									background: '#FFF',
								}}>
								{cvTempId === 1 && <CVPreview item={data} />}

								{cvTempId === 2 && <BlueTemplate item={data} />}

								{cvTempId === 3 && (
									<OrangeTemplate item={data} />
								)}
							</div>
						</div>

						<div className='w-full flex justify-center gap-[.5rem] items-center mt-[6rem]'>
							{parseInt(current) > 1 && (
								<button
									onClick={handleBack}
									type='button'
									className='resume-back-btn'>
									Back
								</button>
							)}

							{parseInt(current) < 7 && (
								<button
									onClick={handleNext}
									className='!w-[15rem] resume-next-btn'
									type='button'>
									Next
								</button>
							)}
						</div>
					</div>
				</Form>
			</div>
		</>
	);
}
