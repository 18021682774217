import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	fetchSaveJobs,
	saveSaveJob,
} from '../../../features/job/jobSlice';
import { customToast } from '../../../notifications';
import { deleteSaveJob } from '../../../features/delete/deleteSlice';
import locationBlue from '../../../assets/location_blue.svg';
import timeBlue from '../../../assets/time_blue.svg';
import { formatImgPath } from '../../../utils';
import SocialShareModal from '../../../components/SocialShareModal';

export default function ApplicationStatusSmallCard({
	item,
	status,
	border,
	arrow,
	background,
	color,
	handleChange,
	tabKey,
	borderSm,
}) {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { appliedJobs, savedJobs } = useSelector((state) => state.job);

	const [open, setopen] = useState(false);
	const [activeJob, setactiveJob] = useState({});

	let saved = savedJobs?.filter(
		(x) =>
			Number(x.josJobId) ===
			Number(item?.jobId || item?.jbrJobId || item?.josJobId || item?.japJobId)
	);

	function handleCancel() {
		setopen(false);
		setactiveJob({});
	}

	async function handleSaveJob() {
		const jobObj = {
			josUsrId: user?.usrId,
			josJobId: item?.jobId || item?.jbrJobId || item?.japJobId,
			josCreatedBy: user?.usrId,
			josDesc: item?.jobInstType,
		};

		const res = await dispatch(saveSaveJob(jobObj));
		if (res?.payload?.success) {
			await customToast({
				content: 'Job saved',
				bdColor: 'success',
				header: 'Success',
			});
			await dispatch(fetchSaveJobs(jobObj));
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	}

	async function handleDeleteSaveJob() {
		const jobObj = {
			josUsrId: user?.usrId,
		};
		const josId = saved[0]?.josId;
		const res = await dispatch(deleteSaveJob(josId));
		if (res?.payload?.success) {
			await dispatch(fetchSaveJobs(jobObj));
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	}

	useEffect(() => {}, [appliedJobs]);

	return (
		<>
			<div
				style={{
					borderRadius: '0.9375rem',
					border: status === 'You have applied' ? '1px solid #1B47B4' : border,
				}}
				className='bg-[#F5F7FF] w-full h-auto py-[1.87rem] pl-[1.87rem] flex flex-col items-start justify-start mb-[2.5rem]'>
				<div className='flex w-full items-start justify-between'>
					<div className='flex items-center w-full'>
						<div className='w-[3.75rem] h-auto'>
							{item?.jobCntIcon || item?.cntIcon ? (
								<img
									loading='lazy'
									className='object-contain'
									src={formatImgPath(item?.jobCntIcon ?? item?.cntIcon)}
									alt='Job advert icon'
								/>
							) : (
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='60'
									height='60'
									viewBox='0 0 60 60'
									fill='none'>
									<mask
										id='mask0_10956_7844'
										style={{ maskType: 'luminance' }}
										maskUnits='userSpaceOnUse'
										x='0'
										y='0'
										width='60'
										height='60'>
										<path
											d='M59.6042 0.296875H0.198242V59.7028H59.6042V0.296875Z'
											fill='white'
										/>
									</mask>
									<g mask='url(#mask0_10956_7844)'>
										<mask
											id='mask1_10956_7844'
											style={{ maskType: 'luminance' }}
											maskUnits='userSpaceOnUse'
											x='0'
											y='1'
											width='59'
											height='58'>
											<path
												d='M58.4639 1.02148H0.506836V58.9785H58.4639V1.02148Z'
												fill='white'
											/>
										</mask>
										<g mask='url(#mask1_10956_7844)'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M37.5352 17.9526C35.1524 16.3605 32.3511 15.5107 29.4854 15.5107V1.02148C35.2168 1.02148 40.8195 2.72105 45.585 5.90525C50.3505 9.08946 54.0647 13.6152 56.2581 18.9103C58.4513 24.2054 59.0252 30.0321 57.9071 35.6534C56.789 41.2747 54.029 46.4382 49.9762 50.4908C45.9236 54.5436 40.7601 57.3036 35.1388 58.4217C29.5175 59.5398 23.6908 58.9659 18.3957 56.7726C13.1006 54.5793 8.57481 50.8651 5.3906 46.0996C2.2064 41.3341 0.506836 35.7314 0.506836 30H14.9961C14.9961 32.8657 15.8459 35.667 17.438 38.0498C19.0301 40.4326 21.293 42.2897 23.9406 43.3864C26.5881 44.483 29.5015 44.7699 32.3121 44.2108C35.1227 43.6518 37.7044 42.2718 39.7309 40.2455C41.7572 38.219 43.1372 35.6373 43.6961 32.8267C44.2553 30.0161 43.9684 27.1027 42.8717 24.4552C41.775 21.8076 39.9179 19.5447 37.5352 17.9526Z'
												fill='#007DFC'
											/>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M14.9961 1.02149C14.9961 2.92424 14.6213 4.80837 13.8932 6.56626C13.165 8.3242 12.0978 9.9215 10.7523 11.2669C9.40685 12.6124 7.80954 13.6796 6.05166 14.4078C4.29371 15.1359 2.40958 15.5107 0.506837 15.5107L0.506836 30C4.31234 30 8.08061 29.2505 11.5964 27.7941C15.1123 26.3379 18.3069 24.2033 20.9977 21.5123C23.6887 18.8215 25.8232 15.627 27.2795 12.1111C28.7359 8.59527 29.4854 4.82699 29.4854 1.02148L14.9961 1.02149Z'
												fill='#007DFC'
											/>
										</g>
									</g>
								</svg>
							)}
						</div>
						<span className='heading_heading_company_name_small w-full truncate ml-[0.63rem]'>
							{item?.cntName ?? item?.jobCntName}
						</span>
					</div>

					<div className='flex items-center justify-end w-full overflow-hidden mr-[1.12rem]'>
						{saved?.length ? (
							<button
								style={{
									background: '#EDF8FF',
								}}
								onClick={() => handleDeleteSaveJob()}
								className='rounded-full p-[0.44rem] ml-[1.25rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='25'
									viewBox='0 0 24 25'
									fill='none'>
									<path
										d='M17.25 3.5H6.75C6.35218 3.5 5.97064 3.65804 5.68934 3.93934C5.40804 4.22064 5.25 4.60218 5.25 5V21.5C5.25007 21.6338 5.28595 21.7652 5.35393 21.8805C5.42191 21.9958 5.5195 22.0908 5.63659 22.1557C5.75367 22.2206 5.88598 22.2529 6.01978 22.2494C6.15358 22.2458 6.284 22.2066 6.3975 22.1356L12 18.6341L17.6034 22.1356C17.7169 22.2063 17.8472 22.2454 17.9809 22.2488C18.1146 22.2522 18.2467 22.2198 18.3636 22.155C18.4806 22.0902 18.5781 21.9953 18.646 21.8801C18.7139 21.7649 18.7498 21.6337 18.75 21.5V5C18.75 4.60218 18.592 4.22064 18.3107 3.93934C18.0294 3.65804 17.6478 3.5 17.25 3.5Z'
										fill='#1B47B4'
									/>
								</svg>
							</button>
						) : (
							<button
								style={{
									background: '#EDF8FF',
								}}
								onClick={() => handleSaveJob()}
								className='rounded-full p-[0.44rem] ml-[1.25rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='25'
									viewBox='0 0 24 25'
									fill='none'>
									<path
										d='M18 21.5L12 17.75L6 21.5V5C6 4.80109 6.07902 4.61032 6.21967 4.46967C6.36032 4.32902 6.55109 4.25 6.75 4.25H17.25C17.4489 4.25 17.6397 4.32902 17.7803 4.46967C17.921 4.61032 18 4.80109 18 5V21.5Z'
										stroke='#1B47B4'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</button>
						)}
					</div>
				</div>

				<span className='heading_heading_job_name_small w-full truncate mt-[1.84rem]'>
					{item?.jobPosition}
				</span>

				<div
					style={{
						width: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'break-spaces',
					}}
					className='flex items-center mt-[1.61rem] truncate'>
					<div className='flex items-center mr-[0.94rem] w-full'>
						<img src={locationBlue} alt='calendar' />
						<span className='heading_heading_job_details ml-[0.5rem]'>
							{item?.jobCity}, {item?.jobCountry}
						</span>
					</div>

					<div className='flex items-center mr-[0.94rem] w-full'>
						<img src={timeBlue} alt='time' />
						<span className='heading_heading_job_details ml-[0.5rem]'>
							{item?.jobEmploymentTerms}
						</span>
					</div>
				</div>

				<button
					style={{
						background: background,
						color: color,
					}}
					onClick={() => handleChange(tabKey)}
					className={`job-btn !flex !flex-row !items-center !justify-center mt-[1rem]`}>
					{status}
				</button>
			</div>
			<SocialShareModal
				activeJob={activeJob}
				open={open}
				handleCancel={handleCancel}
			/>
		</>
	);
}
