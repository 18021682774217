import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './antd.css';
import './responsiveness.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import { HashRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import 'react-tabs/style/react-tabs.css';
import 'react-quill/dist/quill.snow.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-phone-input-2/lib/style.css';
import '@splidejs/react-splide/css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<HashRouter>
				<App />
				<Toaster
					position='top-center'
					reverseOrder={false}
					gutter={8}
					containerClassName=''
					containerStyle={{}}
					toastOptions={{
						// Define default options
						className: '',
						duration: 3000,
						style: {
							background: '#363636',
							color: '#fff',
						},

						// Default options for specific types
						custom: {
							duration: 3000,
						},
					}}
				/>
			</HashRouter>
		</PersistGate>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
