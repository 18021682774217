import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Tabs } from 'antd';
import { fetchJobs } from '../../features/job/jobSlice';
import ScrollTo from '../../ScrollTo';
import JumbotronOne from '../../components/JambotronOne';
import RecommendedJobs from '../dashboard/dash_steps/RecommendedJobs';
import AllJobs from './job_tabs/AllJobs.js';
import FeaturedJobs from './job_tabs/FeaturedJobs.js';
import MatchingJobs from './job_tabs/MatchingJobs.js';
import NewJobs from './job_tabs/NewJobs.js';

const profileTabs = [
	{
		label: <span className='label_label_2'>All jobs</span>,
		key: '1',
		children: <AllJobs />,
	},
	{
		label: <span className='label_label_2'>Featured</span>,
		key: '2',
		children: <FeaturedJobs />,
	},
	{
		label: <span className='label_label_2'>Recommended</span>,
		key: '3',
		children: <RecommendedJobs jobVacancy={true} />,
	},
	{
		label: <span className='label_label_2'>Matching</span>,
		key: '4',
		children: <MatchingJobs />,
	},
	{
		label: <span className='label_label_2'>New</span>,
		key: '5',
		children: <NewJobs />,
	},
];

export default function JobVacancy() {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);

	const [name, setname] = useState('');

	const filterObj = {
		searchValue: '',
		position: name,
		limit: 10,
		start: 0,
	};

	async function handleFetchJobs() {
		await dispatch(fetchJobs(filterObj));
	}

	const onNameChange = async (e) => {
		await setname(e.target.value);
	};

	useEffect(() => {
		if (name.length === 0) {
			handleFetchJobs();
		}
	}, [name]);

	return (
		<>
			<ScrollTo />
			<div className='p-3 lg:p-0 lg:mr-[38px] lg:ml-[22px] mt-[15px]'>
				<JumbotronOne
					text1={`Hi ${user?.usrFirstName}, find your`}
					text2={'next job'}
					text3={'today.'}
					text4={'Search and apply for the job that interests you.'}
				/>

				<div className='bg-white py-[.8rem]'>
					<div
						style={{
							background: 'rgba(255, 255, 255, 0.80)',
						}}
						className='w-full flex flex-col px-[.5rem] xl:px-[2.5rem] py-[.8rem]'>

						<div className='flex items-center mt-[1.2rem] w-full xl:w-[52rem]'>
							<Input
								onPressEnter={handleFetchJobs}
								suffix={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='12'
										height='13'
										viewBox='0 0 12 13'
										fill='none'>
										<path
											d='M10 4.41016L6 8.41016L2 4.41016'
											stroke='#1A1C28'
										/>
									</svg>
								}
								className='search-input min-w-[12rem] xl:min-w-[31.25rem]'
								placeholder='Search jobs by position, location, company'
								onChange={onNameChange}
							/>

							<button
								onClick={handleFetchJobs}
								className='bg-blueDark p-[1rem] rounded-full w-[3.5rem] h-[3.5rem] flex justify-center items-center ml-[.5rem] lg:ml-[2rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='38'
									height='38'
									viewBox='0 0 38 38'
									fill='none'>
									<path
										d='M17.4167 30.0833C24.4123 30.0833 30.0833 24.4123 30.0833 17.4167C30.0833 10.4211 24.4123 4.75 17.4167 4.75C10.4211 4.75 4.75 10.4211 4.75 17.4167C4.75 24.4123 10.4211 30.0833 17.4167 30.0833Z'
										stroke='white'
										strokeWidth='3.16667'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M33.2498 33.2508L26.3623 26.3633'
										stroke='white'
										strokeWidth='3.16667'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</button>
						</div>
					</div>

					<div className='mt-[1rem] mb-[5rem]'>
						<Tabs
							className='jobs-tab'
							defaultActiveKey='1'
							items={profileTabs}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
