import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JobCardUpdated from '../../../components/JobCardUpdated';
import { fetchFeaturedJobs } from '../../../features/job/jobSlice';
import { Pagination } from 'antd';
import JobFilter from '../JobFilter';
import JobCardSmall from '../../../components/JobCardSmall';

export default function FeaturedJobs() {
	const dispatch = useDispatch();
	const { featuredJobs, featuredJobsCount } = useSelector(
		(state) => state.job
	);

	const ref = useRef();

	const [jobList, setjobList] = useState(featuredJobs);
	const [pageIndex, setPageIndex] = useState(0);
	const [pageSize, setPageSize] = useState(10);

	const filterObj = {
		jobFeatured: 'TRUE',
		limit: pageSize,
		start: pageIndex,
	};

	async function handleFetchJobs() {
		await dispatch(fetchFeaturedJobs(filterObj));
	}

	useEffect(() => {}, [jobList]);

	useEffect(() => {
		handleFetchJobs();
	}, [pageIndex, pageSize]);

	useEffect(() => {
		setjobList(featuredJobs);
	}, [featuredJobs]);

	const itemRender = (_, type, originalElement) => {
		if (type === 'prev') {
			return (
				<a className='black-outline-btn'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<path
							d='M11.2908 12.0036L14.8308 8.46361C15.017 8.27625 15.1216 8.0228 15.1216 7.75861C15.1216 7.49442 15.017 7.24097 14.8308 7.05361C14.7378 6.95988 14.6272 6.88549 14.5054 6.83472C14.3835 6.78395 14.2528 6.75781 14.1208 6.75781C13.9888 6.75781 13.8581 6.78395 13.7362 6.83472C13.6144 6.88549 13.5038 6.95988 13.4108 7.05361L9.1708 11.2936C9.07707 11.3866 9.00268 11.4972 8.95191 11.619C8.90114 11.7409 8.875 11.8716 8.875 12.0036C8.875 12.1356 8.90114 12.2663 8.95191 12.3882C9.00268 12.51 9.07707 12.6206 9.1708 12.7136L13.4108 17.0036C13.5042 17.0963 13.6151 17.1696 13.7369 17.2194C13.8587 17.2691 13.9892 17.2944 14.1208 17.2936C14.2524 17.2944 14.3829 17.2691 14.5047 17.2194C14.6265 17.1696 14.7374 17.0963 14.8308 17.0036C15.017 16.8162 15.1216 16.5628 15.1216 16.2986C15.1216 16.0344 15.017 15.781 14.8308 15.5936L11.2908 12.0036Z'
							fill='black'
						/>
					</svg>
					<span className='black-btn-text ml-[0.25rem] !text-black21'>
						Previous
					</span>
				</a>
			);
		}
		if (type === 'next') {
			return (
				<a className='black-btn'>
					<span className='black-btn-text mr-[0.25rem]'>Next</span>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<path
							d='M14.8336 11.2936L10.5936 7.05361C10.5006 6.95988 10.39 6.88549 10.2682 6.83472C10.1463 6.78395 10.0156 6.75781 9.88361 6.75781C9.75159 6.75781 9.62089 6.78395 9.49903 6.83472C9.37717 6.88549 9.26657 6.95988 9.1736 7.05361C8.98735 7.24097 8.88281 7.49442 8.88281 7.75861C8.88281 8.0228 8.98735 8.27625 9.1736 8.46361L12.7136 12.0036L9.1736 15.5436C8.98735 15.731 8.88281 15.9844 8.88281 16.2486C8.88281 16.5128 8.98735 16.7662 9.1736 16.9536C9.26705 17.0463 9.37786 17.1196 9.4997 17.1694C9.62154 17.2191 9.752 17.2444 9.88361 17.2436C10.0152 17.2444 10.1457 17.2191 10.2675 17.1694C10.3893 17.1196 10.5002 17.0463 10.5936 16.9536L14.8336 12.7136C14.9273 12.6206 15.0017 12.51 15.0525 12.3882C15.1033 12.2663 15.1294 12.1356 15.1294 12.0036C15.1294 11.8716 15.1033 11.7409 15.0525 11.619C15.0017 11.4972 14.9273 11.3866 14.8336 11.2936Z'
							fill='white'
						/>
					</svg>
				</a>
			);
		}
		return originalElement;
	};

	return (
		<>
			<div
				ref={ref}
				className='flex items-start justify-start p-[.75rem]'>
				<div className='w-full mt-[2.5rem]'>
					<div className='w-full h-[2.5rem] ml-[2.5rem] mb-[2.38rem] flex items-center'>
						<span className='heading_heading_5 !text-black21'>
							Featured jobs
						</span>
					</div>
					{jobList?.length === 0 ? (
						<>
							<div className='px-[2.5rem]'>
								<h2 className='heading_heading_2'>
									Found 0 jobs
								</h2>
								<h2 className='heading_heading_4 mt-[2rem]'>
									No jobs matching the criteria was found
								</h2>
							</div>
						</>
					) : null}

					<div className='hidden lg:flex lg:flex-col w-full mt-[1rem]'>
						{jobList?.map((item, index) => {
							return <JobCardUpdated key={index} item={item} />;
						})}
					</div>

					<div className='flex flex-col lg:hidden w-full mt-[1rem]'>
						{jobList?.map((item, index) => {
							return <JobCardSmall key={index} item={item} />;
						})}
					</div>

					<div className='mt-[3.75rem] flex justify-center items-center'>
						<Pagination
							responsive
							simple
							current={pageIndex + 1}
							showSizeChanger={false}
							hideOnSinglePage
							total={featuredJobsCount}
							pageSize={pageSize}
							itemRender={itemRender}
							onChange={(page, size) => {
								setPageIndex(page - 1);
								setPageSize(size);
								ref?.current?.scrollIntoView();
							}}
						/>
					</div>
				</div>
				<div className='hidden lg:flex mt-[3.45rem]'>
					<JobFilter />
				</div>
			</div>
		</>
	);
}
