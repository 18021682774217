import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, InputNumber } from 'antd';
import toast from 'react-hot-toast';
import {
	clearActivePerson,
	fetchCVContactPersons,
	saveContactPerson,
	setActivePerson,
	setPersonData,
} from '../../../features/person/personSllice';

export default function CreateReference({ handleCurrent }) {
	const dispatch = useDispatch();
	const [formEdu] = Form.useForm();
	const [formEduEdit] = Form.useForm();
	const formRef = useRef(null);

	const { cvData } = useSelector((state) => state.cv);
	const { user } = useSelector((state) => state.auth);
	const { personCV, personData } = useSelector((state) => state.prs);

	const [addEducation, setaddEducation] = useState(false);
	const [educationList, seteducationList] = useState(personCV);
	const [editedItem, seteditedItem] = useState({});
	const [editing, setediting] = useState(false);
	const [data, setdata] = useState({});

	const usrObj = {
		usrId: user?.usrId,
		conCvlId: cvData?.cvId,
	};

	const handleChange = (e) => {
		seteditedItem((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	const handleEditNumberChange = (e) => {
		seteditedItem((prevData) => ({
			...prevData,
			conMobileNumber: e,
		}));
	};

	async function handleCancel() {
		await seteditedItem({});
		await formEdu.resetFields();
		await setediting(false);
		await setaddEducation(false);
		await setdata({});
		await dispatch(clearActivePerson());
	}

	async function handleaddNew() {
		await seteditedItem({});
		await setediting(false);
		await setaddEducation(true);
	}

	async function handleUpdatePreview(item) {
		await dispatch(setActivePerson(item));
	}

	async function handleValuesChange(values) {
		setdata((prevData) => ({
			...prevData,
			...values,
		}));
	}

	async function handleEditingChange(values) {
		let eduArr = personData?.map((item) =>
			item?.eduId === values?.eduId ? { ...values } : item
		);
		await dispatch(setPersonData(eduArr));
	}

	const onFinish = async (values) => {
		values.conCvlId = cvData?.cvId;
		values.conType = 'CV';

		values.conUsrId = user?.usrId;

		const res = await dispatch(saveContactPerson(values));

		if (res?.payload?.success) {
			const resp = await dispatch(fetchCVContactPersons(usrObj));

			if (resp?.payload?.success) {
				await seteducationList(resp?.payload?.data?.result);
				await dispatch(setPersonData(resp?.payload?.data?.result));
				await dispatch(clearActivePerson());
			}

			await setaddEducation(false);
		} else {
			toast.error(
				'Could not handle your request at the moment. Please try again later'
			);
		}
	};

	const onFinish2 = async () => {
		await dispatch(saveContactPerson(editedItem));
		const resp = await dispatch(fetchCVContactPersons(usrObj));

		if (resp?.payload?.success) {
			await seteducationList(resp?.payload?.data?.result);
			await dispatch(setPersonData(resp?.payload?.data?.result));
		}

		await setaddEducation(false);
	};

	async function handleFetchEducations() {
		const resp = await dispatch(fetchCVContactPersons(usrObj));

		if (resp?.payload?.success) {
			await seteducationList(resp?.payload?.data?.result);
			await dispatch(setPersonData(resp?.payload?.data?.result));
		}
	}

	async function handleUpdateList(e, value) {
		e.preventDefault();
		if (personData?.find((item) => item?.conId === value?.conId)) {
			let newArr = personData?.filter((item) => item?.conId !== value?.conId);
			await dispatch(setPersonData(newArr));
		} else {
			await dispatch(setPersonData([value, ...personData]));
		}
	}

	useEffect(() => {
		handleUpdatePreview(data);
	}, [data]);

	useEffect(() => {
		handleEditingChange(editedItem);
	}, [editedItem]);

	useEffect(() => {}, [educationList]);

	useEffect(() => {
		seteducationList(personCV);
	}, [personCV]);

	useEffect(() => {
		handleFetchEducations();
	}, []);

	return (
		<>
			<div
				className='py-[2.88rem] px-[2.5rem] mt-[1.25rem]'
				style={{
					borderRadius: '0.875rem',
					border: '1px solid var(--Border-secondary, #E0E0E0)',
					background: '#FFF',
				}}>
				<h3 className='heading_heading_4'>Referees</h3>
				<p className='paragraph_paragraph_2 mt-[1rem] !text-black333'>
					Add professionals that can vouch for you
				</p>

				{educationList?.length && !addEducation ? (
					<>
						{educationList?.map((item, index) => {
							return (
							
									<div key={index} className='bg-white mt-[2rem]'>
										<div className='w-full flex flex-col'>
											<div className='flex justify-between items-center'>
												<h3 className='text-darkBlue text-[19px] font-bold'>
													{item?.conDesc}
												</h3>

												{personData?.filter((x) => x?.conId === item?.conId)
													.length === 0 ? (
													<button
														onClick={(e) => handleUpdateList(e, item)}
														type='button'>
														<div className='border-2 border-[#02A548] rounded-full p-1'>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 32 32'
																fill='none'>
																<path
																	fillRule='evenodd'
																	clipRule='evenodd'
																	d='M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z'
																	fill='#02A548'
																/>
																<path
																	fillRule='evenodd'
																	clipRule='evenodd'
																	d='M16 4C16.5523 4 17 4.44772 17 5V27C17 27.5523 16.5523 28 16 28C15.4477 28 15 27.5523 15 27V5C15 4.44772 15.4477 4 16 4Z'
																	fill='#02A548'
																/>
															</svg>
														</div>
													</button>
												) : null}

												{personData?.filter((x) => x?.conId === item?.conId)
													.length > 0 ? (
													<button
														onClick={(e) => handleUpdateList(e, item)}
														type='button'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='20'
															height='20'
															viewBox='0 0 12 12'
															fill='none'>
															<path
																d='M7.40799 5.99628L11.708 1.70628C11.8963 1.51798 12.0021 1.26258 12.0021 0.996279C12.0021 0.729978 11.8963 0.474583 11.708 0.286279C11.5197 0.0979755 11.2643 -0.0078125 10.998 -0.0078125C10.7317 -0.0078125 10.4763 0.0979755 10.288 0.286279L5.99799 4.58628L1.70799 0.286279C1.51968 0.0979755 1.26429 -0.0078125 0.997986 -0.0078125C0.731684 -0.0078125 0.47629 0.0979755 0.287986 0.286279C0.0996821 0.474583 -0.0061059 0.729978 -0.0061059 0.996279C-0.0061059 1.26258 0.0996821 1.51798 0.287986 1.70628L4.58799 5.99628L0.287986 10.2863C0.194257 10.3792 0.119863 10.4898 0.0690947 10.6117C0.018326 10.7336 -0.0078125 10.8643 -0.0078125 10.9963C-0.0078125 11.1283 0.018326 11.259 0.0690947 11.3809C0.119863 11.5027 0.194257 11.6133 0.287986 11.7063C0.380949 11.8 0.49155 11.8744 0.613409 11.9252C0.735268 11.9759 0.865974 12.0021 0.997986 12.0021C1.13 12.0021 1.2607 11.9759 1.38256 11.9252C1.50442 11.8744 1.61502 11.8 1.70799 11.7063L5.99799 7.40628L10.288 11.7063C10.3809 11.8 10.4915 11.8744 10.6134 11.9252C10.7353 11.9759 10.866 12.0021 10.998 12.0021C11.13 12.0021 11.2607 11.9759 11.3826 11.9252C11.5044 11.8744 11.615 11.8 11.708 11.7063C11.8017 11.6133 11.8761 11.5027 11.9269 11.3809C11.9776 11.259 12.0038 11.1283 12.0038 10.9963C12.0038 10.8643 11.9776 10.7336 11.9269 10.6117C11.8761 10.4898 11.8017 10.3792 11.708 10.2863L7.40799 5.99628Z'
																fill='#C8001B'
															/>
														</svg>
													</button>
												) : null}
											</div>

											<span className='text-black333 font-medium text-[18px]'>
												{item?.conName}
											</span>
											<span className='text-black333 font-normal text-[15px]'>
												{item?.conMobileNumber}
											</span>

											<span className='text-black2 font-normal text-[18px] text-justify'>
												{item?.conEmail}
											</span>
										</div>
									</div>
							
							);
						})}

						<div className='mt-10'></div>
					</>
				) : !addEducation && !educationList?.length ? (
					<>
						<div className='my-[7rem]'></div>
					</>
				) : editing ? (
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish2}
						style={{
							maxWidth: '100%',
							marginTop: 20,
						}}
						form={formEduEdit}>
						<div className='mb-5'>
							<div className='grid grid-cols-1'>
								<div className='flex flex-col'>
									<label className='mb-2'>Full Names</label>
									<Input
										value={editedItem?.conName}
										onChange={handleChange}
										name='conName'
										className='input'
									/>
								</div>

								<div className='flex flex-col'>
									<label className='mb-2'>Company/Institution</label>
									<Input
										value={editedItem?.conDesc}
										onChange={handleChange}
										name='conDesc'
										className='input'
									/>
								</div>

								<div className='flex flex-col'>
									<label className='mb-2'>Phone Number</label>
									<InputNumber
										value={
											editedItem?.conMobileNumber?.length > 10
												? editedItem?.conMobileNumber?.slice(3)
												: editedItem?.conMobileNumber
										}
										maxLength={10}
										minLength={9}
										onChange={handleEditNumberChange}
										className='input'
									/>
								</div>

								<div className='flex flex-col'>
									<label className='mb-2'>Email</label>
									<Input
										type='email'
										value={editedItem?.conEmail}
										onChange={handleChange}
										name='conEmail'
										className='input'
									/>
								</div>
							</div>
						</div>

						<div className='flex items-center justify-end mt-10 mb-14 w-full'>
							<button
								onClick={handleCancel}
								className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
								type='button'>
								Cancel
							</button>
							<button
								className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
								type='button'
								onClick={() => formEduEdit.submit()}>
								Save
							</button>
						</div>
					</Form>
				) : (
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: '100%',
							marginTop: 20,
						}}
						form={formEdu}
						onValuesChange={(values) => {
							handleValuesChange(values);
						}}>
						<div className='mb-5'>
							<div className='grid grid-cols-1'>
								<Form.Item
									name='conName'
									label='Full Names'
									rules={[
										{
											required: true,
											message: 'Name is required',
										},
									]}
									className='w-full'>
									<Input className='input' />
								</Form.Item>

								<Form.Item
									name='conDesc'
									label='Company/Intitution'
									rules={[
										{
											required: false,
											message: 'Name is required',
										},
									]}
									className='w-full'>
									<Input className='input' />
								</Form.Item>

								<Form.Item
									label='Phone Number'
									name='conMobileNumber'
									rules={[
										{
											required: false,
											message: 'Phone number is required',
										},
									]}
									className='w-full'>
									<InputNumber
										maxLength={10}
										minLength={9}
										// onChange={handleNumberChange}
										className='input'
									/>
								</Form.Item>

								<Form.Item name='conEmail' label='Email' className='w-full'>
									<Input type='email' className='input' />
								</Form.Item>
							</div>
						</div>

						<div className='flex items-center justify-end mt-10 mb-14 w-full'>
							<button
								onClick={handleCancel}
								className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
								type='button'>
								Cancel
							</button>
							<button
								onClick={() => formEdu.submit()}
								className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
								type='button'>
								Save
							</button>
						</div>
					</Form>
				)}

				<div className='flex justify-center w-full'>
					{addEducation ? null : (
						<button onClick={handleaddNew} className='blue-outline-btn'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='25'
								height='24'
								viewBox='0 0 25 24'
								fill='none'>
								<path
									d='M11.75 18.75V12.75H5.75V11.25H11.75V5.25H13.25V11.25H19.25V12.75H13.25V18.75H11.75Z'
									fill='#1B47B4'
								/>
							</svg>
							<span className='ml-[.5rem]'>Add more referees</span>
						</button>
					)}
				</div>
			</div>
		</>
	);
}
