import Header from '../../../layouts/Header';
import LandingPage from './new_home_modules/LandingPage';
import LandingPartTwo from './new_home_modules/LandingPartTwo';
import LandingPartThree from './new_home_modules/LandingPartThree';
import LandingPartFour from './new_home_modules/LandingPartFour';
import LandingPartFive from './new_home_modules/LandingPartFive';
import LandingPartSix from './new_home_modules/LandingPartSix';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchHomepageCount,
	fetchLandingPageSetups,
} from '../../../features/global/globalSlice';
import { useEffect, useRef, useState } from 'react';
import { logout } from '../../../features/auth/authSlice';
import MobileHeader from '../../../layouts/MobileHeader';

export default function MainHome() {
	const dispatch = useDispatch();
	const { notices } = useSelector((state) => state.global);

	const [noticeObj, setnoticeObj] = useState({});
	const [open, setopen] = useState(false);

	const two = useRef();
	const three = useRef();
	const four = useRef();
	const five = useRef();

	function handleOpen() {
		setopen(!open);
	}

	function handleTwo() {
		two?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	}

	function handleThree() {
		three?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	}

	function handleFour() {
		four?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	}

	async function handleFetch() {
		await dispatch(logout());
		await dispatch(fetchLandingPageSetups());
		await dispatch(fetchHomepageCount());
	}

	function showRandomNotice() {
		if (notices?.length) {
			const randomIndex = Math.floor(Math.random() * notices?.length);
			setnoticeObj(notices[randomIndex]);
		}
	}

	useEffect(() => {
		showRandomNotice();
	}, [notices]);

	useEffect(() => {
		handleFetch();
	}, []);

	return (
		<>
			{/* <div className='sticky top-0 z-[10000]'> */}
			<div className=''>
				<div
					style={{
						flexShrink: 0,
						height: '3.125rem',
					}}
					className='p-[1.09rem] flex items-center justify-center w-full  bg-[#E2E8FF]'>
					<span className='heading_heading_notice animated-notice'>
						{Object.keys(noticeObj).length
							? noticeObj?.lpsTitle + ': ' + noticeObj?.lpsBody
							: 'Notices from NEAIMS will show up here'}
					</span>
				</div>
				<MobileHeader
					onClose={handleOpen}
					open={open}
					handleOpen={handleOpen}
					handleTwo={handleTwo}
					handleThree={handleThree}
					handleFour={handleFour}
				/>
				<Header
					handleOpen={handleOpen}
					handleTwo={handleTwo}
					handleThree={handleThree}
					handleFour={handleFour}
				/>
			</div>

			<LandingPage />

			<div ref={two}>
				<LandingPartTwo />
			</div>

			<div ref={three}>
				<LandingPartThree />
			</div>

			<div ref={four}>
				<LandingPartFour />
			</div>

			<div ref={five}>
				<LandingPartFive />
			</div>

			<LandingPartSix />
		</>
	);
}
