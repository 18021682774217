import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProfileDescriptionModal from '../modals/ProfileDescriptionModal';
import moment from 'moment';

export default function ProfileDescription({ profileApplication }) {
	const { user } = useSelector((state) => state.auth);

	const [open, setopen] = useState(false);

	function handleCancel() {
		setopen(false);
	}

	useEffect(() => {}, [user]);

	return (
		<>
			<div className='bg-white px-[.75rem] lg:px-[2.5rem] py-[1.2rem] w-full'>
				<div className='flex justify-between items-center'>
					<span className='heading_heading_4'>Personal information </span>
					{profileApplication ? null : (
						<button onClick={() => setopen(true)}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'>
								<path
									d='M11.5863 27.0002H6C5.73478 27.0002 5.48043 26.8948 5.29289 26.7073C5.10536 26.5197 5 26.2654 5 26.0002V20.4139C5.00012 20.149 5.10532 19.8951 5.2925 19.7077L20.7075 4.29266C20.895 4.10527 21.1493 4 21.4144 4C21.6795 4 21.9337 4.10527 22.1213 4.29266L27.7075 9.87516C27.8949 10.0627 28.0002 10.3169 28.0002 10.582C28.0002 10.8471 27.8949 11.1014 27.7075 11.2889L12.2925 26.7077C12.1051 26.8948 11.8511 27 11.5863 27.0002Z'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M17 8L24 15'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</button>
					)}
				</div>

				<div
					className='mt-[1.5rem]'
					style={{
						width: '100%',
						height: '0.0625rem',
						background: 'rgba(216, 216, 216, 0.54)',
					}}></div>

				<div className='w-full mt-[2rem] grid grid-cols-2 lg:grid-cols-4 gap-[2.75rem]'>
					<div className='flex flex-col'>
						<span className='label_label_1'>Full Name</span>
						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrFirstName} {user?.usrMiddleName} {user?.usrLastName}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>National ID No.</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrNationalId}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Passport No.</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrPassportNo ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col w-full'>
						<span className='label_label_1'>Email</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrEmail}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Nationality</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrNationality ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>County</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrCounty ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Registration Type</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrType}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Mobile No.</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrMobileNumber}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Date of birth</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{moment(user?.usrDob).format('DD/MM/YYYY') ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Marital status</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrMaritalStatus ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Ethnicity</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrEthnicity ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Gender</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrGender ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Employment Status</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrEmploymentStatus}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Internship Status</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{user?.usrInternStatus ?? 'N/A'}
						</span>
					</div>
				</div>
			</div>

			<ProfileDescriptionModal open={open} handleCancel={handleCancel} />
		</>
	);
}
