import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applyJob, fetchAppliedJobs } from '../../../features/job/jobSlice';
import { customToast } from '../../../notifications';
import { formatImgPath, getDaysPast } from '../../../utils';
import clip_board_pen from '../../../assets/clip_board_pen.svg';
import { Input, Modal } from 'antd';

const { TextArea } = Input;

export default function OfferInfoCard({ item, handleBack }) {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { appliedJobs } = useSelector((state) => state.job);

	const [open, setopen] = useState(false);
	const [open2, setopen2] = useState(false);
	const [declineReason, setdeclineReason] = useState('');

	const usrObj = {
		japUsrId: user?.usrId,
		japProgressStatus: '',
	};

	function handleCancel() {
		setopen(false);
		setopen2(false);
	}

	async function handleFetchJobs() {
		await dispatch(fetchAppliedJobs(usrObj));
	}

	async function handleRejectInterview() {
		let jobObj = {
			...item,
			japProgressStatus: 'DECLINE',
			japDesc: declineReason,
		};

		const res = await dispatch(applyJob(jobObj));
		if (res?.payload?.success) {
			await customToast({
				content: 'Interviewer has been updated on your decision',
				bdColor: 'success',
				header: 'Success',
			});
			await handleFetchJobs();
			await handleCancel();
			await handleBack();
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	}

	async function handleConfirmAvailability() {
		let jobObj = {
			...item,
			japProgressStatus: 'HIRE',
		};

		const res = await dispatch(applyJob(jobObj));
		if (res?.payload?.success) {
			await customToast({
				content: 'Interviewer has been updated on your decision',
				bdColor: 'success',
				header: 'Success',
			});
			await handleFetchJobs();
			await handleCancel();
			await handleBack();
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	}

	useEffect(() => {}, [appliedJobs]);

	return (
		<>
			<div
				style={{
					border: '1px solid #1B47B4',
					borderRadius: '0.5rem',
				}}
				className='bg-white w-full h-auto p-[1.94rem] flex items-start justify-start mb-[2.5rem]'>
				<div className='w-fit'>
					{item?.jobCntIcon || item?.cntIcon ? (
						<img
							loading='lazy'
							className='w-[3.75rem] h-[3.71288rem]'
							src={formatImgPath(item?.jobCntIcon ?? item?.cntIcon)}
							alt='Job advert icon'
						/>
					) : (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='60'
							height='60'
							viewBox='0 0 60 60'
							fill='none'>
							<mask
								id='mask0_10956_7844'
								style={{ maskType: 'luminance' }}
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='60'
								height='60'>
								<path
									d='M59.6042 0.296875H0.198242V59.7028H59.6042V0.296875Z'
									fill='white'
								/>
							</mask>
							<g mask='url(#mask0_10956_7844)'>
								<mask
									id='mask1_10956_7844'
									style={{ maskType: 'luminance' }}
									maskUnits='userSpaceOnUse'
									x='0'
									y='1'
									width='59'
									height='58'>
									<path
										d='M58.4639 1.02148H0.506836V58.9785H58.4639V1.02148Z'
										fill='white'
									/>
								</mask>
								<g mask='url(#mask1_10956_7844)'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M37.5352 17.9526C35.1524 16.3605 32.3511 15.5107 29.4854 15.5107V1.02148C35.2168 1.02148 40.8195 2.72105 45.585 5.90525C50.3505 9.08946 54.0647 13.6152 56.2581 18.9103C58.4513 24.2054 59.0252 30.0321 57.9071 35.6534C56.789 41.2747 54.029 46.4382 49.9762 50.4908C45.9236 54.5436 40.7601 57.3036 35.1388 58.4217C29.5175 59.5398 23.6908 58.9659 18.3957 56.7726C13.1006 54.5793 8.57481 50.8651 5.3906 46.0996C2.2064 41.3341 0.506836 35.7314 0.506836 30H14.9961C14.9961 32.8657 15.8459 35.667 17.438 38.0498C19.0301 40.4326 21.293 42.2897 23.9406 43.3864C26.5881 44.483 29.5015 44.7699 32.3121 44.2108C35.1227 43.6518 37.7044 42.2718 39.7309 40.2455C41.7572 38.219 43.1372 35.6373 43.6961 32.8267C44.2553 30.0161 43.9684 27.1027 42.8717 24.4552C41.775 21.8076 39.9179 19.5447 37.5352 17.9526Z'
										fill='#007DFC'
									/>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M14.9961 1.02149C14.9961 2.92424 14.6213 4.80837 13.8932 6.56626C13.165 8.3242 12.0978 9.9215 10.7523 11.2669C9.40685 12.6124 7.80954 13.6796 6.05166 14.4078C4.29371 15.1359 2.40958 15.5107 0.506837 15.5107L0.506836 30C4.31234 30 8.08061 29.2505 11.5964 27.7941C15.1123 26.3379 18.3069 24.2033 20.9977 21.5123C23.6887 18.8215 25.8232 15.627 27.2795 12.1111C28.7359 8.59527 29.4854 4.82699 29.4854 1.02148L14.9961 1.02149Z'
										fill='#007DFC'
									/>
								</g>
							</g>
						</svg>
					)}
				</div>

				<div className='ml-[0.63rem] w-full'>
					<div className='flex justify-between items-center'>
						<div className='flex flex-col'>
							<span className='label_label_3'>
								Posted {getDaysPast(item?.jobAdvertStartDate)}
							</span>
							<span className='heading_heading_4 my-[.25rem]'>
								{item?.jobPosition}
							</span>
							<span className='heading_heading_company_name'>
								{item?.jobCntName ?? item?.cntName}
							</span>
						</div>
					</div>

					<div className='flex justify-between items-start mt-[1.25rem]'>
						<div className='flex items-center'>
							<div
								style={{
									borderRadius: '1.25rem',
									background: 'rgba(125, 90, 226, 0.10)',
								}}
								className='h-[2.125rem] w-fit flex justify-center items-center px-[0.75rem]'>
								<span className='label_label_3 !text-[#7D5AE2]'>
									Availability: {item?.jobStartPeriod}
								</span>
							</div>

							<div
								style={{
									borderRadius: '1.25rem',
									background: 'rgba(125, 90, 226, 0.10)',
								}}
								className='h-[2.125rem] w-fit flex justify-center items-center px-[0.75rem] ml-[0.62rem]'>
								<span className='label_label_3 !text-[#7D5AE2]'>
									{item?.jobExperience}
								</span>
							</div>
						</div>
					</div>

					{item?.japProgressStatus === 'HIRE' ? (
						<div className='w-full flex justify-end'>
							<div className='flex items-center justify-center p-[0.75rem] bg-[#F0FFF0] w-fit rounded-[0.625rem]'>
								<svg
									className='mt-1'
									xmlns='http://www.w3.org/2000/svg'
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'>
									<g filter='url(#filter0_d_11616_9109)'>
										<circle cx='8' cy='4' r='4' fill='#02A548' />
									</g>
									<defs>
										<filter
											id='filter0_d_11616_9109'
											x='0'
											y='0'
											width='16'
											height='16'
											filterUnits='userSpaceOnUse'
											color-interpolation-filters='sRGB'>
											<feFlood flood-opacity='0' result='BackgroundImageFix' />
											<feColorMatrix
												in='SourceAlpha'
												type='matrix'
												values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
												result='hardAlpha'
											/>
											<feOffset dy='4' />
											<feGaussianBlur stdDeviation='2' />
											<feComposite in2='hardAlpha' operator='out' />
											<feColorMatrix
												type='matrix'
												values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
											/>
											<feBlend
												mode='normal'
												in2='BackgroundImageFix'
												result='effect1_dropShadow_11616_9109'
											/>
											<feBlend
												mode='normal'
												in='SourceGraphic'
												in2='effect1_dropShadow_11616_9109'
												result='shape'
											/>
										</filter>
									</defs>
								</svg>
								<span className='label_label_3 !text-[#02A548] ml-[0.5rem]'>
									You got the job!
								</span>
							</div>
						</div>
					) : null}

					<div className='flex flex-col'>
						{item?.japValidityStartDate && (
							<span className='label_label_1 mt-[1rem]'>
								This is when you are expected to start
							</span>
						)}

						<div className='mt-[1.59rem]'>
							<div className='mb-10'>
								{item?.japValidityStartDate && (
									<div className='flex items-center'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='22'
											height='22'
											viewBox='0 0 22 22'
											fill='none'>
											<path
												d='M17.875 3.4375H4.125C3.7453 3.4375 3.4375 3.7453 3.4375 4.125V17.875C3.4375 18.2547 3.7453 18.5625 4.125 18.5625H17.875C18.2547 18.5625 18.5625 18.2547 18.5625 17.875V4.125C18.5625 3.7453 18.2547 3.4375 17.875 3.4375Z'
												stroke='url(#paint0_linear_11536_40617)'
												strokeWidth='1.375'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M15.125 2.0625V4.8125'
												stroke='url(#paint1_linear_11536_40617)'
												strokeWidth='1.375'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M6.875 2.0625V4.8125'
												stroke='url(#paint2_linear_11536_40617)'
												strokeWidth='1.375'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M3.4375 7.5625H18.5625'
												stroke='url(#paint3_linear_11536_40617)'
												strokeWidth='1.375'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<defs>
												<linearGradient
													id='paint0_linear_11536_40617'
													x1='11'
													y1='3.4375'
													x2='11'
													y2='18.5625'
													gradientUnits='userSpaceOnUse'>
													<stop stopColor='#4276FC' />
													<stop offset='0.0001' stopColor='#3D73FF' />
													<stop offset='1' stopColor='#1844B5' />
												</linearGradient>
												<linearGradient
													id='paint1_linear_11536_40617'
													x1='15.625'
													y1='2.0625'
													x2='15.625'
													y2='4.8125'
													gradientUnits='userSpaceOnUse'>
													<stop stopColor='#4276FC' />
													<stop offset='0.0001' stopColor='#3D73FF' />
													<stop offset='1' stopColor='#1844B5' />
												</linearGradient>
												<linearGradient
													id='paint2_linear_11536_40617'
													x1='7.375'
													y1='2.0625'
													x2='7.375'
													y2='4.8125'
													gradientUnits='userSpaceOnUse'>
													<stop stopColor='#4276FC' />
													<stop offset='0.0001' stopColor='#3D73FF' />
													<stop offset='1' stopColor='#1844B5' />
												</linearGradient>
												<linearGradient
													id='paint3_linear_11536_40617'
													x1='11'
													y1='7.5625'
													x2='11'
													y2='8.5625'
													gradientUnits='userSpaceOnUse'>
													<stop stopColor='#4276FC' />
													<stop offset='0.0001' stopColor='#3D73FF' />
													<stop offset='1' stopColor='#1844B5' />
												</linearGradient>
											</defs>
										</svg>

										<span className='label_label_2 ml-[0.5rem] !text-[#1A1C28]'>
											Start date:{' '}
											{moment(item?.japValidityStartDate).format('Do MMM YYYY')}
										</span>
									</div>
								)}

								{item?.japOfferLetterFile ? (
									<div className='flex items-center mt-[1.31rem]'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='22'
											height='22'
											viewBox='0 0 22 22'
											fill='none'>
											<path
												d='M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z'
												stroke='url(#paint0_linear_11536_40633)'
												strokeWidth='1.375'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M12.2161 10.3581H15.125C15.5047 10.3581 15.8125 10.6659 15.8125 11.0456C15.8125 11.4253 15.5047 11.7331 15.125 11.7331H12.1637C11.9202 12.119 11.4901 12.3754 11 12.3754C10.2406 12.3754 9.625 11.7598 9.625 11.0004C9.625 10.4915 9.90151 10.0471 10.3125 9.80932V6.00391C10.3125 5.62421 10.6203 5.31641 11 5.31641C11.3797 5.31641 11.6875 5.62421 11.6875 6.00391V9.80932C11.9111 9.93869 12.095 10.1293 12.2161 10.3581Z'
												fill='url(#paint1_linear_11536_40633)'
											/>
											<defs>
												<linearGradient
													id='paint0_linear_11536_40633'
													x1='11'
													y1='2.75'
													x2='11'
													y2='19.25'
													gradientUnits='userSpaceOnUse'>
													<stop stopColor='#4276FC' />
													<stop offset='0.0001' stopColor='#3D73FF' />
													<stop offset='1' stopColor='#1844B5' />
												</linearGradient>
												<linearGradient
													id='paint1_linear_11536_40633'
													x1='12.7188'
													y1='12.3754'
													x2='12.7188'
													y2='5.31641'
													gradientUnits='userSpaceOnUse'>
													<stop stopColor='#4276FC' />
													<stop offset='0.0001' stopColor='#3D73FF' />
													<stop offset='1' stopColor='#1844B5' />
												</linearGradient>
											</defs>
										</svg>

										<span className='label_label_2 ml-[0.5rem] !text-[#1A1C28] capitalize'>
											Offer letter:{' '}
											<a
												target='_blank'
												rel='noreferrer'
												href={formatImgPath(item?.japOfferLetterFile)}>
												Click to view
											</a>
										</span>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>

			{item?.japProgressStatus === 'HIRE' ? (
				<div
					style={{
						border: '1px solid #1B47B4',
						borderRadius: '0.5rem',
					}}
					className='bg-white w-full h-auto py-[2.5rem] pl-[3.75rem] flex flex-col mb-[2.5rem]'>
					<span className='label_label_1'>Interview Results</span>

					<div className='mt-[1.25rem] flex flex-col'>
						<div className='flex items-center'>
							<span className='label_label_2 mr-[0.5rem] !text-black14'>
								Score:{' '}
							</span>
							<button className='score-btn'>{item?.japInterviewScore}%</button>
						</div>

						<div className='mt-[1.5rem] flex flex-col'>
							<span className='label_label_2 !text-black14'>Comments: </span>
							<span className='label_label_2 !text-black14 mt-[.5rem]'>
								{item?.japInterviewRemarks}
							</span>
						</div>
					</div>
				</div>
			) : null}

			{item?.japProgressStatus === 'HIRE' ? (
				<>
					<div className='flex justify-end mt-[2rem] w-full'>
						<div className='flex items-center'>
							<button onClick={handleBack} className='blue-outline-btn !w-fit'>
								Back
							</button>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='flex flex-col-reverse gap-[1rem] lg:flex-row lg:gap-0 items-center justify-between mt-[2rem] w-full'>
						<button
							disabled={item?.japProgressStatus === 'HIRE'}
							type='button'
							onClick={() => setopen2(true)}
							className='int-no'>
							No longer interested
						</button>
						<div className='flex items-center'>
							<button onClick={handleBack} className='blue-outline-btn !w-fit'>
								Back
							</button>
							<button
								disabled={item?.japProgressStatus === 'HIRE'}
								onClick={() => setopen(true)}
								className='blue-btn text-white !w-fit ml-[0.5rem]'
								type='button'>
								Accept offer
							</button>
						</div>
					</div>
				</>
			)}

			<Modal
				title={'Accept job offer'}
				footer={null}
				open={open}
				okText={'Next'}
				onCancel={handleCancel}>
				<>
					<div className='w-full flex items-center mt-[5rem] flex-col'>
						<img
							className='w-[7.4rem] h-[7.1rem]'
							src={clip_board_pen}
							alt='clip_board_pen'
						/>
						<span className='heading_heading_5 mt-[1.25rem]'>Accept offer</span>
						<span className='paragraph_paragraph_2 !text-black333 mt-[0.625rem]'>
							Would you like to accept this job offer?{' '}
						</span>

						<div className='flex flex-col w-fit'>
							<button
								onClick={handleConfirmAvailability}
								className='blue-btn mt-[5rem]'>
								Yes, I accept
							</button>
							<button
								onClick={handleCancel}
								className='blue-outline-btn mt-[0.5rem]'>
								Cancel
							</button>
						</div>
					</div>
				</>
			</Modal>

			<Modal
				title={'No longer interested'}
				footer={null}
				open={open2}
				okText={'Next'}
				onCancel={() => setopen2(false)}>
				<>
					<div className='w-full flex flex-col relative'>
						<span className='heading_heading_5 mt-[2.5rem]'>
							Decline job offer?
						</span>
						<span className='paragraph_paragraph_2 !text-black333 mt-[2rem]'>
							Reason for decline
						</span>

						<TextArea
							placeholder='Enter reason'
							onChange={(e) => setdeclineReason(e.target.value)}
							className='w-full mt-[0.38rem]'
							rows={7}
						/>
					</div>

					<div className='w-full flex items-center justify-end absolute bottom-[7rem] right-10 '>
						<button onClick={handleCancel} className='no-outline-btn !w-fit'>
							Cancel
						</button>

						<button onClick={handleRejectInterview} className='blue-btn !w-fit'>
							Decline
						</button>
					</div>
				</>
			</Modal>
		</>
	);
}
