import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppliedJobs } from '../../../features/job/jobSlice';

import EmptyApplicationState from '../EmptyApplicationState';
import InterviewCard from '../job_cards/InterviewCard';
import InterviewInfoCard from '../job_cards/InterviewInfoCard';

export default function ApplicationInterviews() {
	const dispatch = useDispatch();

	const { interviewing } = useSelector((state) => state.job);
	const { user } = useSelector((state) => state.auth);

	const [jobList, setjobList] = useState(interviewing);
	const [showInterview, setshowInterview] = useState(false);
	const [activeInterview, setactiveInterview] = useState({});

	const usrObj = {
		japUsrId: user?.usrId,
		japProgressStatus: '',
		position: '',
	};

	function handleInterviewInfo(item) {
		setshowInterview(true);
		setactiveInterview(item);
	}

	function handleCancel() {
		setshowInterview(false);
		setactiveInterview({});
	}

	async function handleFetchJobs() {
		await dispatch(fetchAppliedJobs(usrObj));
	}

	useEffect(() => {}, [jobList]);

	useEffect(() => {
		setjobList(interviewing);
	}, [interviewing]);

	useEffect(() => {
		handleFetchJobs();
	}, []);

	return (
		<>
			{showInterview ? (
				<>
					<div className='mt-[1.2rem]'>
						<span className='heading_heading_5'>
							Your upcoming interview
						</span>
					</div>

					<div className='mt-[1rem] mb-[5rem]'>
						<InterviewInfoCard
							handleBack={handleCancel}
							item={activeInterview}
						/>
					</div>
				</>
			) : (
				<>
					<div className='mt-[1.2rem]'>
						<span className='heading_heading_5'>
							{jobList?.length
								? `Congratulations ${user?.usrFirstName}. You have been invited to an interview.`
								: 'Application interviews'}
						</span>
					</div>

					<div className='mt-[1rem] mb-[5rem]'>
						{jobList?.length ? (
							jobList?.map((item, index) => {
								return (
									<InterviewCard
										key={index}
										handleInterviewInfo={
											handleInterviewInfo
										}
										item={item}
									/>
								);
							})
						) : (
							<EmptyApplicationState info='Applications you’ve been invited for interviews will show up here' />
						)}
					</div>
				</>
			)}
		</>
	);
}
