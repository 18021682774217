import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, Form, Input, message, Spin, Tabs } from 'antd';
import {
	clearCVLData,
	fetchApplicantCoverLetters,
	saveApplicantCoverLetter,
	setCVLData,
} from '../../features/cv/cvSLice';
import { toast } from 'react-hot-toast';
import jsPDF from 'jspdf';
import axios from 'axios';
import CVLPreview from './CVLPreview';
import CoverLetter from './create_cvl_tabs/CoverLetter';
import JumbotronOne from '../../components/JambotronOne';
import domtoimage from 'dom-to-image-more';
import { formatImgPath } from '../../utils';

const url = process.env.REACT_APP_API_BASE_URL;

export default function CreateCVL() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { cvlData, cvLoading } = useSelector((state) => state.cv);
	const { user } = useSelector((state) => state.auth);

	const usrObj = {
		usrId: user?.usrId,
		cvlUsrId: user?.usrId,
	};

	const [editingHeader, seteditingHeader] = useState(false);
	const [docTitle, setdocTitle] = useState('Cover letter name');

	const [form] = Form.useForm();
	const formRef = useRef(null);
	const docRef = useRef(null);

	const [current, setcurrent] = useState('1');
	const [data, setdata] = useState({
		cvlUsrName: user?.usrFirstName,
		cvlMail: user?.usrEmail,
		cvlTitle: user?.usrOtherTitle,
		cvlPhone: user?.usrMobileNumber,
		cvlBio: user?.usrBio,
		cvlLanguages: user?.usrLanguages,
	});
	const [isLoading, setisLoading] = useState(false);

	function handleCurrent(val) {
		setcurrent(val);
	}

	async function handleDispatch(values) {
		await dispatch(setCVLData(values));
	}

	const handleChange = (values) => {
		setdata((prevData) => ({
			...prevData,
			...values,
			cvlCompanySignOff: values?.cvlUsrName ?? cvlData?.cvlUsrName,
		}));
	};

	const handleTextEditor = (val) => {
		setdata((prevData) => ({
			...prevData,
			...val,
		}));
		form.setFieldsValue(val);
	};

	const cvTabs = [
		{
			key: '1',
			children: (
				<CoverLetter
					data={data}
					docTitle={docTitle}
					handleCurrent={handleCurrent}
					handleTextEditor={handleTextEditor}
				/>
			),
		},
	];

	function handleCVDocName(e) {
		setdocTitle(e.target.value);
		let cvObj = {
			cvlDocName: e.target.value,
		};
		handleDispatch(cvObj);
	}

	async function handleSaveDocNameOnEnter(val) {
		let cvObj = {
			cvlDocName: val,
		};
		await handleDispatch(cvObj);
		await seteditingHeader(false);
	}

	async function handleFetchCV() {
		await dispatch(fetchApplicantCoverLetters(usrObj));
	}

	const handleDownloadPDF = async () => {
		if (docRef.current) {
			setisLoading(true);
			await domtoimage
				.toPng(docRef.current, {
					quality: 1,
					scale: 3,
					bgcolor: '#ffffff',
				})
				.then((dataUrl) => {
					const img = new Image();
					img.src = dataUrl;
					img.onload = () => {
						const pdf = new jsPDF('p', 'mm', 'a4');
						const pdfWidth = pdf.internal.pageSize.getWidth();
						const pdfHeight = pdf.internal.pageSize.getHeight();
						const imgWidth = img.width;
						const imgHeight = img.height;

						const scaledHeight = (pdfWidth / imgWidth) * imgHeight;

						let yPosition = 0;

						while (yPosition < scaledHeight) {
							pdf.addImage(
								img,
								'PNG',
								0,
								-yPosition,
								pdfWidth,
								scaledHeight
							);

							yPosition += pdfHeight;

							if (yPosition < scaledHeight) {
								pdf.addPage();
							}
						}

						var file = pdf.output('blob');
						let data = new FormData();
						data.append('file', file);

						axios
							.post(`${url}/nea/saveFile.action`, data)
							.then((resp) => {
								if (resp?.status === 200) {
									window.open(
										formatImgPath(resp?.data?.jsonData),
										'_blank'
									);
									setisLoading(false);
								} else {
									message.error('Error generating PDF');
									setisLoading(false);
								}
							})
							.catch(() => {
								message.error('Error uploading PDF');
								setisLoading(false);
							});
					};
				});
		}
	};

	const onFinish = async () => {
		let cvCopy = { ...cvlData, cvlUsrId: user?.usrId };

		cvCopy.cvlDocName = cvCopy?.cvlDocName ? cvCopy?.cvlDocName : docTitle;
		cvCopy.cvlCompanySignOff = cvCopy?.cvlUsrName;

		if (docRef.current) {
			setisLoading(true);
			await domtoimage
				.toPng(docRef.current, {
					quality: 1,
					scale: 3,
					bgcolor: '#ffffff',
				})
				.then((dataUrl) => {
					const img = new Image();
					img.src = dataUrl;
					img.onload = () => {
						const pdf = new jsPDF('p', 'mm', 'a4');
						const pdfWidth = pdf.internal.pageSize.getWidth();
						const pdfHeight = pdf.internal.pageSize.getHeight();
						const imgWidth = img.width;
						const imgHeight = img.height;

						const scaledHeight = (pdfWidth / imgWidth) * imgHeight;

						let yPosition = 0;

						while (yPosition < scaledHeight) {
							pdf.addImage(
								img,
								'PNG',
								0,
								-yPosition,
								pdfWidth,
								scaledHeight
							);

							yPosition += pdfHeight;

							if (yPosition < scaledHeight) {
								pdf.addPage();
							}
						}

						var file = pdf.output('blob');
						let data = new FormData();
						data.append('file', file);

						axios
							.post(`${url}/nea/saveFile.action`, data)
							.then(async (resp) => {
								if (resp?.status === 200) {
									cvCopy = { ...cvCopy, cvlFile: resp?.data?.jsonData };

									const res = await dispatch(
										saveApplicantCoverLetter(cvCopy)
									);

									if (res?.payload?.success) {
										await handleDispatch(
											res?.payload?.jsonData
										);
										await handleFetchCV();
										await setisLoading(true);
										await dispatch(clearCVLData());
										await form.resetFields();
										await navigate('/cv-cover-letter');
									} else {
										toast.custom(
											'Could not process your request at the moment. Please try again laster'
										);
									}
									setisLoading(false);
								} else {
									message.error('Error generating PDF');
									setisLoading(false);
								}
							})
							.catch(() => {
								message.error('Error uploading PDF');
								setisLoading(false);
							});
					};
				});
		}
	};

	useEffect(() => {
		handleDispatch(data);
	}, [data]);

	if (window.innerWidth < 1280) {
		return (
			<div className='w-full h-[70vh] flex justify-center items-center text-center flex-col gap-5'>
				<h1>This feature is not available on small screen devices</h1>
				<Link className='btn btn-primary' to='/cv-cover-letter'>
					Go back
				</Link>
			</div>
		);
	}

	return (
		<>
			<div className='p-3 lg:p-0 lg:mr-[38px] lg:ml-[22px] mt-[15px]'>
				<JumbotronOne
					text1={`CV/Cover Letter`}
					text2={''}
					text3={''}
					text4={'A strong CV will help you stand out to employers.'}
				/>
				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: '100%',
					}}
					form={form}
					onValuesChange={(values) => {
						handleChange(values);
					}}
					scrollToFirstError
					initialValues={{
						...cvlData,
						cvlDate: null,
						cvlUsrName: user?.usrFirstName,
						cvlMail: user?.usrEmail,
						cvlTitle: user?.usrOtherTitle,
						cvlPhone: user?.usrMobileNumber,
						cvlBio: user?.usrBio,
						cvlLanguages: user?.usrLanguages,
					}}
					disabled={cvLoading}>
					<div className='bg-white pl-[2.5rem] pr-[1rem] pt-[2.5rem] pb-[7rem]'>
						<div className=''>
							<Breadcrumb
								items={[
									{
										title: (
											<Link to='/cv-cover-letter'>
												CV/Cover Letter
											</Link>
										),
									},
									{
										title: 'CV Builder',
									},
								]}
							/>
						</div>

						<div className='mt-[3.38rem] w-[27.9375rem]'>
							{editingHeader ? (
								<Input
									value={docTitle}
									onBlur={() => seteditingHeader(false)}
									onChange={(e) => handleCVDocName(e)}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											handleSaveDocNameOnEnter(
												e.target.value
											);
										}
									}}
									placeholder='Resume 1'
									className='input'
								/>
							) : (
								<>
									<div className='flex items-center'>
										<span className='cv-title w-full truncate'>
											{docTitle
												? docTitle
												: cvlData?.cvlDocName
												? cvlData?.cvlDocName
												: 'Resume 1'}
										</span>
										<button
											className='ml-5'
											onClick={() =>
												seteditingHeader(true)
											}
											type='button'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'>
												<g clipPath='url(#clip0_6805_834)'>
													<path
														d='M3 17.2525V21.0025H6.75L17.81 9.9425L14.06 6.1925L3 17.2525ZM20.71 7.0425C21.1 6.6525 21.1 6.0225 20.71 5.6325L18.37 3.2925C17.98 2.9025 17.35 2.9025 16.96 3.2925L15.13 5.1225L18.88 8.8725L20.71 7.0425Z'
														fill='black'
													/>
												</g>
												<defs>
													<clipPath id='clip0_6805_834'>
														<rect
															width='24'
															height='24'
															fill='white'
														/>
													</clipPath>
												</defs>
											</svg>
										</button>
									</div>
								</>
							)}
						</div>

						<div className='flex items-start gap-x-[2.5rem] w-full'>
							<div className='h-full flex flex-shrink-0 w-[27.9375rem]'>
								<Tabs
									className='tabless'
									activeKey={current}
									items={cvTabs}
								/>
							</div>

							<div
								ref={docRef}
								className='py-[.88rem] px-[.5rem] h-full w-full sticky top-0'
								style={{
									borderRadius: '0.875rem',
									border: '1px solid var(--Border-secondary, #E0E0E0)',
								}}>
								<CVLPreview item={data} />
							</div>
						</div>

						<div className='w-full flex justify-end mt-[2rem]'>
							<div className='flex items-center '>
								<Button
									disabled={cvLoading || isLoading}
									onClick={handleDownloadPDF}
									className='blue-outline-btn'
									type='button'>
									{isLoading ? (
										<Spin className='blue-spin' />
									) : (
										<div className='flex items-center gap-2'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='25'
												height='24'
												viewBox='0 0 25 24'
												fill='none'>
												<path
													d='M23.6853 11.6962C23.6525 11.6222 22.8584 9.86062 21.0931 8.09531C18.7409 5.74312 15.77 4.5 12.5 4.5C9.22999 4.5 6.25905 5.74312 3.90687 8.09531C2.14155 9.86062 1.34374 11.625 1.31468 11.6962C1.27203 11.7922 1.25 11.896 1.25 12.0009C1.25 12.1059 1.27203 12.2097 1.31468 12.3056C1.34749 12.3797 2.14155 14.1403 3.90687 15.9056C6.25905 18.2569 9.22999 19.5 12.5 19.5C15.77 19.5 18.7409 18.2569 21.0931 15.9056C22.8584 14.1403 23.6525 12.3797 23.6853 12.3056C23.7279 12.2097 23.75 12.1059 23.75 12.0009C23.75 11.896 23.7279 11.7922 23.6853 11.6962ZM12.5 18C9.61437 18 7.09343 16.9509 5.00655 14.8828C4.15028 14.0313 3.42179 13.0603 2.84374 12C3.42164 10.9396 4.15014 9.9686 5.00655 9.11719C7.09343 7.04906 9.61437 6 12.5 6C15.3856 6 17.9066 7.04906 19.9934 9.11719C20.8514 9.9684 21.5815 10.9394 22.1609 12C21.485 13.2619 18.5403 18 12.5 18ZM12.5 7.5C11.61 7.5 10.7399 7.76392 9.99993 8.25839C9.2599 8.75285 8.68313 9.45566 8.34253 10.2779C8.00194 11.1002 7.91282 12.005 8.08646 12.8779C8.26009 13.7508 8.68867 14.5526 9.31801 15.182C9.94735 15.8113 10.7492 16.2399 11.6221 16.4135C12.495 16.5872 13.3998 16.4981 14.2221 16.1575C15.0443 15.8169 15.7471 15.2401 16.2416 14.5001C16.7361 13.76 17 12.89 17 12C16.9988 10.8069 16.5242 9.66303 15.6806 8.81939C14.837 7.97575 13.6931 7.50124 12.5 7.5ZM12.5 15C11.9066 15 11.3266 14.8241 10.8333 14.4944C10.3399 14.1648 9.95542 13.6962 9.72835 13.1481C9.50129 12.5999 9.44188 11.9967 9.55764 11.4147C9.67339 10.8328 9.95911 10.2982 10.3787 9.87868C10.7982 9.45912 11.3328 9.1734 11.9147 9.05764C12.4967 8.94189 13.0999 9.0013 13.648 9.22836C14.1962 9.45542 14.6648 9.83994 14.9944 10.3333C15.324 10.8266 15.5 11.4067 15.5 12C15.5 12.7956 15.1839 13.5587 14.6213 14.1213C14.0587 14.6839 13.2956 15 12.5 15Z'
													fill='#1B47B4'
												/>
											</svg>
											<span className='ml-[.2rem]'>
												Preview
											</span>
										</div>
									)}
								</Button>

								<Button
									disabled={cvLoading || isLoading}
									// onClick={handleSaveCV}
									className='blue-btn  ml-[.5rem]'
									htmlType='submit'>
									{cvLoading ? <Spin /> : 'Save & exit'}
								</Button>
							</div>
						</div>
					</div>
				</Form>
			</div>
		</>
	);
}
