import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveForeignClearance } from '../../../features/job/jobSlice';
import { customToast } from '../../../notifications';
import { Radio } from 'antd';
import TextArea from 'antd/es/input/TextArea';

export default function ArrivalStatusDescription({ foreignJobActive }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [value, setValue] = useState(foreignJobActive?.japArrivalStatus);
	const [arrivalText, setarrivalText] = useState(
		foreignJobActive?.japArrivalComment
	);

	const onArrivalChange = async () => {
		const res = await dispatch(
			saveForeignClearance({
				japId: foreignJobActive?.japId,
				japArrivalStatus: value,
				japArrivalComment: arrivalText,
			})
		);
		if (res?.payload?.success) {
			await customToast({
				content: 'Arrival status updated successfully',
				bdColor: 'success',
				header: 'Success',
			});
			await navigate('/foreign-job-clearance');
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	};

	return (
		<>
			<div className='bg-white px-3 lg:px-[2.5rem] py-[2rem] w-full'>
				<div className='flex justify-between items-center'>
					<span className='heading_heading_4'>Arrival Status </span>
				</div>

				<div
					className='mt-[1.5rem]'
					style={{
						width: '100%',
						height: '0.0625rem',
						background: 'rgba(216, 216, 216, 0.54)',
					}}></div>

				<div className='mt-[2rem] grid grid-cols-1 gap-[1.5rem]'>
					<div className='w-full flex flex-col'>
						<h3 className='text-[22px] font-medium text-black2'>
							Have you arrived at {foreignJobActive?.jobCity},{' '}
							{foreignJobActive?.jobCountry}?
						</h3>

						<Radio.Group
							disabled={
								foreignJobActive?.japArrivalStatus ||
								foreignJobActive?.japProgressStatus !== 'CLEAR'
							}
							className='mt-5 mb-10'
							onChange={(e) => setValue(e.target.value)}
							value={value}>
							<Radio value={'ARRIVE'}>Arrived</Radio>
							<Radio className='ml-7' value={'NOT_ARRIVE'}>
								Not Arrived
							</Radio>
						</Radio.Group>

						{value === 'NOT_ARRIVE' ? (
							<>
								<label>Let us know where you are <br /> (This is only filled if you are experiencing challenges)</label>
								<TextArea
									readOnly={
										foreignJobActive?.japArrivalStatus ||
										foreignJobActive?.japProgressStatus !== 'CLEAR'
									}
									onChange={(e) => setarrivalText(e.target.value)}
									value={arrivalText}
									rows={5}
								/>
							</>
						) : null}

						{!value ? null : (
							<div className='mt-10 flex w-full items-center justify-center'>
								<div className='w-[10rem] flex flex-col items-center justify-center mt-5 gap-y-[.75rem]'>
									<button
										type='button'
										onClick={onArrivalChange}
										disabled={!value}
										className='bg-darkBlue rounded-[35px] text-white w-full p-2'>
										Submit
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
