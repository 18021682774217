import { Form, Input, Radio, Select, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import CountUp from 'react-countup';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { formatImgPath } from '../../../utils';
import { fetchDistressActions } from '../../../features/distress/distressSlice';

const { TextArea } = Input;

const url = process.env.REACT_APP_API_BASE_URL;

const assistanceList = [
	{
		label: 'Assistance required',
		value: 'Assistance required',
	},
];

export default function DistStepThree({
	prev,
	usrObj,
	onFinish,
	handleGetLocation,
	handleGetRecordings,
	handleGetOtherAssisatnce,
}) {
	const dispatch = useDispatch();
	const recorderControls = useAudioRecorder();

	const { distressLoading, distressActions } = useSelector(
		(state) => state.distress
	);

	const [recording, setrecording] = useState(false);
	const [count, setcount] = useState(0);
	const [paused, setpaused] = useState(false);
	const [audioUrl, setaudioUrl] = useState('');
	const [location, setlocation] = useState('');
	const [recState, setrecState] = useState(RecordState.NONE);
	const [loader, setloader] = useState(false);
	const [optionList, setoptionList] = useState(distressActions);
	const [edit, setedit] = useState(false);
	const [otherAssisatnce, setotherAssisatnce] = useState('');

	function getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				let lat = position.coords.latitude;
				let long = position.coords.longitude;
				setlocation(lat + ',' + long);
				handleGetLocation(lat + ',' + long);
			});
		} else {
			toast('Geolocation is not supported by this browser.');
		}
	}

	async function handleStartRecording() {
		// await setaudioUrl('');
		await recorderControls.startRecording();
	}

	function handleOtherChange(value) {
		if (value === 'Other') {
			setedit(true);
		} else {
			setedit(false);
			setotherAssisatnce('');
		}
	}

	function handleUpdateOtherReason(val) {
		handleGetOtherAssisatnce(val);
		setotherAssisatnce(val);
	}

	const onStop = async (audioData) => {
		const fData = new FormData();

		// fData.append("file", audioData?.blob);
		fData.append('file', audioData);

		await fetch(`${url}/nea/saveFile.action`, {
			method: 'POST',
			body: fData,
		})
			.then((res) => res.json())
			.then((data) => {
				if (data?.success) {
					setaudioUrl(URL.createObjectURL(audioData));
					handleGetRecordings(data?.jsonData);
				} else {
					setaudioUrl('');
					toast(
						'We could not upload your voice note at the moment. Please try again later'
					);
				}
			});
	};

	async function handleRemoveAudio() {
		await setaudioUrl('');
		await recorderControls.stopRecording;
	}

	async function handleFetchDistressActions() {
		await dispatch(fetchDistressActions());
	}

	const onChange = (e) => {
		// console.log('radio checked', e.target.value);
	};

	useEffect(() => {
		handleFetchDistressActions();
	}, []);

	useEffect(() => {
		setoptionList(distressActions);
	}, [distressActions]);

	useEffect(() => {
		if (!recorderControls.recordingBlob) return;
		onStop(recorderControls.recordingBlob);
	}, [recorderControls.recordingBlob]);

	return (
		<>
			<div className='flex flex-col justify-center w-full p-3'>
				<span className='distress-headers mb-10'>
					Hi {usrObj?.usrFirstName ?? ''}, please fill in the following details
				</span>
				<Form.Item
					name='dstDistressType'
					label='Complaint'
					rules={[
						{
							required: true,
							message: 'Select complaint type',
						},
					]}>
					<Select
						suffixIcon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
									fill='#212121'
								/>
							</svg>
						}
						loading={distressLoading}
						showSearch
						style={{
							width: '100%',
							height: '44px',
						}}
						options={optionList
							?.filter((item) => item?.disType === 'DISTRESS')
							.map((items) => {
								return {
									label: items?.disTitle,
									value: items?.disTitle,
								};
							})}
					/>
				</Form.Item>
				<Form.Item
					name='dstAssistance'
					label='Assistance required'
					rules={[
						{
							required: true,
							message: 'Select the assistance you require',
						},
					]}>
					<Select
						suffixIcon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
									fill='#212121'
								/>
							</svg>
						}
						loading={distressLoading}
						showSearch
						onChange={handleOtherChange}
						style={{
							width: '100%',
							height: '44px',
						}}
						options={optionList
							?.filter((item) => item?.disType === 'ASSISTANCE')
							.map((items) => {
								return {
									label: items?.disTitle,
									value: items?.disTitle,
								};
							})
							.reverse()}
					/>
				</Form.Item>

				{edit ? (
					<Form.Item
						label='Explain the kind of assistance you require'
						rules={[
							{
								required: false,
								message: 'Explain briefly the assistance you require',
							},
						]}
						className='w-full'>
						<TextArea
							onChange={(e) => handleUpdateOtherReason(e.target.value)}
							value={otherAssisatnce}
							rows={4}
						/>
					</Form.Item>
				) : null}

				<Form.Item
					name='dstReported'
					label='Have you reported to the embassy?'
					rules={[
						{
							required: true,
							message: 'Choose an option',
						},
					]}>
					<Radio.Group onChange={onChange} value={1}>
						<Radio value={true}>Yes</Radio>
						<Radio value={false}>No</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item
					label='What problem are you facing?'
					name='dstProblem'
					rules={[
						{
							required: false,
							message: 'Explain briefly the problem you are facing',
						},
					]}
					className='w-full'>
					<TextArea rows={4} />
				</Form.Item>

				<span className='text-center text-lg text-black'>or</span>

				<div className='flex justify-center w-full items-center my-5'>
					<Form.Item
						label=''
						name='dstVoiceNote'
						rules={[
							{
								required: false,
								message: 'Record an audio of the your current situation',
							},
						]}
						className='w-fit'>
						{recorderControls.isRecording ? (
							<>
								<div className='flex justify-start items-center border border-[#C0C0C0] rounded-[46px] w-full xl:w-[520px] py-2 px-7'>
									<Tooltip title='Save recording'>
										<button
											onClick={recorderControls.stopRecording}
											type='button'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='42'
												height='42'
												viewBox='0 0 42 42'
												fill='none'>
												<rect
													x='14.7031'
													y='14.7031'
													width='12.6'
													height='12.6'
													rx='2'
													fill='#E15534'
												/>
												<circle
													cx='21'
													cy='21'
													r='19'
													stroke='#E15534'
													strokeWidth='4'
												/>
											</svg>
										</button>
									</Tooltip>

									<div className='ml-5'>
										<AudioRecorder
											showVisualizer
											canvasWidth={380}
											canvasHeight={40}
											foregroundColor='#E15534'
											backgroundColor='#fff'
											type='audio'
											// state={recState} console.log("recording complete-------------------", blob)
											// onRecordingComplete={(blob) => onStop(blob)}
											recorderControls={recorderControls}
										/>
									</div>
								</div>
							</>
						) : // loader ? (
						// 	<>
						// 		<div className='flex justify-start items-center border border-[#147CBC] rounded-[46px] w-[520px] py-2 px-7'>
						// 			<Spin
						// 				className='!bg-darkBlue p-2 rounded-full'
						// 				size='large'
						// 			/>
						// 			<span className='ml-2 text-darkBlue'>
						// 				Processing audio...
						// 			</span>
						// 		</div>
						// 	</>
						// ) :

						audioUrl?.length && !recorderControls.isRecording ? (
							<>
								<div className='flex items-center'>
									<div className='flex justify-start items-center border border-[#147CBC] rounded-[46px] w-full xl:w-[520px] py-2 px-7'>
										<audio
											className='w-full'
											src={formatImgPath(audioUrl)}
											controls></audio>
									</div>

									<button
										onClick={handleRemoveAudio}
										className='flex items-center ml-7'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='19'
											height='20'
											viewBox='0 0 19 20'
											fill='none'>
											<path
												d='M7.5 16C7.76522 16 8.01957 15.8946 8.20711 15.7071C8.39464 15.5196 8.5 15.2652 8.5 15V9C8.5 8.73478 8.39464 8.48043 8.20711 8.29289C8.01957 8.10536 7.76522 8 7.5 8C7.23478 8 6.98043 8.10536 6.79289 8.29289C6.60536 8.48043 6.5 8.73478 6.5 9V15C6.5 15.2652 6.60536 15.5196 6.79289 15.7071C6.98043 15.8946 7.23478 16 7.5 16ZM17.5 4H13.5V3C13.5 2.20435 13.1839 1.44129 12.6213 0.87868C12.0587 0.316071 11.2956 0 10.5 0H8.5C7.70435 0 6.94129 0.316071 6.37868 0.87868C5.81607 1.44129 5.5 2.20435 5.5 3V4H1.5C1.23478 4 0.98043 4.10536 0.792893 4.29289C0.605357 4.48043 0.5 4.73478 0.5 5C0.5 5.26522 0.605357 5.51957 0.792893 5.70711C0.98043 5.89464 1.23478 6 1.5 6H2.5V17C2.5 17.7956 2.81607 18.5587 3.37868 19.1213C3.94129 19.6839 4.70435 20 5.5 20H13.5C14.2956 20 15.0587 19.6839 15.6213 19.1213C16.1839 18.5587 16.5 17.7956 16.5 17V6H17.5C17.7652 6 18.0196 5.89464 18.2071 5.70711C18.3946 5.51957 18.5 5.26522 18.5 5C18.5 4.73478 18.3946 4.48043 18.2071 4.29289C18.0196 4.10536 17.7652 4 17.5 4ZM7.5 3C7.5 2.73478 7.60536 2.48043 7.79289 2.29289C7.98043 2.10536 8.23478 2 8.5 2H10.5C10.7652 2 11.0196 2.10536 11.2071 2.29289C11.3946 2.48043 11.5 2.73478 11.5 3V4H7.5V3ZM14.5 17C14.5 17.2652 14.3946 17.5196 14.2071 17.7071C14.0196 17.8946 13.7652 18 13.5 18H5.5C5.23478 18 4.98043 17.8946 4.79289 17.7071C4.60536 17.5196 4.5 17.2652 4.5 17V6H14.5V17ZM11.5 16C11.7652 16 12.0196 15.8946 12.2071 15.7071C12.3946 15.5196 12.5 15.2652 12.5 15V9C12.5 8.73478 12.3946 8.48043 12.2071 8.29289C12.0196 8.10536 11.7652 8 11.5 8C11.2348 8 10.9804 8.10536 10.7929 8.29289C10.6054 8.48043 10.5 8.73478 10.5 9V15C10.5 15.2652 10.6054 15.5196 10.7929 15.7071C10.9804 15.8946 11.2348 16 11.5 16Z'
												fill='#147CBC'
											/>
										</svg>
										<span className='text-darkBlue ml-2'>Remove</span>
									</button>
								</div>
							</>
						) : (
							<>
								<button
									onClick={handleStartRecording}
									className='flex justify-center items-center border border-darkBlue rounded-[46px] w-full xl:w-[520px] p-2'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'>
										<path
											d='M12 15C13.0609 15 14.0783 14.5786 14.8284 13.8284C15.5786 13.0783 16 12.0609 16 11V5C16 3.93913 15.5786 2.92172 14.8284 2.17157C14.0783 1.42143 13.0609 1 12 1C10.9391 1 9.92172 1.42143 9.17157 2.17157C8.42143 2.92172 8 3.93913 8 5V11C8 12.0609 8.42143 13.0783 9.17157 13.8284C9.92172 14.5786 10.9391 15 12 15ZM10 5C10 4.46957 10.2107 3.96086 10.5858 3.58579C10.9609 3.21071 11.4696 3 12 3C12.5304 3 13.0391 3.21071 13.4142 3.58579C13.7893 3.96086 14 4.46957 14 5V11C14 11.5304 13.7893 12.0391 13.4142 12.4142C13.0391 12.7893 12.5304 13 12 13C11.4696 13 10.9609 12.7893 10.5858 12.4142C10.2107 12.0391 10 11.5304 10 11V5ZM20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10C18.7348 10 18.4804 10.1054 18.2929 10.2929C18.1054 10.4804 18 10.7348 18 11C18 12.5913 17.3679 14.1174 16.2426 15.2426C15.1174 16.3679 13.5913 17 12 17C10.4087 17 8.88258 16.3679 7.75736 15.2426C6.63214 14.1174 6 12.5913 6 11C6 10.7348 5.89464 10.4804 5.70711 10.2929C5.51957 10.1054 5.26522 10 5 10C4.73478 10 4.48043 10.1054 4.29289 10.2929C4.10536 10.4804 4 10.7348 4 11C4.00177 12.9473 4.71372 14.8271 6.0024 16.287C7.29107 17.7469 9.06798 18.6866 11 18.93V21H9C8.73478 21 8.48043 21.1054 8.29289 21.2929C8.10536 21.4804 8 21.7348 8 22C8 22.2652 8.10536 22.5196 8.29289 22.7071C8.48043 22.8946 8.73478 23 9 23H15C15.2652 23 15.5196 22.8946 15.7071 22.7071C15.8946 22.5196 16 22.2652 16 22C16 21.7348 15.8946 21.4804 15.7071 21.2929C15.5196 21.1054 15.2652 21 15 21H13V18.93C14.932 18.6866 16.7089 17.7469 17.9976 16.287C19.2863 14.8271 19.9982 12.9473 20 11Z'
											fill='#147CBC'
										/>
									</svg>
									<span className='ml-2 text-darkBlue font-medium text-[18px]'>
										Send a voice note instead
									</span>
								</button>
							</>
						)}
					</Form.Item>
				</div>

				<Form.Item
					name='dstCurrentLocation'
					rules={[
						{
							required: false,
							message: 'Send your location',
						},
					]}
					className='w-full'>
					<div
						style={{
							borderRadius: ' 8px 8px 0px 0px',
							border: '1px solid #000',
						}}
						className='h-[350px]'>
						<div className='flex justify-end p-5'>
							<button
								onClick={getLocation}
								type='button'
								className='rounded-[46px] bg-[#11679D] text-white flex items-center justify-center p-2 w-[253px]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M12 10.7981C12.3956 10.7981 12.7822 10.6808 13.1111 10.4611C13.44 10.2413 13.6964 9.92895 13.8478 9.5635C13.9991 9.19805 14.0387 8.79592 13.9616 8.40795C13.8844 8.01999 13.6939 7.66363 13.4142 7.38392C13.1345 7.10422 12.7781 6.91373 12.3902 6.83656C12.0022 6.75939 11.6001 6.799 11.2346 6.95038C10.8692 7.10175 10.5568 7.3581 10.3371 7.68699C10.1173 8.01589 10 8.40257 10 8.79813C10 9.32857 10.2107 9.83728 10.5858 10.2123C10.9609 10.5874 11.4696 10.7981 12 10.7981ZM11.29 17.7081C11.383 17.8019 11.4936 17.8763 11.6154 17.927C11.7373 17.9778 11.868 18.0039 12 18.0039C12.132 18.0039 12.2627 17.9778 12.3846 17.927C12.5064 17.8763 12.617 17.8019 12.71 17.7081L16.8 13.6081C17.75 12.6587 18.397 11.4488 18.6593 10.1316C18.9216 8.81437 18.7873 7.44894 18.2735 6.20802C17.7597 4.96711 16.8894 3.90644 15.7727 3.16019C14.656 2.41394 13.3431 2.01563 12 2.01562C10.6569 2.01563 9.344 2.41394 8.22731 3.16019C7.11062 3.90644 6.24033 4.96711 5.72652 6.20802C5.2127 7.44894 5.07845 8.81437 5.34073 10.1316C5.60301 11.4488 6.25005 12.6587 7.2 13.6081L11.29 17.7081ZM7.23 8.33813C7.29833 7.62515 7.52502 6.93643 7.89352 6.32225C8.26203 5.70807 8.76305 5.18394 9.36 4.78813C10.1442 4.27323 11.0619 3.99889 12 3.99889C12.9381 3.99889 13.8558 4.27323 14.64 4.78813C15.233 5.18259 15.7311 5.70354 16.0987 6.31352C16.4663 6.9235 16.6942 7.60736 16.7659 8.31592C16.8376 9.02448 16.7514 9.74012 16.5135 10.4114C16.2756 11.0827 15.8919 11.6929 15.39 12.1981L12 15.5881L8.61 12.1981C8.10752 11.6978 7.72293 11.0917 7.48421 10.4239C7.24548 9.75621 7.15864 9.04365 7.23 8.33813ZM19 19.9981H5C4.73478 19.9981 4.48043 20.1035 4.29289 20.291C4.10536 20.4786 4 20.7329 4 20.9981C4 21.2634 4.10536 21.5177 4.29289 21.7052C4.48043 21.8928 4.73478 21.9981 5 21.9981H19C19.2652 21.9981 19.5196 21.8928 19.7071 21.7052C19.8946 21.5177 20 21.2634 20 20.9981C20 20.7329 19.8946 20.4786 19.7071 20.291C19.5196 20.1035 19.2652 19.9981 19 19.9981Z'
										fill='#EDF8FF'
									/>
								</svg>
								<span>Send current location</span>
							</button>
						</div>

						<iframe
							className='w-full h-[265px]'
							src={`https://maps.google.com/maps?q=${location}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
							frameborder='0'
							marginheight='0'
							marginwidth='0'
							title='Current Location Map'></iframe>
					</div>
				</Form.Item>

				<div className='mt-10 flex justify-center mb-20'>
					<button
						disabled={distressLoading}
						onClick={() => prev()}
						className='border-2 border-darkBlue text-darkBlue p-2 rounded-[56px] w-[287px]'
						type='button'>
						Back
					</button>
					<button
						onClick={() => onFinish()}
						className='bg-blueDark text-white p-2 rounded-[56px] w-[287px] ml-3'
						type='button'>
						{distressLoading ? <Spin /> : 'Submit'}
					</button>
				</div>
			</div>
		</>
	);
}
