import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import con from '../../assets/con2.png';
import { Modal, Table } from 'antd';
import { fetchCounselors } from '../../features/sess/sessSlice';
import ScrollTo from '../../ScrollTo';
import { fetchCounsellingCategorys } from '../../features/dropDowns/dropDownSlice';
import JumbotronOne from '../../components/JambotronOne';
import moment from 'moment';

export default function CounselorHistory() {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { sessions, sessLoading } = useSelector((state) => state.sess);

	const [sessList, setsessList] = useState(sessions);
	const [activeSession, setactiveSession] = useState({});
	const [open, setopen] = useState(false);

	const usrObj = {
		cnsUsrId: user?.usrId,
	};

	const sessHistory = sessList?.filter(
		(item) =>
			item?.cnsStatus !== 'ACTIVE' ||
			item?.cnsStatus !== 'AWAITING_COUNSELLING'
	);

	function handleSessionView(item) {
		setactiveSession(item);
		setopen(true);
	}

	function handleClose() {
		setactiveSession({});
		setopen(false);
	}

	async function handleFetchsessions() {
		const res = await dispatch(fetchCounselors(usrObj));
		if (res?.payload?.success) {
			setsessList(res?.payload?.data?.result);
		}
		await dispatch(fetchCounsellingCategorys());
	}

	const columns = [
		{
			title: 'Date',
			key: 'cnsDate',
			dataIndex: 'cnsDate',
			render: (item) => <span>{moment(item).format('Do MMMM YYY')}</span>,
		},
		{
			title: 'Time',
			key: 'cnsSession',
			dataIndex: 'cnsSession',
		},
		{
			title: 'Venue',
			key: 'neaOffice',
			dataIndex: 'neaOffice',
		},
		{
			title: 'Room',
			key: 'cnsVenue',
			dataIndex: 'cnsVenue',
		},
		{
			title: '',
			key: 'cnsId',
			render: (item) => (
				<button
					onClick={() => handleSessionView(item)}
					className='paragraph_paragraph_councelling'>
					View
				</button>
			),
		},
	];

	useEffect(() => {}, [sessList]);

	useEffect(() => {
		setsessList(sessions);
	}, [sessions]);

	useEffect(() => {
		handleFetchsessions();
	}, []);

	return (
		<>
			<ScrollTo />
			<div className='py-3 lg:p-0 lg:mr-[38px] lg:ml-[22px] mt-[15px] mb-[3rem]'>
				<JumbotronOne
					text1={'Need'}
					text2={'guidance?'}
					text3={'Let’s book a counselor.'}
					text4={'Book an appointment to discuss your thoughts.'}
				/>

				<div className='w-full flex justify-center my-[2.25rem]'>
					<img
						loading='lazy'
						className='w-[8.78381rem] h-[7.25rem] object-contain'
						src={con}
						alt='counsellor'
					/>

					<div
						style={{
							borderRadius: '0.5rem',
							background: '#4166C0',
						}}
						className='w-full md:w-[42.3145rem] h-auto md:h-[7.1875] flex flex-col px-[2.38rem] pb-[2.25rem]'>
						<span className='label_label_2 mb-[0.75rem] mt-[1.25rem]'>
							Request a counseling session
						</span>
						<span className='paragraph_paragraph_3 !text-white'>
							NEA Provides guidance counseling to help you find
							the job that best suits your skills. You can book a
							counseling session at a NEA Center near you.
						</span>
					</div>
				</div>

				<div
					className='w-full mb-[3rem]'
					style={{
						borderRadius: '0.4375rem 0.4375rem 0rem 0rem',
						background: '#fff',
						border: '1px solid #147CBC',
					}}>
					<div
						style={{
							borderRadius: ' 0.4375rem 0.4375rem 0rem 0rem',
							background: '#F9FDFF',
						}}
						className='w-full h-[5.0625rem] mb-[2.69rem] flex items-center'>
						<svg
							className='ml-[2.44rem]'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<path
								d='M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z'
								stroke='#212121'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M16.5 2.25V5.25'
								stroke='#212121'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M7.5 2.25V5.25'
								stroke='#212121'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M3.75 8.25H20.25'
								stroke='#212121'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
						<span className='label_label_1 ml-[1.31rem]'>
							Session history
						</span>
					</div>

					<div className='dash-inner-page px-3 xl:px-[2.81rem] mt-[3.61rem] mb-[5rem] max-w-full w-full overflow-x-auto'>
						<section>
							<Table
								rowKey={'cnsId'}
								loading={sessLoading}
								className='no-header-bg-table no-hover'
								size={'middle'}
								bordered={false}
								columns={columns}
								dataSource={sessHistory}
								scroll={{
									x: 700,
								}}
								pagination={{
									defaultPageSize: 5,
									hideOnSinglePage: true,
									pageSizeOptions: [10, 20, 50, 100],
								}}
							/>
						</section>
					</div>
				</div>
			</div>

			<Modal
				className='share-modal'
				title='Appointment history'
				open={open}
				width={850}
				footer={false}
				onCancel={handleClose}>
				<div className='flex justify-between items-center mt-3'>
					<p className='heading_heading_4'>Appointment history</p>
					<button onClick={handleClose}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<path
								d='M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z'
								fill='#212121'
							/>
						</svg>
					</button>
				</div>

				<div className='w-full mt-[1rem]'>
					<span className='paragraph_paragraph_2 !text-black333'>
						Your session was confirmed as follows
					</span>
				</div>
				<div className='w-full mt-[1rem]'>
					<div className='flex items-center mt-[2.13rem] mb-[2rem]'>
						<div className='flex items-center w-[5rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M3 4.5C3 3.67157 3.67157 3 4.5 3H19.5C20.3284 3 21 3.67157 21 4.5V19.5C21 20.3284 20.3284 21 19.5 21H4.5C3.67157 21 3 20.3284 3 19.5V4.5ZM19.5 4.5H4.5V19.5H19.5V4.5Z'
									fill='#1B47B4'
								/>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M16.5 1.5C16.9142 1.5 17.25 1.83579 17.25 2.25V5.25C17.25 5.66421 16.9142 6 16.5 6C16.0858 6 15.75 5.66421 15.75 5.25V2.25C15.75 1.83579 16.0858 1.5 16.5 1.5Z'
									fill='#1B47B4'
								/>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M7.5 1.5C7.91421 1.5 8.25 1.83579 8.25 2.25V5.25C8.25 5.66421 7.91421 6 7.5 6C7.08579 6 6.75 5.66421 6.75 5.25V2.25C6.75 1.83579 7.08579 1.5 7.5 1.5Z'
									fill='#1B47B4'
								/>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M3 8.25C3 7.83579 3.33579 7.5 3.75 7.5H20.25C20.6642 7.5 21 7.83579 21 8.25C21 8.66421 20.6642 9 20.25 9H3.75C3.33579 9 3 8.66421 3 8.25Z'
									fill='#1B47B4'
								/>
							</svg>
							<span className='ml-2 label_label_2 !text-[#1B47B4]'>
								Date
							</span>
						</div>
						<span className='ml-[2.75rem] paragraph_paragraph_2 !text-black14'>
							{moment(activeSession?.cnsDate).format(
								'Do MMMM YYYY'
							)}
						</span>
					</div>

					<div className='flex items-center mt-[2.13rem] mb-[2rem]'>
						<div className='flex items-center w-[5rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M4.5 21.75C4.5 21.3358 4.83579 21 5.25 21H18.75C19.1642 21 19.5 21.3358 19.5 21.75C19.5 22.1642 19.1642 22.5 18.75 22.5H5.25C4.83579 22.5 4.5 22.1642 4.5 21.75Z'
									fill='#1B47B4'
								/>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M12 7.5C10.7574 7.5 9.75 8.50736 9.75 9.75C9.75 10.9926 10.7574 12 12 12C13.2426 12 14.25 10.9926 14.25 9.75C14.25 8.50736 13.2426 7.5 12 7.5ZM8.25 9.75C8.25 7.67893 9.92893 6 12 6C14.0711 6 15.75 7.67893 15.75 9.75C15.75 11.8211 14.0711 13.5 12 13.5C9.92893 13.5 8.25 11.8211 8.25 9.75Z'
									fill='#1B47B4'
								/>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M12 3C10.2098 3 8.4929 3.71116 7.22703 4.97703C5.96116 6.2429 5.25 7.95979 5.25 9.75C5.25 12.855 6.98389 15.6822 8.81595 17.7891C9.72283 18.832 10.6316 19.6729 11.3139 20.2528C11.5837 20.4821 11.8172 20.6699 12 20.8125C12.1828 20.6699 12.4163 20.4821 12.6861 20.2528C13.3684 19.6729 14.2772 18.832 15.184 17.7891C17.0161 15.6822 18.75 12.855 18.75 9.75C18.75 7.95979 18.0388 6.2429 16.773 4.97703C15.5071 3.71116 13.7902 3 12 3ZM12 21.75C11.5699 22.3644 11.5697 22.3643 11.5695 22.3641L11.5688 22.3637L11.5671 22.3624L11.5615 22.3585L11.5425 22.345C11.5263 22.3334 11.5031 22.3167 11.4736 22.2952C11.4144 22.252 11.3294 22.189 11.2223 22.1071C11.0081 21.9434 10.7049 21.7038 10.3424 21.3957C9.61838 20.7803 8.65217 19.8867 7.68405 18.7734C5.76611 16.5678 3.75 13.395 3.75 9.75C3.75 7.56196 4.61919 5.46354 6.16637 3.91637C7.71354 2.36919 9.81196 1.5 12 1.5C14.188 1.5 16.2865 2.36919 17.8336 3.91637C19.3808 5.46354 20.25 7.56196 20.25 9.75C20.25 13.395 18.2339 16.5678 16.316 18.7734C15.3478 19.8867 14.3816 20.7803 13.6576 21.3957C13.2951 21.7038 12.9919 21.9434 12.7777 22.1071C12.6706 22.189 12.5856 22.252 12.5264 22.2952C12.4969 22.3167 12.4737 22.3334 12.4575 22.345L12.4385 22.3585L12.4329 22.3624L12.4312 22.3637L12.4305 22.3641C12.4303 22.3643 12.4301 22.3644 12 21.75ZM12 21.75L12.4301 22.3644C12.1719 22.5452 11.8281 22.5452 11.5699 22.3644L12 21.75Z'
									fill='#1B47B4'
								/>
							</svg>
							<span className='ml-2 label_label_2 !text-[#1B47B4]'>
								Venue
							</span>
						</div>
						<span className='ml-[2.75rem] paragraph_paragraph_2 !text-black14'>
							{activeSession?.neaOffice}
						</span>
					</div>

					<div className='flex items-center mt-[2.13rem] mb-[2rem]'>
						<div className='flex items-center w-[5rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M9.75 20.2506V14.2506H14.25V20.2506H20.25V11.2506C20.2501 11.1521 20.2307 11.0545 20.1931 10.9635C20.1555 10.8724 20.1003 10.7897 20.0306 10.72L12.5306 3.21996C12.461 3.15023 12.3783 3.09491 12.2872 3.05717C12.1962 3.01943 12.0986 3 12 3C11.9014 3 11.8038 3.01943 11.7128 3.05717C11.6217 3.09491 11.539 3.15023 11.4694 3.21996L3.96938 10.72C3.89975 10.7897 3.84454 10.8724 3.8069 10.9635C3.76926 11.0545 3.74992 11.1521 3.75 11.2506V20.2506H9.75Z'
									stroke='#1B47B4'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
							<span className='ml-2 label_label_2 !text-[#1B47B4]'>
								Room
							</span>
						</div>
						<span className='ml-[2.75rem] paragraph_paragraph_2 !text-black14'>
							{activeSession?.cnsVenue}
						</span>
					</div>

					<div className='flex items-center mt-[2.13rem] mb-[2rem]'>
						<div className='flex items-center w-[5rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z'
									fill='#1B47B4'
								/>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M12 6C12.4142 6 12.75 6.33579 12.75 6.75V11.25H17.25C17.6642 11.25 18 11.5858 18 12C18 12.4142 17.6642 12.75 17.25 12.75H12C11.5858 12.75 11.25 12.4142 11.25 12V6.75C11.25 6.33579 11.5858 6 12 6Z'
									fill='#1B47B4'
								/>
							</svg>
							<span className='ml-2 label_label_2 !text-[#1B47B4]'>
								Time
							</span>
						</div>
						<span className='ml-[2.75rem] paragraph_paragraph_2 !text-black14'>
							{activeSession?.cnsSession}
						</span>
					</div>

					{activeSession?.cnsSessionRemarks ? (
						<>
							<div className='flex w-[80%] items-start mt-[2.13rem] mb-[2rem]'>
								<span className='ml-2 label_label_2 !text-[#1B47B4] w-[5rem]'>
									Session Remarks
								</span>
								<span className='ml-[2.75rem] w-full paragraph_paragraph_2 !text-black14'>
									{activeSession?.cnsSessionRemarks}
								</span>
							</div>
						</>
					) : null}
				</div>
			</Modal>
		</>
	);
}
