import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Spin, Space, Divider, Radio } from 'antd';

import {
	fetchDisabilityTypes,
	fetchUnemploymentDurations,
} from '../../../../features/dropDowns/dropDownSlice';
import {
	fetchUserDisabilitys,
	saveUserDisability,
	updateUser,
} from '../../../../features/auth/authSlice';
import {
	fetchJobSeekerInfos,
	saveJobSeekerInfo,
} from '../../../../features/job/jobSlice';
import { fetchSkillCategory } from '../../../../features/skill/skillSlice';
import ScrollTo from '../../../../ScrollTo';
import {
	fetchWorkCategorys,
	fetchWorkTypeCategorys,
} from '../../../../features/work/workSLice';
import { handleRegNextStep } from '../../../../features/profile/profileSlice';

export default function PersonalInfo() {
	const dispatch = useDispatch();
	const [form2] = Form.useForm();
	const formRef = useRef(null);

	const { user, disability, authLoading } = useSelector((state) => state.auth);
	const { seekingInfo } = useSelector((state) => state.job);
	const { unEmpDuration, disTypeOptions } = useSelector(
		(state) => state.dropDown
	);

	const {
		workCategoryLoading,
		workTypeLoading,
		workCategoryData,
		workTypeData,
	} = useSelector((state) => state.work);

	const [hasDis, sethasDis] = useState(disability?.disIsPresent);
	const [worksDisbaled, setworksDisbaled] = useState(true);
	const [casulWork, setcasulWork] = useState(false);
	const [hasError, sethasError] = useState(false);

	function handleGetJobTitleName(val) {
		let wrkObj = workTypeData?.find((item) => item?.wktId === val);
		if (
			String(wrkObj?.wktName)
				.toUpperCase()
				.includes(String('casual').toUpperCase())
		) {
			setcasulWork(true);
		} else {
			setcasulWork(false);
		}
	}

	async function handleDropdownOptions() {
		await dispatch(fetchUnemploymentDurations());
		await dispatch(fetchWorkTypeCategorys());
		await dispatch(fetchDisabilityTypes());
	}

	async function fetchWorkCategory(workType) {
		await dispatch(fetchWorkCategorys(workType));
	}

	async function handleUpdateWorkType(value) {
		let usrObj = {
			usrId: user?.usrId,
			usrNationalId: user?.usrNationalId,
			usrWktId: value,
		};

		await dispatch(updateUser(usrObj));
		handleGetJobTitleName(value);
	}

	async function handleUpdateWorks(value) {
		let usrObj = {
			usrId: user?.usrId,
			usrNationalId: user?.usrNationalId,
			usrWrkIds: value.toString(),
		};

		await dispatch(updateUser(usrObj));
	}

	const onFinish2 = async (values) => {
		form2
			.validateFields({ validateOnly: true })
			.then((formValues) => {
				sethasError(false); // check keys to handle init state
			})
			.catch((error) => {
				sethasError(true);
			});

		values.disUsrId = user?.usrId;
		values.disCreatedBy = user?.usrId;

		values.disType = values?.disType?.toString();

		if (disability?.disId) {
			values.disId = disability?.disId;
		}

		if (seekingInfo?.jsiId) {
			values.jsiId = seekingInfo?.jsiId;
		}

		values.jsiUsrId = user?.usrId;
		values.jsiCreatedBy = user?.usrId;

		const jsiObj = Object.keys(values || {})
			.filter((key) => key.includes('jsi'))
			.reduce((obj, key) => {
				return Object.assign(obj, {
					[key]: values[key],
				});
			}, {});

		const disObj = Object.keys(values || {})
			.filter((key) => key.includes('dis'))
			.reduce((obj, key) => {
				return Object.assign(obj, {
					[key]: values[key],
				});
			}, {});

		await dispatch(saveJobSeekerInfo(jsiObj));

		await dispatch(saveUserDisability(disObj));

		await handleFetchJobSeekingInfo();

		await handleFetchDisability();

		await dispatch(handleRegNextStep());
	};

	async function handleFetchSKillCategory() {
		const res = await dispatch(fetchSkillCategory());
		if (res?.payload?.success) {
			let skills = [];

			await res?.payload?.data?.result?.forEach((item) => {
				skills.push({
					label: item?.sklcatName,
					value: item?.sklcatName,
				});
			});

			// await setskillsList(skills);
		}
	}

	async function handleFetchJobSeekingInfo() {
		const usrObj = {
			jsiUsrId: user?.usrId,
		};

		await dispatch(fetchJobSeekerInfos(usrObj));
	}

	async function handleFetchDisability() {
		const usrObj = {
			disUsrId: user?.usrId,
		};
		await dispatch(fetchUserDisabilitys(usrObj));
	}

	useEffect(() => {
		handleGetJobTitleName(user?.usrWktId);
	}, [user]);

	useEffect(() => {
		form2.resetFields()
	}, [hasDis, disability, seekingInfo]);

	useEffect(() => {
		sethasDis(disability?.disIsPresent);
	}, [disability]);

	useEffect(() => {
		handleDropdownOptions();
		handleFetchJobSeekingInfo();
		handleFetchDisability();
		handleFetchSKillCategory();
	}, []);	

	return (
		<>
			<ScrollTo />
			<div className='mt-10 p-3 lg:p-0 py-[3.5rem] flex flex-col justify-center items-center w-full bg-white'>
				<h3 className='heading_heading_2 w-full text-center mt-[3.75rem]'>
					<span>Personal Info</span>
				</h3>
				<p className='heading_heading_5 mt-[1.75rem] mb-[2.25rem] text-center'>
					Make sure you give accurate details so that future employers can reach
					you.
				</p>

				<div
					style={{
						width: '100%',
						height: '0.0625rem',
						background: '#F1F1F1',
					}}></div>

				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish2}
					onFinishFailed={({ errorFields }) => {
						if (errorFields?.length) {
							sethasError(true);
						} else {
							sethasError(false);
						}
					}}
					style={{
						maxWidth: '100%',
						marginTop: '3.62rem',
						width: '40.625rem',
					}}
					form={form2}
					initialValues={{
						disIsPresent: hasDis,
						jsiUnempDuration: seekingInfo?.jsiUnempDuration,
						usrWktId: user?.usrWktId,
						disCertNumber: disability?.disCertNumber
					}}
					scrollToFirstError={true}>
					<div className='grid grid-cols-1 gap-x-6 w-full'>
						<Form.Item
							help={
								hasError ? null : (
									<span className='paragraph_paragraph_3'>Choose option</span>
								)
							}
							name='jsiUnempDuration'
							label='How long have you been looking for work?'
							rules={[
								{
									required: false,
									message: 'Field is required',
								},
							]}>
							<Select
								suffixIcon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'>
										<path
											d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
											fill='#212121'
										/>
									</svg>
								}
								options={
									unEmpDuration?.length &&
									unEmpDuration?.map((item) => {
										return {
											label: item?.undName,
											value: item?.undName,
										};
									})
								}
							/>
						</Form.Item>

						<Form.Item
							help={
								hasError ? null : (
									<span className='paragraph_paragraph_3'>
										Choose at least one option
									</span>
								)
							}
							name='usrWktId'
							label='What type of work would you like to do?'
							rules={[
								{
									required: true,
									message:
										'Please select the type of work you would like to do',
								},
							]}>
							<Select
								suffixIcon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'>
										<path
											d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
											fill='#212121'
										/>
									</svg>
								}
								allowClear
								optionFilterProp='children'
								filterOption={(input, option) =>
									(option?.label?.toLocaleLowerCase() ?? '').includes(
										input?.toLocaleLowerCase()
									)
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								size='large'
								className='reg-input'
								dropdownRender={(menu) => (
									<>
										<Space
											style={{
												padding: '8px 8px 4px',
											}}>
											<span className='select-info mt-1'>
												Choose one of the following
											</span>
										</Space>
										<Divider
											style={{
												margin: '8px 0',
											}}
										/>
										{menu}
									</>
								)}
								options={
									workTypeData?.length > 0 &&
									workTypeData?.map((item) => ({
										value: item?.wktId,
										label: item?.wktName,
									}))
								}
								loading={workTypeLoading}
								onChange={(value) => {
									fetchWorkCategory(value);
									setworksDisbaled(false);
									handleUpdateWorkType(value);
								}}
							/>
						</Form.Item>

						{casulWork ? null : (
							<Form.Item
								help={
									hasError ? null : (
										<span className='paragraph_paragraph_3'>
											Choose at least one option
										</span>
									)
								}
								label='What kind of job are you interested in? (Choose at least one option)'
								rules={[
									{
										required: true,
										message:
											'Please kind of job are you interested in? (Choose at least one option)',
									},
								]}>
								<Select
									suffixIcon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
												fill='#212121'
											/>
										</svg>
									}
									defaultValue={
										user?.usrWrkIds?.length ? user?.usrWrkIds?.split(',') : []
									}
									mode='multiple'
									maxCount={5}
									maxTagCount={3}
									showSearch
									optionFilterProp='children'
									filterOption={(input, option) =>
										(option?.label?.toLocaleLowerCase() ?? '').includes(
											input?.toLocaleLowerCase()
										)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									allowClear
									options={
										workCategoryData?.length > 0 &&
										workCategoryData?.map((item) => ({
											value: item?.wrkName,
											label: item?.wrkName,
										}))
									}
									onChange={(value) => {
										handleUpdateWorks(value);
									}}
									loading={workCategoryLoading}
									disabled={worksDisbaled}
								/>
							</Form.Item>
						)}

						<Form.Item
							name='disIsPresent'
							label='Are you a person with disability?'
							rules={[
								{
									required: false,
									message: 'Field is required',
								},
							]}>
							<Radio.Group
								onChange={(e) => sethasDis(e.target.value)}
								value={hasDis}>
								<Radio value={true}>Yes</Radio>
								<Radio value={false}>No</Radio>
							</Radio.Group>
						</Form.Item>

						{hasDis ? (
							<>
								<Form.Item
									help={
										hasError ? null : (
											<span className='paragraph_paragraph_3'>
												Choose option
											</span>
										)
									}
									name='disType'
									label='Type of disability present'
									rules={[
										{
											required: false,
											message: 'Field is required',
										},
									]}>
									<Select
										suffixIcon={
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'>
												<path
													d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
													fill='#212121'
												/>
											</svg>
										}
										defaultValue={
											disability?.disType?.length
												? disability?.disType?.split(',')
												: []
										}
										mode='multiple'
										maxTagCount={2}
										showSearch
										className='step1-select-tag step1-select-icon'
										optionFilterProp='children'
										filterOption={(input, option) =>
											(option?.label?.toLocaleLowerCase() ?? '').includes(
												input?.toLocaleLowerCase()
											)
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										placeholder={'Select multiple (If applicable)'}
										allowClear
										options={
											disTypeOptions?.length > 0 &&
											disTypeOptions?.map((item) => ({
												value: item?.dtpName,
												label: item?.dtpName,
											}))
										}
									/>
								</Form.Item>

								<Form.Item
									help={
										hasError ? null : (
											<span className='paragraph_paragraph_3'>
												Enter number
											</span>
										)
									}
									name={'disCertNumber'}
									label='NCPWD Registration No'
									rules={[
										{
											required: false,
											message: 'Field is required',
										},
										{
											message: 'Enter a valid certificate name',
											validator: (_, value) => {
												if (/^[0-9]*$/.test(value)) {
													return Promise.reject();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}>
									<Input
										defaultValue={disability?.disCertNumber}
										className='input'
									/>
								</Form.Item>
							</>
						) : null}
					</div>

					<div className='flex flex-col items-center justify-center mt-10 mb-14 w-full'>
						<button
							disabled={authLoading}
							className='bg-darkBlue rounded-[35px] text-white w-[300px] p-3'
							type='submit'>
							{authLoading ? <Spin /> : 'Next'}
						</button>
					</div>
				</Form>
			</div>
		</>
	);
}
