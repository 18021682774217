import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DisabilityDescriptionModal from '../modals/DisablityDescriptionModal';

export default function DisabilityDescription({ profileApplication }) {
	const { disability } = useSelector((state) => state.auth);

	const [open, setopen] = useState(false);

	function handleCancel() {
		setopen(false);
	}

	useEffect(() => {}, [disability]);

	return (
		<>
			<div className='bg-white px-[.75rem] lg:px-[2.5rem] py-[2rem] w-full'>
				<div className='flex justify-between items-center'>
					<span className='heading_heading_4'>Persons with disability </span>
					{profileApplication ? null : (
						<button onClick={() => setopen(true)}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'>
								<path
									d='M11.5863 27.0002H6C5.73478 27.0002 5.48043 26.8948 5.29289 26.7073C5.10536 26.5197 5 26.2654 5 26.0002V20.4139C5.00012 20.149 5.10532 19.8951 5.2925 19.7077L20.7075 4.29266C20.895 4.10527 21.1493 4 21.4144 4C21.6795 4 21.9337 4.10527 22.1213 4.29266L27.7075 9.87516C27.8949 10.0627 28.0002 10.3169 28.0002 10.582C28.0002 10.8471 27.8949 11.1014 27.7075 11.2889L12.2925 26.7077C12.1051 26.8948 11.8511 27 11.5863 27.0002Z'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M17 8L24 15'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</button>
					)}
				</div>

				<div
					className='mt-[1.5rem]'
					style={{
						width: '100%',
						height: '0.0625rem',
						background: 'rgba(216, 216, 216, 0.54)',
					}}></div>

				<div className='flex flex-col mt-[2.25rem] w-full'>
					<span className='label_label_1'>
						Are you a person with disability?
					</span>
					<div className='flex items-center mt-[1rem] w-full'>
						{disability?.disIsPresent === true ? (
							<>
								<div className='flex items-center'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='32'
										height='32'
										viewBox='0 0 32 32'
										fill='none'>
										<g clipPath='url(#clip0_10936_21311)'>
											<path
												d='M25.3333 4H6.66667C5.18667 4 4 5.2 4 6.66667V25.3333C4 26.8 5.18667 28 6.66667 28H25.3333C26.8133 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8133 4 25.3333 4ZM13.3333 22.6667L6.66667 16L8.54667 14.12L13.3333 18.8933L23.4533 8.77333L25.3333 10.6667L13.3333 22.6667Z'
												fill='#1B47B4'
											/>
										</g>
										<defs>
											<clipPath id='clip0_10936_21311'>
												<rect width='32' height='32' fill='white' />
											</clipPath>
										</defs>
									</svg>
									<span className='label_label_disability'>Yes</span>
								</div>
							</>
						) : (
							<>
								<div className='flex items-center'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='25'
										height='25'
										viewBox='0 0 25 25'
										fill='none'>
										<path
											d='M22.2222 2.77778V22.2222H2.77778V2.77778H22.2222ZM22.2222 0H2.77778C1.25 0 0 1.25 0 2.77778V22.2222C0 23.75 1.25 25 2.77778 25H22.2222C23.75 25 25 23.75 25 22.2222V2.77778C25 1.25 23.75 0 22.2222 0Z'
											fill='#1B47B4'
										/>
									</svg>
									<span className='label_label_disability'>Yes</span>
								</div>
							</>
						)}

						<div className='ml-[3.06rem]'>
							{!disability?.disIsPresent ? (
								<>
									<div className='flex items-center'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='32'
											height='32'
											viewBox='0 0 32 32'
											fill='none'>
											<g clipPath='url(#clip0_10936_21311)'>
												<path
													d='M25.3333 4H6.66667C5.18667 4 4 5.2 4 6.66667V25.3333C4 26.8 5.18667 28 6.66667 28H25.3333C26.8133 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8133 4 25.3333 4ZM13.3333 22.6667L6.66667 16L8.54667 14.12L13.3333 18.8933L23.4533 8.77333L25.3333 10.6667L13.3333 22.6667Z'
													fill='#1B47B4'
												/>
											</g>
											<defs>
												<clipPath id='clip0_10936_21311'>
													<rect width='32' height='32' fill='white' />
												</clipPath>
											</defs>
										</svg>
										<span className='label_label_disability'>No</span>
									</div>
								</>
							) : (
								<>
									<div className='flex items-center'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='25'
											height='25'
											viewBox='0 0 25 25'
											fill='none'>
											<path
												d='M22.2222 2.77778V22.2222H2.77778V2.77778H22.2222ZM22.2222 0H2.77778C1.25 0 0 1.25 0 2.77778V22.2222C0 23.75 1.25 25 2.77778 25H22.2222C23.75 25 25 23.75 25 22.2222V2.77778C25 1.25 23.75 0 22.2222 0Z'
												fill='#1B47B4'
											/>
										</svg>
										<span className='label_label_disability'>No</span>
									</div>
								</>
							)}
						</div>
					</div>
				</div>

				<div className='mt-[2rem] grid grid-cols-2 gap-[2.75rem] w-full'>
					<div className='flex flex-col'>
						<span className='label_label_1'>Disability type(s)</span>
						<span className='paragraph_paragraph_1 mt-[0.75rem]'>
							{disability?.disType?.length ? disability?.disType : 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>NCPWD Registration No </span>

						<span className='paragraph_paragraph_1 mt-[0.75rem]'>
							{disability?.disCertNumber ?? 'N/A'}
						</span>
					</div>
				</div>
			</div>

			<DisabilityDescriptionModal open={open} handleCancel={handleCancel} />
		</>
	);
}
