import { formatImgPath, getRemainingTime } from '../utils';
import { useEffect, useState } from 'react';
import SocialShareModal from './SocialShareModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	setActiveJob,
} from '../features/job/jobSlice';
import home23 from '../assets/landing/home23.svg';

export default function JobCardSmallUpdated({ item }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { appliedJobs } = useSelector((state) => state.job);

	const [open, setopen] = useState(false);
	const [activeJob, setactiveJob] = useState({});

	let applied = appliedJobs?.filter(
		(x) => Number(x.japJobId) === Number(item?.jobId)
	);

	function handleCancel() {
		setopen(false);
		setactiveJob({});
	}


	async function handleActiveJob() {
		await dispatch(setActiveJob(item));
		await navigate(
			`/home/job-info/${
				item?.jobId || item?.jbrJobId || item?.josJobId || item?.japJobId
			}/${item?.jobPosition}`.replace(/\s+/g, '-')
		);
	}

	useEffect(() => {}, [appliedJobs]);

	return (
		<>
			<div
				style={{
					borderRadius: '0.9375rem',
					border: '1px solid #1B47B4',
				}}
				className='bg-[#F5F7FF] min-w-[26.6875rem] max-w-full w-auto flex-grow h-auto py-[1.87rem] px-[1.87rem] flex flex-col items-start justify-start mb-[2.5rem]'>
				<div className='flex w-full items-start justify-between'>
					<div className='flex items-center'>
						<div className='w-[3.75rem] h-[3.75rem] flex-shrink-0'>
							{item?.jobCntIcon || item?.cntIcon ? (
								<img
									loading='lazy'
									className='object-cover w-[3.75rem] h-[3.75rem] rounded-full'
									src={formatImgPath(item?.jobCntIcon ?? item?.cntIcon)}
									alt='Job advert icon'
								/>
							) : (
								<svg
									width='61'
									height='61'
									viewBox='0 0 61 61'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'>
									<g clipPath='url(#clip0_1_2203)'>
										<g clipPath='url(#clip1_1_2203)'>
											<g clipPath='url(#clip2_1_2203)'>
												<path
													d='M30.1016 1.19705C26.201 1.19705 22.3384 1.96542 18.7347 3.45804C15.1308 4.95082 11.8566 7.13866 9.09833 9.89681C6.34017 12.6551 4.15234 15.9294 2.65955 19.5332C1.16694 23.1369 0.39856 26.9994 0.39856 30.9C0.39856 34.8006 1.16694 38.6632 2.65955 42.2669C4.15234 45.8706 6.34017 49.145 9.09833 51.9033C11.8566 54.6614 15.1308 56.8493 18.7347 58.342C22.3384 59.8346 26.201 60.603 30.1016 60.603V45.7515C28.1511 45.7515 26.22 45.3675 24.4181 44.6209C22.6163 43.8746 20.979 42.7808 19.5999 41.4017C18.2208 40.0226 17.1269 38.3853 16.3806 36.5836C15.6342 34.7816 15.2501 32.8505 15.2501 30.9C15.2501 28.9495 15.6342 27.0184 16.3806 25.2165C17.1269 23.4147 18.2208 21.7775 19.5999 20.3984C20.979 19.0193 22.6163 17.9255 24.4181 17.1792C26.22 16.4326 28.1511 16.0485 30.1016 16.0485V1.19705Z'
													fill='#0F52FF'
												/>
												<path
													d='M30.1054 40.8025C35.5736 40.8025 40.0062 36.3698 40.0062 30.9014C40.0062 25.4333 35.5736 21.0005 30.1054 21.0005C24.637 21.0005 20.2045 25.4333 20.2045 30.9014C20.2045 36.3698 24.637 40.8025 30.1054 40.8025Z'
													fill='#0F52FF'
												/>
												<path
													d='M0.39856 30.9C0.39856 34.8006 1.16694 38.6632 2.65955 42.2669C4.15234 45.8706 6.34017 49.145 9.09833 51.9033C11.8566 54.6614 15.1308 56.8493 18.7347 58.342C22.3384 59.8346 26.201 60.603 30.1016 60.603C34.0022 60.603 37.8648 59.8346 41.4684 58.342C45.0722 56.8493 48.3465 54.6614 51.1047 51.9033C53.8629 49.145 56.0507 45.8706 57.5436 42.2669C59.0362 38.6632 59.8045 34.8006 59.8045 30.9H44.9531C44.9531 32.8505 44.5688 34.7816 43.8224 36.5836C43.0762 38.3854 41.9822 40.0226 40.6032 41.4017C39.2241 42.7808 37.5869 43.8746 35.785 44.6209C33.9832 45.3675 32.0519 45.7515 30.1016 45.7515C28.1511 45.7515 26.22 45.3675 24.4181 44.6209C22.6163 43.8746 20.979 42.7808 19.5999 41.4017C18.2208 40.0226 17.1269 38.3854 16.3806 36.5836C15.6342 34.7816 15.2501 32.8505 15.2501 30.9H0.39856Z'
													fill='#5BD0F4'
												/>
												<path
													d='M40.0064 30.9016C40.0064 28.2757 38.9633 25.7574 37.1066 23.9005C35.2498 22.0437 32.7314 21.0005 30.1055 21.0005C27.4796 21.0005 24.9613 22.0437 23.1044 23.9005C21.2476 25.7574 20.2045 28.2757 20.2045 30.9016H30.1055H40.0064Z'
													fill='#5BD0F4'
												/>
											</g>
										</g>
									</g>
									<defs>
										<clipPath id='clip0_1_2203'>
											<rect
												width='60'
												height='60'
												fill='white'
												transform='translate(0.200195 0.900024)'
											/>
										</clipPath>
										<clipPath id='clip1_1_2203'>
											<rect
												width='60'
												height='60'
												fill='white'
												transform='translate(0.200195 0.900024)'
											/>
										</clipPath>
										<clipPath id='clip2_1_2203'>
											<rect
												width='60'
												height='59.4059'
												fill='white'
												transform='translate(0.200195 1.19705)'
											/>
										</clipPath>
									</defs>
								</svg>
							)}
						</div>
						<span className='heading_heading_company_name_small_v2 w-full truncate ml-[0.63rem]'>
							{item?.jobCntName ?? item?.cntName}
						</span>
					</div>
				</div>

				<span className='heading_heading_job_name_small w-full truncate mt-[1.25rem]'>
					{item?.jobPosition}
				</span>

				<span className='heading_heading_company_vacancy mt-[.34rem]'>
					No. of vacancies: {item?.jobVacancyCount}
				</span>

				<div className='flex items-center h-full mt-[.76rem]'>
					<div
						style={{
							padding: '0.125rem 0.375rem',
							borderRadius: '0.1875rem',
							background: 'rgba(125, 90, 226, 0.10)',
						}}
						className='h-full w-full flex justify-center items-center'>
						<span className='label_label_3 truncate !text-center !text-[#7D5AE2]'>
							{item?.jobEmploymentTerms}
						</span>
					</div>

					<div
						style={{
							padding: '0.125rem 0.375rem',
							borderRadius: '0.1875rem',
							background: 'rgba(125, 90, 226, 0.10)',
						}}
						className='h-full w-full flex justify-center items-center ml-[0.62rem]'>
						<span className='label_label_3 truncate !text-center !text-[#7D5AE2]'>
							{item?.jobExperience}
						</span>
					</div>

					<div
						style={{
							padding: '0.125rem 0.375rem',
							borderRadius: '0.1875rem',
							background: 'rgba(125, 90, 226, 0.10)',
						}}
						className='h-full w-full flex justify-center items-center ml-[0.62rem]'>
						<span className='label_label_3 truncate !text-center !text-[#7D5AE2]'>
							{item?.jobWorkStyle}
						</span>
					</div>
				</div>

				<div className='flex items-center mt-[1.61rem]'>
					<div className='flex items-center mr-[0.94rem]'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='22'
							height='22'
							viewBox='0 0 22 22'
							fill='none'>
							<path
								d='M11 5.5C10.3201 5.5 9.65552 5.70161 9.09023 6.07932C8.52493 6.45704 8.08434 6.99391 7.82416 7.62203C7.56399 8.25015 7.49591 8.94131 7.62855 9.60812C7.76119 10.2749 8.08858 10.8874 8.56932 11.3682C9.05006 11.8489 9.66257 12.1763 10.3294 12.3089C10.9962 12.4416 11.6874 12.3735 12.3155 12.1133C12.9436 11.8532 13.4805 11.4126 13.8582 10.8473C14.2359 10.282 14.4375 9.61737 14.4375 8.9375C14.4375 8.02582 14.0753 7.15148 13.4307 6.50682C12.786 5.86216 11.9117 5.5 11 5.5ZM11 11C10.5921 11 10.1933 10.879 9.85414 10.6524C9.51496 10.4258 9.2506 10.1037 9.0945 9.72678C8.93839 9.34991 8.89755 8.93521 8.97713 8.53513C9.05671 8.13504 9.25315 7.76754 9.54159 7.47909C9.83004 7.19065 10.1975 6.99421 10.5976 6.91463C10.9977 6.83505 11.4124 6.87589 11.7893 7.032C12.1662 7.1881 12.4883 7.45246 12.7149 7.79164C12.9415 8.13081 13.0625 8.52958 13.0625 8.9375C13.0625 9.48451 12.8452 10.0091 12.4584 10.3959C12.0716 10.7827 11.547 11 11 11ZM11 1.375C8.995 1.37727 7.07277 2.17477 5.65502 3.59252C4.23727 5.01027 3.43977 6.9325 3.4375 8.9375C3.4375 11.6359 4.68445 14.4959 7.04688 17.209C8.10839 18.4349 9.30312 19.5389 10.609 20.5004C10.7246 20.5814 10.8623 20.6248 11.0034 20.6248C11.1446 20.6248 11.2823 20.5814 11.3979 20.5004C12.7013 19.5385 13.8938 18.4346 14.9531 17.209C17.3121 14.4959 18.5625 11.6359 18.5625 8.9375C18.5602 6.9325 17.7627 5.01027 16.345 3.59252C14.9272 2.17477 13.005 1.37727 11 1.375ZM11 19.0781C9.57945 17.9609 4.8125 13.8574 4.8125 8.9375C4.8125 7.29647 5.4644 5.72266 6.62478 4.56228C7.78516 3.4019 9.35897 2.75 11 2.75C12.641 2.75 14.2148 3.4019 15.3752 4.56228C16.5356 5.72266 17.1875 7.29647 17.1875 8.9375C17.1875 13.8557 12.4205 17.9609 11 19.0781Z'
								fill='#333333'
							/>
						</svg>
						<span className='heading_heading_job_details ml-[0.5rem]'>
							{item?.jobCity}, {item?.jobCountry}
						</span>
					</div>

					<div className='flex items-center mr-[0.94rem]'>
						<img
							className='w-[1.25rem] h-[1.25rem] object-none'
							src={home23}
							alt='Time icon'
						/>
						<span className='heading_heading_job_details ml-[0.44rem]'>
							{getRemainingTime(item?.jobAdvertEndDate, applied)}
						</span>
					</div>
				</div>

				<button
					style={{
						padding: '0.70625rem 2.20625rem 0.75063rem 2.1875rem',
					}}
					onClick={() => handleActiveJob()}
					className='black-btn !h-[2.9375rem] !w-fit mt-[1.95rem]'>
					<span className='black-btn-text'>View job details</span>
				</button>
			</div>
			<SocialShareModal
				activeJob={activeJob}
				open={open}
				handleCancel={handleCancel}
			/>
		</>
	);
}
