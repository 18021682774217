import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Spin } from 'antd';
import {
	deleteSkill,
	fetchSkills,
	saveSkill,
} from '../../../../../features/skill/skillSlice';
import { customToast } from '../../../../../notifications';

const url = process.env.REACT_APP_API_BASE_URL;

let timeout;
let currentValue;

const fetchFunc = (value, callback) => {
	if (timeout) {
		clearTimeout(timeout);
		timeout = null;
	}
	currentValue = value;

	const fake = () => {
		fetch(
			`${url}/nea/fetchGlobalSkills.action?gsklName=${
				value?.length > 0 ? value : ' '
			}`
		)
			.then((response) => response.json())
			.then((d) => {
				if (currentValue === value) {
					const data = d?.data?.result
						?.sort((a, b) => a?.gsklName?.localeCompare(b?.gsklName))
						?.map((item) => ({
							value: item?.gsklId,
							text: item?.gsklName,
						}));
					callback(data);
				}
			});
	};
	if (value) {
		timeout = setTimeout(fake, 300);
	}
};

export default function SkillsForm({ viewOnly }) {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { user } = useSelector((state) => state.auth);
	const { mySkills, skillLoading, globalSkills } = useSelector(
		(state) => state.skill
	);

	const [showSearch, setshowSearch] = useState(true);

	const [skillData, setskillData] = useState([]);
	const [value, setValue] = useState();
	const [skillsList, setskillsList] = useState(mySkills);
	const [globalSkillsList, setglobalSkillsList] = useState(globalSkills);

	const usrObj = {
		usrId: user?.usrId,
	};

	const handleSearch = (newValue) => {
		fetchFunc(newValue, setskillData);
	};

	const handleSkillChange = async (newValue) => {
		await setValue(newValue);
		let newArr = await [...newValue];
		let val = await newArr?.pop();
		const skillObj = {
			sklUsrId: user?.usrId,
			sklGsklId: val,
			sklType: 'PERSONAL',
		};
		await console.log(val);

		const res = await dispatch(saveSkill(skillObj));
		if (res?.payload?.success) {
			await handleFetchSkills();
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	};

	const onFinish = async () => {};

	async function handleFetchSkills() {
		const resp = await dispatch(fetchSkills(usrObj));

		if (resp?.payload?.success) {
			await setskillsList(resp?.payload?.data?.result);
		}
	}

	async function handleDeleteSkill(id) {
		await dispatch(deleteSkill(id));
		await handleFetchSkills();
	}

	useEffect(() => {}, [skillsList, globalSkillsList]);

	useEffect(() => {
		setskillsList(mySkills);
	}, [mySkills]);

	useEffect(() => {
		setglobalSkillsList(globalSkills);
	}, [globalSkills]);

	useEffect(() => {
		handleFetchSkills();
	}, []);

	return (
		<>
			<div className='mt-[4.5rem]'>
				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: '100%',
					}}
					form={form}>
					{viewOnly ? null : (
						<div
							style={{
								borderRadius: '40px',
								border: '1px solid #BCBCBC',
								background: '#FFF',
							}}
							className='px-3 py-1 bg-transparent flex items-center justify-between max-w-full min-w-[30%] mb-10'>
							<div className='flex items-center w-full'>
								{showSearch ? (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='28'
										height='28'
										viewBox='0 0 28 28'
										fill='none'>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M12.6875 4.375C8.09663 4.375 4.375 8.09663 4.375 12.6875C4.375 17.2784 8.09663 21 12.6875 21C17.2784 21 21 17.2784 21 12.6875C21 8.09663 17.2784 4.375 12.6875 4.375ZM2.625 12.6875C2.625 7.13013 7.13013 2.625 12.6875 2.625C18.2449 2.625 22.75 7.13013 22.75 12.6875C22.75 18.2449 18.2449 22.75 12.6875 22.75C7.13013 22.75 2.625 18.2449 2.625 12.6875Z'
											fill='#333333'
										/>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M18.5656 18.5649C18.9073 18.2232 19.4613 18.2232 19.803 18.5649L25.1187 23.8805C25.4604 24.2222 25.4604 24.7762 25.1187 25.1179C24.777 25.4596 24.2229 25.4596 23.8812 25.1179L18.5656 19.8023C18.2239 19.4606 18.2239 18.9066 18.5656 18.5649Z'
											fill='#333333'
										/>
									</svg>
								) : null}

								<Select
									maxTagCount={1}
									maxCount={8}
									loading={skillLoading}
									value={value}
									showSearch
									id='search-select'
									mode='multiple'
									className='!placeholder-[#333] max-h-auto h-[44px] w-full focus:border-none focus:outline-none focus:ring-0 active:ring-0 search-select'
									placeholder={'Search Skills'}
									optionFilterProp='label'
									onChange={handleSkillChange}
									onSearch={handleSearch}
									onFocus={() => handleSearch(' ')}
									options={(skillData || []).map((d) => ({
										value: d.value,
										label: d.text,
									}))}
								/>
							</div>
						</div>
					)}

					<div className='mt-[4.13rem]'>
						<p className='heading_heading_skills'>Chosen skills:</p>
						{skillLoading ? (
							<Spin className='spin-white' size='large' />
						) : (
							<div className='flex items-center flex-wrap mt-[1.5rem]'>
								{skillsList?.map((item) => {
									return (
										<>
											<div className='bg-[#EBE9FE] rounded-[63px] text-center p-[0.75rem] mr-[1.5rem] flex items-center justify-between mb-3'>
												<span className='heading_heading_5 !text-[#1B47B4]'>
													{item?.skillName}
												</span>
												<button
													onClick={() => handleDeleteSkill(item?.sklId)}
													type='button'
													className='ml-[0.5rem]'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'>
														<path
															d='M18.75 5.25L5.25 18.75'
															stroke='#1B47B4'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
														/>
														<path
															d='M18.75 18.75L5.25 5.25'
															stroke='#1B47B4'
															strokeWidth='2'
															strokeLinecap='round'
															strokeLinejoin='round'
														/>
													</svg>
												</button>
											</div>
										</>
									);
								})}
							</div>
						)}
					</div>
				</Form>
			</div>
		</>
	);
}
