import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { dashboardCount } from '../../features/job/jobSlice';
import { useNavigate } from 'react-router-dom';
import ScrollTo from '../../ScrollTo';
import JumbotronOne from '../../components/JambotronOne';
import RecommendedJobs from './dash_steps/RecommendedJobs';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import ReferredJobs from './dash_steps/ReferredJobs';
import SavedJob from './dash_steps/SavedJob';
import AppliedJob from './dash_steps/AppliedJobs';

const profileTabs = [
	{
		label: <span className='ml-2'>Recommended</span>,
		key: '1',
		children: <RecommendedJobs />,
	},
	{
		label: <span className='ml-2'>Referrals</span>,
		key: '2',
		children: <ReferredJobs />,
	},
	{
		label: <span className='ml-2'>Saved jobs</span>,
		key: '3',
		children: <SavedJob />,
	},
	{
		label: <span className='ml-2'>Applied jobs</span>,
		key: '4',
		children: <AppliedJob />,
	},
];

export default function ActiveDash() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user } = useSelector((state) => state.auth);
	const { dashCount } = useSelector((state) => state.job);

	const [selected, setselected] = useState(1);
	const [activeTab, setactiveTab] = useState('1');
	const [dashCountObj, setdashCountObj] = useState(dashCount);

	const usrObj = {
		usrId: user?.usrId,
	};

	const cardInfo = [
		{
			key: 1,
			title: 'Recommended',
			count: dashCountObj?.recommendedCount,
			icon:
				selected !== 1 ? (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<rect width='24' height='24' rx='8' fill='#F9FDFF' />
						<path
							d='M3 9.75H7.5V19.5H3C2.80109 19.5 2.61032 19.421 2.46967 19.2803C2.32902 19.1397 2.25 18.9489 2.25 18.75V10.5C2.25 10.3011 2.32902 10.1103 2.46967 9.96967C2.61032 9.82902 2.80109 9.75 3 9.75Z'
							stroke='#212121'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M7.5 9.75L11.25 2.25C12.0456 2.25 12.8087 2.56607 13.3713 3.12868C13.9339 3.69129 14.25 4.45435 14.25 5.25V7.5H20.25C20.4628 7.50006 20.6731 7.54539 20.867 7.63297C21.0609 7.72056 21.234 7.8484 21.3747 8.008C21.5154 8.1676 21.6206 8.35532 21.6832 8.55868C21.7458 8.76204 21.7644 8.97639 21.7378 9.1875L20.6128 18.1875C20.5672 18.5499 20.3908 18.8832 20.1169 19.1249C19.843 19.3665 19.4903 19.4999 19.125 19.5H7.5'
							stroke='#212121'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				) : (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<rect width='24' height='24' rx='8' fill='#F9FDFF' />
						<path
							opacity='0.2'
							d='M3 9.75H7.5V19.5H3C2.80109 19.5 2.61032 19.421 2.46967 19.2803C2.32902 19.1397 2.25 18.9489 2.25 18.75V10.5C2.25 10.3011 2.32902 10.1103 2.46967 9.96967C2.61032 9.82902 2.80109 9.75 3 9.75Z'
							fill='#1B47B4'
						/>
						<path
							d='M3 9.75H7.5V19.5H3C2.80109 19.5 2.61032 19.421 2.46967 19.2803C2.32902 19.1397 2.25 18.9489 2.25 18.75V10.5C2.25 10.3011 2.32902 10.1103 2.46967 9.96967C2.61032 9.82902 2.80109 9.75 3 9.75Z'
							stroke='#1B47B4'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M7.5 9.75L11.25 2.25C12.0456 2.25 12.8087 2.56607 13.3713 3.12868C13.9339 3.69129 14.25 4.45435 14.25 5.25V7.5H20.25C20.4628 7.50006 20.6731 7.54539 20.867 7.63297C21.0609 7.72056 21.234 7.8484 21.3747 8.008C21.5154 8.1676 21.6206 8.35532 21.6832 8.55868C21.7458 8.76204 21.7644 8.97639 21.7378 9.1875L20.6128 18.1875C20.5672 18.5499 20.3908 18.8832 20.1169 19.1249C19.843 19.3665 19.4903 19.4999 19.125 19.5H7.5'
							stroke='#1B47B4'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				),
		},
		{
			key: 2,
			title: 'Referrals',
			count: dashCountObj?.referralsCount,
			icon:
				selected !== 2 ? (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<path
							d='M12 17.7272L17.13 20.8819C17.2631 20.9627 17.4172 21.0023 17.5728 20.9955C17.7283 20.9888 17.8784 20.9361 18.004 20.8441C18.1296 20.7521 18.2252 20.6249 18.2785 20.4786C18.3319 20.3323 18.3407 20.1735 18.3038 20.0222L16.9088 14.1357L21.4744 10.1982C21.5908 10.096 21.6748 9.9619 21.7158 9.81253C21.7569 9.66317 21.7534 9.50504 21.7056 9.35767C21.6578 9.21031 21.568 9.08015 21.4471 8.98328C21.3262 8.88641 21.1796 8.82705 21.0254 8.81255L15.0338 8.32505L12.7257 2.73755C12.6668 2.5934 12.5664 2.47004 12.4371 2.38321C12.3079 2.29637 12.1557 2.25 12 2.25C11.8443 2.25 11.6922 2.29637 11.5629 2.38321C11.4337 2.47004 11.3333 2.5934 11.2744 2.73755L8.96629 8.32505L2.97473 8.81255C2.81945 8.82619 2.67163 8.88528 2.54973 8.98244C2.42784 9.0796 2.33728 9.21053 2.28937 9.35886C2.24146 9.5072 2.23832 9.66636 2.28035 9.81647C2.32237 9.96658 2.4077 10.101 2.52567 10.2029L7.09129 14.1404L5.69629 20.0222C5.65941 20.1735 5.66821 20.3323 5.72156 20.4786C5.77492 20.6249 5.87044 20.7521 5.99606 20.8441C6.12168 20.9361 6.27175 20.9888 6.42731 20.9955C6.58287 21.0023 6.73694 20.9627 6.87004 20.8819L12 17.7272Z'
							stroke='#212121'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				) : (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<path
							opacity='0.2'
							d='M12 17.7272L17.13 20.8819C17.2631 20.9627 17.4172 21.0023 17.5727 20.9955C17.7283 20.9888 17.8783 20.9361 18.004 20.8441C18.1296 20.7521 18.2251 20.6249 18.2785 20.4786C18.3318 20.3323 18.3406 20.1735 18.3037 20.0222L16.9087 14.1357L21.4744 10.1982C21.5908 10.096 21.6747 9.9619 21.7158 9.81253C21.7569 9.66317 21.7533 9.50504 21.7055 9.35767C21.6578 9.21031 21.5679 9.08015 21.447 8.98328C21.3261 8.88641 21.1795 8.82705 21.0253 8.81255L15.0337 8.32505L12.7256 2.73755C12.6668 2.5934 12.5663 2.47004 12.4371 2.38321C12.3078 2.29637 12.1557 2.25 12 2.25C11.8443 2.25 11.6921 2.29637 11.5629 2.38321C11.4336 2.47004 11.3332 2.5934 11.2744 2.73755L8.96623 8.32505L2.97467 8.81255C2.81939 8.82619 2.67156 8.88528 2.54967 8.98244C2.42778 9.0796 2.33722 9.21053 2.28931 9.35886C2.2414 9.5072 2.23826 9.66636 2.28028 9.81647C2.32231 9.96658 2.40764 10.101 2.52561 10.2029L7.09123 14.1404L5.69623 20.0222C5.65935 20.1735 5.66815 20.3323 5.7215 20.4786C5.77486 20.6249 5.87038 20.7521 5.996 20.8441C6.12162 20.9361 6.27169 20.9888 6.42725 20.9955C6.58281 21.0023 6.73688 20.9627 6.86998 20.8819L12 17.7272Z'
							fill='#1B47B4'
						/>
						<path
							d='M12 17.7272L17.13 20.8819C17.2631 20.9627 17.4172 21.0023 17.5727 20.9955C17.7283 20.9888 17.8783 20.9361 18.004 20.8441C18.1296 20.7521 18.2251 20.6249 18.2785 20.4786C18.3318 20.3323 18.3406 20.1735 18.3037 20.0222L16.9087 14.1357L21.4744 10.1982C21.5908 10.096 21.6747 9.9619 21.7158 9.81253C21.7569 9.66317 21.7533 9.50504 21.7055 9.35767C21.6578 9.21031 21.5679 9.08015 21.447 8.98328C21.3261 8.88641 21.1795 8.82705 21.0253 8.81255L15.0337 8.32505L12.7256 2.73755C12.6668 2.5934 12.5663 2.47004 12.4371 2.38321C12.3078 2.29637 12.1557 2.25 12 2.25C11.8443 2.25 11.6921 2.29637 11.5629 2.38321C11.4336 2.47004 11.3332 2.5934 11.2744 2.73755L8.96623 8.32505L2.97467 8.81255C2.81939 8.82619 2.67156 8.88528 2.54967 8.98244C2.42778 9.0796 2.33722 9.21053 2.28931 9.35886C2.2414 9.5072 2.23826 9.66636 2.28028 9.81647C2.32231 9.96658 2.40764 10.101 2.52561 10.2029L7.09123 14.1404L5.69623 20.0222C5.65935 20.1735 5.66815 20.3323 5.7215 20.4786C5.77486 20.6249 5.87038 20.7521 5.996 20.8441C6.12162 20.9361 6.27169 20.9888 6.42725 20.9955C6.58281 21.0023 6.73688 20.9627 6.86998 20.8819L12 17.7272Z'
							stroke='#1B47B4'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				),
		},
		{
			key: 3,
			title: 'Saved jobs',
			count: dashCountObj?.savedCount,
			icon:
				selected === 3 ? (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<path
							opacity='0.2'
							d='M18 21L12 17.25L6 21V4.5C6 4.30109 6.07902 4.11032 6.21967 3.96967C6.36032 3.82902 6.55109 3.75 6.75 3.75H17.25C17.4489 3.75 17.6397 3.82902 17.7803 3.96967C17.921 4.11032 18 4.30109 18 4.5V21Z'
							fill='#1B47B4'
						/>
						<path
							d='M18 21L12 17.25L6 21V4.5C6 4.30109 6.07902 4.11032 6.21967 3.96967C6.36032 3.82902 6.55109 3.75 6.75 3.75H17.25C17.4489 3.75 17.6397 3.82902 17.7803 3.96967C17.921 4.11032 18 4.30109 18 4.5V21Z'
							stroke='#1B47B4'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				) : (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<path
							d='M18 21L12 17.25L6 21V4.5C6 4.30109 6.07902 4.11032 6.21967 3.96967C6.36032 3.82902 6.55109 3.75 6.75 3.75H17.25C17.4489 3.75 17.6397 3.82902 17.7803 3.96967C17.921 4.11032 18 4.30109 18 4.5V21Z'
							stroke='#212121'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				),
		},
		{
			key: 4,
			title: 'Applied Jobs',
			count: dashCountObj?.appliedCount,
			icon:
				selected === 4 ? (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<path
							opacity='0.2'
							d='M12.75 11.2501L10.5 13.5001V6.04883L6.21937 10.2807C6.07899 10.4212 6.00009 10.6117 6 10.8104V18.0001H13.1897C13.3883 18 13.5788 17.9211 13.7194 17.7807L20.2125 11.2126L12.75 11.2501Z'
							fill='#1B47B4'
						/>
						<path
							d='M17.25 6.75L3 21'
							stroke='#1B47B4'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M13.7194 17.7809C13.5788 17.9213 13.3883 18.0002 13.1897 18.0003H6V10.8106C6.00009 10.6119 6.07899 10.4214 6.21937 10.2809L12.7875 3.78777C13.7721 2.80315 15.1075 2.25 16.5 2.25C17.1895 2.25 17.8722 2.3858 18.5092 2.64965C19.1462 2.9135 19.725 3.30024 20.2125 3.78777C20.7 4.2753 21.0868 4.85409 21.3506 5.49108C21.6145 6.12807 21.7503 6.81079 21.7503 7.50027C21.7503 8.18974 21.6145 8.87247 21.3506 9.50946C21.0868 10.1464 20.7 10.7252 20.2125 11.2128L13.7194 17.7809Z'
							stroke='#1B47B4'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M10.5 6.04883V13.5001'
							stroke='#1B47B4'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M12.75 11.25H20.175'
							stroke='#1B47B4'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				) : (
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<path
							d='M17.25 6.75L3 21'
							stroke='#1B47B4'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M13.7194 17.7809C13.5788 17.9213 13.3883 18.0002 13.1897 18.0003H6V10.8106C6.00009 10.6119 6.07899 10.4214 6.21937 10.2809L12.7875 3.78777C13.7721 2.80315 15.1075 2.25 16.5 2.25C17.1895 2.25 17.8722 2.3858 18.5092 2.64965C19.1462 2.9135 19.725 3.30024 20.2125 3.78777C20.7 4.2753 21.0868 4.85409 21.3506 5.49108C21.6145 6.12807 21.7503 6.81079 21.7503 7.50027C21.7503 8.18974 21.6145 8.87247 21.3506 9.50946C21.0868 10.1464 20.7 10.7252 20.2125 11.2128L13.7194 17.7809Z'
							stroke='#212121'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M10.5 6.04883V13.5001'
							stroke='#212121'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M12.75 11.25H20.175'
							stroke='#212121'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				),
		},
	];

	function handleSelected(item) {
		setselected(item.key);
		setactiveTab(String(item.key));
	}

	function handleCvBuilder() {
		return navigate('/cv-cover-letter');
	}

	function currentHour() {
		const currentHour = moment().format('HH');
		if (currentHour === 0 || currentHour < 12) return 'morning';
		else if (currentHour <= 19) return 'afternoon';
		else return 'evening';
	}

	async function handleFetch() {
		if (!user?.usrId) return;
		await dispatch(dashboardCount(usrObj));
	}

	useEffect(() => {}, [dashCountObj]);

	useEffect(() => {
		setdashCountObj(dashCount);
	}, [dashCount]);

	useEffect(() => {
		handleFetch();
	}, [user]);

	return (
		<>
			<ScrollTo />
			<div className='p-3 lg:p-0 lg:mr-[38px] lg:ml-[22px] mt-[15px]'>
				<JumbotronOne
					text1={`Good ${currentHour()},`}
					text2={`${user?.usrFirstName}.`}
					text3={''}
					text4={'Let’s see what you’ve been up to.'}
					// txtBlue={true}
				/>

				<div className='bg-white py-[.8rem]'>
					<div className='pt-[1.2rem] px-[.75rem] lg:px-[2.5rem]'>
						<span className='heading_heading_4'>Quick menu</span>

						<div className='flex gap-x-[1.5rem] pb-5 lg:min-w-[57rem] w-full mt-[1.63rem]'>
							<Splide
								className='custom-splide'
								options={{
									rewind: true,
									gap: '1.5rem',
									type: 'slide',
									perPage: 4,
									rewindByDrag: true,
									width: '100%',
									pagination: true,
									drag: true,
									direction: 'ltr',
									releaseWheel: true,
									perMove: 1,
									keyboard: 'global',
									breakpoints: {
										1440: {
											perPage: 3,
										},
										1023: {
											perPage: 3,
										},
										991: {
											perPage: 3,
										},
										990: {
											perPage: 2,
										},
										640: {
											perPage: 1,
										},
									},
									trimSpace: true,
								}}
								aria-label='Dashboard cards'>
								{cardInfo.map((item, index) => {
									return (
										<SplideSlide key={index}>
											<button
												style={{
													width: '100%',
													height: '6.3125rem',
													flexShrink: 0,
													borderRadius: '0.5rem',
													background:
														selected === item.key
															? '#F9FDFF'
															: '#FFF',
													boxShadow:
														selected === item.key
															? '0px 4px 4px 0px rgba(27, 71, 180, 0.30)'
															: '0px 4px 4px rgba(0, 0, 0, 0.05',
													padding:
														'1.37rem 1.5rem 2rem 1.75rem',
												}}
												onClick={() =>
													handleSelected(item)
												}
												key={item?.key}
												type='button'>
												<div className='flex w-full justify-between'>
													<div className='flex flex-col items-start justify-start'>
														<span
															className={`label_label_2 ${
																selected ===
																item.key
																	? '!text-blueDark'
																	: '!text-black21'
															}`}>
															{item?.title}
														</span>
														<span
															className={`heading_heading_3 ${
																selected ===
																item.key
																	? '!text-blueDark'
																	: '!text-black21'
															}`}>
															{item?.count}
														</span>
													</div>

													{item?.icon}
												</div>
											</button>
										</SplideSlide>
									);
								})}

								<SplideSlide>
									<button
										onClick={handleCvBuilder}
										style={{
											display: 'flex',
											width: '100%',
											height: '6.3125rem',
											flexShrink: 0,
											borderRadius: '0.5rem',
											background: '#FFF',
											boxShadow:
												'0px 4px 4px rgba(0, 0, 0, 0.05',
											padding:
												'1.37rem 1.5rem 2rem 1.75rem',
										}}
										type='button'>
										<div className='flex w-full items-start justify-between'>
											<div className='flex flex-col items-start justify-start'>
												<span className='label_label_2 !text-black21'>
													CV Builder
												</span>
												<span className='heading_heading_3'>
													{' '}
												</span>
											</div>

											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'>
												<path
													d='M12 15C15.866 15 19 11.866 19 8C19 4.13401 15.866 1 12 1C8.13401 1 5 4.13401 5 8C5 11.866 8.13401 15 12 15Z'
													stroke='#212121'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
												<path
													d='M8.21 13.8928L7 23.0028L12 20.0028L17 23.0028L15.79 13.8828'
													stroke='#212121'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
											</svg>
										</div>
									</button>
								</SplideSlide>
							</Splide>
						</div>

						<div className='mt-[1.56rem]'>
							<Tabs
								activeKey={activeTab}
								className='tabless'
								defaultActiveKey='1'
								items={profileTabs}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
