import { useNavigate } from 'react-router-dom';
import JumbotronOne from '../../components/JambotronOne';
import ScrollTo from '../../ScrollTo';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchPersonalNotificationSettings,
	handleNotificationObj,
	savePersonalNotificationSetting,
} from '../../features/global/globalSlice';

const notOn = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='40'
		height='20'
		viewBox='0 0 40 20'
		fill='none'>
		<rect width='40' height='20' rx='10' fill='#34B849' />
		<circle cx='29' cy='10' r='8' fill='white' />
	</svg>
);

const notOff = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='40'
		height='20'
		viewBox='0 0 40 20'
		fill='none'>
		<rect width='40' height='20' rx='10' fill='#E2E8F0' />
		<circle cx='11' cy='10' r='8' fill='#64748B' />
	</svg>
);

const arrowIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'>
		<g clipPath='url(#clip0_12706_19572)'>
			<path d='M24 0H0V24H24V0Z' fill='white' />
			<path
				d='M9 4.5L16.5 12L9 19.5'
				stroke='#212121'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</g>
		<defs>
			<clipPath id='clip0_12706_19572'>
				<rect width='24' height='24' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

export default function NotificationSettings() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { personalNotifications } = useSelector((state) => state.global);

	let isAll = personalNotifications?.find(
		(item) => item?.pnsType === 'ALL_NOTIFICATION'
	);

	const [allNotifications, setallNotifications] = useState(isAll?.pnsIsMobile);

	async function handleToggleIsAll() {
		const saveObj = {
			...isAll,
			pnsIsMobile: !allNotifications,
		};
		const res = await dispatch(savePersonalNotificationSetting(saveObj));
		if (res?.payload?.success) {
			handleFetch();
		}
	}

	async function handleFetch() {
		await dispatch(fetchPersonalNotificationSettings(user?.usrId));
		await dispatch(handleNotificationObj({}));
	}

	async function handleUpdateNotifications(item) {
		await dispatch(handleNotificationObj(item));
		await navigate('/settings/notifications/update');
	}

	useEffect(() => {
		handleFetch();
	}, []);

	useEffect(() => {
		setallNotifications(isAll?.pnsIsMobile);
	}, [personalNotifications]);

	const notArray = [
		{
			id: 1,
			title: 'New job alerts',
			subTitle:
				'Get notified on new job vacancies that match your interests or search activity',
			inApp: 'In-app',
			email: 'Email',
			sms: 'SMS',
			type: 'JOB_ALERT',
		},
		{
			id: 2,
			title: 'Saved job alerts',
			subTitle: 'These are notifications with reminders about your saved jobs.',
			inApp: 'In-app',
			email: 'Email',
			sms: 'SMS',
			type: 'SAVE_JOB',
		},
		{
			id: 3,
			title: 'Job recommendations',
			subTitle:
				'These are notifications about jobs you might be interested in, based on your activity.',
			inApp: 'In-app',
			email: 'Email',
			sms: 'SMS',
			type: 'RECOMMEND_JOB',
		},
		{
			id: 4,
			title: 'Job application updates',
			subTitle: 'These are notifications about jobs you have applied to.',
			inApp: 'In-app',
			email: 'Email',
			sms: 'SMS',
			type: 'APPLICATION_STATUS',
		},
	];

	function combineArrays(array1, array2) {
		return array2?.map((item) => {
			const match = array1?.find((a) => a?.pnsType === item?.type);
			return match ? { ...item, ...match } : item;
		});
	}

	const combinedArray = combineArrays(personalNotifications, notArray);

	return (
		<>
			<ScrollTo />
			<div className='p-3 lg:p-0 lg:mr-[38px] lg:ml-[22px] mt-[15px]'>
				<JumbotronOne
					text1={`Settings`}
					text2={``}
					text3={''}
					text4={'Manage your account preferences'}
				/>

				<div className='mt-[.75rem] flex flex-col rounded-[0.5rem] bg-white w-full min-h-[38.125rem] h-auto pb-[3rem] max-h-full py-[3.5rem] pr-3'>
					<div className='flex justify-start lg:pl-[1.25rem]'>
						<button onClick={() => navigate(-1)} className='back-btn'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='20'
								height='20'
								viewBox='0 0 20 20'
								fill='none'>
								<path
									d='M8.33317 18.0443L0.291504 10.0026L8.33317 1.96094L9.52067 3.14844L2.64567 10.0026L9.52067 16.8568L8.33317 18.0443Z'
									fill='#616161'
								/>
							</svg>
							<span>Back</span>
						</button>
					</div>

					<div className='mt-[2.5rem] pl-[1.25rem] lg:pl-[2.75rem] w-full h-full flex flex-col'>
						<div
							style={{
								marginBottom: allNotifications ? '2.75rem' : null,
							}}
							className='w-full flex justify-between items-center'>
							<span className='heading_heading_3'>Allow notifications</span>
							<button
								className='flex items-center gap-x-[.5rem] lg:pr-[4.87rem]'
								onClick={handleToggleIsAll}>
								<span className='label_label_1 !text-[#212121]'>
									{allNotifications ? 'On' : 'Off'}
								</span>
								{allNotifications ? notOn : notOff}
							</button>
						</div>

						{!allNotifications ? (
							<span className='paragraph_paragraph_1 !text-[#212121] mt-[2.75rem]'>
								When Notifications are turned on, you may choose which ones you
								would like to receive
							</span>
						) : null}

						{allNotifications ? (
							<>
								<div className='flex flex-col gap-y-[2.63rem] h-full w-full lg:pr-[4.31rem]'>
									{combinedArray.map((item, index) => {
										return (
											<>
												<div
													key={index}
													className='flex justify-between items-center pb-[.5rem] border-b-[0.0625rem] border-[#F5F5F5]'>
													<div className='flex flex-col gap-y-[.75rem]'>
														<span className='label_label_1 !text-[#212121]'>
															{item?.title}
														</span>
														<span className='paragraph_paragraph_1 !text-[#616161]'>
															{item?.pnsIsMobile ||
															item?.pnsIsEmail ||
															item?.pnsIsSms ? (
																<>
																	({item?.pnsIsMobile && item.inApp}
																	{item?.pnsIsEmail &&
																		(item.pnsIsMobile ? ', ' : '') + item.email}
																	{item?.pnsIsSms &&
																		(item.pnsIsMobile || item.pnsIsEmail
																			? ', '
																			: '') + item.sms}
																	)
																</>
															) : (
																'None'
															)}
														</span>
													</div>

													<button
														onClick={() => handleUpdateNotifications(item)}>
														{arrowIcon}
													</button>
												</div>
											</>
										);
									})}
								</div>
							</>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
}
