import React, { useState } from 'react';
import '../signin/signin.css';
import { useNavigate } from 'react-router-dom';
import AuthHeader from '../../layouts/AuthHeader';
import RegistrationTypeCard from './RegistrationTypeCard';
import { cleanSignupEmployerDetails } from '../../features/auth/authSlice';
import { useDispatch } from 'react-redux';

const regItems = [
	{
		key: 1,
		title: 'I’m a job seeker',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='32'
				height='32'
				viewBox='0 0 32 32'
				fill='none'>
				<path
					d='M16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C11.5817 4 8 7.58172 8 12C8 16.4183 11.5817 20 16 20Z'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M4 27C6.42125 22.8162 10.8187 20 16 20C21.1813 20 25.5787 22.8162 28 27'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		),
	},
	{
		key: 2,
		title: 'I’m an employer',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='32'
				height='32'
				viewBox='0 0 32 32'
				fill='none'>
				<path
					d='M27 8H5C4.44772 8 4 8.44772 4 9V25C4 25.5523 4.44772 26 5 26H27C27.5523 26 28 25.5523 28 25V9C28 8.44772 27.5523 8 27 8Z'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M21 8V6C21 5.46957 20.7893 4.96086 20.4142 4.58579C20.0391 4.21071 19.5304 4 19 4H13C12.4696 4 11.9609 4.21071 11.5858 4.58579C11.2107 4.96086 11 5.46957 11 6V8'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M28 14.7887C24.3532 16.8987 20.2132 18.0066 16 17.9999C11.7869 18.0067 7.64702 16.8992 4 14.7899'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M14 14H18'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		),
	},
	{
		key: 3,
		title: 'I’m an employment agency',
		icon: (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='32'
				height='32'
				viewBox='0 0 32 32'
				fill='none'>
				<path
					d='M17 27V4.00001C16.9999 3.81903 16.9507 3.64147 16.8576 3.48627C16.7645 3.33106 16.631 3.20403 16.4714 3.11871C16.3118 3.03339 16.1321 2.99298 15.9513 3.0018C15.7705 3.01061 15.5955 3.06832 15.445 3.16876L5.445 9.83501C5.30784 9.92652 5.19543 10.0505 5.11779 10.196C5.04015 10.3415 4.99969 10.5039 5 10.6688V27'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M17 11H26C26.2652 11 26.5196 11.1054 26.7071 11.2929C26.8946 11.4804 27 11.7348 27 12V27'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M2 27H30'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M13 14V16'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M9 14V16'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M9 21V23'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M13 21V23'
					stroke='#212121'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		),
	},
];

export default function RegistrationType() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [active, setactive] = useState();

	function handleSetActiveItem(key) {
		setactive(key);
	}

	function handleNext() {
		if (active === 1) {
			navigate('/registration');
		} else if (active === 2) {
			dispatch(cleanSignupEmployerDetails());
			navigate('/registration-employer');
		} else {
			navigate('/registration-agency');
		}
	}

	return (
		<>
			<AuthHeader hide={true} />
			<div className='bg-[#052B85] w-full min-h-[100vh] h-auto flex flex-col'>
				<div className='flex flex-col justify-center items-center h-full overflow-hidden w-full mb-[5.5rem]'>
					<div className='w-full h-full flex items-center flex-col mt-[8.44rem] px-3 xl:px-[5rem] lg:px-0'>
						<span className='login_title'>
							Join as a job seeker, employer or agent
						</span>

						<div className='flex flex-wrap justify-center gap-[1.19rem] items-center w-full mt-[3.75rem]'>
							{regItems.map((item, index) => {
								return (
									<RegistrationTypeCard
										key={index}
										active={active}
										handleSetActiveItem={
											handleSetActiveItem
										}
										item={item}
									/>
								);
							})}
						</div>

						<div className='mt-[5.81rem]'>
							{active ? (
								<button
									onClick={handleNext}
									className='reg_btn'>
									Next
								</button>
							) : (
								<button className='reg_btn_disabled'>
									Next
								</button>
							)}
						</div>

						<button
							onClick={() => navigate('/login')}
							className='flex items-center justify-center mt-[3.25rem] paragraph_paragraph_2 !text-white'>
							<span>Already have an account?</span>
							<span className='ml-[.3rem] underline'>Login</span>
						</button>
					</div>
				</div>

				<div className='w-full flex justify-center items-center'>
					<span className='paragraph_paragraph_5'>
						© 2024 NEAIMS. All Rights Reserved.
					</span>
				</div>
			</div>
		</>
	);
}
