import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../../features/auth/authSlice';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { Input, Spin } from 'antd';
import { fetchPasswordSetups } from '../../../../features/global/globalSlice';
import ReactPasswordChecklist from 'react-password-checklist';
import circleSvg from '../../../../assets/CheckCircle.svg';

export default function NewPasswordStep({ handleCancel, handleNext }) {
	const dispatch = useDispatch();

	const { authLoading, user } = useSelector((state) => state.auth);
	const { pwdSetup } = useSelector((state) => state.global);

	const [phone, setphone] = useState('');
	const [confirm, setconfirm] = useState('');
	const [checkValue, setcheckValue] = useState(false);

	let pswrdRules = [];

	let pwdMsg = {};

	pwdSetup?.forEach((item) => {
		pswrdRules.push(item?.pwdDesc);
		pwdMsg[item.pwdDesc] = item?.pwdName;
	});

	function handlePasswordCheck(values) {
		setcheckValue(values);
	}

	function handleClose() {
		handleCancel();
		setphone('');
	}

	async function handleSubmit() {
		let usrObj = {
			usrId: user?.usrId,
			usrNationalId: user?.usrNationalId,
			usrEncryptedPassword: phone,
		};

		const res = await dispatch(updateUser(usrObj));

		if (res?.payload?.success) {
			handleNext();
		} else {
			toast.error(res?.payload?.messages?.message);
		}
	}

	async function handleFetchPwdSetup() {
		await dispatch(fetchPasswordSetups());
	}

	useEffect(() => {}, [pwdSetup]);

	useEffect(() => {
		handleFetchPwdSetup();
	}, []);

	return (
		<>
			<div className='w-full mt-[2.5rem] flex flex-col'>
				<span className='heading_heading_3'>New Password</span>
				<span className='paragraph_paragraph_2 !text-[#4B5563] mt-[1.75rem]'>
					Enter your new password
					<span className='!text-[#F00]'>*</span>
				</span>
			</div>

			<div className='flex flex-col w-full gap-y-[1rem]'>
				<Input.Password
                    placeholder='Enter new password'
					value={phone}
					onChange={(e) => setphone(e.target.value)}
					className='!w-full h-[3rem]'
					style={{
						borderRadius: ' 0.375rem',
						border: '1px solid #D4D4D8',
						background: '#FFF',
						width: '100%',
					}}
				/>

				<Input.Password
                    placeholder='Confirm new password'
					value={confirm}
					onChange={(e) => setconfirm(e.target.value)}
					className='!w-full h-[3rem]'
					style={{
						borderRadius: ' 0.375rem',
						border: '1px solid #D4D4D8',
						background: '#FFF',
						width: '100%',
					}}
				/>

				<ReactPasswordChecklist
					onChange={(val) => handlePasswordCheck(val)}
					className='text-darkBlue mt[-23px]'
					rules={pswrdRules}
					minLength={8}
					value={phone}
					valueAgain={confirm}
					messages={{
						minLength: 'A minimum of 8 characters',
						specialChar: 'At least one symbol (~!@$&)',
						number: 'At least one number (0-9)',
						capital: 'At least one upper case letter (A-Z)',
						match: 'Confirm password to match with the password',
					}}
					iconComponents={{
						ValidIcon: (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth='1.5'
								stroke='currentColor'
								className='w-6 h-6 text-darkBlue'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
								/>
							</svg>
						),
						InvalidIcon: <img loading='lazy' src={circleSvg} alt='lazy' />,
					}}
					validColor='#147CBC'
					iconSize={2}
				/>
			</div>

			<div className='flex items-center justify-end gap-x-[.88rem] mt-[2.87rem] w-full'>
				<button
					onClick={handleClose}
					disabled={authLoading}
					className='modal-cancel-btn'
					type='button'>
					Cancel
				</button>
				<button
					onClick={handleSubmit}
					disabled={authLoading || checkValue === false}
					className='modal-action-btn'
					type='button'>
					{authLoading ? <Spin /> : 'Next'}
				</button>
			</div>
		</>
	);
}
