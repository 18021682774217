import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, InputNumber, Select, Spin, Form } from 'antd';
import ScrollTo from '../../ScrollTo';
import AttestationDocumentsTab from './clearance_tabs/AttestationDocumentsTab';
import {
	fetchAttestationDocSetups,
	fetchAttestationOffices,
} from '../../features/dropDowns/dropDownSlice';
import JumbotronOne from '../../components/JambotronOne';
import {
	applyJob,
	clearActiveForeignJob,
	saveAttestationDocument,
	saveClient,
	saveJob,
	setNewAttestation,
} from '../../features/job/jobSlice';
import { countries } from '../../countries';
import { countryCity } from '../../countryCity';
import { currencyData } from '../../currency';
import PhoneInput from 'react-phone-input-2';
import ForeignClearanceUpload from '../../components/ForeignClearanceUpload';
import { customToast } from '../../notifications';
import { useNavigate } from 'react-router-dom';
import InAppNotificationBar from '../../components/InAppNotificationBar';

let countryOptions = [];

const url = process.env.REACT_APP_API_BASE_URL;

countries.forEach((item) => {
	countryOptions.push({
		label: item?.name,
		value: item?.name,
	});
});

export default function ForeignJobDetails() {
	const [form] = Form.useForm();
	const formRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { newAttestation, jobLoading, attDocuments } = useSelector(
		(state) => state.job
	);
	const { attestationDocs } = useSelector((state) => state.dropDown);
	const { user } = useSelector((state) => state.auth);

	const [city, setcity] = useState([]);
	const [loading, setloading] = useState(false);

	const docsArray = useMemo(() => {
		const tempArray = [];

		attestationDocs?.forEach((item) => {
			let attObj = attDocuments?.find(
				(x) =>
					String(x?.attsdType).toUpperCase() ===
					String(item?.adsName).toUpperCase()
			);

			let obj = {
				id: attObj?.attsdId ?? null,
				docName: attObj?.attsdType ?? item?.adsName,
				value:
					attObj?.attsdName ??
					newAttestation[attObj?.attsdType ?? item?.adsName] ??
					null,
				template: item?.adsFileUrl,
			};
			tempArray.push(obj);
		});

		return tempArray;
	}, [attestationDocs, attDocuments, newAttestation]);

	const handleValuesChange = (allValues) => {
		dispatch(setNewAttestation(allValues));
	};

	function selectedCountry(value) {
		const country = countryCity?.find(
			(city) => String(city.name).toUpperCase() === String(value).toUpperCase()
		);

		const cityNames = country?.cities?.map((city) => city.name);
		setcity(cityNames);
	}

	const handleSuccess = async (response) => {
		await dispatch(
			setNewAttestation({
				...newAttestation,
				[response.fileName]: response.jsonData,
			})
		);
	};

	const handleError = async (response) => {
		await customToast({
			content: response,
			bdColor: 'error',
		});
	};

	async function onFinish(data) {
		await setloading(true);

		let finished = false;

		let hasNull = await docsArray?.filter((item) => item?.value === null);

		console.log(docsArray?.filter((item) => item?.value === null));

		if (hasNull?.length === 0) {
			finished = true;
			console.log('We are finished', finished);
		} else {
			finished = false;
			console.log('We are NOT finished', finished);
		}

		if (finished && newAttestation?.japAttestationLocation) {
			const mainObj = {
				...data,
				...newAttestation,
				japUsrId: user?.usrId,
				japCreatedBy: user?.usrId,
				japStatus: 'MIGRANT_WORKER',
				japProgressStatus: 'SEND_TO_ATTESTATION',
				japJobId: null,
				japType: 'PERSONAL',
				jobInstType: 'PERSONAL',
				cntStatus: 'PERSONAL',
				jobName: data?.jobPosition,
				jobCntId: null,
			};
			const cntCategory = {};
			const japCategory = {};
			const jobCategory = {};
			const otherCategory = {};

			Object.keys(mainObj).forEach((key) => {
				if (key.startsWith('cnt')) {
					cntCategory[key] = mainObj[key];
				} else if (key.startsWith('jap')) {
					japCategory[key] = mainObj[key];
				} else if (key.startsWith('job')) {
					jobCategory[key] = mainObj[key];
				} else {
					otherCategory[key] = mainObj[key]; // Everything else goes here
				}
			});

			const cntRes = await dispatch(saveClient(cntCategory));

			if (cntRes?.payload?.success) {
				jobCategory.jobCntId = cntRes?.payload?.jsonData?.cntId;
				const jobRes = await dispatch(saveJob(jobCategory));

				if (jobRes?.payload?.success) {
					japCategory.japJobId = jobRes?.payload?.jsonData?.jobId;
					const japRes = await dispatch(applyJob(japCategory));

					if (japRes?.payload?.success) {
						let updObj = {
							japId: japRes?.payload?.jsonData?.japId,
							japProgressStatus: 'SEND_TO_ATTESTATION',
							japStatus: 'MIGRANT_WORKER',
						};
						await dispatch(applyJob(updObj));
						await docsArray?.forEach((item) => {
							dispatch(
								saveAttestationDocument({
									attsdId: item?.id,
									attsdType: item.docName,
									attsdName: item.value,
									attsdUsrId: user?.usrId,
									attsdCreatedBy: user?.usrId,
									attsdJapId: japRes?.payload?.jsonData?.japId,
								})
							);
						});
						await setloading(false);
						await dispatch(clearActiveForeignJob());
						await navigate('/foreign-job-clearance');
					} else {
						await customToast({
							content: japRes?.payload?.messages?.message,
							bdColor: 'error',
						});
						await setloading(false);
					}
				} else {
					await customToast({
						content: jobRes?.payload?.messages?.message,
						bdColor: 'error',
					});
					await setloading(false);
				}
			} else {
				await customToast({
					content: cntRes?.payload?.messages?.message,
					bdColor: 'error',
				});
				await setloading(false);
			}
		} else {
			await setloading(false);
			await customToast({
				content:
					'Attestation location must be selected and all attestation documents uploaded before submitting',
				bdColor: 'error',
			});
		}
	}

	async function handleSaveDraft(data) {
		const mainObj = {
			...data,
			...newAttestation,
			japUsrId: user?.usrId,
			japCreatedBy: user?.usrId,
			japStatus: 'MIGRANT_WORKER',
			japProgressStatus: 'SEND_TO_ATTESTATION',
			japJobId: null,
			japType: 'PERSONAL',
			jobInstType: 'PERSONAL',
			cntStatus: 'PERSONAL',
			jobName: data?.jobPosition,
			jobCntId: null,
		};
		const cntCategory = {};
		const japCategory = {};
		const jobCategory = {};
		const otherCategory = {};

		Object.keys(mainObj).forEach((key) => {
			if (key.startsWith('cnt')) {
				cntCategory[key] = mainObj[key];
			} else if (key.startsWith('jap')) {
				japCategory[key] = mainObj[key];
			} else if (key.startsWith('job')) {
				jobCategory[key] = mainObj[key];
			} else {
				otherCategory[key] = mainObj[key]; // Everything else goes here
			}
		});

		const cntRes = await dispatch(saveClient(cntCategory));

		if (cntRes?.payload?.success) {
			jobCategory.jobCntId = cntRes?.payload?.jsonData?.cntId;
			const jobRes = await dispatch(saveJob(jobCategory));

			if (jobRes?.payload?.success) {
				japCategory.japJobId = jobRes?.payload?.jsonData?.jobId;
				const japRes = await dispatch(applyJob(japCategory));

				if (japRes?.payload?.success) {
					let updObj = {
						japId: japRes?.payload?.jsonData?.japId,
						japProgressStatus: 'DRAFT',
						japStatus: 'MIGRANT_WORKER',
					};
					await dispatch(applyJob(updObj));
					await docsArray?.forEach((item) => {
						dispatch(
							saveAttestationDocument({
								attsdId: item?.id,
								attsdType: item.docName,
								attsdName: item.value,
								attsdUsrId: user?.usrId,
								attsdCreatedBy: user?.usrId,
								attsdJapId: japRes?.payload?.jsonData?.japId,
							})
						);
					});
					await setloading(false);
					await dispatch(clearActiveForeignJob());
					await navigate('/foreign-job-clearance');
				} else {
					await customToast({
						content: japRes?.payload?.messages?.message,
						bdColor: 'error',
					});
					await setloading(false);
				}
			} else {
				await customToast({
					content: jobRes?.payload?.messages?.message,
					bdColor: 'error',
				});
				await setloading(false);
			}
		} else {
			await customToast({
				content: cntRes?.payload?.messages?.message,
				bdColor: 'error',
			});
			await setloading(false);
		}
	}

	async function handleFetchDropDowns() {
		await dispatch(fetchAttestationDocSetups());
		await dispatch(fetchAttestationOffices());
	}

	useEffect(() => {}, [newAttestation, docsArray]);

	useEffect(() => {
		handleFetchDropDowns();
	}, []);

	return (
		<>
			<ScrollTo />
			<div className='w-full p-3 lg:p-0 lg:w-[95%] lg:mx-[42px] '>
				<JumbotronOne
					text1={`Your`}
					text2={'attestation'}
					text3={'process.'}
					text4={'Prepare your documentation to work abroad.'}
				/>

				<div className='w-full'>
					<div className='flex flex-col w-full'>
						{newAttestation?.japProgressStatus === 'FAILED_ATTESTATION' ? (
							<>
								<InAppNotificationBar message={newAttestation?.japAttestationRemarks} color={'red'} />
							</>
						) : null}

						<Form
							layout='vertical'
							ref={formRef}
							name='control-ref'
							onFinish={onFinish}
							style={{
								maxWidth: '100%',
							}}
							scrollToFirstError={true}
							initialValues={newAttestation}
							onValuesChange={handleValuesChange}
							form={form}>
							<div className='mt-[1rem] p-3 xl:p-[3.5rem] !pr-0 xl:!pr-10 flex flex-col justify-center w-full bg-white rounded-[.5625rem]'>
								<div className='my-[2.25rem] px-3 xl:px-[1.37rem] flex flex-col lg:flex-row items-start justify-start '>
									<div className='w-[3.45rem]'>
										<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
											<span className='paragraph_paragraph_bb08'>1</span>
										</div>
									</div>

									<div className='w-full flex flex-col ml-0 lg:ml-3 xl:ml-[1.31rem]'>
										<span className='paragraph_paragraph_bb13'>
											Foreign employer details
										</span>

										<Form.Item
											className='table-form-item'
											name='cntName'
											label='Employer Name'
											rules={[
												{
													required: true,
													message: 'Field is required',
												},
											]}>
											<Input className='input' />
										</Form.Item>

										<div className='grid grid-cols-1 lg:grid-cols-2 gap-[1.25rem] mt-[1rem]'>
											<Form.Item
												className='table-form-item'
												label='Country'
												name='cntCountry'
												rules={[
													{
														required: true,
														message: 'Please select a country',
													},
												]}>
												<Select
													onChange={(value) => selectedCountry(value)}
													suffixIcon={
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'>
															<path
																d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
																fill='#212121'
															/>
														</svg>
													}
													showSearch
													options={countryOptions}
												/>
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='cntLocation'
												label='City'
												rules={[
													{
														required: true,
														message: 'Please select a city',
													},
												]}>
												<Select
													suffixIcon={
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'>
															<path
																d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
																fill='#212121'
															/>
														</svg>
													}
													showSearch
													options={city?.map((item) => {
														return {
															label: item,
															value: item,
														};
													})}
												/>
											</Form.Item>

											<Form.Item
												validateTrigger='onBlur'
												className='table-form-item'
												name='cntOfficeMobile'
												label='Phone No.'
												rules={[
													{
														required: true,
														message: 'Please add number',
													},
													{
														validator: (_, value) => {
															if (value && value.length >= 11) {
																return Promise.resolve();
															}
															return Promise.reject(
																new Error(
																	'Phone number must be exactly 10 characters excluding country code'
																)
															);
														},
													},
												]}>
												<PhoneInput
													country={'us'}
													countryCodeEditable={false}
													className='input'
												/>
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='cntEmail'
												label='Email'
												rules={[
													{
														required: true,
														message: 'Field is required',
													},
												]}>
												<Input type='email' className='input' />
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='cntAddress'
												label='Address/Physical Location'
												rules={[
													{
														required: false,
														message: 'Field is required',
													},
												]}>
												<Input className='input' />
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='cntWebsite'
												label='Website'
												rules={[
													{
														required: false,
														message: 'Field is required',
													},
												]}>
												<Input type='url' className='input' />
											</Form.Item>
										</div>
									</div>
								</div>
							</div>

							<div className='mt-[1rem] p-3 !pr-0 lg:!pr-10 lg:p-0 py-3 xl:py-[3.5rem] flex flex-col justify-center w-full bg-white rounded-[.5625rem]'>
								<div className='my-[2.25rem] px-3 xl:px-[1.37rem] flex flex-col lg:flex-row items-start justify-start '>
									<div className='w-[3.45rem]'>
										<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
											<span className='paragraph_paragraph_bb08'>2</span>
										</div>
									</div>

									<div className='w-full flex flex-col ml-0 lg:ml-3 xl:ml-[1.31rem]'>
										<span className='paragraph_paragraph_bb13'>
											Job details
										</span>

										<div className='grid grid-cols-1 lg:grid-cols-2 gap-[1.25rem]'>
											<Form.Item
												className='table-form-item'
												name='japOfferLetterNo'
												label='Employment Offer Letter Ref No.'
												rules={[
													{
														required: false,
														message: 'Field is required',
													},
												]}>
												<Input className='input' />
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='japOfferLetterFile'
												label='Employment Offer Letter'
												rules={[
													{
														required: newAttestation?.japOfferLetterFile?.length
															? false
															: true,
														message: 'Field is required',
													},
												]}>
												<ForeignClearanceUpload
													fileName={newAttestation?.japOfferLetterFile
														?.split('_')
														.pop()}
													name={'japOfferLetterFile'}
													url={url}
													onSuccess={handleSuccess}
													onError={handleError}
													className={'upload-input'}
												/>
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='jobPosition'
												label='Job Position'
												rules={[
													{
														required: true,
														message: 'Field is required',
													},
												]}>
												<Input className='input' />
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='jobDuration'
												label='Contract Period (Months)'
												rules={[
													{
														required: true,
														message: 'Field is required',
													},
												]}>
												<Input className='input' />
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='jobWorkingHours'
												label='Working Hours (per day)'
												rules={[
													{
														required: true,
														message: 'Field is required',
													},
												]}>
												<InputNumber className='input' />
											</Form.Item>

											<div className='w-full flex flex-col lg:flex-row lg:items-center gap-[.75rem]'>
												<Form.Item
													className='table-form-item'
													name='jobCurrency'
													label='Currency'
													rules={[
														{
															required: true,
															message: 'Field is required',
														},
													]}>
													<Select
														style={{
															width: 'fit-content',
															height: '44px',
														}}
														options={
															currencyData?.length > 0 &&
															currencyData?.map((item) => ({
																value: item?.Ccy,
																label: item?.Ccy,
															}))
														}
														showSearch
													/>
												</Form.Item>

												<Form.Item
													className='table-form-item w-full'
													name='jobGrossPay'
													label='Expected Salary'
													rules={[
														{
															required: true,
															message: 'Field is required',
														},
													]}>
													<Input className='input' />
												</Form.Item>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='mt-[1rem] p-3 !pr-0 lg:!pr-10 lg:p-0 py-3 xl:py-[3.5rem] flex flex-col justify-center w-full bg-white rounded-[.5625rem]'>
								<div className='my-[2.25rem] px-3 xl:px-[1.37rem] pb-[5rem] flex flex-col lg:flex-row items-start justify-start '>
									<div className='w-[3.45rem]'>
										<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
											<span className='paragraph_paragraph_bb08'>3</span>
										</div>
									</div>

									<div className='w-full flex flex-col ml-0 lg:ml-3 xl:ml-[1.31rem]'>
										<span className='paragraph_paragraph_bb13'>
											Attestation documents
										</span>

										<AttestationDocumentsTab />
									</div>
								</div>
							</div>

							<div className='w-full flex justify-center items-center flex-col gap-[1rem] mt-[2.5rem]'>
								<button
									type='submit'
									disabled={loading}
									style={{
										border: '0.5px solid #FFF',
									}}
									className='text-white text-[18px] font-bold font-dmSans
                    w-[299px] h-[53px] px-16 py-[19px] bg-blueDark rounded-[38px] 
                    justify-center items-center gap-2.5 inline-flex'>
									{loading ? <Spin className='text-white' /> : 'Submit'}
								</button>

								<button
									onClick={handleSaveDraft}
									type='button'
									disabled={jobLoading}
									className='text-blueDark text-[18px] font-bold font-dmSans
                    min-w-[299px] w-auto h-[53px] px-16 py-[19px] border border-blueDark rounded-[38px] 
                    justify-center items-center gap-2.5 inline-flex'>
									{jobLoading ? <Spin className='blue-spin' /> : 'Save & Exit'}
								</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}
