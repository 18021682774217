import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Table } from 'antd';
import ForeignClearanceUpload from '../../../components/ForeignClearanceUpload';
import {
	fetchAttestationDocuments,
	setNewAttestation,
} from '../../../features/job/jobSlice';
import { customToast } from '../../../notifications';
import { formatImgPath } from '../../../utils';

const url = process.env.REACT_APP_API_BASE_URL;

const downloadIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 16 16'
		fill='none'>
		<path
			d='M6.00008 13H4.50008C4.0038 12.9994 3.51333 12.8932 3.06121 12.6886C2.60909 12.484 2.20565 12.1855 1.87767 11.8131C1.54969 11.4406 1.30467 11.0027 1.15886 10.5283C1.01306 10.0539 0.969798 9.55398 1.03196 9.06162C1.09412 8.56925 1.26028 8.09573 1.51941 7.67248C1.77854 7.24923 2.12471 6.88592 2.53497 6.60667C2.94522 6.32742 3.41017 6.13861 3.89896 6.05276C4.38776 5.96692 4.88922 5.986 5.37008 6.10875'
			stroke='#1B47B4'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M5 8C5 7.20774 5.18827 6.42682 5.54928 5.7216C5.9103 5.01637 6.43373 4.40703 7.07645 3.94379C7.71916 3.48055 8.46276 3.17667 9.24596 3.05719C10.0292 2.93771 10.8295 3.00605 11.5811 3.25658C12.3327 3.50712 13.0141 3.93267 13.5689 4.49817C14.1238 5.06368 14.5363 5.75294 14.7726 6.50916C15.0088 7.26539 15.0619 8.06692 14.9276 8.84771C14.7933 9.6285 14.4754 10.3662 14 11'
			stroke='#1B47B4'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7.5 11L9.5 13L11.5 11'
			stroke='#1B47B4'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M9.5 8V13'
			stroke='#1B47B4'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default function AttestationDocumentsTab() {
	const dispatch = useDispatch();

	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { newAttestation, foreignJobActive, attDocuments } = useSelector(
		(state) => state.job
	);
	const { dropDownLoading, attestationDocs, attestationOffices } = useSelector(
		(state) => state.dropDown
	);

	const view = useRef();

	const [value, setValue] = useState(foreignJobActive?.japAttestationLocation);

	const onOfficeChange = async (val) => {
		let attOffObj = attestationOffices?.find(
			(item) =>
				String(item?.atofName).toUpperCase() === String(val).toUpperCase()
		);

		await dispatch(
			setNewAttestation({
				...newAttestation,
				japAttestationLocation: val,
				japAttestationLocationId: attOffObj?.atofId,
			})
		);

		await setValue(val);
	};

	const docsArray = useMemo(() => {
		const tempArray = [];

		attestationDocs?.forEach((item, index) => {
			let attObj = attDocuments?.find(
				(x) =>
					String(x?.attsdType).toUpperCase() ===
					String(item?.adsName).toUpperCase()
			);

			let obj = {
				id: attObj?.attsdId ?? index,
				docName: attObj?.attsdType ?? item?.adsName,
				value: attObj?.attsdName ?? null,
				template: item?.adsFileUrl,
			};
			tempArray.push(obj);
		});

		return tempArray;
	}, [attestationDocs, attDocuments]);

	const handleSuccess = async (response) => {
		await dispatch(
			setNewAttestation({
				...newAttestation,
				[response.fileName]: response.jsonData,
			})
		);
	};

	const handleError = async (response) => {
		await customToast({
			content: response,
			bdColor: 'error',
			header: 'Error',
		});
	};

	const columns = [
		{
			title: 'Document Title',
			key: 'id',
			dataIndex: 'docName',
		},
		// {
		// 	title: 'Template',
		// 	key: 'id',
		// 	dataIndex: 'template',
		// 	render: (item) => (
		// 		<>
		// 			<a
		// 				target='_blank'
		// 				rel='noreferrer'
		// 				href={formatImgPath(item)}
		// 				download>
		// 				<span className='paragraph_paragraph_3 !text-[#344054] flex items-center gap-x-[.75rem]'>
		// 					{item ? (
		// 						<>
		// 							{downloadIcon}
		// 							<span>Download</span>
		// 						</>
		// 					) : (
		// 						'N/A'
		// 					)}
		// 				</span>
		// 			</a>
		// 		</>
		// 	),
		// },
		{
			title: 'Attached File',
			key: 'id',
			render: (item) => (
				<>
					<button className='w-full'>
						<ForeignClearanceUpload
							className={'att-docs-dragger'}
							fileName={item?.value?.split('_').pop()}
							name={item?.docName}
							keyId={item?.id}
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</button>
				</>
			),
		},
	];

	const onFinish = async () => {
		let finished = false;

		let hasNull = await docsArray?.filter((item) => item?.value === null);

		if (hasNull?.length === 0) {
			finished = true;
		} else {
			finished = false;
		}
		if (finished && foreignJobActive?.japAttestationLocation) {
			console.log('ITS OVER');
		}		
	};

	async function handleFetchAttDocs() {
		await dispatch(fetchAttestationDocuments(foreignJobActive?.japId));
	}

	useEffect(() => {}, [
		foreignJobActive,
		attDocuments,
		attestationDocs,
		docsArray,
	]);

	useEffect(() => {
		handleFetchAttDocs();
	}, []);

	return (
		<div ref={view}>
			<Form
				layout='vertical'
				ref={formRef}
				name='control-ref'
				onFinish={onFinish}
				style={{
					maxWidth: '100%',
				}}
				form={form}
				initialValues={{
					...newAttestation,
				}}>
				<div ref={view} className='mt-5 w-full'>
					<Table
						rowKey={'id'}
						style={{
							maxWidth: '100%',
							width: '100%',
						}}
						scroll={{ x: 500}}
						loading={dropDownLoading}
						className=''
						size={'middle'}
						bordered={true}
						columns={columns}
						dataSource={docsArray}
						pagination={false}
					/>

					<h3 className='text-[22px] font-medium text-black2 mt-10 mb-3'>
						Where would you like your attestation to be done?
					</h3>

					<div className='grid grid-cols-1 xl:grid-cols-2 w-full'>
						<Select
							suffixIcon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
										fill='#212121'
									/>
								</svg>
							}
							showSearch
							style={{
								width: '100%',
								height: '44px',
							}}
							placeholder='Search to Select'
							optionFilterProp='children'
							filterOption={(input, option) =>
								(option?.label?.toLocaleLowerCase() ?? '').includes(
									input?.toLocaleLowerCase()
								)
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							options={
								attestationOffices?.length &&
								attestationOffices?.map((item) => ({
									value: item.atofName,
									label: item.atofName,
								}))
							}
							onChange={onOfficeChange}
							allowClear
							loading={dropDownLoading}
							defaultValue={value}
						/>
					</div>
				</div>
			</Form>
		</div>
	);
}
