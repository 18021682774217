import React, { useEffect, useRef } from 'react';
import './details.css';
import { Form, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	createInstitution,
	
	setSignupEmployerDetails,
} from '../../features/auth/authSlice';
import PhoneInput from 'react-phone-input-2';
import { customToast } from '../../notifications';

function Detail() {
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { institution, authLoading, signUpEmployerDetails } = useSelector(
		(state) => state.auth
	);

	const onFinish = async (data) => {
		data.instWorkTel =
			data.instWorkTel?.charAt(3) === '0'
				? data.instWorkTel?.replace('0', '')
				: data.instWorkTel;		

		const res = await dispatch(
			createInstitution({
				instId:
					Object?.keys(institution)?.length > 0
						? institution?.jsonData?.instId
						: null,
				...data,
				instEconomic: '',
				instType: 'EMPLOYMENT_AGENCY',
				instStatus: 'PENDING_VERIFICATION',
				instPassword: 1234,
				instNeaId: 1,
				instRoleId: 3,
				instNearNeaOffice: 'NEA HQ',
				instNeaVerified: true,
			})
		);

		if (res?.payload?.success) {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'success',
			});
			await navigate('/verify-email');
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	};

	const handleValuesChange = (changedValues, allValues) => {
		dispatch(setSignupEmployerDetails(allValues));
	};

	useEffect(() => {}, []);

	return (
		<div className='flex items-center justify-center'>
			<div className='w-full lg:w-[61.25rem]'>
				<div className='flex flex-col w-full px-3 lg:px-[52px] p-3'>
					<div className='bg-white mt-[3.75rem] pt-[3.75rem] pb-[3.19rem] rounded-[.5625rem]'>
						<h2 className='setup-title text-center'>
							Sign up to <span className='text-blueDark'>recruit </span>
							new talent.
						</h2>
						<p className='heading_heading_5 mt-[1rem] text-center'>
							Fill in your details to create an account.
						</p>
					</div>

					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: '100%',
						}}
						initialValues={signUpEmployerDetails}
						onValuesChange={handleValuesChange}
						form={form}>
						<div className='mt-[1rem] p-3 !pr-0 lg:!pr-10 lg:p-0 py-[3.5rem] flex flex-col justify-center w-full bg-white rounded-[.5625rem]'>
							<div className='my-[2.25rem] px-[1.37rem] flex items-start justify-start '>
								<div className='w-[3.45rem]'>
									<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
										<span className='paragraph_paragraph_bb08'>1</span>
									</div>
								</div>

								<div className='w-full flex flex-col ml-[1.31rem]'>
									<span className='paragraph_paragraph_bb13'>
										Private employment agency details
									</span>

									<Form.Item
										className='table-form-item pb-[1.25rem]'
										name='instName'
										label='Employment agency name'
										rules={[
											{
												required: true,
												message: 'Please add Employer name',
											},
										]}>
										<Input className='input' />
									</Form.Item>

									<div className='grid grid-cols-1 lg:grid-cols-2 pb-[2.5rem] gap-[1.25rem] w-full'>
										<Form.Item
											className='table-form-item'
											name='instRegCertNo'
											label='Company Reg No'
											rules={[
												{
													required: true,
													message: 'Please add business reg no',
												},
											]}>
											<Input className='input' />
										</Form.Item>

										<Form.Item
											className='table-form-item'
											name='instKraPin'
											label='Employment agency KRA PIN'
											rules={[
												{
													required: true,
													message: 'Please add KRA pin',
												},
												{
													pattern: /^[A-Z0-9]{11}$/,
													message:
														'Invalid Kra Pin format. A valid Kra Pin should be 11 alphanumeric characters.',
												},
											]}>
											<Input className='input' />
										</Form.Item>

										<Form.Item
											className='table-form-item'
											name='instEmail'
											label='Agency Email Address'
											extra='Official email should ONLY be for the organization'
											rules={[
												{
													required: true,
													message: 'Please add agency email address',
												},
												{
													type: 'email',
													message: 'Please enter a valid email address',
												},
											]}>
											<Input type='email' className='input' />
										</Form.Item>

										<Form.Item
											className='table-form-item'
											name='instWorkTel'
											label='Office Phone No'
											rules={[
												{
													required: true,
													message: 'Please add office phone no',
												},
												{
													validator: (_, value) => {
														if (value && value.length === 12) {
															return Promise.resolve();
														}
														return Promise.reject(
															new Error(
																'Phone number must be exactly 10 characters'
															)
														);
													},
												},
											]}>
											<PhoneInput
												country='ke'
												countryCodeEditable={false}
												className='input'
											/>
										</Form.Item>
									</div>
								</div>
							</div>
						</div>

						<div className='mt-[1rem] p-3 !pr-0 lg:!pr-10 lg:p-0 py-[3.5rem] flex flex-col justify-center w-full bg-white rounded-[.5625rem]'>
							<div className='my-[2.25rem] px-[1.37rem] flex items-start justify-start '>
								<div className='w-[3.45rem]'>
									<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
										<span className='paragraph_paragraph_bb08'>2</span>
									</div>
								</div>

								<div className='w-full flex flex-col ml-[1.31rem]'>
									<span className='paragraph_paragraph_bb13'>
										Additional details (optional)
									</span>

									<div className='grid grid-cols-1 pb-[2.5rem] gap-[1.25rem] w-full'>
										<Form.Item
											className='table-form-item'
											name='instWebsite'
											label='Official website'>
											<Input className='input' />
										</Form.Item>

										<Form.Item
											className='table-form-item'
											name='instCellPhone'
											label='Office Landline No'
											// rules={[
											// 	{
											// 		required: false,
											// 		validator: (_, value) => {
											// 			if (value && value.length === 12) {
											// 				return Promise.resolve();
											// 			}
											// 			return Promise.reject(
											// 				new Error(
											// 					'Phone number must be exactly 10 characters'
											// 				)
											// 			);
											// 		},
											// 	},
											// ]}
											>
											<PhoneInput
												country='ke'
												countryCodeEditable={false}
												className='input'
											/>
										</Form.Item>
									</div>
								</div>
							</div>
						</div>

						<div className='text-center mt-10'>
							<button
								disabled={authLoading}
								style={{
									border: '0.5px solid #FFF',
								}}
								className='text-white text-[18px] font-bold font-dmSans
                    w-[299px] h-[53px] px-16 py-[19px] bg-blueDark rounded-[38px] 
                    justify-center items-center gap-2.5 inline-flex'>
								{authLoading ? <Spin className='text-white' /> : 'Next'}
							</button>
						</div>

						<div
							style={{
								opacity: 0.8,
							}}
							className='flex justify-center items-center text-center mt-[2.5rem] mb-[50px]'>
							<button
								onClick={() => navigate('/login')}
								className='flex items-center justify-center paragraph_paragraph_2 !text-white'>
								<span>Already have an account?</span>
								<span className='ml-[.3rem] underline'>Login</span>
							</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default Detail;
