import { Link } from 'react-router-dom';
import JumbotronOne from '../../components/JambotronOne';
import ScrollTo from '../../ScrollTo';

export default function Settings() {
	return (
		<>
			<ScrollTo />
			<div className='p-3 lg:p-0 lg:mr-[38px] lg:ml-[22px] mt-[15px]'>
				<JumbotronOne
					text1={`Settings`}
					text2={``}
					text3={''}
					text4={'Manage your account preferences'}
				/>

				<div className='mt-[.75rem] flex flex-col rounded-[0.5rem] bg-white w-full h-[38.125rem] max-h-full py-[3.5rem] pl-3 lg:pl-[2.5rem] pr-3 lg:pr-[4.88rem]'>
					<Link to="/settings/security" className='flex w-full justify-between items-center pb-[1rem] border-b-[0.0625rem] border-[#F5F5F5]'>
						<div className='flex items-center gap-x-[1rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<g clipPath='url(#clip0_12706_19500)'>
									<path d='M24 0H0V24H24V0Z' fill='white' />
									<path
										d='M19.5 8.25H4.5C4.08579 8.25 3.75 8.58579 3.75 9V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V9C20.25 8.58579 19.9142 8.25 19.5 8.25Z'
										stroke='#212121'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M8.25 8.25V5.25C8.25 4.25544 8.64509 3.30161 9.34835 2.59835C10.0516 1.89509 11.0054 1.5 12 1.5C12.9946 1.5 13.9484 1.89509 14.6517 2.59835C15.3549 3.30161 15.75 4.25544 15.75 5.25V8.25'
										stroke='#212121'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</g>
								<defs>
									<clipPath id='clip0_12706_19500'>
										<rect width='24' height='24' fill='white' />
									</clipPath>
								</defs>
							</svg>

							<span className='heading_heading_4'>Login & Security</span>
						</div>

						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<g clipPath='url(#clip0_12706_19504)'>
								<path d='M24 0H0V24H24V0Z' fill='white' />
								<path
									d='M9 4.5L16.5 12L9 19.5'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</g>
							<defs>
								<clipPath id='clip0_12706_19504'>
									<rect width='24' height='24' fill='white' />
								</clipPath>
							</defs>
						</svg>
					</Link>

					<Link to="/settings/notifications" className='flex w-full justify-between items-center pb-[1rem] border-b-[0.0625rem] border-[#F5F5F5] mt-[2.63rem]'>
						<div className='flex items-center gap-x-[1rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<g clipPath='url(#clip0_12706_19509)'>
									<path d='M24 0H0V24H24V0Z' fill='white' />
									<path
										d='M9 21H15'
										stroke='#212121'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M17.25 2.25C18.6983 3.17411 19.873 4.46888 20.6522 6'
										stroke='#212121'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M3.34766 6C4.12688 4.46888 5.30155 3.17411 6.74984 2.25'
										stroke='#212121'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M5.24988 10.5C5.24988 8.70979 5.96104 6.9929 7.22691 5.72703C8.49278 4.46116 10.2097 3.75 11.9999 3.75C13.7901 3.75 15.507 4.46116 16.7729 5.72703C18.0387 6.9929 18.7499 8.70979 18.7499 10.5C18.7499 13.8581 19.528 15.8063 20.1468 16.875C20.2125 16.9888 20.2471 17.1179 20.2472 17.2493C20.2474 17.3808 20.2129 17.5099 20.1474 17.6239C20.0819 17.7378 19.9876 17.8325 19.874 17.8985C19.7603 17.9645 19.6313 17.9995 19.4999 18H4.49988C4.36861 17.9992 4.23985 17.964 4.12646 17.8978C4.01308 17.8317 3.91904 17.7369 3.85375 17.6231C3.78845 17.5092 3.7542 17.3801 3.7544 17.2489C3.75459 17.1176 3.78925 16.9887 3.85488 16.875C4.4727 15.8063 5.24988 13.8572 5.24988 10.5Z'
										stroke='#212121'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</g>
								<defs>
									<clipPath id='clip0_12706_19509'>
										<rect width='24' height='24' fill='white' />
									</clipPath>
								</defs>
							</svg>

							<span className='heading_heading_4'>Notifications</span>
						</div>

						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<g clipPath='url(#clip0_12706_19504)'>
								<path d='M24 0H0V24H24V0Z' fill='white' />
								<path
									d='M9 4.5L16.5 12L9 19.5'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</g>
							<defs>
								<clipPath id='clip0_12706_19504'>
									<rect width='24' height='24' fill='white' />
								</clipPath>
							</defs>
						</svg>
					</Link>
				</div>
			</div>
		</>
	);
}
