import { Form, Input } from 'antd';
import { countries } from '../../../countries';
import { languages } from '../../../languages';

const { TextArea } = Input;

let languageOptions = [];

let countryOptions = [];

countries.forEach((item) => {
	countryOptions.push({
		label: item?.name,
		value: item?.name,
	});
});

languages.forEach((item) => {
	languageOptions.push({
		label: item?.name,
		value: item?.name,
	});
});

export default function CreateProfileTab2() {
	return (
		<>
			<div
				className='py-[2.88rem] px-[2.5rem]'
				style={{
					borderRadius: '0.875rem',
					border: '1px solid var(--Border-secondary, #E0E0E0)',
					background: '#FFF',
				}}>
				<h3 className='heading_heading_4'>Professional summary</h3>
				<p className='paragraph_paragraph_2 mt-[1rem] !text-black333'>
					Write your CV summary
				</p>
				<div className='flex flex-col mt-[.81rem] w-full'>
					<Form.Item
						help={
							<span className='paragraph_paragraph_3 !text-blueDark'>
								Enter a brief professional summary
							</span>
						}
						name='cvBio'
						label='About'
						rules={[
							{
								required: false,
								message: 'Please add password',
							},
						]}>
						<TextArea showCount maxLength={500} rows={5} />
					</Form.Item>
				</div>
			</div>
		</>
	);
}
