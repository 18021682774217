export default function AttestationLocationDescription({ foreignJobActive }) {
	return (
		<>
			<div className='bg-white px-3 lg:px-[2.5rem] py-[2rem] w-full'>
				<div className='flex justify-between items-center'>
					<span className='heading_heading_4'>Attestation Location </span>
				</div>

				<div
					className='mt-[1.5rem]'
					style={{
						width: '100%',
						height: '0.0625rem',
						background: 'rgba(216, 216, 216, 0.54)',
					}}></div>

				<div className='mt-[2rem] grid grid-cols-1 gap-[1.5rem]'>
					<div className='flex flex-col'>
						<span className='label_label_1'>
							Location for attestation
						</span>
						<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
							{foreignJobActive?.japAttestationLocation}
						</span>
					</div>
				</div>
			</div>
		</>
	);
}
