import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Input, InputNumber, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { saveApplicantCv, setCVData } from '../../../features/cv/cvSLice';
import {
	clearActiveEdu,
	fetchCVEducations,
} from '../../../features/edu/eduSlice';
import jsPDF from 'jspdf';
import axios from 'axios';
import toast from 'react-hot-toast';
import {
	clearActiveExp,
	fetchCVExperiences,
} from '../../../features/exp/expSlice';
import {
	clearActiveDoc,
	fetchCVAttachments,
} from '../../../features/doc/docSlice';
import {
	clearActiveAward,
	fetchCVAwards,
} from '../../../features/award/awardSlice';
import {
	fetchSkills,
} from '../../../features/skill/skillSlice';
import {
	clearActivePerson,
	fetchCVContactPersons,
} from '../../../features/person/personSllice';

const url = process.env.REACT_APP_API_BASE_URL;

const getBase64 = (img, callback) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
};

export default function CreateProfileTab({
	docTitle,
	hasError,
	cvTempId
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { cvData } = useSelector((state) => state.cv);
	const { user } = useSelector((state) => state.auth);

	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState();

	const emailRegex =
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

	async function handleDispatch(values) {
		await dispatch(setCVData(values));
	}

	const handleProfileChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, (url) => {
				setLoading(false);
				setImageUrl(url);
				const imgData = {
					cvIcon: url,
				};
				handleDispatch(imgData);
			});
		}
	};

	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div
				style={{
					marginTop: 8,
				}}>
				Upload
			</div>
		</div>
	);

	const handleCreateCV = async () => {
		let cvCopy = { ...cvData };

		cvCopy.cvDocName = docTitle;
		cvCopy.cvUsrId = user?.usrId;
		cvCopy.cvCreatedBy = user?.usrId;
		cvCopy.cvTempId = cvTempId

		const doc = new jsPDF('p', 'pt', 'a4');

		const opt = {
			callback: async function (doc) {
				var pdf = doc.output('blob');
				let data = new FormData();
				data.append('file', pdf);

				const resp = await axios.post(`${url}/nea/saveFile.action`, data);

				if (resp?.data?.success) {
					cvCopy.cvFile = resp?.data?.jsonData;
					const res = await dispatch(saveApplicantCv(cvCopy));
					if (res?.payload?.success) {
						await handleDispatch(res?.payload?.jsonData);
						toast.success('Draft created');
					} else {
						await toast.error(
							'We are experiencing some technical issues. Please try again later'
						);
						navigate('/cv-cover-letter');
					}
				} else {
					await toast.error(
						'We are experiencing some technical issues. Please try again later'
					);
					navigate('/cv-cover-letter');
				}
			},
			x: 0,
			y: 0,
			margin: [30, 30, 30, 30],
			autoPaging: 'text',
			html2canvas: {
				scale: 0.7,
				scrollY: -window.scrollY,
				// width: 595,
				height: window.outerHeight + window.innerHeight,
				windowHeight: window.outerHeight + window.innerHeight,
			},
		};

		doc.html(document.getElementById('print'), opt);
	};

	async function handleDataFetch() {
		const usrObj = {
			usrId: user?.usrId,
			eduCvlId: cvData?.cvId ? cvData?.cvId : 0,
			expCvlId: cvData?.cvId ? cvData?.cvId : 0,
			attCvlId: cvData?.cvId ? cvData?.cvId : 0,
			achCvlId: cvData?.cvId ? cvData?.cvId : 0,
			sklCvlId: cvData?.cvId ? cvData?.cvId : 0,
			conCvlId: cvData?.cvId ? cvData?.cvId : 0,
		};

		await dispatch(clearActiveEdu());
		await dispatch(clearActiveExp());
		await dispatch(clearActiveDoc());
		await dispatch(clearActiveAward());
		await dispatch(clearActivePerson());

		const eduList = await dispatch(fetchCVEducations(usrObj));
		// if (eduList?.payload?.success) {
		// 	await dispatch(setEduData(eduList?.payload?.data?.result));
		// }

		const expList = await dispatch(fetchCVExperiences(usrObj));

		// if (expList?.payload?.success) {
		// 	await dispatch(setExpData(expList?.payload?.data?.result));
		// }

		const docList = await dispatch(fetchCVAttachments(usrObj));

		// if (docList?.payload?.success) {
		// 	await dispatch(setDocData(docList?.payload?.data?.result));
		// }

		const awardList = await dispatch(fetchCVAwards(usrObj));

		// if (awardList?.payload?.success) {
		// 	await dispatch(setAwardData(awardList?.payload?.data?.result));
		// }

		const skillList = await dispatch(fetchSkills(usrObj));

		// if (skillList?.payload?.success) {
		// 	await dispatch(setSkillData(skillList?.payload?.data?.result));
		// }

		const personList = await dispatch(fetchCVContactPersons(usrObj));

		// if (personList?.payload?.success) {
		// 	await dispatch(setPersonData(personList?.payload?.data?.result));
		// }
	}

	useEffect(() => {
		handleDataFetch();

		if (cvData?.cvId) {
		} else {
			handleCreateCV();
		}
	}, []);

	return (
		<>
			<div
				className='py-[2.88rem] px-[2.5rem]'
				style={{
					borderRadius: '0.875rem',
					border: '1px solid var(--Border-secondary, #E0E0E0)',
				}}>
				<h3 className='heading_heading_4'>Personal Details</h3>
				<p className='paragraph_paragraph_2 mt-[1rem] !text-black333'>
					Get started with the basics
				</p>
				<div className='flex flex-col mt-[.81rem] w-full'>
					<Form.Item className='table-form-item' name='cvIconMain'>
						<Upload
							accept='image/jpeg,image/png'
							name='file'
							listType='picture-circle'
							className='avatar-uploader-cv'
							showUploadList={false}
							action={`${url}/nea/saveFile.action`}
							beforeUpload={beforeUpload}
							onChange={handleProfileChange}>
							{imageUrl || cvData?.cvIcon ? (
								<img
									loading='lazy'
									src={imageUrl ?? cvData?.cvIcon}
									alt='avatar'
									style={{
										width: '100%',
										height: '100%',
									}}
									className='rounded-full'
								/>
							) : (
								uploadButton
							)}
						</Upload>
					</Form.Item>

					<Form.Item
						help={
							hasError ? null : (
								<span className='paragraph_paragraph_3 !text-blueDark'>
									Enter your full name
								</span>
							)
						}
						className='mt-[2rem]'
						name='cvUsrName'
						label='Full name'
						rules={[
							{
								required: false,
								message: 'Please add name',
							},
						]}>
						<Input className='input' />
					</Form.Item>

					<Form.Item
						help={
							hasError ? null : (
								<span className='paragraph_paragraph_3 !text-blueDark'>
									For example, Waitress, Marketer, Plumber{' '}
								</span>
							)
						}
						name='cvTitle'
						label='Job Title'
						rules={[
							{
								required: false,
								message: 'Please add password',
							},
						]}>
						<Input className='input' />
					</Form.Item>

					<Form.Item
						help={
							hasError ? null : (
								<span className='paragraph_paragraph_3 !text-blueDark'>
									Enter your phone number
								</span>
							)
						}
						name='cvPhone'
						label='Phone Number'
						rules={[
							{
								required: false,
								message: 'Please add number',
							},
						]}>
						<InputNumber maxLength={10} minLength={9} className='input' />
					</Form.Item>

					<Form.Item
						help={
							hasError ? null : (
								<span className='paragraph_paragraph_3 !text-blueDark'>
									Enter your email address
								</span>
							)
						}
						name='cvMail'
						label='Email'
						validateTrigger='onBlur'
						rules={[
							{
								required: false,
								message: 'Please add email',
							},
							{
								pattern: emailRegex,
								message: 'Enter valid emial',
							},
						]}>
						<Input
							// name='cvMail'
							// onChang={handleChange}
							type='email'
							className='input'
						/>
					</Form.Item>

					<Form.Item
						help={
							hasError ? null : (
								<span className='paragraph_paragraph_3 !text-blueDark'>
									Enter a link to your website, if you have one
								</span>
							)
						}
						name='cvWebsite'
						label='Personal website'
						validateTrigger='onBlur'
						rules={[
							{
								required: false,
								message: 'Please add number',
							},
						]}>
						<Input type='url' className='input' />
					</Form.Item>

					<Form.Item
						help={
							hasError ? null : (
								<span className='paragraph_paragraph_3 !text-blueDark'>
									Enter a link to your LinkedIn, if you have an account
								</span>
							)
						}
						name='cvLinkedIn'
						validateTrigger='onBlur'
						label='LinkedIn profile'
						rules={[
							{
								required: false,
								message: 'Please add number',
							},
						]}>
						<Input type='url' className='input' />
					</Form.Item>

					<Form.Item
						help={
							hasError ? null : (
								<span className='paragraph_paragraph_3 !text-blueDark'>
									Enter your physical address
								</span>
							)
						}
						name='cvLocation'
						label='Address'
						rules={[
							{
								required: false,
								message: 'Please add password',
							},
						]}>
						<Input className='input' />
					</Form.Item>
				</div>
			</div>
		</>
	);
}
