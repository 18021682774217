import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Select, Spin, Radio } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	fetchCounties,
	fetchNeaOffices,
	fetchRegistrationDocs,
	fetchSubCounties,
} from '../../features/global/globalSlice';
import PhoneInput from 'react-phone-input-2';
import {
	createUserDetails,
	saveReferralCode,
} from '../../features/auth/authSlice';
import { customToast } from '../../notifications';
import AuthHeader from '../../layouts/AuthHeader';

export default function Registration() {
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { authLoading, userDetails } = useSelector((state) => state.auth);

	const { globalLoading, nea, regDocs, counties } = useSelector(
		(state) => state.global
	);

	const [neaOffices, setneaOffices] = useState(nea);
	const [docHolder, setDocHolder] = useState();
	const [docList, setdocList] = useState(regDocs);
	const [referred, setreferred] = useState(false);
	const [refCode, setrefCode] = useState('');
	const [subCounties, setsubCounties] = useState([]);
	const [employed, setemployed] = useState(
		userDetails?.usrEmploymentStatus === 'Employed' ? true : false
	);
	const [isIntern, setisIntern] = useState(
		userDetails?.usrInternStatus === 'Intern' ? true : false
	);

	const onChange = (val) => {
		setreferred(val);
	};

	const handleVerificationChange = (e) => {
		setrefCode(e.target.value);
	};

	const onFinish = async (data) => {
		let neaId = data.usrNeaId;

		const neaObj = neaOffices?.find((item) => item?.instId === neaId);

		data.usrMobileNumber =
			data?.usrMobileNumber.charAt(3) === '0'
				? data.usrMobileNumber.replace('0', '')
				: data.usrMobileNumber;

		const res = await dispatch(
			createUserDetails({
				...data,
				usrId: userDetails?.usrId ?? null,
				usrEncryptedPassword: Math.random().toString(36).slice(0, 8),
				usrRoleId: 7,
				usrNeaId: neaId,
				usrNearNeaOffice: neaObj?.instName,
				usrType: 'JOBSEEKER',
				usrEmploymentStatus:
					data.usrEmploymentStatus === true
						? 'Employed'
						: 'Unemployed',
				usrInternStatus:
					data.usrInternStatus === true ? 'Intern' : 'N/A',
			})
		);
		if (res?.payload?.success) {
			if (referred) {
				let refObj = {
					rcodOfficerCode: refCode,
					rcodUsrId: res?.payload?.jsonData?.usrId,
					rcodCreatedBy: res?.payload?.jsonData?.usrId,
				};

				await dispatch(saveReferralCode(refObj));
			}

			await customToast({
				content: 'Registration successful',
				bdColor: 'success',
				header: 'Success',
			});
			await navigate('/registration-confirmation');
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	};

	async function handleDropdownOptions() {
		await dispatch(fetchCounties());
		await dispatch(fetchNeaOffices());
	}

	async function handleFetchSubCounties(val) {
		const res = await dispatch(fetchSubCounties(val));
		setsubCounties(res?.payload);
	}

	async function handleFetchRegDocs() {
		await dispatch(fetchRegistrationDocs());
	}

	useEffect(() => {
		handleDropdownOptions();
		handleFetchRegDocs();
		handleFetchSubCounties();
	}, []);

	useEffect(() => {}, [
		neaOffices,
		docList,
		userDetails,
		counties,
		subCounties,
	]);

	useEffect(() => {
		setdocList(regDocs);
		setneaOffices(nea);
	}, [regDocs, nea]);

	return (
		<>
			<div className='sticky top-0 w-full z-[1000]'>
				<AuthHeader hide={true} />
			</div>

			<div className='flex items-center justify-center'>
				<div className='w-full lg:w-[61.25rem]'>
					<div className='flex flex-col w-full px-3 lg:px-[52px] p-3'>
						<h2 className='setup-title mt-[2rem] text-center'>
							Sign up to find{' '}
							<span className='text-blueDark'>job </span>
							opportunities.
						</h2>
						<p className='heading_heading_5 mt-[1rem] text-center'>
							Let’s start by creating an account.
						</p>

						<Form
							layout='vertical'
							ref={formRef}
							name='control-ref'
							onFinish={onFinish}
							style={{
								width: '100%',
							}}
							form={form}
							initialValues={{
								...userDetails,
							}}>
							<div className='mt-10 p-0 !pr-0 lg:!pr-10 lg:p-0 py-5 lg:py-[3.5rem] flex flex-col justify-center w-full bg-white'>
								<div className='my-3 lg:my-[2.25rem] px-[1.37rem] flex items-start justify-start '>
									<div className='w-[3.45rem]'>
										<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
											<span className='paragraph_paragraph_bb08'>
												1
											</span>
										</div>
									</div>

									<div className='w-full flex flex-col ml-3 lg:ml-[1.31rem]'>
										<span className='paragraph_paragraph_bb13'>
											Identification
										</span>

										<div className='grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-5 w-full'>
											<Form.Item
												name='usrDocType'
												label='Choose ID'
												rules={[
													{
														required: true,
														message:
															'Please select document type',
													},
												]}>
												<Select
													suffixIcon={
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'>
															<path
																d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
																fill='#212121'
															/>
														</svg>
													}
													options={docList?.map(
														(item) => {
															return {
																label: item?.rgdName,
																value: item?.rgdName,
															};
														}
													)}
													onChange={(value) => {
														setDocHolder(value);
													}}
												/>
											</Form.Item>

											<Form.Item
												name='usrNationalId'
												label={`Enter ${
													docHolder
														? docHolder
														: 'Document ID'
												}`}
												rules={[
													{
														required: true,
														message:
															'Please add document ID number',
													},
													// {
													// 	pattern: /^[0-9]$/,
													// 	message: '',
													// },
												]}>
												<Input
													disabled={!docHolder}
													readOnly={
														userDetails?.usrId
													}
													className='input'
												/>
											</Form.Item>
										</div>
									</div>
								</div>
							</div>

							<div className='mt-[1.44rem] p-0 !pr-0 lg:!pr-10 lg:p-0 py-5 lg:py-[3.5rem] flex flex-col justify-center w-full bg-white'>
								<div className='my-3 lg:my-[2.25rem] px-[1.37rem] flex items-start justify-start '>
									<div className='w-[3.45rem]'>
										<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
											<span className='paragraph_paragraph_bb08'>
												2
											</span>
										</div>
									</div>

									<div className='w-full flex flex-col ml-3 lg:ml-[1.31rem]'>
										<span className='paragraph_paragraph_bb13'>
											Basic details
										</span>

										<div className='grid grid-cols-1 w-full'>
											<Form.Item
												name='usrFirstName'
												label='First name'
												rules={[
													{
														required: true,
														message:
															'Please add first name',
													},
												]}>
												<Input className='input' />
											</Form.Item>

											<Form.Item
												name='usrEmail'
												label='Email address'
												rules={[
													{
														required: true,
														message:
															'Please add email',
													},
												]}>
												<Input
													type='email'
													className='input'
												/>
											</Form.Item>

											<Form.Item
												name='usrMobileNumber'
												label='Phone Number'
												rules={[
													{
														required: true,
														message:
															'Please add office phone number',
													},
												]}>
												<PhoneInput
													country='ke'
													onlyCountries={['ke']}
													countryCodeEditable={false}
													className='input'
												/>
											</Form.Item>

											<Form.Item
												name='usrEmploymentStatus'
												label='Are you currently employed?'
												rules={[
													{
														required: false,
														message:
															'Field is required',
													},
												]}>
												<Radio.Group
													onChange={(e) =>
														setemployed(
															e.target.value
														)
													}
													defaultValue={employed}>
													<Radio value={true}>
														Yes
													</Radio>
													<Radio value={false}>
														No
													</Radio>
												</Radio.Group>
											</Form.Item>

											{employed === false && (
												<Form.Item
													name='usrInternStatus'
													label='Are you looking for internship?'
													rules={[
														{
															required: false,
															message:
																'Field is required',
														},
													]}>
													<Radio.Group
														onChange={(e) =>
															setisIntern(
																e.target.value
															)
														}
														defaultValue={isIntern}>
														<Radio value={true}>
															Yes
														</Radio>
														<Radio value={false}>
															No
														</Radio>
													</Radio.Group>
												</Form.Item>
											)}

											<Form.Item
												help={
													referred ? (
														<span className='paragraph_paragraph_3'>
															Enter code
														</span>
													) : null
												}
												label='Do you have a referral code?'
												rules={[
													{
														required: false,
														message:
															'Field is required',
													},
												]}>
												<Radio.Group
													onChange={(e) =>
														onChange(e.target.value)
													}
													defaultValue={referred}>
													<Radio value={true}>
														Yes
													</Radio>
													<Radio value={false}>
														No
													</Radio>
												</Radio.Group>

												{referred ? (
													<>
														<div className='w-[11.5rem]'>
															<Input
																onChange={
																	handleVerificationChange
																}
																className='input mt-[1.5rem]'
															/>
														</div>
													</>
												) : null}
											</Form.Item>
										</div>
									</div>
								</div>
							</div>

							<div className='mt-[1.44rem] p-0 !pr-0 lg:!pr-10 lg:p-0 py-5 lg:py-[3.5rem] flex flex-col justify-center w-full bg-white'>
								<div className='my-3 lg:my-[2.25rem] px-[1.37rem] flex items-start justify-start '>
									<div className='w-[3.45rem]'>
										<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
											<span className='paragraph_paragraph_bb08'>
												3
											</span>
										</div>
									</div>

									<div className='w-full flex flex-col ml-3 lg:ml-[1.31rem]'>
										<span className='paragraph_paragraph_bb13'>
											Where are you based?
										</span>

										<div className='grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-5 w-full'>
											<Form.Item
												name='usrCounty'
												label='What is your home county'
												rules={[
													{
														required: true,
														message:
															'Please select your county',
													},
												]}>
												<Select
													suffixIcon={
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'>
															<path
																d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
																fill='#212121'
															/>
														</svg>
													}
													showSearch
													loading={globalLoading}
													style={{
														width: '100%',
														height: '44px',
													}}
													optionFilterProp='children'
													filterOption={(
														input,
														option
													) =>
														(
															option?.label?.toLocaleLowerCase() ??
															''
														).includes(
															input?.toLocaleLowerCase()
														)
													}
													filterSort={(
														optionA,
														optionB
													) =>
														(optionA?.label ?? '')
															.toLowerCase()
															.localeCompare(
																(
																	optionB?.label ??
																	''
																).toLowerCase()
															)
													}
													options={
														counties?.length &&
														counties?.map(
															(item) => {
																return {
																	label: item?.admCounty,
																	value: item?.admCounty,
																};
															}
														)
													}
													onChange={
														handleFetchSubCounties
													}
												/>
											</Form.Item>

											<Form.Item
												name='usrConstituency'
												label='Sub county'
												rules={[
													{
														required: true,
														message:
															'Please select your sub-county',
													},
												]}>
												<Select
													suffixIcon={
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'>
															<path
																d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
																fill='#212121'
															/>
														</svg>
													}
													showSearch
													loading={globalLoading}
													style={{
														width: '100%',
														height: '44px',
													}}
													optionFilterProp='children'
													filterOption={(
														input,
														option
													) =>
														(
															option?.label?.toLocaleLowerCase() ??
															''
														).includes(
															input?.toLocaleLowerCase()
														)
													}
													filterSort={(
														optionA,
														optionB
													) =>
														(optionA?.label ?? '')
															.toLowerCase()
															.localeCompare(
																(
																	optionB?.label ??
																	''
																).toLowerCase()
															)
													}
													options={
														subCounties?.length &&
														subCounties?.map(
															(item) => {
																return {
																	label: item?.admSubCounty,
																	value: item?.admSubCounty,
																};
															}
														)
													}
												/>
											</Form.Item>
										</div>

										<Form.Item
											name='usrNeaId'
											label='Which NEA Office is closest to you?'
											rules={[
												{
													required: true,
													message:
														'Please select NEA office closest to you',
												},
											]}>
											<Select
												suffixIcon={
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'>
														<path
															d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
															fill='#212121'
														/>
													</svg>
												}
												showSearch
												style={{
													width: '100%',
													height: '44px',
												}}
												optionFilterProp='children'
												filterOption={(input, option) =>
													(
														option?.label?.toLocaleLowerCase() ??
														''
													).includes(
														input?.toLocaleLowerCase()
													)
												}
												filterSort={(
													optionA,
													optionB
												) =>
													(optionA?.label ?? '')
														.toLowerCase()
														.localeCompare(
															(
																optionB?.label ??
																''
															).toLowerCase()
														)
												}
												options={
													neaOffices?.length &&
													neaOffices?.map(
														(office) => ({
															value: office.instId,
															label: `${office.instName}`,
														})
													)
												}
												allowClear
												loading={globalLoading}
											/>
										</Form.Item>
									</div>
								</div>
							</div>

							<div className='text-center mt-10'>
								<button
									disabled={authLoading}
									className='text-white text-[18px] font-bold font-dmSans
                    w-[299px] h-[53px] px-16 py-[19px] bg-blueDark rounded-[38px] 
                    justify-center items-center gap-2.5 inline-flex'>
									{authLoading ? (
										<Spin className='text-white' />
									) : (
										'Next'
									)}
								</button>
							</div>

							<div className='text-center mt-[20px] mb-[50px]'>
								<div
									className='cursor-pointer text-blueDark text-xl font-normal font-dmSans leading-normal
				w-[299px] h-[53px] pl-[18px] pr-[17.20px] pt-2 pb-[7.50px] bg-white rounded-[35px] 
				shadow border-2 border-blueDark justify-center items-center inline-flex'
									onClick={() => navigate('/login')}>
									Back to login
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}
