import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Input, Pagination } from 'antd';
import { fetchJobs } from '../../features/job/jobSlice';
import ScrollTo from '../../ScrollTo';
import Header from '../../layouts/Header';
import JobFilter from '../job_vacancy/JobFilter';
import JobCardSmallUpdated from '../../components/JobCardSmallUpdated';
import home24 from '../../assets/landing/home24.jpg';
import home25 from '../../assets/landing/home25.png';
import home26 from '../../assets/landing/home26.png';
import home27 from '../../assets/landing/home27.jpg';
import LandingPartSix from './new_home/new_home_modules/LandingPartSix';

export default function HomeJobs() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const { jobs, jobsCount } = useSelector((state) => state.job);

	const ref = useRef();

	const [jobList, setjobList] = useState(jobs);
	const [pageIndex, setPageIndex] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [filters, setfilters] = useState([]);
	const [name, setname] = useState('');

	const filterObj = {
		limit: pageSize,
		start: pageIndex,
		position: name,
		internationalJobs: searchParams.get("type") === 'ABROAD' ? 'INTERNATIONAL' : '',
		localJobs: searchParams.get("type") === "LOCAL" ? 'KENYA' : '',
	};

	function handleAddFilter(item) {
		setfilters((prevData) => [...prevData, item]);
	}

	function handleRemoveFilter(item) {
		let newArray = filters?.filter(
			(x) => String(x).toUpperCase() !== String(item).toUpperCase()
		);
		setfilters(newArray);
	}

	async function handleFetchJobs() {
		await dispatch(fetchJobs(filterObj));
	}

	const onNameChange = async (e) => {
		await setname(e.target.value);
	};

	useEffect(() => {}, [jobList, filters]);

	useEffect(() => {
		handleFetchJobs();
	}, [pageIndex, pageSize]);

	useEffect(() => {
		if (name.length === 0) {
			handleFetchJobs();
		}
	}, [name]);

	useEffect(() => {
		setjobList(jobs);
	}, [jobs]);

	const itemRender = (_, type, originalElement) => {
		if (type === 'prev') {
			return (
				<a className='black-outline-btn'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<path
							d='M11.2908 12.0036L14.8308 8.46361C15.017 8.27625 15.1216 8.0228 15.1216 7.75861C15.1216 7.49442 15.017 7.24097 14.8308 7.05361C14.7378 6.95988 14.6272 6.88549 14.5054 6.83472C14.3835 6.78395 14.2528 6.75781 14.1208 6.75781C13.9888 6.75781 13.8581 6.78395 13.7362 6.83472C13.6144 6.88549 13.5038 6.95988 13.4108 7.05361L9.1708 11.2936C9.07707 11.3866 9.00268 11.4972 8.95191 11.619C8.90114 11.7409 8.875 11.8716 8.875 12.0036C8.875 12.1356 8.90114 12.2663 8.95191 12.3882C9.00268 12.51 9.07707 12.6206 9.1708 12.7136L13.4108 17.0036C13.5042 17.0963 13.6151 17.1696 13.7369 17.2194C13.8587 17.2691 13.9892 17.2944 14.1208 17.2936C14.2524 17.2944 14.3829 17.2691 14.5047 17.2194C14.6265 17.1696 14.7374 17.0963 14.8308 17.0036C15.017 16.8162 15.1216 16.5628 15.1216 16.2986C15.1216 16.0344 15.017 15.781 14.8308 15.5936L11.2908 12.0036Z'
							fill='black'
						/>
					</svg>
					<span className='black-btn-text ml-[0.25rem] !text-black21'>
						Previous
					</span>
				</a>
			);
		}
		if (type === 'next') {
			return (
				<a className='black-btn'>
					<span className='black-btn-text mr-[0.25rem]'>Next</span>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'>
						<path
							d='M14.8336 11.2936L10.5936 7.05361C10.5006 6.95988 10.39 6.88549 10.2682 6.83472C10.1463 6.78395 10.0156 6.75781 9.88361 6.75781C9.75159 6.75781 9.62089 6.78395 9.49903 6.83472C9.37717 6.88549 9.26657 6.95988 9.1736 7.05361C8.98735 7.24097 8.88281 7.49442 8.88281 7.75861C8.88281 8.0228 8.98735 8.27625 9.1736 8.46361L12.7136 12.0036L9.1736 15.5436C8.98735 15.731 8.88281 15.9844 8.88281 16.2486C8.88281 16.5128 8.98735 16.7662 9.1736 16.9536C9.26705 17.0463 9.37786 17.1196 9.4997 17.1694C9.62154 17.2191 9.752 17.2444 9.88361 17.2436C10.0152 17.2444 10.1457 17.2191 10.2675 17.1694C10.3893 17.1196 10.5002 17.0463 10.5936 16.9536L14.8336 12.7136C14.9273 12.6206 15.0017 12.51 15.0525 12.3882C15.1033 12.2663 15.1294 12.1356 15.1294 12.0036C15.1294 11.8716 15.1033 11.7409 15.0525 11.619C15.0017 11.4972 14.9273 11.3866 14.8336 11.2936Z'
							fill='white'
						/>
					</svg>
				</a>
			);
		}
		return originalElement;
	};

	// useEffect(() => {
	// 	if (searchParams?.length) {
	// 		handleFetchJobs();
	// 	}
	// }, [searchParams])
	

	function handleBack() {
		navigate(-1);
	}
	

	return (
		<>
			<ScrollTo />
			<Header handleTwo={handleBack} handleThree={handleBack} handleFour={handleBack} handleFive={handleBack} />
			<div className='bg-white pt-[5.31rem]'>
				<div className='p-3 lg:p-0 lg:mr-[38px] lg:ml-[22px]'>
					<div className='p-[.75rem] lg:p-0 ml-0 lg:ml-[4.31rem]'>
						<div className='flex items-start justify-start'>
							<button
								style={{
									width: '3.375rem',
									height: '3.375rem',
									flexShrink: 0,
									borderRadius: '3.125rem',
									border: '1px solid #1B47B4',
								}}
								className='flex justify-center items-center'
								onClick={handleBack}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path d='M16 20L8 12L16 4' stroke='#1B47B4' />
								</svg>
							</button>
							<div className='flex justify-center items-center flex-col w-full'>
								<span className='prof-name text-center'>
									Find the work you love
								</span>
								<span className='footer-head !text-black333 !normal-case'>
									Search for your next job opportunity below.
								</span>

								<div className='flex w-full items-center justify-center'>
									<div className='flex justify-center max-w-[40rem] w-full items-center mt-[1.62rem]'>
										<Input
											onPressEnter={handleFetchJobs}
											className='search-input'
											placeholder='Search job...'
											onChange={onNameChange}
										/>

										<button
											onClick={handleFetchJobs}
											className='bg-[#272466] p-[1rem] rounded-full w-[4.3125rem] h-[4.5rem] -ml-[4rem] z-[10000] flex justify-center items-center'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='38'
												height='38'
												viewBox='0 0 38 38'
												fill='none'>
												<path
													d='M17.4167 30.0833C24.4123 30.0833 30.0833 24.4123 30.0833 17.4167C30.0833 10.4211 24.4123 4.75 17.4167 4.75C10.4211 4.75 4.75 10.4211 4.75 17.4167C4.75 24.4123 10.4211 30.0833 17.4167 30.0833Z'
													stroke='white'
													strokeWidth='3.16667'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
												<path
													d='M33.2498 33.2508L26.3623 26.3633'
													stroke='white'
													strokeWidth='3.16667'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					ref={ref}
					className='flex items-start justify-start p-[.75rem] py-[3.75rem] bg-[#FAFBFF] mt-[2.75rem]'>
					<div className='hidden lg:flex'>
						<JobFilter
							handleAddFilter={handleAddFilter}
							handleRemoveFilter={handleRemoveFilter}
						/>
					</div>
					<div className='w-full mt-[3.25rem]'>
						{filters?.length ? (
							<>
								<div className='flex flex-col mb-[2.5rem] px-[4.19rem]'>
									<span className='heading_heading_5'>Filter:</span>

									<div className='flex flex-wrap w-full items-center mt-[0.81rem]'>
										{filters
											?.filter((x) => x)
											?.map((item) => {
												return (
													<>
														<div className='job_filter mr-[1.5rem]'>
															<span className='job_filter_text'>{item}</span>
															<button onClick={() => handleRemoveFilter(item)}>
																<svg
																	className='ml-[0.5rem]'
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																	fill='none'>
																	<path
																		d='M18.75 5.25L5.25 18.75'
																		stroke='#1B47B4'
																		strokeWidth='2'
																		strokeLinecap='round'
																		strokeLinejoin='round'
																	/>
																	<path
																		d='M18.75 18.75L5.25 5.25'
																		stroke='#1B47B4'
																		strokeWidth='2'
																		strokeLinecap='round'
																		strokeLinejoin='round'
																	/>
																</svg>
															</button>
														</div>
													</>
												);
											})}

										{filters?.length > 1 ? (
											<button
												onClick={() => setfilters([])}
												className='heading_heading_5 !text-blueDark'>
												Clear
											</button>
										) : null}
									</div>
								</div>
							</>
						) : null}

						{jobList?.length === 0 ? (
							<>
								<div className='px-[4.19rem]'>
									<h2 className='heading_heading_2'>Found 0 job</h2>
									<h2 className='heading_heading_4 mt-[2rem]'>
										No job matching the criteria was found
									</h2>
								</div>
							</>
						) : null}
						<div className='w-full flex items-center flex-shrink flex-wrap gap-[3.12rem] px-[4.19rem]'>
							{jobList?.map((item) => {
								return (
									<>
										<JobCardSmallUpdated showRecommended={true} item={item} />
									</>
								);
							})}
						</div>

						<div className='mt-[3.75rem] flex justify-center items-center'>
							<Pagination
								responsive
								simple
								current={pageIndex + 1}
								showSizeChanger={false}
								hideOnSinglePage
								total={jobsCount}
								pageSize={pageSize}
								itemRender={itemRender}
								onChange={(page, size) => {
									setPageIndex(page - 1);
									setPageSize(size);
									ref?.current?.scrollIntoView();
								}}
							/>
						</div>
					</div>
				</div>

				<div className='flex flex-col w-full mt-[1.31rem] pb-[7.81rem] px-[2.5rem]'>
					<span className='heading_heading_main !text-[#1A1C28] text-center mt-[4.25rem]'>
						Popular search
					</span>

					<div className='grid grid-cols-2 lg:grid-cols-4 gap-[2.5rem] mt-[2.31rem]'>
						<div className='flex flex-col items-start justify-start'>
							<img
								src={home24}
								className='w-full h-[14rem] object-cover'
								alt='popular search'
							/>
							<span className='popular-search-text mt-[1.44rem]'>
								Nairobi, Kenya
							</span>
						</div>

						<div className='flex flex-col items-start justify-start'>
							<img
								src={home25}
								className='w-full h-[14rem] object-cover'
								alt='popular search'
							/>
							<span className='popular-search-text mt-[1.44rem]'>
								Mombasa, Kenya
							</span>
						</div>

						<div className='flex flex-col items-start justify-start'>
							<img
								src={home26}
								className='w-full h-[14rem] object-cover'
								alt='popular search'
							/>
							<span className='popular-search-text mt-[1.44rem]'>
								Kenyan Government Jobs
							</span>
						</div>

						<div className='flex flex-col items-start justify-start'>
							<img
								src={home27}
								className='w-full h-[14rem] object-cover'
								alt='popular search'
							/>
							<span className='popular-search-text mt-[1.44rem]'>
								Foreign Jobs
							</span>
						</div>
					</div>
				</div>
			</div>
			<LandingPartSix />
		</>
	);
}
