import { Button, Drawer, Menu, Space } from 'antd';
import { Link } from 'react-router-dom';
import emblem from '../assets/emblem.png';
import flagDivider from '../assets/flag_divider.svg';
import neaLogo from '../assets/landing/home20.png';
import home28 from '../assets/landing/home28.svg';

const MobileHeader = ({
	open,
	onClose,
	handleTwo,
	handleThree,
	handleFour,
	handleOpen,
}) => {
	const menuItems = [
		{
			label: (
				<button
					onClick={() => handleTwo()}
					type='button'
					className='bg-transparent nav-items mr-[2.87rem]'>
					Job seekers
				</button>
			),
			key: '#1',
		},
		{
			label: (
				<button
					onClick={() => handleThree()}
					type='button'
					className='bg-transparent nav-items mr-[2.87rem]'>
					Employers
				</button>
			),
			key: '#2',
		},
		{
			label: (
				<button
					onClick={() => handleFour()}
					type='button'
					className='bg-transparent nav-items mr-[2.87rem]'>
					Employment Agents
				</button>
			),
			key: '#3',
		},
		{
			label: (
				<a
					rel='noreferrer'
					href='https://neaims.go.ke'
					type='button'
					className='bg-transparent nav-items mr-[2.87rem]'>
					About us
				</a>
			),
			key: '#4',
		},
		{
			label: (
				<Button
					className='flex bg-transparent nav-items gap-[.2rem] h-[2.3rem] w-full'
					type='button'>
					<img
						loading='lazy'
						className='w-10 h-10 object-contain -mt-[5px] -ml-[18px]'
						src={home28}
						alt='Accessibility'
					/>
					<span>Accessibility</span>
				</Button>
			),
			key: '#5',
		},
	];

	return (
		<>
			<Drawer
				className='job-drawer'
				title='Basic Drawer'
				placement={'left'}
				closable={true}
				onClose={onClose}
				open={open}
				key={'1'}>
				<div className='w-[100%] h-[95vh]  max-h-[100vh] bg-white p-3 cv-scroll overflow-y-scroll'>
					<div className='flex justify-end p-2'>
						<button onClick={() => onClose()} type='button'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='30'
								height='30'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M13.408 11.9963L19.708 5.70628C19.8963 5.51798 20.0021 5.26258 20.0021 4.99628C20.0021 4.72998 19.8963 4.47458 19.708 4.28628C19.5197 4.09798 19.2643 3.99219 18.998 3.99219C18.7317 3.99219 18.4763 4.09798 18.288 4.28628L11.998 10.5863L5.70799 4.28628C5.51968 4.09798 5.26429 3.99219 4.99799 3.99219C4.73168 3.99219 4.47629 4.09798 4.28799 4.28628C4.09968 4.47458 3.99389 4.72998 3.99389 4.99628C3.99389 5.26258 4.09968 5.51798 4.28799 5.70628L10.588 11.9963L4.28799 18.2863C4.19426 18.3792 4.11986 18.4898 4.06909 18.6117C4.01833 18.7336 3.99219 18.8643 3.99219 18.9963C3.99219 19.1283 4.01833 19.259 4.06909 19.3809C4.11986 19.5027 4.19426 19.6133 4.28799 19.7063C4.38095 19.8 4.49155 19.8744 4.61341 19.9252C4.73527 19.9759 4.86597 20.0021 4.99799 20.0021C5.13 20.0021 5.2607 19.9759 5.38256 19.9252C5.50442 19.8744 5.61502 19.8 5.70799 19.7063L11.998 13.4063L18.288 19.7063C18.3809 19.8 18.4915 19.8744 18.6134 19.9252C18.7353 19.9759 18.866 20.0021 18.998 20.0021C19.13 20.0021 19.2607 19.9759 19.3826 19.9252C19.5044 19.8744 19.615 19.8 19.708 19.7063C19.8017 19.6133 19.8761 19.5027 19.9269 19.3809C19.9776 19.259 20.0038 19.1283 20.0038 18.9963C20.0038 18.8643 19.9776 18.7336 19.9269 18.6117C19.8761 18.4898 19.8017 18.3792 19.708 18.2863L13.408 11.9963Z'
									fill='black'
								/>
							</svg>
						</button>
					</div>
					<div className='w-full max-w-sm rounded-lg'>
						<Link to='/' className='flex items-center'>
							<img
								loading='lazy'
								src={emblem}
								className='ml-0 lg:ml-[1.97rem] object-contain w-[3.9375rem] h-[3.49rem]'
								alt='kenya Emplem'
							/>

							<img
								loading='lazy'
								className='h-10 ml-3'
								src={flagDivider}
								alt='kenya flag colors'
							/>

							<img
								loading='lazy'
								src={neaLogo}
								className='ml-[1.06rem] object-cover w-[9.3125rem] h-[2.73813rem]'
								alt='NEAIMIS Logo'
							/>
						</Link>
					</div>

					<div className='mt-4'>
						<Menu
							style={{
								height: 'auto',
								paddingTop: '5px',
							}}
							onClick={onClose}
							defaultSelectedKeys={['1']}
							defaultOpenKeys={['find_counselor']}
							mode='inline'
							theme='light'
							inlineCollapsed={false}
							items={menuItems}
						/>
					</div>

					<div className='flex flex-col w-full gap-[.5rem] mt-10'>
						<Link
							type='button'
							className='login-home-btn !w-full'
							to='/login'>
							Log in
						</Link>

						<Link
							to='/registration/type'
							className='signup-home-btn !w-full'>
							<Space>Sign up</Space>
						</Link>
					</div>
				</div>
			</Drawer>
		</>
	);
};
export default MobileHeader;
