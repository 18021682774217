import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import applied from '../../../assets/applied.svg';
import ApplicationStatusCard from '../../application_status/ApplicationStatusCard';
import { useNavigate } from 'react-router-dom';
import ApplicationStatusSmallCard from '../../application_status/job_cards/ApplicationStatusSmallCard';
import { fetchAppliedJobs } from '../../../features/job/jobSlice';

export default function AppliedJob() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { appliedJobs } = useSelector((state) => state.job);
	const { user } = useSelector((state) => state.auth);

	async function handleChange() {
		navigate('/application-status');
	}

	const usrObj = {
		japUsrId: user?.usrId,
		japProgressStatus: '',
	};

	const handleFetchJobs = async () => await dispatch(fetchAppliedJobs(usrObj));

	useEffect(() => {
		handleFetchJobs()
	}, []);

	useEffect(() => {
	}, [appliedJobs]);

	return (
		<>
			<div className='w-full h-auto'>
				{appliedJobs?.length ? (
					<>
						<div className='w-full h-[2.5rem] flex items-center border-l-[0.1875rem] border-blueDark pl-[2rem] bg-white'>
							<span className='label_label_3'>
								These are jobs you have applied to
							</span>
						</div>
						<div className='flex flex-col w-full mt-[1rem]'>
							{appliedJobs?.map((item, index) => {
								let status;
								let border;
								let color;
								let background;
								let tabKey;

								if (item?.japProgressStatus === 'SHORTLIST') {
									tabKey = '2';
									status = 'You’ve been shortlisted';
									border = '1px solid var(--Lime-5, #BAE637)';
									color = '#212121';
									background =
										'linear-gradient(21deg, #E0F38E 59.71%, #F0FFAD 96.92%)';
								} else if (
									item?.japProgressStatus === 'INTERVIEW' ||
									item?.japProgressStatus ===
										'SCHEDULE INTERVIEW' ||
									item?.japProgressStatus ===
										'INTERVIEW RESULTS'
								) {
									tabKey = '3';
									status =
										item?.japProgressStatus ===
										'INTERVIEW RESULTS'
											? 'Interview complete'
											: 'Invited to interview';
									border = '1px solid #70CADE';
									color = '#212121';
									background =
										'linear-gradient(161deg, #ADF0FF -6.24%, #69D8F0 189.63%)';
								} else if (
									item?.japProgressStatus === 'OFFER' ||
									item?.japProgressStatus === 'HIRE' ||
									item?.japProgressStatus ===
										'FOREIGN JOB CLEARANCE' ||
									item?.japProgressStatus === 'UPLOADED' ||
									item?.japProgressStatus ===
										'AWAITING_CORRECTION'
								) {
									tabKey = 4;
									status =
										item?.japProgressStatus === 'OFFER'
											? 'Job offer available'
											: item?.japProgressStatus === 'HIRE'
											? 'Congratulations on your new role'
											: 'Preparations to work abroad';
									border = '1px solid #02A548';
									color = '#fff';
									background = '#02A548';
								} else if (item?.japProgressStatus === 'DROP') {
									tabKey = '5';
									status = 'Unsuccessful application';
									border = '1px solid #FF4D4F';
									color = '#212121';
									background =
										'linear-gradient(161deg, #FFC4C8 -6.24%, #F05863 189.63%)';
								} else if (
									item?.japProgressStatus === 'DECLINE'
								) {
									tabKey = '6';
									status = 'Declined application';
									border = '1px solid #212121';
									color = '#fff';
									background = '#212121';
								} else {
									tabKey = '1';
									status = 'You have applied';
									border = '1px solid #F5F7FF';
									color = '#fff';
									background =
										'var(--Blue-gradient, linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #1844B5 100%), #4276FC)';
								}

								return (
									<section key={index} className='w-full'>
										<div className='w-full h-full flex lg:hidden flex-col'>
											<ApplicationStatusSmallCard
												item={item}
												status={status}
												border={border}
												color={color}
												background={background}
												tabKey={tabKey}
												handleChange={handleChange}
											/>
										</div>

										<div className='hidden w-full h-full lg:flex flex-col'>
											<ApplicationStatusCard
												item={item}
												status={status}
												border={border}
												color={color}
												background={background}
												tabKey={tabKey}
												handleChange={handleChange}
											/>
										</div>
									</section>
								);
							})}
						</div>
					</>
				) : (
					<>
						<div className='w-full h-[2.5rem] flex items-center border-l-[0.1875rem] border-blueDark pl-[2rem] bg-white'>
							<span className='label_label_3'>
								You haven’t applied to any jobs yet
							</span>
						</div>
						<div className='w-full mt-[1rem] bg-white h-[23.5rem] flex justify-center items-center'>
							<img
								src={applied}
								alt='referral'
								className='w-full h-full mt-[4rem]'
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
}
