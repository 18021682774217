import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import {
	handleNextStep,
	handlePrevStep,
	handleRegNextStep,
	handleRegPrevStep,
} from '../../../../features/profile/profileSlice';
import expImg from '../../../../assets/eduImg.png';
import {
	fetchJobExperienceLevels,
	fetchJobWorkStyles,
} from '../../../../features/dropDowns/dropDownSlice';
import {
	fetchExperiences,
	saveExperience,
} from '../../../../features/exp/expSlice';
import ExperienceForm from './experience_forms/ExperienceForm';

export default function ExperienceInfo({ buildprofile }) {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { jobExperienceLevels, jobStyles } = useSelector((state) => state.edu);
	const { experiences } = useSelector((state) => state.exp);

	const [addEducation, setaddEducation] = useState(false);
	const [educationList, seteducationList] = useState(experiences);
	const [skip, setskip] = useState(false);

	const usrObj = {
		usrId: user?.usrId,
	};

	function onSkipChange(e) {
		setskip(e.target.checked);
	}

	async function handleSKip() {
		// const eduObj = {
		// 	expEmployer: 'None',
		// 	expType: 'N/A',
		// 	expCertNo: 'N/A',
		// 	expDesc: 'N/A',
		// 	expUsrId: user?.usrId,
		// };
		// await dispatch(saveExperience(eduObj));
		// await dispatch(fetchExperiences(usrObj));
		(await buildprofile) ? handleProfileGoNext() : handleGoNext();
	}


	async function handleGoNext() {
		await experiences?.forEach((item) => {
			dispatch(saveExperience(item));
		});
		
		await dispatch(handleRegNextStep());
	}

	function handleGoBack() {
		setaddEducation(false);
		dispatch(handleRegPrevStep());
	}

	async function handleProfileGoNext() {
		await experiences?.forEach((item) => {
			dispatch(saveExperience(item));
		});

		await dispatch(handleNextStep());
	}

	function handleProfileGoBack() {
		dispatch(handlePrevStep());
	}

	async function handleFetchEducations() {
		const resp = await dispatch(fetchExperiences(usrObj));

		if (resp?.payload?.success) {
			await seteducationList(resp?.payload?.data?.result);
		}
	}

	async function handleDropdownOptions() {
		await dispatch(fetchJobExperienceLevels());
		await dispatch(fetchJobWorkStyles());
	}

	useEffect(() => {}, [educationList, jobExperienceLevels, jobStyles]);

	useEffect(() => {
		seteducationList(experiences);
	}, [experiences]);

	useEffect(() => {
		handleFetchEducations();
		handleDropdownOptions();
	}, []);

	return (
		<>
			{/* <ScrollTo /> */}

			{educationList?.length || addEducation ? (
				<>
					<div className='flex justify-center items-center w-full'>
						<div
							style={{
								borderRadius: '0.5rem',
								border: '1px solid #147CBC',
							}}
							className='mt-10 p-3 xl:p-[2.75rem] !w-full xl:!w-[80.875rem] bg-white'>
							<div className='flex justify-center items-center pt-[3.56rem]'>
								<div
									style={{
										background:
											'linear-gradient(180deg, rgba(0, 0, 0, 0.00) -50%, #1844B5 50%)',
										position: 'relative',
									}}
									className='w-[6.25rem] h-[6.25rem] rounded-full p-[1.81rem] inline'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='32'
										height='32'
										viewBox='0 0 32 32'
										fill='none'>
										<path
											opacity='0.2'
											d='M25.9826 15.3974L28.0801 12.7724C27.7972 11.7089 27.3739 10.6879 26.8214 9.73617L23.4839 9.36117C23.2172 9.06079 22.933 8.77654 22.6326 8.50992L22.2576 5.17117C21.3049 4.62196 20.2834 4.20162 19.2201 3.92117L16.5951 6.01742C16.1938 5.99367 15.7914 5.99367 15.3901 6.01742L12.7651 3.91992C11.7051 4.20367 10.6874 4.62695 9.73886 5.17867L9.36386 8.51617C9.06348 8.78279 8.77922 9.06704 8.51261 9.36742L5.17386 9.74242C4.62465 10.6952 4.20431 11.7166 3.92386 12.7799L6.02011 15.4049C5.99636 15.8062 5.99636 16.2086 6.02011 16.6099L3.92261 19.2349C4.20552 20.2984 4.62882 21.3195 5.18136 22.2712L8.51886 22.6462C8.78547 22.9466 9.06973 23.2308 9.37011 23.4974L9.74511 26.8362C10.6978 27.3854 11.7193 27.8057 12.7826 28.0862L15.4076 25.9899C15.8089 26.0137 16.2113 26.0137 16.6126 25.9899L19.2376 28.0874C20.3011 27.8045 21.3222 27.3812 22.2739 26.8287L22.6489 23.4912C22.9492 23.2246 23.2335 22.9403 23.5001 22.6399L26.8389 22.2649C27.3881 21.3122 27.8084 20.2908 28.0889 19.2274L25.9926 16.6024C26.013 16.2009 26.0097 15.7985 25.9826 15.3974ZM16.0001 20.9999C15.0112 20.9999 14.0445 20.7067 13.2223 20.1573C12.4 19.6079 11.7591 18.827 11.3807 17.9133C11.0023 16.9997 10.9033 15.9944 11.0962 15.0245C11.2891 14.0546 11.7653 13.1637 12.4646 12.4644C13.1638 11.7651 14.0548 11.2889 15.0247 11.096C15.9946 10.9031 16.9999 11.0021 17.9135 11.3805C18.8272 11.759 19.608 12.3998 20.1575 13.2221C20.7069 14.0443 21.0001 15.011 21.0001 15.9999C21.0001 17.326 20.4733 18.5978 19.5356 19.5355C18.598 20.4731 17.3262 20.9999 16.0001 20.9999Z'
											fill='white'
										/>
										<path
											d='M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z'
											stroke='white'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M5.17867 22.2612C4.62614 21.3095 4.20283 20.2884 3.91992 19.2249L6.01742 16.5999C5.99367 16.1986 5.99367 15.7962 6.01742 15.3949L3.92117 12.7699C4.2036 11.7063 4.62604 10.6848 5.17742 9.73242L8.51617 9.35742C8.78279 9.05704 9.06704 8.77279 9.36742 8.50617L9.74242 5.16867C10.6935 4.61992 11.7132 4.19999 12.7749 3.91992L15.3999 6.01742C15.8012 5.99367 16.2036 5.99367 16.6049 6.01742L19.2299 3.92117C20.2936 4.2036 21.315 4.62604 22.2674 5.17742L22.6424 8.51617C22.9428 8.78279 23.2271 9.06704 23.4937 9.36742L26.8312 9.74242C27.3837 10.6941 27.807 11.7152 28.0899 12.7787L25.9924 15.4037C26.0162 15.805 26.0162 16.2074 25.9924 16.6087L28.0887 19.2337C27.8082 20.297 27.3879 21.3184 26.8387 22.2712L23.4999 22.6462C23.2333 22.9466 22.9491 23.2308 22.6487 23.4974L22.2737 26.8349C21.322 27.3875 20.3009 27.8108 19.2374 28.0937L16.6124 25.9962C16.2111 26.0199 15.8087 26.0199 15.4074 25.9962L12.7824 28.0924C11.7191 27.812 10.6977 27.3916 9.74492 26.8424L9.36992 23.5037C9.06954 23.2371 8.78529 22.9528 8.51867 22.6524L5.17867 22.2612Z'
											stroke='white'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>

									<svg
										style={{
											position: 'absolute',
											right: '19px',
											top: '44px',
										}}
										xmlns='http://www.w3.org/2000/svg'
										width='32'
										height='32'
										viewBox='0 0 32 32'
										fill='none'>
										<path
											opacity='0.2'
											d='M13.0001 15.8673C12.2007 14.4206 11.869 12.7618 12.0506 11.1189C12.2322 9.47608 12.9183 7.92981 14.0144 6.6927C15.1105 5.45558 16.5629 4.58834 18.172 4.21021C19.781 3.83208 21.4677 3.96161 23.0001 4.58101L18.0001 9.99976L18.7076 13.2923L22.0001 13.9998L27.4188 8.99976C28.0382 10.5322 28.1678 12.2188 27.7896 13.8279C27.4115 15.4369 26.5443 16.8893 25.3071 17.9854C24.07 19.0816 22.5238 19.7676 20.8809 19.9492C19.238 20.1309 17.5793 19.7992 16.1326 18.9998L9.12508 27.1248C8.56149 27.6883 7.79711 28.005 7.00008 28.005C6.20305 28.005 5.43866 27.6883 4.87508 27.1248C4.31149 26.5612 3.99487 25.7968 3.99487 24.9998C3.99487 24.2027 4.31149 23.4383 4.87508 22.8748L13.0001 15.8673Z'
											fill='white'
										/>
										<path
											d='M13.0001 15.8673C12.2007 14.4206 11.869 12.7618 12.0506 11.1189C12.2322 9.47608 12.9183 7.92981 14.0144 6.6927C15.1105 5.45558 16.5629 4.58834 18.172 4.21021C19.781 3.83208 21.4677 3.96161 23.0001 4.58101L18.0001 9.99976L18.7076 13.2923L22.0001 13.9998L27.4188 8.99976C28.0382 10.5322 28.1678 12.2188 27.7896 13.8279C27.4115 15.4369 26.5443 16.8893 25.3071 17.9854C24.07 19.0816 22.5238 19.7676 20.8809 19.9492C19.238 20.1309 17.5793 19.7992 16.1326 18.9998L9.12508 27.1248C8.56149 27.6883 7.79711 28.005 7.00008 28.005C6.20305 28.005 5.43866 27.6883 4.87508 27.1248C4.31149 26.5612 3.99487 25.7968 3.99487 24.9998C3.99487 24.2027 4.31149 23.4383 4.87508 22.8748L13.0001 15.8673Z'
											stroke='white'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
							</div>
							<h3 className='heading_heading_2 w-full text-center mt-[2rem]'>
								<span>Experience</span>
							</h3>
							<p className='heading_heading_5 mt-[1.75rem] mb-[4rem] text-center'>
								Add your work experience below.
							</p>
							<ExperienceForm />

							<div className='mt-20'></div>

							<div className='flex flex-col justify-center items-center w-full'>
								<button
									disabled={educationList?.length === 0}
									title={
										educationList?.length === 0
											? 'Education is required to proceed'
											: null
									}
									onClick={buildprofile ? handleProfileGoNext : handleGoNext}
									className='bg-blueDark rounded-[35px] text-white w-[15rem] p-3'
									type='button'>
									Next
								</button>
								<button
									onClick={buildprofile ? handleProfileGoBack : handleGoBack}
									className='bg-white rounded-[35px] text-blueDark border border-blueDark w-[15rem] p-2 my-3'
									type='button'>
									Back
								</button>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='mt-10 p-3 lg:p-0 py-[3.5rem] flex flex-col justify-center items-center w-full bg-white'>
						<h3 className='heading_heading_2 w-full text-center mt-[2rem]'>
							<span>Experience</span>
						</h3>
						<span className='heading_heading_5 mt-[1.75rem] text-center'>
							Tell us about your work experience so far.
						</span>
						<span className='heading_heading_5 mb-[2.25rem] text-center'>
							You may include volunteer work and internships too.
						</span>
						<div className='flex items-center justify-center flex-col w-full mt-7'>
							<img
								loading='lazy'
								className='object-contain w-[550px] h-auto'
								src={expImg}
								alt='education'
							/>

							<button
								onClick={() => setaddEducation(true)}
								className='bg-blueDark rounded-[35px] text-white w-[15rem] p-3 mt-10'
								type='button'>
								Add
							</button>

							<div className='flex items-center justify-center flex-col mt-3 w-full'>
								<button
									onClick={buildprofile ? handleProfileGoBack : handleGoBack}
									className='bg-white rounded-[35px] text-blueDark border border-blueDark w-[15rem] p-2 my-3'
									type='button'>
									Previous
								</button>
							</div>
							<div className='flex flex-col w-full lg:w-[33rem] mt-[5rem] h-full mb-[4.1rem]'>
								<div className='flex items-center'>
									<Checkbox onChange={onSkipChange}></Checkbox>
									<span className='paragraph_paragraph_2 ml-[1.5rem]'>
										Don’t have work experience? Check the box to skip this part.
										This section of your profile will be empty, but you can add
										to it later.
									</span>
								</div>
								<button
									onClick={handleSKip}
									style={{
										opacity: skip ? 1 : 0.4,
									}}
									disabled={!skip}
									className='bg-none text-blueDark font-bold text-[20px] mt-[3.81rem]'>
									Skip
								</button>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
