import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import JobSeekingDescriptionModal from '../modals/JobSeekingDescriptionModal';

export default function JobSeekingDescription({ profileApplication }) {
	const { seekingInfo } = useSelector((state) => state.job);

	const [open, setopen] = useState(false);

	function handleCancel() {
		setopen(false);
	}

	useEffect(() => {}, [seekingInfo]);

	return (
		<>
			<div className='bg-white px-[.75rem] lg:px-[2.5rem] py-[2rem] w-full'>
				<div className='flex justify-between items-center'>
					<span className='heading_heading_4'>Job Seeking information </span>
					{profileApplication ? null : (
						<button onClick={() => setopen(true)}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'>
								<path
									d='M11.5863 27.0002H6C5.73478 27.0002 5.48043 26.8948 5.29289 26.7073C5.10536 26.5197 5 26.2654 5 26.0002V20.4139C5.00012 20.149 5.10532 19.8951 5.2925 19.7077L20.7075 4.29266C20.895 4.10527 21.1493 4 21.4144 4C21.6795 4 21.9337 4.10527 22.1213 4.29266L27.7075 9.87516C27.8949 10.0627 28.0002 10.3169 28.0002 10.582C28.0002 10.8471 27.8949 11.1014 27.7075 11.2889L12.2925 26.7077C12.1051 26.8948 11.8511 27 11.5863 27.0002Z'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M17 8L24 15'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</button>
					)}
				</div>

				<div
					className='mt-[1.5rem]'
					style={{
						width: '100%',
						height: '0.0625rem',
						background: 'rgba(216, 216, 216, 0.54)',
					}}></div>

				<div className='mt-[2rem] grid grid-cols-2 gap-[2.75rem]'>
					<div className='flex flex-col'>
						<span className='label_label_1'>Job seeking methods</span>
						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{seekingInfo?.jsiSeekingMethod ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Means of maintenance</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{seekingInfo?.jsiMaintenance ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Duration of unemployment</span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{seekingInfo?.jsiUnempDuration ?? 'N/A'}
						</span>
					</div>

					<div className='flex flex-col'>
						<span className='label_label_1'>Reason for being unemployed </span>

						<span className='paragraph_paragraph_1 mt-[0.75rem] truncate'>
							{seekingInfo?.jsiUnempReason ?? 'N/A'}
						</span>
					</div>
				</div>
			</div>

			<JobSeekingDescriptionModal open={open} handleCancel={handleCancel} />
		</>
	);
}
