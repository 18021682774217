import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppliedJobs } from '../../../features/job/jobSlice';
import EmptyApplicationState from '../EmptyApplicationState';
import JobOfferCard from '../job_cards/JobOfferCard';
import OfferInfoCard from '../job_cards/OfferInfoCard';

export default function JobOffers() {
	const dispatch = useDispatch();

	const { offers } = useSelector((state) => state.job);
	const { user } = useSelector((state) => state.auth);

	const [jobList, setjobList] = useState(offers);
	const [offer, setoffer] = useState(false);
	const [activeOffer, setactiveOffer] = useState({});

	const usrObj = {
		japUsrId: user?.usrId,
		japProgressStatus: '',
		position: '',
	};

	function handleOfferInfo(item) {
		setoffer(true);
		setactiveOffer(item);
	}

	function handleCancel() {
		setoffer(false);
		setactiveOffer({});
	}

	async function handleFetchJobs() {
		await dispatch(fetchAppliedJobs(usrObj));
	}

	useEffect(() => {}, [jobList]);

	useEffect(() => {
		setjobList(offers);
	}, [offers]);

	useEffect(() => {
		handleFetchJobs();
	}, []);

	return (
		<>
			{offer ? (
				<>
					<div className='mt-[1.2rem]'>
						<span className='heading_heading_5'>
							Well done, {user?.usrFirstName}. You got the job!{' '}
						</span>
					</div>

					<div className='mt-[1rem] mb-[5rem]'>
						<OfferInfoCard
							handleBack={handleCancel}
							item={activeOffer}
						/>
					</div>
				</>
			) : (
				<>
					<div className='mt-[1.2rem]'>
						<span className='heading_heading_5'>
							{jobList?.length
								? `Congratulations ${user?.usrFirstName} on a successful application.`
								: 'Job offers'}
						</span>
					</div>

					<div className='mt-[1rem] mb-[5rem]'>
						{jobList?.length ? (
							jobList?.map((item, index) => {
								return (
									<JobOfferCard
										key={index}
										handleOfferInfo={handleOfferInfo}
										item={item}
									/>
								);
							})
						) : (
							<EmptyApplicationState info='Applications you’ve been invited for interviews will show up here' />
						)}
					</div>
				</>
			)}
		</>
	);
}
