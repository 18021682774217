import { Breadcrumb } from 'antd';
import JumbotronOne from '../../../components/JambotronOne';
import { Link, useNavigate } from 'react-router-dom';
import template1 from '../../../assets/tmp/cv_template.png';
import templateblue from '../../../assets/tmp/blue-temp.png';
import templateorange from '../../../assets/tmp/orange-temp.png';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	clearCVData,
	clearCVTempId,
	setCVTempId,
} from '../../../features/cv/cvSLice';
import SmallScreenWarningModal from '../SmallScreenWarningModal';

export default function CVTemplateSelector() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [open, setopen] = useState(false);

	async function handleSelectTemplate(id) {
		await dispatch(setCVTempId(id));
		await navigate('/cv-cover-letter/create/resume');
	}

	async function handleClear() {
		await dispatch(clearCVData());
		await dispatch(clearCVTempId());
	}

	useEffect(() => {
		handleClear();

		if (window.innerWidth < 1170) {
			setopen(true)
		}else{
			setopen(false)
		}
	}, []);

	return (
		<>
			<div className='p-3 lg:p-0 lg:pr-[38px] lg:pl-[22px] mt-[15px]'>
				<JumbotronOne
					text1={`CV/Cover Letter`}
					text2={''}
					text3={''}
					text4={'A strong CV will help you stand out to employers.'}
				/>

				<div className='flex flex-col mt-[1.2rem] px-[.75rem] lg:px-[2.5rem] w-full pb-[11rem]'>
					<div className='w-full'>
						<Breadcrumb
							items={[
								{
									title: <Link to='/cv-cover-letter'>CV/Cover Letter</Link>,
								},
								{
									title: 'CV Builder',
								},
							]}
						/>
					</div>
					<span className='heading_heading_4 mt-[2.5rem] w-full'>
						Select a template
					</span>
					<span className='paragraph_paragraph_1 mt-[1.25rem] w-full'>
						Select a template to get started
					</span>

					<div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[2.5rem] mt-[1.69rem] w-full'>
						<button onClick={() => handleSelectTemplate(1)}>
							<div
								style={{
									borderRadius: '0.25rem',
									border: '1px solid #1B47B4',
									background: 'var(--White, #FFF)',
								}}
								className='mr-5 flex justify-center items-center w-full h-[20.2185rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M11.25 18.75V12.75H5.25V11.25H11.25V5.25H12.75V11.25H18.75V12.75H12.75V18.75H11.25Z'
										fill='#1B47B4'
									/>
								</svg>
								<span className='ml-[.38rem] label_label_2 !text-blueDark'>
									Blank
								</span>
							</div>
						</button>

						{/* <button onClick={() => handleSelectTemplate(1)}>
							<div
								style={{
									borderRadius: '0.25rem',
									border: '1px solid #1B47B4',
									background: 'var(--White, #FFF)',
								}}
								className='mr-5 flex justify-center items-center w-full h-[20.2185rem]'>
								<img
									loading='lazy'
									className='w-full h-[300px] max-w-none'
									src={template1}
									alt='template1'
								/>
							</div>
						</button>

						<button onClick={() => handleSelectTemplate(2)}>
							<div
								style={{
									borderRadius: '0.25rem',
									border: '1px solid #1B47B4',
									background: 'var(--White, #FFF)',
								}}
								className='mr-5 flex justify-center items-center w-full h-[20.2185rem]'>
								<img
									loading='lazy'
									className='w-full h-[300px] max-w-none'
									src={templateblue}
									alt='blue template'
								/>
							</div>
						</button>

						<button onClick={() => handleSelectTemplate(3)}>
							<div
								style={{
									borderRadius: '0.25rem',
									border: '1px solid #1B47B4',
									background: 'var(--White, #FFF)',
								}}
								className='mr-5 flex justify-center items-center w-full h-[20.2185rem]'>
								<img
									loading='lazy'
									className='w-full h-[300px] max-w-none'
									src={templateorange}
									alt='orange template'
								/>
							</div>
						</button> */}
					</div>
				</div>
			</div>

			<SmallScreenWarningModal open={open} />
		</>
	);
}
