import {
	Checkbox,
	DatePicker,
	Form,
	Input,
	Popconfirm,
	Select,
	Table,
} from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProfessionalQualification } from '../../../../../features/delete/deleteSlice';
import { customToast } from '../../../../../notifications';
import dayjs from 'dayjs';
import ForeignClearanceUpload from '../../../../../components/ForeignClearanceUpload';
import {
	fetchProfessionalQualifications,
	saveProfessionalQualification,
	setQualifications,
} from '../../../../../features/edu/eduSlice';

const url = process.env.REACT_APP_API_BASE_URL;

export default function ProfessionalTableForm({ viewOnly }) {
	const dispatch = useDispatch();

	const { professionalQualifications, eduLoading } = useSelector(
		(state) => state.edu
	);
	const { dropDownLoading, instTypes, studyAreas, awards, professionalBodies } =
		useSelector((state) => state.dropDown);

	const { user } = useSelector((state) => state.auth);

	const hasClickedRef = useRef(false);

	const [dataSource, setDataSource] = useState(professionalQualifications);
	const [minDate, setminDate] = useState();
	const [deleteArray, setdeleteArray] = useState([]);
	const [showBorder, setshowBorder] = useState(true);

	const usrObj = {
		usrId: user?.usrId,
	};

	const EditableContext = React.createContext(null);
	const EditableRow = ({ index, ...props }) => {
		const [form] = Form.useForm();
		return (
			<Form form={form} component={false}>
				<EditableContext.Provider value={form}>
					<tr {...props} />
				</EditableContext.Provider>
			</Form>
		);
	};

	const EditableCell = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		handleSave,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef(null);
		const form = useContext(EditableContext);
		useEffect(() => {
			if (editing) {
				inputRef.current?.focus();
			}
		}, [editing]);
		const toggleEdit = () => {
			const { pqlStartDate, endEndDate } = record;

			setEditing(!editing);

			form.setFieldsValue({
				[dataIndex]: record[dataIndex],
				pqlStartDate: pqlStartDate ? dayjs(pqlStartDate) : null,
				endEndDate: endEndDate ? dayjs(endEndDate) : null,
			});
		};
		const save = async () => {
			try {
				const values = await form.validateFields();
				toggleEdit();
				handleSave({
					...record,
					...values,
				});
			} catch (errInfo) {
				console.log('Save failed:', errInfo);
			}
		};

		let childNode = children;
		if (editable) {
			childNode = editing ? (
				dataIndex === 'pqlInstType' ? (
					<Form.Item
						className='table-form-item'
						style={{
							margin: 0,
						}}
						name={dataIndex}>
						<Select
							id='select-focus'
							className='select-borderless'
							variant='borderless'
							ref={inputRef}
							suffixIcon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
										fill='#212121'
									/>
								</svg>
							}
							showSearch
							loading={dropDownLoading}
							optionFilterProp='children'
							filterOption={(input, option) =>
								(option?.label?.toLocaleLowerCase() ?? '').includes(
									input?.toLocaleLowerCase()
								)
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							options={
								instTypes?.length > 0 &&
								instTypes?.map((item) => ({
									value: item.instpName,
									label: item.instpName,
								}))
							}
							onPressEnter={save}
							onChange={save}
						/>
					</Form.Item>
				) : dataIndex === 'pqlAreaOfStudy' ? (
					<Form.Item
						className='table-form-item'
						style={{
							margin: 0,
						}}
						name={dataIndex}>
						<Select
							className='select-borderless'
							variant='borderless'
							ref={inputRef}
							suffixIcon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
										fill='#212121'
									/>
								</svg>
							}
							showSearch
							loading={dropDownLoading}
							optionFilterProp='children'
							filterOption={(input, option) =>
								(option?.label?.toLocaleLowerCase() ?? '').includes(
									input?.toLocaleLowerCase()
								)
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							options={
								studyAreas?.length > 0 &&
								studyAreas?.map((item) => ({
									value: item.stdyName,
									label: item.stdyName,
								}))
							}
							onPressEnter={save}
							onChange={save}
						/>
					</Form.Item>
				) : dataIndex === 'pqlAward' ? (
					<Form.Item
						className='table-form-item'
						style={{
							margin: 0,
						}}
						name={dataIndex}>
						<Select
							className='select-borderless'
							variant='borderless'
							ref={inputRef}
							suffixIcon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
										fill='#212121'
									/>
								</svg>
							}
							showSearch
							loading={dropDownLoading}
							optionFilterProp='children'
							filterOption={(input, option) =>
								(option?.label?.toLocaleLowerCase() ?? '').includes(
									input?.toLocaleLowerCase()
								)
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							options={
								awards?.length > 0 &&
								awards?.map((item) => ({
									value: item.awdName,
									label: item.awdName,
								}))
							}
							onPressEnter={save}
							onChange={save}
						/>
					</Form.Item>
				) : dataIndex === 'pqlMembershipBody' ? (
					<Form.Item
						className='table-form-item'
						style={{
							margin: 0,
						}}
						name={dataIndex}>
						<Select
							className='select-borderless'
							variant='borderless'
							mode='multiple'
							maxTagCount={1}
							ref={inputRef}
							suffixIcon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
										fill='#212121'
									/>
								</svg>
							}
							showSearch
							loading={dropDownLoading}
							optionFilterProp='children'
							filterOption={(input, option) =>
								(option?.label?.toLocaleLowerCase() ?? '').includes(
									input?.toLocaleLowerCase()
								)
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							options={
								professionalBodies?.length > 0 &&
								professionalBodies?.map((item) => ({
									value: item.prbName,
									label: item.prbName,
								}))
							}
							onPressEnter={save}
							onChange={save}
						/>
					</Form.Item>
				) : dataIndex === 'pqlStartDate' ? (
					<Form.Item
						className='table-form-item'
						style={{
							margin: 0,
						}}
						name={dataIndex}>
						<DatePicker
							className='date-borderless'
							variant='borderless'
							ref={inputRef}
							onChange={(date) => {
								handleMinDateChange(date);
								handleDateChange('pqlStartDate', date, record?.pqlId);
								setEditing(!editing);
							}}
							format={'MMMM - YYYY'}
							picker='month'
							maxDate={dayjs(new Date())}
						/>
					</Form.Item>
				) : dataIndex === 'endEndDate' ? (
					<Form.Item
						className='table-form-item'
						style={{
							margin: 0,
						}}
						name={dataIndex}>
						<DatePicker
							className='date-borderless'
							variant='borderless'
							ref={inputRef}
							format={'MMMM - YYYY'}
							picker='month'
							minDate={minDate}
							onChange={(date) => {
								handleDateChange('endEndDate', date, record?.pqlId);
								setEditing(!editing);
							}}
						/>
					</Form.Item>
				) : dataIndex === 'pqlFileUrl' ? (
					<Form.Item
						className='table-form-item'
						style={{
							margin: 0,
						}}
						name={dataIndex}>
						<ForeignClearanceUpload
							className={'table-dragger'}
							fileName={record?.pqlFileUrl?.split('_').pop()}
							name={'pqlFileUrl'}
							keyId={record?.pqlId}
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</Form.Item>
				) : (
					<Form.Item
						className='table-form-item'
						style={{
							margin: 0,
						}}
						name={dataIndex}>
						<Input
							className='input-borderless'
							variant='borderless'
							ref={inputRef}
							onPressEnter={save}
							onBlur={save}
							onChange={(e) =>
								form.setFieldsValue({ [dataIndex]: e.target.value })
							}
						/>
					</Form.Item>
				)
			) : (
				<div
					className='editable-cell-value-wrap'
					style={{
						paddingRight: 24,
					}}
					onClick={toggleEdit}>
					{children}
				</div>
			);
		}
		return <td {...restProps}>{childNode}</td>;
	};

	const handleSuccess = async (response) => {
		await dataSource?.forEach((item) => {
			let copyObj = {
				...item,
				pqlMembershipBody: item?.pqlMembershipBody?.toString(),
			};
			dispatch(saveProfessionalQualification(copyObj));
		});

		const res = await dispatch(
			saveProfessionalQualification({
				pqlId: response?.keyId,
				pqlFileName: response.fileName,
				pqlFileUrl: response.jsonData,
				pqlUsrId: user?.usrId,
			})
		);
		if (res?.payload?.success) {
			await customToast({
				content: 'File uploaded successful',
				bdColor: 'success',
				header: 'Success',
			});
			await handleFetchData();
		} else {
			await customToast({
				content:
					'An error occured while uploading file. Please try again later.',
				bdColor: 'error',
				header: 'Error',
			});
		}
	};

	const handleError = async (response) => {
		await customToast({
			content: response,
			bdColor: 'error',
			header: 'Error',
		});
	};

	function handleDeleteArray(checked, item) {
		let arr = [];
		if (checked) {
			arr.push(item);
			arr = [...deleteArray, item];
		} else {
			arr.filter((x) => x !== item);
		}
		setdeleteArray(arr);
	}

	function handleDeleteCancel(item) {
		let arr = [...deleteArray];
		arr.filter((x) => x !== item);
		setdeleteArray(arr);
	}

	async function handleDelete() {
		let itemId = deleteArray[0];
		const res = await dispatch(deleteProfessionalQualification(itemId));
		if (res?.payload?.success) {
			// await customToast({
			// 	content: res?.payload?.messages?.message,
			// 	bdColor: 'success',
			// 	header: 'Success',
			// });
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
		await handleFetchData();
		await setdeleteArray([]);
	}

	async function handleMultipleDelete() {
		await deleteArray?.forEach((item) => {
			dispatch(deleteProfessionalQualification(item));
		});
		await customToast({
			content: 'Operation completed',
			bdColor: '',
			header: 'Done',
		});
		await handleFetchData();
		await setdeleteArray([]);
	}

	const handleAdd = async () => {
		const newData = {
			pqlUsrId: user?.usrId,
			pqlInstType: 'Click to add item',
		};

		await dataSource?.forEach((item) => {
			let copyObj = {
				...item,
				pqlMembershipBody: item?.pqlMembershipBody?.toString(),
			};
			dispatch(saveProfessionalQualification(copyObj));
		});

		await dispatch(saveProfessionalQualification(newData));
		await handleFetchData();
	};

	const defaultColumns = [
		viewOnly
			? {}
			: {
					title: '',
					dataIndex: 'pqlId',
					render: (item) => (
						// console.log(deleteArray.findIndex((x) => x === item) === 1)
						<>
							<Popconfirm
								className='no-desc-no-title'
								title=''
								description=''
								open={
									deleteArray.findIndex((x) => x === item) === -1
										? false
										: true && deleteArray.length <= 1
								}
								onConfirm={handleDelete}
								onCancel={() => handleDeleteCancel(item)}
								okText={
									<>
										<div className='flex items-center'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='20'
												height='20'
												viewBox='0 0 20 20'
												fill='none'>
												<path
													d='M16.875 3.75H13.75V3.125C13.75 2.62772 13.5525 2.15081 13.2008 1.79917C12.8492 1.44754 12.3723 1.25 11.875 1.25H8.125C7.62772 1.25 7.15081 1.44754 6.79917 1.79917C6.44754 2.15081 6.25 2.62772 6.25 3.125V3.75H3.125C2.95924 3.75 2.80027 3.81585 2.68306 3.93306C2.56585 4.05027 2.5 4.20924 2.5 4.375C2.5 4.54076 2.56585 4.69973 2.68306 4.81694C2.80027 4.93415 2.95924 5 3.125 5H3.75V16.25C3.75 16.5815 3.8817 16.8995 4.11612 17.1339C4.35054 17.3683 4.66848 17.5 5 17.5H15C15.3315 17.5 15.6495 17.3683 15.8839 17.1339C16.1183 16.8995 16.25 16.5815 16.25 16.25V5H16.875C17.0408 5 17.1997 4.93415 17.3169 4.81694C17.4342 4.69973 17.5 4.54076 17.5 4.375C17.5 4.20924 17.4342 4.05027 17.3169 3.93306C17.1997 3.81585 17.0408 3.75 16.875 3.75ZM7.5 3.125C7.5 2.95924 7.56585 2.80027 7.68306 2.68306C7.80027 2.56585 7.95924 2.5 8.125 2.5H11.875C12.0408 2.5 12.1997 2.56585 12.3169 2.68306C12.4342 2.80027 12.5 2.95924 12.5 3.125V3.75H7.5V3.125ZM15 16.25H5V5H15V16.25ZM8.75 8.125V13.125C8.75 13.2908 8.68415 13.4497 8.56694 13.5669C8.44973 13.6842 8.29076 13.75 8.125 13.75C7.95924 13.75 7.80027 13.6842 7.68306 13.5669C7.56585 13.4497 7.5 13.2908 7.5 13.125V8.125C7.5 7.95924 7.56585 7.80027 7.68306 7.68306C7.80027 7.56585 7.95924 7.5 8.125 7.5C8.29076 7.5 8.44973 7.56585 8.56694 7.68306C8.68415 7.80027 8.75 7.95924 8.75 8.125ZM12.5 8.125V13.125C12.5 13.2908 12.4342 13.4497 12.3169 13.5669C12.1997 13.6842 12.0408 13.75 11.875 13.75C11.7092 13.75 11.5503 13.6842 11.4331 13.5669C11.3158 13.4497 11.25 13.2908 11.25 13.125V8.125C11.25 7.95924 11.3158 7.80027 11.4331 7.68306C11.5503 7.56585 11.7092 7.5 11.875 7.5C12.0408 7.5 12.1997 7.56585 12.3169 7.68306C12.4342 7.80027 12.5 7.95924 12.5 8.125Z'
													fill='#343330'
												/>
											</svg>
											<span className='paragraph_paragraph_2 !text-[#101828] ml-[0.75rem]'>
												Remove row
											</span>
										</div>
									</>
								}
								showCancel={false}
								okButtonProps={{
									style: {
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										background: '#fff',
										color: '#000',
										fontWeight: 500,
										fontSize: '14px',
										padding: '1.25rem',
										boxShadow: 'none !importnat',
									},
								}}>
								<Checkbox
									checked={
										deleteArray.findIndex((x) => x === item) === -1
											? false
											: true
									}
									onChange={(e) => handleDeleteArray(e.target.checked, item)}
								/>
							</Popconfirm>
						</>
					),
			  },
		{
			title: 'Institution Type',
			dataIndex: 'pqlInstType',
			sorter: (a, b) => a.pqlInstType - b.pqlInstType,
			editable: true,
		},
		{
			title: 'Institution Name',
			dataIndex: 'pqlInstName',
			key: 'pqlInstname',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Membership Body',
			dataIndex: 'pqlMembershipBody',
			key: 'pqlMembershipBody',
			sorter: (a, b) => a - b,
			render: (item) => <span>{item ? item : ''}</span>,
			editable: true,
		},
		{
			title: 'Area of Study',
			dataIndex: 'pqlAreaOfStudy',
			key: 'pqlAreaOfStudy',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Award',
			dataIndex: 'pqlAward',
			key: 'pqlAward',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Course',
			dataIndex: 'pqlCourse',
			key: 'pqlCourse',
			sorter: (a, b) => a - b,
			editable: true,
		},

		{
			title: 'Certificate No',
			key: 'pqlCertNo',
			dataIndex: 'pqlCertNo',
			sorter: (a, b) => a.pqlCertNo - b.pqlCertNo,
			editable: true,
		},
		{
			title: 'Start Date',
			dataIndex: 'pqlStartDate',
			key: 'pqlStartDate',
			sorter: (a, b) => a - b,
			render: (item) => (
				<span>{item ? moment(item).format('MMMM YYYY') : ''}</span>
			),
			editable: true,
		},
		{
			title: 'Graduation Date',
			dataIndex: 'endEndDate',
			key: 'endEndDate',
			sorter: (a, b) => a - b,
			render: (item) => (
				<span>{item ? moment(item).format('MMMM YYYY') : ''}</span>
			),
			editable: true,
		},
		// {
		// 	title: 'Cerificate',
		// 	dataIndex: 'pqlFileUrl',
		// 	key: 'pqlFileUrl',
		// 	render: (item) => <span>{item ? item?.split('_').pop() : ''}</span>,
		// 	editable: true,
		// },
	];

	const defaultColumnsViewOnly = [
		{
			title: 'Institution Type',
			dataIndex: 'pqlInstType',
			sorter: (a, b) => a.pqlInstType - b.pqlInstType,
			editable: true,
		},
		{
			title: 'Institution Name',
			dataIndex: 'pqlInstName',
			key: 'pqlInstname',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Membership Body',
			dataIndex: 'pqlMembershipBody',
			key: 'pqlMembershipBody',
			sorter: (a, b) => a - b,
			render: (item) => <span>{item ? item : ''}</span>,
			editable: true,
		},
		{
			title: 'Area of Study',
			dataIndex: 'pqlAreaOfStudy',
			key: 'pqlAreaOfStudy',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Award',
			dataIndex: 'pqlAward',
			key: 'pqlAward',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Course',
			dataIndex: 'pqlCourse',
			key: 'pqlCourse',
			sorter: (a, b) => a - b,
			editable: true,
		},

		{
			title: 'Certificate No',
			key: 'pqlCertNo',
			dataIndex: 'pqlCertNo',
			sorter: (a, b) => a.pqlCertNo - b.pqlCertNo,
			editable: true,
		},
		{
			title: 'Start Date',
			dataIndex: 'pqlStartDate',
			key: 'pqlStartDate',
			sorter: (a, b) => a - b,
			render: (item) => (
				<span>{item ? moment(item).format('MMMM YYYY') : ''}</span>
			),
			editable: true,
		},
		{
			title: 'Graduation Date',
			dataIndex: 'endEndDate',
			key: 'endEndDate',
			sorter: (a, b) => a - b,
			render: (item) => (
				<span>{item ? moment(item).format('MMMM YYYY') : ''}</span>
			),
			editable: true,
		},
		// {
		// 	title: 'Cerificate',
		// 	dataIndex: 'pqlFileUrl',
		// 	key: 'pqlFileUrl',
		// 	render: (item) => <span>{item ? item?.split('_').pop() : ''}</span>,
		// 	editable: true,
		// },
	];

	const handleSave = async (row) => {
		await dispatch(setQualifications(row));
	};

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};
	const columns = viewOnly
		? defaultColumnsViewOnly
		: defaultColumns.map((col) => {
				if (!col.editable) {
					return col;
				}
				return {
					...col,
					onCell: (record) => ({
						record,
						editable: col.editable,
						dataIndex: col.dataIndex,
						title: col.title,
						handleSave,
					}),
				};
		  });

	function handleMinDateChange(date) {
		setminDate(date);
	}

	const handleDateChange = async (name, date, id) => {
		const dateObj = {
			pqlId: id,
			[name]: date,
		};
		await dataSource?.forEach((item) => {
			let copyObj = {
				...item,
				pqlMembershipBody: item?.pqlMembershipBody?.toString(),
			};
			dispatch(saveProfessionalQualification(copyObj));
		});
		await dispatch(saveProfessionalQualification(dateObj));
		await handleFetchData();
	};

	async function handleFetchData() {
		await dispatch(fetchProfessionalQualifications(usrObj));
	}

	useEffect(() => {
		handleFetchData();
	}, []);

	useEffect(() => {
		if (dataSource?.length === 0 && !hasClickedRef.current) {
			document.getElementById('addBtn2')?.click();
			hasClickedRef.current = true; // Prevent further clicks
		}
	}, [dataSource]);

	useEffect(() => {
		setDataSource(professionalQualifications);
	}, [professionalQualifications]);

	return (
		<>
			<div className='dash-inner-page mt-3 max-w-full w-full overflow-x-auto'>
				<section>
					<Table
						// rowSelection={true}
						loading={eduLoading}
						className={`no-header-bg-table no-hover ${
							showBorder ? 'editor' : ''
						}`}
						size={'middle'}
						bordered={false}
						columns={columns}
						components={components}
						dataSource={dataSource}
						scroll={{
							x: 1500,
						}}
						pagination={{
							defaultPageSize: 5,
							hideOnSinglePage: true,
							pageSizeOptions: [10, 20, 50, 100],
						}}
						title={
							deleteArray?.length > 1 || viewOnly === false
								? () => {
										return (
											<>
												<button
													onClick={handleMultipleDelete}
													className='bg-transparent flex items-center border-none'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='25'
														height='25'
														viewBox='0 0 20 20'
														fill='none'>
														<path
															d='M16.875 3.75H13.75V3.125C13.75 2.62772 13.5525 2.15081 13.2008 1.79917C12.8492 1.44754 12.3723 1.25 11.875 1.25H8.125C7.62772 1.25 7.15081 1.44754 6.79917 1.79917C6.44754 2.15081 6.25 2.62772 6.25 3.125V3.75H3.125C2.95924 3.75 2.80027 3.81585 2.68306 3.93306C2.56585 4.05027 2.5 4.20924 2.5 4.375C2.5 4.54076 2.56585 4.69973 2.68306 4.81694C2.80027 4.93415 2.95924 5 3.125 5H3.75V16.25C3.75 16.5815 3.8817 16.8995 4.11612 17.1339C4.35054 17.3683 4.66848 17.5 5 17.5H15C15.3315 17.5 15.6495 17.3683 15.8839 17.1339C16.1183 16.8995 16.25 16.5815 16.25 16.25V5H16.875C17.0408 5 17.1997 4.93415 17.3169 4.81694C17.4342 4.69973 17.5 4.54076 17.5 4.375C17.5 4.20924 17.4342 4.05027 17.3169 3.93306C17.1997 3.81585 17.0408 3.75 16.875 3.75ZM7.5 3.125C7.5 2.95924 7.56585 2.80027 7.68306 2.68306C7.80027 2.56585 7.95924 2.5 8.125 2.5H11.875C12.0408 2.5 12.1997 2.56585 12.3169 2.68306C12.4342 2.80027 12.5 2.95924 12.5 3.125V3.75H7.5V3.125ZM15 16.25H5V5H15V16.25ZM8.75 8.125V13.125C8.75 13.2908 8.68415 13.4497 8.56694 13.5669C8.44973 13.6842 8.29076 13.75 8.125 13.75C7.95924 13.75 7.80027 13.6842 7.68306 13.5669C7.56585 13.4497 7.5 13.2908 7.5 13.125V8.125C7.5 7.95924 7.56585 7.80027 7.68306 7.68306C7.80027 7.56585 7.95924 7.5 8.125 7.5C8.29076 7.5 8.44973 7.56585 8.56694 7.68306C8.68415 7.80027 8.75 7.95924 8.75 8.125ZM12.5 8.125V13.125C12.5 13.2908 12.4342 13.4497 12.3169 13.5669C12.1997 13.6842 12.0408 13.75 11.875 13.75C11.7092 13.75 11.5503 13.6842 11.4331 13.5669C11.3158 13.4497 11.25 13.2908 11.25 13.125V8.125C11.25 7.95924 11.3158 7.80027 11.4331 7.68306C11.5503 7.56585 11.7092 7.5 11.875 7.5C12.0408 7.5 12.1997 7.56585 12.3169 7.68306C12.4342 7.80027 12.5 7.95924 12.5 8.125Z'
															fill='#C8001B'
														/>
													</svg>

													<span className='paragraph_paragraph_2 !text-[#C8001B] ml-3'>
														Delete selected
													</span>
												</button>
											</>
										);
								  }
								: null
						}
						footer={
							viewOnly
								? null
								: () => {
										return (
											<>
												<button
													id='addBtn2'
													onClick={handleAdd}
													className='bg-transparent flex items-center border-none'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='25'
														height='24'
														viewBox='0 0 25 24'
														fill='none'>
														<path
															fillRule='evenodd'
															clipRule='evenodd'
															d='M3.2207 12C3.2207 11.5858 3.55649 11.25 3.9707 11.25H20.4707C20.8849 11.25 21.2207 11.5858 21.2207 12C21.2207 12.4142 20.8849 12.75 20.4707 12.75H3.9707C3.55649 12.75 3.2207 12.4142 3.2207 12Z'
															fill='#147CBC'
														/>
														<path
															fillRule='evenodd'
															clipRule='evenodd'
															d='M12.2207 3C12.6349 3 12.9707 3.33579 12.9707 3.75V20.25C12.9707 20.6642 12.6349 21 12.2207 21C11.8065 21 11.4707 20.6642 11.4707 20.25V3.75C11.4707 3.33579 11.8065 3 12.2207 3Z'
															fill='#147CBC'
														/>
													</svg>

													<span className='paragraph_paragraph_2 ml-3'>
														Add
													</span>
												</button>
											</>
										);
								  }
						}
					/>
				</section>
			</div>
		</>
	);
}
