import { useDispatch, useSelector } from 'react-redux';
import InAppNotificationBar from '../../components/InAppNotificationBar';
import JumbotronOne from '../../components/JambotronOne';
import DocumentsDescription from './descriptions/DocumentsDescription';
import EmployerDescription from './descriptions/EmployerDescription';
import EmploymentTermsDescription from './descriptions/EmploymentTermsDescription';
import { fetchAttestationDocSetups } from '../../features/dropDowns/dropDownSlice';
import {
	fetchAttestationDocuments,
	saveForeignClearance,
	setActiveForeignJob,
} from '../../features/job/jobSlice';
import { useEffect, useState } from 'react';
import AttestationLocationDescription from './descriptions/AttestationLocationDescription';
import AttestationResultsDescription from './descriptions/AttestationResultsDescription';
import ArrivalStatusDescription from './descriptions/ArrivalStatusDescription';
import { Navigate, useNavigate } from 'react-router-dom';

export default function AttestationProcess() {
	const dispatch = useDispatch();
	const navigate = useNavigate()

	const { foreignJobActive } = useSelector((state) => state.job);

	const [message, setmessage] = useState('');
	const [current, setcurrent] = useState(1);
	const [color, setcolor] = useState('#616161');

	function handleAttestationResults() {
		setcurrent(2);
	}

	function handleArrivalStatus() {
		setcurrent(3);
	}

	function handleBack() {
		if (foreignJobActive?.japProgressStatus === 'FAILED_ATTESTATION') {
			return navigate(-1);
		}
		setcurrent(1);
	}

	async function handleFetch() {
		await dispatch(fetchAttestationDocuments(foreignJobActive?.japId));
		await dispatch(fetchAttestationDocSetups());
	}

	async function handleUpdate() {
		const res = await dispatch(
			saveForeignClearance({
				japId: foreignJobActive?.japId,
			})
		);

		if (res?.payload?.success) {
			let japObj = { ...foreignJobActive, ...res?.payload?.jsonData };
			await dispatch(setActiveForeignJob(japObj));
		}
	}

	function handleStatus(status) {
		switch (status) {
			case 'UPLOADED':
				setcolor('#616161');
				return setmessage('All relevant documents have been uploaded.');

			case 'SEND_TO_ATTESTATION':
				setcolor('#69D8F0');
				return setmessage(
					'Application has been submited for attestation.'
				);

			case 'PASSED_ATTESTATION':
				setcolor('#02A548');
				return setmessage(
					'Passed Attestation. Waiting for border clearance'
				);

			case 'FAILED_ATTESTATION':
				setcolor('#C8001B');
				return setmessage(
					'Attestation failed. Please check your details and try again later or contact your Employment agency'
				);

			case 'CLEAR':
				setcolor('#F0FFAD');
				return setmessage(
					'You have been cleared for diparture by border management.'
				);

			default:
				setcolor('#616161');
				return setmessage('Application is under review.');
		}
	}

	useEffect(() => {
		handleStatus(foreignJobActive?.japProgressStatus);
	}, [foreignJobActive]);

	useEffect(() => {
		handleFetch();
		handleUpdate();
	}, []);

	return (
		<>
			<div className='w-full p-3 lg:p-0 lg:w-[95%] lg:mx-[42px] '>
				<JumbotronOne
					text1={`Your`}
					text2={'attestation'}
					text3={'process.'}
					text4={'Prepare your documentation to work abroad.'}
				/>

				<div className='mt-[1.12rem] flex flex-col lg:flex-row justify-between items-center w-full gap-5'>
					<InAppNotificationBar message={message} color={color} />

					{foreignJobActive?.japProgressStatus ===
						'FAILED_ATTESTATION' ||
					foreignJobActive?.japProgressStatus ===
						'PASSED_ATTESTATION' ||
					foreignJobActive?.japProgressStatus === 'CLEAR' ? (
						<>
							{foreignJobActive?.japProgressStatus ===
								'PASSED_ATTESTATION' && current === 1 ? (
								<button
									onClick={handleAttestationResults}
									className='!w-fit resume-next-btn'
									type='button'>
									<span>Attestation results</span>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'>
										<path
											d='M3.75 12H20.25'
											stroke='#fff'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M13.5 5.25L20.25 12L13.5 18.75'
											stroke='#fff'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</button>
							) : foreignJobActive?.japProgressStatus ===
									'CLEAR' && current === 1 ? (
								<button
									onClick={handleArrivalStatus}
									className='!w-fit resume-next-btn'
									type='button'>
									<span>Arrival status</span>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'>
										<path
											d='M3.75 12H20.25'
											stroke='#fff'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M13.5 5.25L20.25 12L13.5 18.75'
											stroke='#fff'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</button>
							) : (
								<>
									<button
										onClick={handleBack}
										className='!w-[15rem] resume-back-btn'
										type='button'>
										Back
									</button>
								</>
							)}
						</>
					) : null}
				</div>

				<div className='flex flex-col mt-[1.25rem]  gap-[.75rem]'>
					{/* <UserDesciption /> */}
					{current === 3 ? (
						<ArrivalStatusDescription
							foreignJobActive={foreignJobActive}
						/>
					) : null}

					{current === 2 ? (
						<AttestationResultsDescription
							foreignJobActive={foreignJobActive}
						/>
					) : null}

					{current === 1 ? (
						<>
							<div className='grid grid-cols-1 lg:grid-cols-2 gap-[.5rem]'>
								<EmployerDescription
									foreignJobActive={foreignJobActive}
								/>
								<EmploymentTermsDescription
									foreignJobActive={foreignJobActive}
								/>
							</div>
							<DocumentsDescription />
							<AttestationLocationDescription
								foreignJobActive={foreignJobActive}
							/>
						</>
					) : null}
				</div>
			</div>
		</>
	);
}
