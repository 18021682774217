import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JobCardUpdated from '../../../components/JobCardUpdated';
import saved from '../../../assets/saved.svg';
import JobCardSmall from '../../../components/JobCardSmall';
import { fetchSaveJobs } from '../../../features/job/jobSlice';

export default function SavedJob() {
	const dispatch = useDispatch();

	const { savedJobs } = useSelector((state) => state.job);
	const { user } = useSelector((state) => state.auth);

	const jobObj = {
		josUsrId: user?.usrId,
	};

	const handleFetchJobs = async () => await dispatch(fetchSaveJobs(jobObj));

	useEffect(() => {
		handleFetchJobs();
	}, []);

	useEffect(() => {}, [savedJobs]);

	return (
		<>
			<div className='w-full h-auto'>
				{savedJobs?.length ? (
					<>
						<div className='w-full h-[2.5rem] flex items-center border-l-[0.1875rem] border-blueDark pl-[2rem] bg-white'>
							<span className='label_label_3'>
								These are the jobs you have saved
							</span>
						</div>
						<div className='hidden lg:flex flex-col w-full mt-[1rem]'>
							{savedJobs?.map((item, index) => {
								return (
									<JobCardUpdated key={index} item={item} />
								);
							})}
						</div>

						<div className='flex flex-col lg:hidden w-full mt-[1rem]'>
							{savedJobs?.map((item, index) => {
								return <JobCardSmall key={index} item={item} />;
							})}
						</div>
					</>
				) : (
					<>
						<div className='w-full h-[2.5rem] flex items-center border-l-[0.1875rem] border-blueDark pl-[2rem] bg-white'>
							<span className='label_label_3'>
								You haven’t saved any jobs yet
							</span>
						</div>
						<div className='w-full mt-[1rem] bg-white h-[23.5rem] flex justify-center items-center'>
							<img
								src={saved}
								alt='referral'
								className='w-full h-full mt-[4rem]'
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
}
