import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import JobCardUpdated from '../../../components/JobCardUpdated';
import referral from '../../../assets/referral.svg';
import JobCardSmall from '../../../components/JobCardSmall';

export default function ReferredJobs() {
	const { refJobs } = useSelector((state) => state.job);

	const [jobList, setjobList] = useState(refJobs);

	useEffect(() => {}, [jobList]);

	useEffect(() => {
		setjobList(refJobs);
	}, [refJobs]);

	return (
		<>
			<div className='w-full h-auto'>
				{jobList?.length ? (
					<>
						<div className='w-full h-[2.5rem] flex items-center border-l-[0.1875rem] border-blueDark pl-[2rem] bg-white'>
							<span className='label_label_3'>
								These are jobs you have been referred to.
							</span>
						</div>
						<div className='hidden lg:flex flex-col w-full mt-[1rem]'>
							{jobList?.map((item, index) => {
								return (
									<>
										<JobCardUpdated key={index} item={item} />
									</>
								);
							})}
						</div>

						<div className='flex flex-col lg:hidden w-full mt-[1rem]'>
							{jobList?.map((item, index) => {
								return (
									<>
										<JobCardSmall key={index} item={item} />
									</>
								);
							})}
						</div>
					</>
				) : (
					<>
						<div className='w-full h-[2.5rem] flex items-center border-l-[0.1875rem] border-blueDark pl-[2rem] bg-white'>
							<span className='label_label_3'>No referrals yet</span>
						</div>
						<div className='w-full mt-[1rem] bg-white h-[23.5rem] flex justify-center items-center'>
							<img src={referral} alt="referral" className='w-full h-full mt-[4rem]' />
						</div>
					</>
				)}
			</div>
		</>
	);
}
