import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import con from '../../assets/con2.png';
import { DatePicker, Form, Modal, Popconfirm, Select, Spin } from 'antd';
import { fetchNeaOffices } from '../../features/global/globalSlice';
import {
	fetchCounselorCalendars,
	fetchCounselors,
	saveCounselor,
} from '../../features/sess/sessSlice';
import moment from 'moment/moment';
import ScrollTo from '../../ScrollTo';
import { fetchCounsellingCategorys } from '../../features/dropDowns/dropDownSlice';
import dayjs from 'dayjs';
import { customToast } from '../../notifications';
import JumbotronOne from '../../components/JambotronOne';

export default function Counselor() {
	// const navigate = useNavigate()
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { globalLoading, nea } = useSelector((state) => state.global);
	const { sessions, sessLoading, counselorCalendars } = useSelector(
		(state) => state.sess
	);

	const { dropDownLoading, counsellingCategories } = useSelector(
		(state) => state.dropDown
	);

	const [options, setOptions] = useState(nea);
	const [formData, setformData] = useState({
		cnsCategory: '',
		cnsDate: null,
		cnsSession: '',
	});
	const [sessList, setsessList] = useState(sessions);
	const [open2, setopen2] = useState(false);
	const [hasError, sethasError] = useState(false);
	const [hasDates, sethasDates] = useState(true);

	const activeSession = sessList?.find(
		(item) =>
			item?.cnsStatus === 'ACTIVE' || item?.cnsStatus === 'AWAITING_COUNSELLING'
	);

	const usrObj = {
		cnsUsrId: user?.usrId,
	};

	const dateFormat = 'YYYY-MM-DD';

	let mon = undefined;
	let tue = undefined;
	let wed = undefined;
	let thur = undefined;
	let fri = undefined;
	let sat = undefined;
	let sun = undefined;

	counselorCalendars?.forEach((item) => {
		if (
			String(item?.cocaDay).toUpperCase() === String('MONDAY').toUpperCase()
		) {
			mon = 1;
		} else if (
			String(item?.cocaDay).toUpperCase() === String('TUESDAY').toUpperCase()
		) {
			tue = 2;
		} else if (
			String(item?.cocaDay).toUpperCase() === String('WEDNESDAY').toUpperCase()
		) {
			wed = 3;
		} else if (
			String(item?.cocaDay).toUpperCase() === String('THURSDAY').toUpperCase()
		) {
			thur = 4;
		} else if (
			String(item?.cocaDay).toUpperCase() === String('FRIDAY').toUpperCase()
		) {
			fri = 5;
		} else if (
			String(item?.cocaDay).toUpperCase() === String('SATURDAY').toUpperCase()
		) {
			sat = 6;
		} else if (
			String(item?.cocaDay).toUpperCase() === String('SUNDAY').toUpperCase()
		) {
			sun = 0;
		}
	});

	let morningTimes = counselorCalendars?.find(
		(item) =>
			item?.cocaMorningStartTime != null && item?.cocaMorningEndTime != null
	);

	let afternoonTimes = counselorCalendars?.find(
		(item) =>
			item?.cocaAfternoonStartTime != null && item?.cocaAfternoonEndTime != null
	);

	let eveningTimes = counselorCalendars?.find(
		(item) =>
			item?.cocaEveningStartTime != null && item?.cocaEveningEndTime != null
	);

	let sessTime = [
		{
			sessName: 'Morning',
			timeFrame:
				morningTimes?.cocaMorningStartTime || morningTimes?.cocaMorningEndTime
					? morningTimes?.cocaMorningStartTime +
					  ' - ' +
					  morningTimes?.cocaMorningEndTime
					: null,
		},
		{
			sessName: 'Afternoon',
			timeFrame:
				afternoonTimes?.cocaAfternoonStartTime ||
				afternoonTimes?.cocaAfternoonEndTime
					? afternoonTimes?.cocaAfternoonStartTime +
					  ' - ' +
					  afternoonTimes?.cocaAfternoonEndTime
					: null,
		},
		{
			sessName: 'Evening',
			timeFrame:
				eveningTimes?.cocaEveningStartTime || eveningTimes?.cocaEveningEndTime
					? eveningTimes?.cocaEveningStartTime +
					  ' - ' +
					  eveningTimes?.cocaEveningEndTime
					: null,
		},
	];

	async function handleFetchCounselorCalendars(instId) {
		const res = await dispatch(fetchCounselorCalendars(instId));
		if (res?.payload?.length) {
			sethasDates(true);
		} else {
			sethasDates(false);
		}
	}

	async function handleReject(item) {
		let cnsObj = { ...item, cnsStatus: 'REJECT' };
		const res = await dispatch(saveCounselor(cnsObj));
		if (res?.payload?.success) {
			await customToast({
				content: 'Session cancelled',
				bdColor: 'success',
				header: 'Success',
			});
			await dispatch(fetchCounselors(usrObj));
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	}

	function handleClose() {
		setformData({
			cnsCategory: '',
			cnsDate: null,
			cnsSession: '',
		});
		setopen2(false);
	}

	async function onFinish(values) {
		const sessObj = {
			...values,
			cnsUsrId: user?.usrId,
			cnsAvailability: 'AVAILABLE',
			cnsCreatedBy: user?.usrId,
		};

		const res = await dispatch(saveCounselor(sessObj));
		if (res?.payload?.success) {
			await customToast({
				content: 'Session request has been sent',
				bdColor: 'success',
				header: 'Success',
			});
			await handleFetchsessions();
			await form.resetFields();
			await handleClose();
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	}

	async function handleFetchNeaOffices(data) {
		await dispatch(fetchNeaOffices(data));
	}

	async function handleFetchsessions() {
		const res = await dispatch(fetchCounselors(usrObj));
		if (res?.payload?.success) {
			setsessList(res?.payload?.data?.result);
		}
		await dispatch(fetchCounsellingCategorys());
	}

	function handleOfficeCalendar(item) {
		handleFetchCounselorCalendars(item);
	}

	useEffect(() => {}, [
		options,
		sessList,
		counsellingCategories,
		formData,
		counselorCalendars,
	]);

	useEffect(() => {
		setOptions(nea);
	}, [nea]);

	useEffect(() => {
		setsessList(sessions);
	}, [sessions]);

	useEffect(() => {
		handleFetchsessions();
		handleFetchNeaOffices();
	}, []);

	function disabledDate(current) {
		return (
			new Date(current).getDay() !== mon &&
			new Date(current).getDay() !== tue &&
			new Date(current).getDay() !== wed &&
			new Date(current).getDay() !== thur &&
			new Date(current).getDay() !== fri &&
			new Date(current).getDay() !== sat &&
			new Date(current).getDay() !== sun
		);
	}

	return (
		<>
			<ScrollTo />
			<div className='py-3 lg:p-0 lg:mr-[38px] lg:ml-[22px] mt-[15px] mb-[3rem]'>
				<JumbotronOne
					text1={'Need'}
					text2={'guidance?'}
					text3={'Let’s book a counselor.'}
					text4={'Book an appointment to discuss your thoughts.'}
				/>

				<div className='w-full flex justify-center my-[2.25rem]'>
					<img
						loading='lazy'
						className='w-[8.78381rem] h-[7.25rem] object-contain'
						src={con}
						alt='counsellor'
					/>

					<div
						style={{
							borderRadius: '0.5rem',
							background: '#4166C0',
						}}
						className='w-full md:w-[42.3145rem] h-auto md:h-[7.1875] flex flex-col px-[2.38rem] pb-[2.25rem]'>
						<span className='label_label_2 mb-[0.75rem] mt-[1.25rem]'>
							Request a counseling session
						</span>
						<span className='paragraph_paragraph_3 !text-white'>
							NEA Provides guidance counseling to help you find the job that
							best suits your skills. You can book a counseling session at a NEA
							Center near you.
						</span>
					</div>
				</div>

				{Object.keys(activeSession || {}).length === 0 ? (
					<div
						className='w-full p-3 lg:p-0'
						style={{
							borderRadius: '0.4375rem 0.4375rem 0rem 0rem',
							background: '#fff',
							border: '1px solid #147CBC',
						}}>
						<div
							style={{
								borderRadius: ' 0.4375rem 0.4375rem 0rem 0rem',
								background: '#F9FDFF',
							}}
							className='w-full h-[5.0625rem] flex items-center'>
							<svg
								className='ml-[2.44rem]'
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M16.5 2.25V5.25'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M7.5 2.25V5.25'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M3.75 8.25H20.25'
									stroke='#212121'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
							<span className='label_label_1 ml-[1.31rem]'>
								Request session
							</span>
						</div>
						<div className='w-full flex justify-center'>
							<Form
								layout='vertical'
								ref={formRef}
								name='control-ref'
								onFinish={onFinish}
								onFinishFailed={({ errorFields }) => {
									console.log(errorFields);
									if (errorFields?.length) {
										sethasError(true);
									} else {
										sethasError(false);
									}
								}}
								style={{
									maxWidth: '100%',
									marginTop: '3.62rem',
									width: '41.875rem',
								}}
								form={form}
								scrollToFirstError={true}>
								<div className='flex flex-col h-auto'>
									<>
										<span className='label_label_2 !text-[#212121] mb-[1.5rem]'>
											Fill in the following details
										</span>
										<Form.Item
											// extra={
											// 	!hasDates && counselorCalendars?.length === 0 ? (
											// 		<span className='paragraph_paragraph_3 !text-[#F00]'>
											// 			No available dates for selected NEA office
											// 		</span>
											// 	) : null
											// }
											help={
												!hasDates && counselorCalendars?.length === 0 ? (
													<span className='paragraph_paragraph_3 !text-[#F00]'>
														No available dates for selected NEA office
													</span>
												) : hasError ? null : (
													<span className='paragraph_paragraph_3'>
														Choose option
													</span>
												)
											}
											name='cnsInstId'
											label='Choose NEA Office closest to you'
											rules={[
												{
													required: true,
													message: 'Field is required',
												},
											]}>
											<Select
												suffixIcon={
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'>
														<path
															d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
															fill='#212121'
														/>
													</svg>
												}
												showSearch
												style={{
													width: '100%',
													height: '44px',
												}}
												optionFilterProp='children'
												filterOption={(input, option) =>
													(option?.label?.toLocaleLowerCase() ?? '').includes(
														input?.toLocaleLowerCase()
													)
												}
												filterSort={(optionA, optionB) =>
													(optionA?.label ?? '')
														.toLowerCase()
														.localeCompare((optionB?.label ?? '').toLowerCase())
												}
												onChange={handleOfficeCalendar}
												options={
													options?.length &&
													options?.map((office) => ({
														value: office.instId,
														label: `${office.instName}`,
													}))
												}
												allowClear
												loading={globalLoading}
											/>
										</Form.Item>

										<Form.Item
											help={
												hasError ? null : (
													<span className='paragraph_paragraph_3'>
														Choose option
													</span>
												)
											}
											name='cnsCategory'
											label='Type of guidance required'
											rules={[
												{
													required: true,
													message: 'Field is required',
												},
											]}>
											<Select
												suffixIcon={
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'>
														<path
															d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
															fill='#212121'
														/>
													</svg>
												}
												style={{
													width: '100%',
													height: '44px',
												}}
												optionFilterProp='children'
												filterOption={(input, option) =>
													(option?.label?.toLocaleLowerCase() ?? '').includes(
														input?.toLocaleLowerCase()
													)
												}
												filterSort={(optionA, optionB) =>
													(optionA?.label ?? '')
														.toLowerCase()
														.localeCompare((optionB?.label ?? '').toLowerCase())
												}
												options={
													counsellingCategories?.length &&
													counsellingCategories?.map((item) => ({
														value: item.cctName,
														label: `${item.cctName}`,
													}))
												}
												allowClear
												loading={dropDownLoading}
											/>
										</Form.Item>

										<div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
											<Form.Item
												help={
													hasError ? null : (
														<span className='paragraph_paragraph_3'>
															Select date
														</span>
													)
												}
												name='cnsDate'
												label='Date'
												rules={[
													{
														required: true,
														message: 'Field is required',
													},
												]}>
												<DatePicker
													format={dateFormat}
													minDate={dayjs(new Date())}
													disabledDate={disabledDate}
												/>
											</Form.Item>

											<Form.Item
												help={
													hasError ? null : (
														<span className='paragraph_paragraph_3'>
															Select time
														</span>
													)
												}
												name='cnsSession'
												label='Time'
												rules={[
													{
														required: true,
														message: 'Field is required',
													},
												]}>
												<Select
													suffixIcon={
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'>
															<path
																d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
																fill='#212121'
															/>
														</svg>
													}
													style={{
														width: '100%',
														minHeight: '44px !important',
														height: 'auto',
													}}
													placeholder='Select prefered time'
													options={
														sessTime?.length &&
														sessTime
															?.filter((item) => item.timeFrame !== null)
															?.map((item) => ({
																value: `${item.sessName} (${item.timeFrame})`,
																label: (
																	<div className='flex ite'>
																		<span className='text-[16px] font-medium'>
																			{item.sessName} - ({item.timeFrame})
																		</span>

																		{/* <span className='text-[13px] font-normal'>
																			({item.timeFrame})
																		</span> */}
																	</div>
																),
															}))
													}
													allowClear
												/>
											</Form.Item>
										</div>

										<div className='w-full flex flex-col justify-center items-center mt-[1.5rem] mb-[3.31rem]'>
											<button
												disabled={sessLoading}
												type='submit'
												className='w-[12.6875rem] rounded-[1.75rem] bg-[#1B47B4] text-white p-2'>
												{sessLoading ? <Spin /> : 'Submit'}
											</button>

											{sessList?.length > 0 ? (
												<button
													onClick={() => form.resetFields()}
													className='label_label_2 !text-[#1B47B4] mt-[2rem]'>
													Cancel
												</button>
											) : null}
										</div>
									</>
								</div>
							</Form>
						</div>
					</div>
				) : (
					<>
						<div
							className='w-full mb-[3rem]'
							style={{
								borderRadius: '0.4375rem 0.4375rem 0rem 0rem',
								background: '#fff',
								border: '1px solid #147CBC',
							}}>
							<div
								style={{
									borderRadius: ' 0.4375rem 0.4375rem 0rem 0rem',
									background: '#F9FDFF',
								}}
								className='w-full h-[5.0625rem] mb-[2.69rem] flex items-center'>
								<svg
									className='ml-[2.44rem]'
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z'
										stroke='#212121'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M16.5 2.25V5.25'
										stroke='#212121'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M7.5 2.25V5.25'
										stroke='#212121'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M3.75 8.25H20.25'
										stroke='#212121'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
								<span className='label_label_1 ml-[1.31rem]'>
									Your appointment
								</span>
							</div>

							<div className='ml-3 lg:ml-[5.44rem] w-full'>
								<span className='label_label_2 !text-[#212121]'>
									{activeSession?.cnsStatus === 'COMPLETED'
										? 'Session completed'
										: activeSession?.cnsStatus === 'REJECT'
										? 'Session cancelled'
										: activeSession?.cnsStatus === 'REFER'
										? 'You have been referred for further councelling'
										: activeSession?.cnsStatus === 'AWAITING_COUNSELLING'
										? 'Session confirmed'
										: 'Waiting for feedback'}
								</span>

								<div className='flex items-center mt-[2.13rem] mb-[2rem]'>
									<div className='flex items-center w-[5rem]'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M3 4.5C3 3.67157 3.67157 3 4.5 3H19.5C20.3284 3 21 3.67157 21 4.5V19.5C21 20.3284 20.3284 21 19.5 21H4.5C3.67157 21 3 20.3284 3 19.5V4.5ZM19.5 4.5H4.5V19.5H19.5V4.5Z'
												fill='#1B47B4'
											/>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M16.5 1.5C16.9142 1.5 17.25 1.83579 17.25 2.25V5.25C17.25 5.66421 16.9142 6 16.5 6C16.0858 6 15.75 5.66421 15.75 5.25V2.25C15.75 1.83579 16.0858 1.5 16.5 1.5Z'
												fill='#1B47B4'
											/>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M7.5 1.5C7.91421 1.5 8.25 1.83579 8.25 2.25V5.25C8.25 5.66421 7.91421 6 7.5 6C7.08579 6 6.75 5.66421 6.75 5.25V2.25C6.75 1.83579 7.08579 1.5 7.5 1.5Z'
												fill='#1B47B4'
											/>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M3 8.25C3 7.83579 3.33579 7.5 3.75 7.5H20.25C20.6642 7.5 21 7.83579 21 8.25C21 8.66421 20.6642 9 20.25 9H3.75C3.33579 9 3 8.66421 3 8.25Z'
												fill='#1B47B4'
											/>
										</svg>
										<span className='ml-2 label_label_2 !text-[#1B47B4]'>
											Date
										</span>
									</div>
									<span className='ml-[2.75rem] paragraph_paragraph_2 !text-black14'>
										{moment(activeSession?.cnsDate).format('Do MMMM YYYY')}
									</span>
								</div>

								<div className='flex items-center mt-[2.13rem] mb-[2rem]'>
									<div className='flex items-center w-[5rem]'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M4.5 21.75C4.5 21.3358 4.83579 21 5.25 21H18.75C19.1642 21 19.5 21.3358 19.5 21.75C19.5 22.1642 19.1642 22.5 18.75 22.5H5.25C4.83579 22.5 4.5 22.1642 4.5 21.75Z'
												fill='#1B47B4'
											/>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M12 7.5C10.7574 7.5 9.75 8.50736 9.75 9.75C9.75 10.9926 10.7574 12 12 12C13.2426 12 14.25 10.9926 14.25 9.75C14.25 8.50736 13.2426 7.5 12 7.5ZM8.25 9.75C8.25 7.67893 9.92893 6 12 6C14.0711 6 15.75 7.67893 15.75 9.75C15.75 11.8211 14.0711 13.5 12 13.5C9.92893 13.5 8.25 11.8211 8.25 9.75Z'
												fill='#1B47B4'
											/>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M12 3C10.2098 3 8.4929 3.71116 7.22703 4.97703C5.96116 6.2429 5.25 7.95979 5.25 9.75C5.25 12.855 6.98389 15.6822 8.81595 17.7891C9.72283 18.832 10.6316 19.6729 11.3139 20.2528C11.5837 20.4821 11.8172 20.6699 12 20.8125C12.1828 20.6699 12.4163 20.4821 12.6861 20.2528C13.3684 19.6729 14.2772 18.832 15.184 17.7891C17.0161 15.6822 18.75 12.855 18.75 9.75C18.75 7.95979 18.0388 6.2429 16.773 4.97703C15.5071 3.71116 13.7902 3 12 3ZM12 21.75C11.5699 22.3644 11.5697 22.3643 11.5695 22.3641L11.5688 22.3637L11.5671 22.3624L11.5615 22.3585L11.5425 22.345C11.5263 22.3334 11.5031 22.3167 11.4736 22.2952C11.4144 22.252 11.3294 22.189 11.2223 22.1071C11.0081 21.9434 10.7049 21.7038 10.3424 21.3957C9.61838 20.7803 8.65217 19.8867 7.68405 18.7734C5.76611 16.5678 3.75 13.395 3.75 9.75C3.75 7.56196 4.61919 5.46354 6.16637 3.91637C7.71354 2.36919 9.81196 1.5 12 1.5C14.188 1.5 16.2865 2.36919 17.8336 3.91637C19.3808 5.46354 20.25 7.56196 20.25 9.75C20.25 13.395 18.2339 16.5678 16.316 18.7734C15.3478 19.8867 14.3816 20.7803 13.6576 21.3957C13.2951 21.7038 12.9919 21.9434 12.7777 22.1071C12.6706 22.189 12.5856 22.252 12.5264 22.2952C12.4969 22.3167 12.4737 22.3334 12.4575 22.345L12.4385 22.3585L12.4329 22.3624L12.4312 22.3637L12.4305 22.3641C12.4303 22.3643 12.4301 22.3644 12 21.75ZM12 21.75L12.4301 22.3644C12.1719 22.5452 11.8281 22.5452 11.5699 22.3644L12 21.75Z'
												fill='#1B47B4'
											/>
										</svg>
										<span className='ml-2 label_label_2 !text-[#1B47B4]'>
											Venue
										</span>
									</div>
									<span className='ml-[2.75rem] paragraph_paragraph_2 !text-black14'>
										{activeSession?.neaOffice}
									</span>
								</div>

								<div className='flex items-center mt-[2.13rem] mb-[2rem]'>
									<div className='flex items-center w-[5rem]'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												d='M9.75 20.2506V14.2506H14.25V20.2506H20.25V11.2506C20.2501 11.1521 20.2307 11.0545 20.1931 10.9635C20.1555 10.8724 20.1003 10.7897 20.0306 10.72L12.5306 3.21996C12.461 3.15023 12.3783 3.09491 12.2872 3.05717C12.1962 3.01943 12.0986 3 12 3C11.9014 3 11.8038 3.01943 11.7128 3.05717C11.6217 3.09491 11.539 3.15023 11.4694 3.21996L3.96938 10.72C3.89975 10.7897 3.84454 10.8724 3.8069 10.9635C3.76926 11.0545 3.74992 11.1521 3.75 11.2506V20.2506H9.75Z'
												stroke='#1B47B4'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
										<span className='ml-2 label_label_2 !text-[#1B47B4]'>
											Room
										</span>
									</div>
									<span className='ml-[2.75rem] paragraph_paragraph_2 !text-black14'>
										{activeSession?.cnsVenue}
									</span>
								</div>

								<div className='flex items-center mt-[2.13rem] mb-[2rem]'>
									<div className='flex items-center w-[5rem]'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z'
												fill='#1B47B4'
											/>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M12 6C12.4142 6 12.75 6.33579 12.75 6.75V11.25H17.25C17.6642 11.25 18 11.5858 18 12C18 12.4142 17.6642 12.75 17.25 12.75H12C11.5858 12.75 11.25 12.4142 11.25 12V6.75C11.25 6.33579 11.5858 6 12 6Z'
												fill='#1B47B4'
											/>
										</svg>
										<span className='ml-2 label_label_2 !text-[#1B47B4]'>
											Time
										</span>
									</div>
									<span className='ml-[2.75rem] paragraph_paragraph_2 !text-black14'>
										{activeSession?.cnsSession}
									</span>
								</div>

								{activeSession?.cnsSessionRemarks ? (
									<>
										<div className='flex w-[80%] items-start mt-[2.13rem] mb-[2rem]'>
											<span className='ml-2 label_label_2 !text-[#1B47B4] w-[5rem]'>
												Session Remarks
											</span>
											<span className='ml-[2.75rem] w-full paragraph_paragraph_2 !text-black14'>
												{activeSession?.cnsSessionRemarks}
											</span>
										</div>
									</>
								) : null}

								<div className='mt-[6.22rem] mb-[2.38rem]'>
									<Popconfirm
										title='Confirm'
										className='w-fit'
										description={
											<span className='!flex'>
												Would you like to cancel this session?
											</span>
										}
										onConfirm={() => handleReject(activeSession)}
										okText='Cancel this session'
										cancelText='Dont cancel'
										cancelButtonProps={{
											style: {
												background: '#fff',
												color: '#212143',
												fontWeight: 500,
												fontSize: '14px',
												marginRight: 10,
											},
										}}
										okButtonProps={{
											style: {
												background: '#147CBC',
												color: '#fff',
												fontWeight: 500,
												fontSize: '14px',
											},
										}}>
										<button
											disabled={activeSession?.cnsStatus === 'REJECT'}
											className='label_label_2 !text-[#1B47B4]'>
											No longer interested
										</button>
									</Popconfirm>
								</div>
							</div>
						</div>
					</>
				)}
			</div>

			<Modal
				title={'Confirm Session'}
				style={{
					top: 20,
				}}
				open={open2}
				confirmLoading={sessLoading}
				okText={'Submit'}
				onCancel={handleClose}
				width={'639px'}
				cancelButtonProps={{
					style: {
						background: '#fff',
						color: '#212143',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}
				okButtonProps={{
					style: {
						background: '#147CBC',
						color: '#fff',
						fontWeight: 500,
						fontSize: '14px',
					},
				}}>
				<></>
			</Modal>
		</>
	);
}
