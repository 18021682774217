import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	handlePrevStep,
	handleRegPrevStep,
} from '../../../../features/profile/profileSlice';
import ScrollTo from '../../../../ScrollTo';
import NextOfKinForm from './next_of_kin_form/NextOfKinForm';
import {
	fetchKinContacts,
	saveKinContact,
} from '../../../../features/person/personSllice';
import { useNavigate } from 'react-router-dom';
import { updateUser } from '../../../../features/auth/authSlice';
import { fetchRelationships } from '../../../../features/dropDowns/dropDownSlice';

export default function NextOfKinInfo({ buildprofile }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user } = useSelector((state) => state.auth);
	const { kins } = useSelector((state) => state.prs);

	const onFinish = async (obj) => {
		let values = { ...obj };
		values.kinCreatedBy = user?.usrId;

		values.kinMobileNumber =
			values?.kinMobileNumber?.charAt(3) === '0'
				? values?.kinMobileNumber?.replace('0', '')
				: values?.kinMobileNumber;
		await dispatch(saveKinContact(values));
	};

	async function handleGoNext() {
		let profObj = {
			usrId: user?.usrId,
			usrNationalId: user?.usrNationalId,
			usrStatus: 'PROFILE_COMPLETE',
		};

		await kins?.forEach((item) => {
			onFinish(item);
		});

		await dispatch(updateUser(profObj));
		await navigate('/home');
	}

	async function handleProfileGoNext() {
		await kins?.forEach((item) => {
			onFinish(item);
		});

		await navigate('/profile')
	}

	function handleGoBack() {
		dispatch(handleRegPrevStep());
	}

	function handleProfileGoBack() {
		dispatch(handlePrevStep());
	}

	async function handleFetchKins() {
		const usrObj = {
			usrId: user?.usrId,
		};
		await dispatch(fetchKinContacts(usrObj));
		await dispatch(fetchRelationships());
	}

	useEffect(() => {}, [kins]);

	useEffect(() => {
		handleFetchKins();
	}, []);

	return (
		<>
			{/* <ScrollTo /> */}

			<div className='flex justify-center items-center w-full'>
				<div
					style={{
						borderRadius: '0.5rem',
						border: '1px solid #147CBC',
					}}
					className='mt-10 p-3 xl:p-[2.75rem] !w-full xl:!w-[80.875rem] bg-white'>
					<div className='flex justify-center items-center pt-[3.56rem]'>
						<div
							style={{
								background:
									'linear-gradient(180deg, rgba(0, 0, 0, 0.00) -50%, #1844B5 50%)',
							}}
							className='w-[6.25rem] h-[6.25rem] rounded-full p-[1.81rem] flex justify-center items-center'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='32'
								height='32'
								viewBox='0 0 32 32'
								fill='none'>
								<path
									d='M19 6C20.6819 6.4429 22.2161 7.32427 23.4459 8.55409C24.6757 9.78391 25.5571 11.3181 26 13'
									fill='white'
								/>
								<path
									d='M19 6C20.6819 6.4429 22.2161 7.32427 23.4459 8.55409C24.6757 9.78391 25.5571 11.3181 26 13'
									stroke='white'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M18 10C20.065 10.5525 21.4475 11.935 22 14'
									stroke='white'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									opacity='0.2'
									d='M19.5488 19.167C19.6872 19.0748 19.8465 19.0187 20.0122 19.0036C20.1779 18.9886 20.3447 19.0151 20.4975 19.0807L26.3925 21.722C26.5912 21.8069 26.757 21.9539 26.8651 22.1409C26.9733 22.3279 27.018 22.5449 26.9925 22.7595C26.7983 24.2108 26.0836 25.5422 24.9813 26.506C23.8791 27.4699 22.4642 28.0006 21 27.9995C16.4913 27.9995 12.1673 26.2084 8.97919 23.0203C5.79107 19.8322 4 15.5082 4 10.9995C3.99888 9.53524 4.5296 8.12042 5.49345 7.01815C6.45731 5.91588 7.78869 5.20119 9.24 5.00698C9.45454 4.9815 9.67157 5.02618 9.8586 5.13434C10.0456 5.2425 10.1926 5.40832 10.2775 5.60698L12.9188 11.507C12.9836 11.6585 13.0101 11.8237 12.9957 11.988C12.9813 12.1522 12.9265 12.3103 12.8363 12.4482L10.165 15.6245C10.0702 15.7675 10.0142 15.9326 10.0024 16.1037C9.99055 16.2748 10.0233 16.4461 10.0975 16.6007C11.1313 18.717 13.3188 20.8782 15.4413 21.902C15.5967 21.9758 15.7688 22.0079 15.9404 21.9949C16.112 21.982 16.2773 21.9246 16.42 21.8282L19.5488 19.167Z'
									fill='white'
								/>
								<path
									d='M19.5488 19.167C19.6872 19.0748 19.8465 19.0187 20.0122 19.0036C20.1779 18.9886 20.3447 19.0151 20.4975 19.0807L26.3925 21.722C26.5912 21.8069 26.757 21.9539 26.8651 22.1409C26.9733 22.3279 27.018 22.5449 26.9925 22.7595C26.7983 24.2108 26.0836 25.5422 24.9813 26.506C23.8791 27.4699 22.4642 28.0006 21 27.9995C16.4913 27.9995 12.1673 26.2084 8.97919 23.0203C5.79107 19.8322 4 15.5082 4 10.9995C3.99888 9.53524 4.5296 8.12042 5.49345 7.01815C6.45731 5.91588 7.78869 5.20119 9.24 5.00698C9.45454 4.9815 9.67157 5.02618 9.8586 5.13434C10.0456 5.2425 10.1926 5.40832 10.2775 5.60698L12.9188 11.507C12.9836 11.6585 13.0101 11.8237 12.9957 11.988C12.9813 12.1522 12.9265 12.3103 12.8363 12.4482L10.165 15.6245C10.0702 15.7675 10.0142 15.9326 10.0024 16.1037C9.99055 16.2748 10.0233 16.4461 10.0975 16.6007C11.1313 18.717 13.3188 20.8782 15.4413 21.902C15.5967 21.9758 15.7688 22.0079 15.9404 21.9949C16.112 21.982 16.2773 21.9246 16.42 21.8282L19.5488 19.167Z'
									stroke='white'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</div>
					</div>
					<h3 className='heading_heading_2 w-full text-center mt-[2rem]'>
						<span>Next of Kin</span>
					</h3>
					<p className='heading_heading_5 mt-[1.75rem] mb-[4rem] text-center'>
						Let us know who to reach in case of emergencies
					</p>
					<NextOfKinForm />

					<div className='mt-20'></div>

					<div className='flex flex-col justify-center items-center w-full mb-[6rem]'>
						<button
							disabled={kins?.length === 0}
							title={
								kins?.length === 0
									? 'Next of kin is required before proceeding'
									: null
							}
							onClick={buildprofile ? handleProfileGoNext : handleGoNext}
							className='bg-blueDark rounded-[35px] text-white w-[15rem] p-3'
							type='button'>
							Finish
						</button>
						<button
							onClick={buildprofile ? handleProfileGoBack : handleGoBack}
							className='bg-white rounded-[35px] text-blueDark border border-blueDark w-[15rem] p-2 my-3'
							type='button'>
							Back
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
