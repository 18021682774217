import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSaveJobs, saveSaveJob, setActiveJob } from '../../../features/job/jobSlice';
import { customToast } from '../../../notifications';
import { deleteSaveJob } from '../../../features/delete/deleteSlice';
import { formatImgPath, formatMoney, getDaysPast, getRemainingTime } from '../../../utils';
import SocialShareModal from '../../../components/SocialShareModal';

export default function ShortListedCard({ item }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { appliedJobs, savedJobs } = useSelector((state) => state.job);

	const [open, setopen] = useState(false);
	const [activeJob, setactiveJob] = useState({});

	let applied = appliedJobs?.filter(
		(x) => Number(x.japJobId) === Number(item?.jobId)
	);

	let saved = savedJobs?.filter(
		(x) =>
			Number(x.josJobId) ===
			Number(item?.jobId || item?.jbrJobId || item?.josJobId || item?.japJobId)
	);

	function handleCancel() {
		setopen(false);
		setactiveJob({});
	}


	async function handleShare(item) {
		await setactiveJob(item);
		await setopen(true);
	}

	async function handleSaveJob() {
		const jobObj = {
			josUsrId: user?.usrId,
			josJobId: item?.jobId || item?.jbrJobId || item?.japJobId,
			josCreatedBy: user?.usrId,
			josDesc: item?.jobInstType,
		};

		const res = await dispatch(saveSaveJob(jobObj));
		if (res?.payload?.success) {
			await customToast({
				content: 'Job saved',
				bdColor: 'success',
				header: 'Success',
			});
			await dispatch(fetchSaveJobs(jobObj));
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	}

	async function handleDeleteSaveJob() {
		const jobObj = {
			josUsrId: user?.usrId,
		};
		const josId = saved[0]?.josId;
		const res = await dispatch(deleteSaveJob(josId));
		if (res?.payload?.success) {
			// await customToast({
			// 	content: 'Job saved',
			// 	bdColor: 'success',
			// 	header: 'Success',
			// });
			await dispatch(fetchSaveJobs(jobObj));
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	}

	async function handleActiveJob() {
		await dispatch(setActiveJob(item));
		await navigate(
			`/job-info/${
				item?.jobId || item?.jbrJobId || item?.josJobId || item?.japJobId
			}/${item?.jobPosition}`.replace(/\s+/g, '-')
		);
	}

	useEffect(() => {}, [appliedJobs]);

	return (
		<>
			<div
				style={{
					border: '1px solid #BAE637',
					borderRadius: '0.9375rem',
				}}
				className='bg-white w-full h-auto p-[1.94rem] flex items-start justify-start mb-[2.5rem]'>
				<div className='w-fit'>
					{item?.jobCntIcon || item?.cntIcon ? (
						<img
							loading='lazy'
							className='w-[3.75rem] h-[3.71288rem]'
							src={formatImgPath(item?.jobCntIcon ?? item?.cntIcon)}
							alt='Job advert icon'
						/>
					) : (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='60'
							height='60'
							viewBox='0 0 60 60'
							fill='none'>
							<mask
								id='mask0_10956_7844'
								style={{ maskType: 'luminance' }}
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='60'
								height='60'>
								<path
									d='M59.6042 0.296875H0.198242V59.7028H59.6042V0.296875Z'
									fill='white'
								/>
							</mask>
							<g mask='url(#mask0_10956_7844)'>
								<mask
									id='mask1_10956_7844'
									style={{ maskType: 'luminance' }}
									maskUnits='userSpaceOnUse'
									x='0'
									y='1'
									width='59'
									height='58'>
									<path
										d='M58.4639 1.02148H0.506836V58.9785H58.4639V1.02148Z'
										fill='white'
									/>
								</mask>
								<g mask='url(#mask1_10956_7844)'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M37.5352 17.9526C35.1524 16.3605 32.3511 15.5107 29.4854 15.5107V1.02148C35.2168 1.02148 40.8195 2.72105 45.585 5.90525C50.3505 9.08946 54.0647 13.6152 56.2581 18.9103C58.4513 24.2054 59.0252 30.0321 57.9071 35.6534C56.789 41.2747 54.029 46.4382 49.9762 50.4908C45.9236 54.5436 40.7601 57.3036 35.1388 58.4217C29.5175 59.5398 23.6908 58.9659 18.3957 56.7726C13.1006 54.5793 8.57481 50.8651 5.3906 46.0996C2.2064 41.3341 0.506836 35.7314 0.506836 30H14.9961C14.9961 32.8657 15.8459 35.667 17.438 38.0498C19.0301 40.4326 21.293 42.2897 23.9406 43.3864C26.5881 44.483 29.5015 44.7699 32.3121 44.2108C35.1227 43.6518 37.7044 42.2718 39.7309 40.2455C41.7572 38.219 43.1372 35.6373 43.6961 32.8267C44.2553 30.0161 43.9684 27.1027 42.8717 24.4552C41.775 21.8076 39.9179 19.5447 37.5352 17.9526Z'
										fill='#007DFC'
									/>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M14.9961 1.02149C14.9961 2.92424 14.6213 4.80837 13.8932 6.56626C13.165 8.3242 12.0978 9.9215 10.7523 11.2669C9.40685 12.6124 7.80954 13.6796 6.05166 14.4078C4.29371 15.1359 2.40958 15.5107 0.506837 15.5107L0.506836 30C4.31234 30 8.08061 29.2505 11.5964 27.7941C15.1123 26.3379 18.3069 24.2033 20.9977 21.5123C23.6887 18.8215 25.8232 15.627 27.2795 12.1111C28.7359 8.59527 29.4854 4.82699 29.4854 1.02148L14.9961 1.02149Z'
										fill='#007DFC'
									/>
								</g>
							</g>
						</svg>
					)}
				</div>

				<div className='ml-[0.63rem] w-full'>
					<div className='flex justify-between items-center'>
						<div className='flex flex-col'>
							<span className='label_label_3'>
								Posted {getDaysPast(item?.jobAdvertStartDate)}
							</span>
							<span className='heading_heading_4 my-[.25rem]'>
								{item?.jobPosition}
							</span>
							<span className='heading_heading_company_name'>
								{item?.jobCntName ?? item?.cntName}
							</span>
						</div>

					</div>

					<div className='flex justify-between items-start mt-[1.25rem]'>
						<div className='flex items-center'>
							<div
								style={{
									borderRadius: '1.25rem',
									background: 'rgba(125, 90, 226, 0.10)',
								}}
								className='h-[2.125rem] w-fit flex justify-center items-center px-[0.75rem]'>
								<span className='label_label_3 !text-[#7D5AE2]'>
									Availability: {item?.jobStartPeriod}
								</span>
							</div>

							<div
								style={{
									borderRadius: '1.25rem',
									background: 'rgba(125, 90, 226, 0.10)',
								}}
								className='h-[2.125rem] w-fit flex justify-center items-center px-[0.75rem] ml-[0.62rem]'>
								<span className='label_label_3 !text-[#7D5AE2]'>
									{item?.jobExperience}
								</span>
							</div>
						</div>

						<button
							style={{
								background:
									'linear-gradient(21deg, #E0F38E 59.71%, #F0FFAD 96.92%)',
							}}
							className='job-btn !text-black21 !flex !flex-row !items-center !justify-center'>
							You’ve been shortlisted
						</button>
					</div>

					<div className='flex items-center mt-[0.75rem]'>
						<div className='flex items-center mr-[0.94rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='22'
								height='22'
								viewBox='0 0 22 22'
								fill='none'>
								<path
									d='M11.0003 2.75C6.444 2.75 2.75021 6.41667 3.66702 11C4.27686 14.0487 7.32012 16.8947 9.28965 18.4587C9.93337 18.9698 10.2552 19.2254 10.755 19.2929C10.8935 19.3116 11.1071 19.3116 11.2457 19.2929C11.7454 19.2254 12.0673 18.9698 12.711 18.4587C14.6806 16.8947 17.724 14.0488 18.3336 11C19.2502 6.41667 15.5567 2.75 11.0003 2.75Z'
									stroke='url(#paint0_linear_10982_5579)'
									strokeWidth='1.375'
									strokeLinejoin='round'
								/>
								<path
									d='M13.75 10.2207C13.75 11.7395 12.5188 12.9707 11 12.9707C9.48122 12.9707 8.25 11.7395 8.25 10.2207C8.25 8.70192 9.48122 7.4707 11 7.4707C12.5188 7.4707 13.75 8.70192 13.75 10.2207Z'
									stroke='url(#paint1_linear_10982_5579)'
									strokeWidth='1.375'
									strokeLinejoin='round'
								/>
								<defs>
									<linearGradient
										id='paint0_linear_10982_5579'
										x1='11.0003'
										y1='2.75'
										x2='11.0003'
										y2='19.7083'
										gradientUnits='userSpaceOnUse'>
										<stop stopColor='#4276FC' />
										<stop offset='0.0001' stopColor='#3D73FF' />
										<stop offset='1' stopColor='#1844B5' />
									</linearGradient>
									<linearGradient
										id='paint1_linear_10982_5579'
										x1='11.0001'
										y1='2.74987'
										x2='11.0001'
										y2='19.7082'
										gradientUnits='userSpaceOnUse'>
										<stop stopColor='#4276FC' />
										<stop offset='0.0001' stopColor='#3D73FF' />
										<stop offset='1' stopColor='#1844B5' />
									</linearGradient>
								</defs>
							</svg>
							<span className='heading_heading_job_details ml-[0.5rem]'>
								{item?.jobCity}, {item?.jobCountry}
							</span>
						</div>

						<div className='flex items-center mr-[0.94rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='22'
								height='22'
								viewBox='0 0 22 22'
								fill='none'>
								<path
									d='M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z'
									stroke='url(#paint0_linear_10982_5587)'
									strokeWidth='1.375'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M12.2161 10.3581H15.125C15.5047 10.3581 15.8125 10.6659 15.8125 11.0456C15.8125 11.4253 15.5047 11.7331 15.125 11.7331H12.1637C11.9202 12.119 11.4901 12.3754 11 12.3754C10.2406 12.3754 9.625 11.7598 9.625 11.0004C9.625 10.4915 9.90151 10.0471 10.3125 9.80932V6.00391C10.3125 5.62421 10.6203 5.31641 11 5.31641C11.3797 5.31641 11.6875 5.62421 11.6875 6.00391V9.80932C11.9111 9.93869 12.095 10.1293 12.2161 10.3581Z'
									fill='url(#paint1_linear_10982_5587)'
								/>
								<defs>
									<linearGradient
										id='paint0_linear_10982_5587'
										x1='11'
										y1='2.75'
										x2='11'
										y2='19.25'
										gradientUnits='userSpaceOnUse'>
										<stop stopColor='#4276FC' />
										<stop offset='0.0001' stopColor='#3D73FF' />
										<stop offset='1' stopColor='#1844B5' />
									</linearGradient>
									<linearGradient
										id='paint1_linear_10982_5587'
										x1='12.7188'
										y1='12.3754'
										x2='12.7188'
										y2='5.31641'
										gradientUnits='userSpaceOnUse'>
										<stop stopColor='#4276FC' />
										<stop offset='0.0001' stopColor='#3D73FF' />
										<stop offset='1' stopColor='#1844B5' />
									</linearGradient>
								</defs>
							</svg>
							<span className='heading_heading_job_details ml-[0.5rem]'>
								{item?.jobEmploymentTerms}
							</span>
						</div>

						<div className='flex items-center mr-[0.94rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='22'
								height='22'
								viewBox='0 0 22 22'
								fill='none'>
								<path
									d='M2.75 11.9167V12.65C2.75 15.3998 2.75 16.7747 3.45027 17.7385C3.67643 18.0498 3.95017 18.3236 4.26145 18.5497C5.22529 19.25 6.6002 19.25 9.35 19.25H12.65C15.3998 19.25 16.7747 19.25 17.7385 18.5497C18.0498 18.3236 18.3236 18.0498 18.5497 17.7385C19.25 16.7747 19.25 15.3998 19.25 12.65V11.9167M2.75 11.9167C2.75 10.2082 2.75 9.35399 3.02911 8.68016C3.40125 7.78172 4.11506 7.06792 5.01349 6.69578C5.68732 6.41667 6.54155 6.41667 8.25 6.41667H13.75C15.4585 6.41667 16.3127 6.41667 16.9865 6.69578C17.8849 7.06792 18.5987 7.78172 18.9709 8.68016C19.25 9.35399 19.25 10.2082 19.25 11.9167M2.75 11.9167H19.25M9.16667 9.16667H12.8333M7.33333 6.41667C7.33333 4.39162 8.97496 2.75 11 2.75C13.025 2.75 14.6667 4.39162 14.6667 6.41667'
									stroke='url(#paint0_linear_10982_5595)'
									strokeWidth='1.375'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<defs>
									<linearGradient
										id='paint0_linear_10982_5595'
										x1='11'
										y1='2.75'
										x2='11'
										y2='19.25'
										gradientUnits='userSpaceOnUse'>
										<stop stopColor='#4276FC' />
										<stop offset='0.0001' stopColor='#3D73FF' />
										<stop offset='1' stopColor='#1844B5' />
									</linearGradient>
								</defs>
							</svg>
							<span className='heading_heading_job_details ml-[0.5rem]'>
								{formatMoney(item?.jobGrossPay)}
							</span>
						</div>

						<div className='flex items-center mr-[0.94rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='22'
								height='22'
								viewBox='0 0 22 22'
								fill='none'>
								<path
									d='M17.875 3.4375H4.125C3.7453 3.4375 3.4375 3.7453 3.4375 4.125V17.875C3.4375 18.2547 3.7453 18.5625 4.125 18.5625H17.875C18.2547 18.5625 18.5625 18.2547 18.5625 17.875V4.125C18.5625 3.7453 18.2547 3.4375 17.875 3.4375Z'
									stroke='url(#paint0_linear_10982_5601)'
									strokeWidth='1.375'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M15.125 2.0625V4.8125'
									stroke='url(#paint1_linear_10982_5601)'
									strokeWidth='1.375'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M6.875 2.0625V4.8125'
									stroke='url(#paint2_linear_10982_5601)'
									strokeWidth='1.375'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M3.4375 7.5625H18.5625'
									stroke='url(#paint3_linear_10982_5601)'
									strokeWidth='1.375'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<defs>
									<linearGradient
										id='paint0_linear_10982_5601'
										x1='11'
										y1='3.4375'
										x2='11'
										y2='18.5625'
										gradientUnits='userSpaceOnUse'>
										<stop stopColor='#4276FC' />
										<stop offset='0.0001' stopColor='#3D73FF' />
										<stop offset='1' stopColor='#1844B5' />
									</linearGradient>
									<linearGradient
										id='paint1_linear_10982_5601'
										x1='15.625'
										y1='2.0625'
										x2='15.625'
										y2='4.8125'
										gradientUnits='userSpaceOnUse'>
										<stop stopColor='#4276FC' />
										<stop offset='0.0001' stopColor='#3D73FF' />
										<stop offset='1' stopColor='#1844B5' />
									</linearGradient>
									<linearGradient
										id='paint2_linear_10982_5601'
										x1='7.375'
										y1='2.0625'
										x2='7.375'
										y2='4.8125'
										gradientUnits='userSpaceOnUse'>
										<stop stopColor='#4276FC' />
										<stop offset='0.0001' stopColor='#3D73FF' />
										<stop offset='1' stopColor='#1844B5' />
									</linearGradient>
									<linearGradient
										id='paint3_linear_10982_5601'
										x1='11'
										y1='7.5625'
										x2='11'
										y2='8.5625'
										gradientUnits='userSpaceOnUse'>
										<stop stopColor='#4276FC' />
										<stop offset='0.0001' stopColor='#3D73FF' />
										<stop offset='1' stopColor='#1844B5' />
									</linearGradient>
								</defs>
							</svg>
							<span className='heading_heading_job_details ml-[0.5rem]'>
							{getRemainingTime(item?.jobAdvertEndDate, applied)}
							</span>
						</div>
					</div>
				</div>
			</div>
			<SocialShareModal
				activeJob={activeJob}
				open={open}
				handleCancel={handleCancel}
			/>
		</>
	);
}
