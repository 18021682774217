import { Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobs } from '../../features/job/jobSlice';
import { fetchWorkTypeCategorys } from '../../features/work/workSLice';

export default function JobFilter({ handleAddFilter, handleRemoveFilter }) {
	const dispatch = useDispatch();

	const { workTypeData } = useSelector((state) => state.work);

	const [location, setlocation] = useState('');
	const [category, setcategory] = useState('');
	const [expLevel, setexpLevel] = useState('');
	const [type, settype] = useState('');
	const [style, setstyle] = useState('');

	const filterObj = {
		jobRecommendation: '',
		jobCountry: '',
		category: category,
		jobExperience: expLevel,
		jobEmploymentTerms: type,
		jobWorkStyle: style,
		jobName: '',
		jobOccupation: '',
		internationalJobs: location === 'INTERNATIONAL' ? 'INTERNATIONAL' : '',
		localJobs: location === 'KENYA' ? 'KENYA' : '',
		jobStatus: '',
		limit: 10,
		start: 0,
	};

	async function handleFetchJobs() {
		await dispatch(fetchJobs(filterObj));
	}

	async function handleFetchFilters() {
		await dispatch(fetchWorkTypeCategorys());
	}

	const onStyleChange = (val) => {
		setstyle(val);
	};

	const ontypeChange = (val) => {
		settype(val);
	};

	const onexpLevelChange = (val) => {
		setexpLevel(val);
	};

	const onCategoryChange = async (val) => {
		await setcategory(val);
	};

	const onLocationChange = (val) => {
		setlocation(val);
	};

	useEffect(() => {
		handleFetchJobs();
	}, [location, category, expLevel, type, style]);

	useEffect(() => {}, [workTypeData]);

	useEffect(() => {
		handleFetchFilters();
	}, []);

	return (
		<>
			<div
				className='ml-[1.5rem] p-[.75rem] 2xl:p-[2.12rem] w-[15rem] 2xl:w-[22.875rem] cv-scroll overflow-y-scroll'
				style={{
					height: '100%',
					borderRadius: '0.9375rem',
					opacity: '0.9819',
					background: '#F0F4FF',
				}}>
				<div className='flex flex-col'>
					<span className='heading_heading_job_filter'>Location</span>
					<button
						onClick={() => {
							onLocationChange('');
							let x = ['Local jobs', 'Foreign jobs'];
							x.forEach((item) => handleRemoveFilter(item));
						}}
						className={`${
							location === '' ? 'job-filter-btn-active' : 'job-filter-btn'
						} mt-[1.29rem]`}>
						Any
					</button>
					<button
						onClick={() => {
							onLocationChange('KENYA');
							handleAddFilter('Local jobs');
						}}
						className={`${
							location === 'KENYA' ? 'job-filter-btn-active' : 'job-filter-btn'
						} mt-[1.27rem]`}>
						Local jobs
					</button>
					<button
						onClick={() => {
							onLocationChange('INTERNATIONAL');
							handleAddFilter('Foreign jobs');
						}}
						className={`${
							location === 'INTERNATIONAL'
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Foreign jobs
					</button>
				</div>

				<div className='flex flex-col mt-[1.5rem]'>
					<span className='heading_heading_job_filter'>Categories</span>
					<button
						onClick={() => {
							onCategoryChange('');
							workTypeData.forEach((item) =>
								handleRemoveFilter(item?.wktLookupName)
							);
						}}
						className={`${
							category === '' ? 'job-filter-btn-active' : 'job-filter-btn'
						} mt-[1.29rem]`}>
						Any
					</button>
					{workTypeData?.map((item, index) => {
						return (
							
								<button
									key={index}
									onClick={() => {
										onCategoryChange(item?.wktId);
										handleAddFilter(item?.wktLookupName);
									}}
									className={`${
										String(category) === String(item?.wktId)
											? 'job-filter-btn-active'
											: 'job-filter-btn'
									} mt-[1.27rem]`}>
									{item?.wktLookupName}
								</button>
						
						);
					})}
				</div>

				<div className='flex flex-col mt-[1.5rem]'>
					<span className='heading_heading_job_filter'>Experience level</span>
					<button
						onClick={() => {
							onexpLevelChange('');
							let x = [
								'Student/Recent graduate',
								'Junior Level',
								'Senior Level',
								'Middle Management',
								'Executive Management',
							];
							x.forEach((item) => handleRemoveFilter(item));
						}}
						className={`${
							expLevel === '' ? 'job-filter-btn-active' : 'job-filter-btn'
						} mt-[1.29rem]`}>
						Any
					</button>
					<button
						onClick={() => {
							onexpLevelChange('Student/Recent graduate')
							handleAddFilter('Student/Recent graduate');
						}}
						className={`${
							String(expLevel) === String('Student/Recent graduate')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Student/Recent graduate
					</button>
					<button
						onClick={() => {
							onexpLevelChange('Junior Level')
							handleAddFilter('Junior Level')
						}}
						className={`${
							String(expLevel) === String('Junior Level')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Junior Level
					</button>
					<button
						onClick={() => {
							onexpLevelChange('Senior Level')
							handleAddFilter('Senior Level')
						}}
						className={`${
							String(expLevel) === String('Senior Level')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Senior Level
					</button>
					<button
						onClick={() => {
							onexpLevelChange('Middle Management')
							handleAddFilter('Middle Management')
						}}
						className={`${
							String(expLevel) === String('Middle Management')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Middle Management
					</button>
					<button
						onClick={() => {
							onexpLevelChange('Executive Management')
							handleAddFilter('Executive Management')
						}}
						className={`${
							String(expLevel) === String('Executive Management')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Executive Management
					</button>
				</div>

				<div className='flex flex-col mt-[1.5rem]'>
					<span className='heading_heading_job_filter'>Job Type</span>
					<button
						onClick={() => {
							ontypeChange('')
							let x = [
								'Internship',
								'Casual',
								'Full time',
								'Contract',
								'Volunteer',
							];
							x.forEach((item) => handleRemoveFilter(item));
						}}
						className={`${
							type === '' ? 'job-filter-btn-active' : 'job-filter-btn'
						} mt-[1.29rem]`}>
						Any
					</button>
					<button
						onClick={() => {
							ontypeChange('Internship')
							handleAddFilter('Internship')
						}}
						className={`${
							String(type) === String('Internship')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Internship
					</button>
					<button
						onClick={() => {
							ontypeChange('Casual')
							handleAddFilter('Casual')
						}}
						className={`${
							String(type) === String('Casual')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Casual
					</button>
					<button
						onClick={() => {
							ontypeChange('Full time')
							handleAddFilter('Full time')
						}}
						className={`${
							String(type) === String('Full time')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Full time
					</button>
					<button
						onClick={() => {
							handleAddFilter('Contract')
							ontypeChange('Contract')
						}}
						className={`${
							String(type) === String('Contract')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Contract
					</button>
					<button
						onClick={() => {
							ontypeChange('Volunteer')
							handleAddFilter('Volunteer')
						}}
						className={`${
							String(type) === String('Volunteer')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Volunteer
					</button>
				</div>

				<div className='flex flex-col mt-[1.5rem] pb-[50px]'>
					<span className='heading_heading_job_filter'>Work Style</span>
					<button
						onClick={() => {
							onStyleChange('')
							let x = [
								'On-site',
								'Hybrid',
								'Remote',
							];
							x.forEach((item) => handleRemoveFilter(item));
						}}
						className={`${
							style === '' ? 'job-filter-btn-active' : 'job-filter-btn'
						} mt-[1.29rem]`}>
						Any
					</button>
					<button
						onClick={() => {
							onStyleChange('On-site')
							handleAddFilter('On-site')
						}}
						className={`${
							String(style) === String('On-site')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						On-site
					</button>
					<button
						onClick={() => {
							onStyleChange('Hybrid')
							handleAddFilter('Hybrid')
						}}
						className={`${
							String(style) === String('Hybrid')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Hybrid
					</button>
					<button
						onClick={() => {
							onStyleChange('Remote')
							handleAddFilter('Remote')
						}}
						className={`${
							String(style) === String('Remote')
								? 'job-filter-btn-active'
								: 'job-filter-btn'
						} mt-[1.27rem]`}>
						Remote
					</button>
				</div>
			</div>
		</>
	);
}
