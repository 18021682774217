import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatImgPath } from '../../../utils';
import checked from '../../../assets/checked.png';
import ProfileInfoModal from '../../profile/modals/ProfileInfoModal';
import ProfileHeaderModal from '../../profile/modals/ProfileHeaderModal';

export default function UserDesciption({ profileApplication }) {
	const { user } = useSelector((state) => state.auth);

	const [open, setopen] = useState(false);

	function handleCancel() {
		setopen(false);
	}

	useEffect(() => {}, [user]);

	return (
		<>
			<div className='py-[1.2rem] px-[2.38rem]  bg-white pb-14 rounded-[0.5rem] flex justify-between items-start'>
				<div className='flex flex-col xl:flex-row w-full xl:items-center gap-[1.57rem]'>
					<div className='flex'>
						<img
							loading='lazy'
							src={
								user?.usrProfileImage
									? formatImgPath(user?.usrProfileImage)
									: 'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png'
							}
							alt='profile'
							className='w-[9.6305rem] h-[9.704rem] rounded-full object-cover'
						/>
					</div>

					<div className='flex flex-col'>
						<div className='flex items-center'>
							<span className='prof-card-name w-full truncate'>
								{user?.usrFirstName} {user?.usrLastName}
							</span>

							<img
								loading='lazy'
								className='w-11 object-contain ml-1'
								src={checked}
								alt='Verified'
							/>
						</div>
						<span className='prof-reg'>REG: {user?.usrCode}</span>
						<span className='prof-job  mt-5'>{user?.usrOtherTitle}</span>
						<span className='prof-loc mt-1'>
							{user?.usrCounty ? user?.usrCounty + ', ' : ''}Kenya
						</span>
					</div>
				</div>

				{profileApplication ? null : (
					<button onClick={() => setopen(true)}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='32'
							height='32'
							viewBox='0 0 32 32'
							fill='none'>
							<path
								d='M11.5863 27.0002H6C5.73478 27.0002 5.48043 26.8948 5.29289 26.7073C5.10536 26.5197 5 26.2654 5 26.0002V20.4139C5.00012 20.149 5.10532 19.8951 5.2925 19.7077L20.7075 4.29266C20.895 4.10527 21.1493 4 21.4144 4C21.6795 4 21.9337 4.10527 22.1213 4.29266L27.7075 9.87516C27.8949 10.0627 28.0002 10.3169 28.0002 10.582C28.0002 10.8471 27.8949 11.1014 27.7075 11.2889L12.2925 26.7077C12.1051 26.8948 11.8511 27 11.5863 27.0002Z'
								stroke='#212121'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
							<path
								d='M17 8L24 15'
								stroke='#212121'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</button>
				)}
			</div>

			<ProfileHeaderModal open={open} handleCancel={handleCancel} />
		</>
	);
}
