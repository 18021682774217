import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Form, Select } from 'antd';

import {
	deleteSkill,
	fetchSkills,
	saveSkill,
	setSkillData,
} from '../../../features/skill/skillSlice';
import { customToast } from '../../../notifications';

const url = process.env.REACT_APP_API_BASE_URL;

let timeout;
let currentValue;
const fetchFunc = (value, callback) => {
	if (timeout) {
		clearTimeout(timeout);
		timeout = null;
	}
	currentValue = value;

	const fake = () => {
		fetch(
			`${url}/nea/fetchGlobalSkills.action?gsklName=${
				value?.length > 0 ? value : ' '
			}`
		)
			.then((response) => response.json())
			.then((d) => {
				if (currentValue === value) {
					const data = d?.data?.result
						?.sort((a, b) => a?.gsklName?.localeCompare(b?.gsklName))
						?.map((item) => ({
							value: item?.gsklId,
							text: item?.gsklName,
						}));
					callback(data);
				}
			});
	};
	if (value) {
		timeout = setTimeout(fake, 300);
	}

	// else {
	// 	callback([]);
	// }
};

export default function CreateSkills({ handleCurrent, form }) {
	const dispatch = useDispatch();
	const [formEdu] = Form.useForm();
	const formRef = useRef(null);

	const { cvData } = useSelector((state) => state.cv);
	const { user } = useSelector((state) => state.auth);
	const { mySkills, skillLoading } = useSelector((state) => state.skill);

	const [showSearch, setshowSearch] = useState(true);

	const [skillData, setskillData] = useState([]);
	const [value, setValue] = useState();
	const [skillsList, setskillsList] = useState(mySkills);

	const usrObj = {
		usrId: user?.usrId,
		sklCvlId: cvData?.cvId,
	};

	const handleSearch = (newValue) => {
		fetchFunc(newValue, setskillData);
	};

	const handleSkillChange = async (newValue) => {
		await setValue(newValue);
		let newArr = await [...newValue];
		let val = await newArr?.pop();
		const skillObj = {
			sklUsrId: user?.usrId,
			sklGsklId: val,
			sklType: 'PERSONAL',
		};
		await console.log(val);

		const res = await dispatch(saveSkill(skillObj));
		if (res?.payload?.success) {
			await handleFetchSkills();
		}else{
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	};

	async function handleFetchSkills() {
		const resp = await dispatch(fetchSkills(usrObj));

		if (resp?.payload?.success) {
			await setskillsList(resp?.payload?.data?.result);
			await dispatch(setSkillData(resp?.payload?.data?.result));
		}
	}

	async function handleDeleteSkill(id) {
		await dispatch(deleteSkill(id));
		await handleFetchSkills();
	}

	useEffect(() => {
		handleFetchSkills();
	}, []);

	return (
		<>
			<div
				className='py-[2.88rem] px-[2.5rem] mt-[1.25rem]'
				style={{
					borderRadius: '0.875rem',
					border: '1px solid var(--Border-secondary, #E0E0E0)',
					background: '#FFF',
				}}>
				<h3 className='heading_heading_4'>Personal Skills</h3>
				<p className='paragraph_paragraph_2 mt-[1rem] !text-black333'>
					Select upto 8 skills
				</p>
				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					style={{
						maxWidth: '100%',
						marginTop: 20,
					}}
					form={formEdu}>
					<div
						style={{
							borderRadius: '40px',
							border: '1px solid #BCBCBC',
							background: '#FFF',
						}}
						className='px-3 py-1 bg-transparent flex items-center justify-between max-w-full min-w-[30%] mb-10'>
						<div className='flex items-center w-full'>
							{showSearch ? (
								<MaterialIcon color='#333' size={25} icon='search' />
							) : null}

							<Select
								maxTagCount={1}
								maxCount={8}
								loading={skillLoading}
								value={value}
								showSearch
								id='search-select'
								mode='multiple'
								className='!placeholder-[#333] max-h-auto h-[44px] w-full focus:border-none focus:outline-none focus:ring-0 active:ring-0 search-select'
								placeholder={'Search Skills'}
								optionFilterProp='label'
								onChange={handleSkillChange}
								onSearch={handleSearch}
								onFocus={() => handleSearch(' ')}
								options={(skillData || []).map((d) => ({
									value: d.value,
									label: d.text,
								}))}
							/>
						</div>
					</div>
				</Form>

				<div className='flex items-center flex-wrap mt-2'>
					{skillsList?.length ? (
						skillsList?.map((item, index) => {
							return (
							
									<div key={index} className='bg-lightPurple rounded-[63px] text-center px-4 py-1 mr-2 flex items-center justify-between mb-3'>
										<span className='prof-card-badge'>{item?.skillName}</span>
										<button
											onClick={() => handleDeleteSkill(item?.sklId)}
											type='button'
											className='ml-3'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='19'
												height='19'
												viewBox='0 0 19 19'
												fill='none'>
												<path
													d='M14.25 4.75L4.75 14.25'
													stroke='#262168'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
												<path
													d='M4.75 4.75L14.25 14.25'
													stroke='#262168'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
											</svg>
										</button>
									</div>
								
							);
						})
					) : (
						<div className='flex mt-10 mb-4 w-full justify-center items-center'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<g clipPath='url(#clip0_3177_18210)'>
									<path
										d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM16.23 18L12 15.45L7.77 18L8.89 13.19L5.16 9.96L10.08 9.54L12 5L13.92 9.53L18.84 9.95L15.11 13.18L16.23 18Z'
										fill='black'
									/>
								</g>
								<defs>
									<clipPath id='clip0_3177_18210'>
										<rect width='24' height='24' fill='white' />
									</clipPath>
								</defs>
							</svg>
							<span className='ml-2 text-lg font-semibold'>
								Add up to 5 skills
							</span>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
