import { Checkbox, Radio } from 'antd';

export default function RegistrationTypeCard({
	item,
	handleSetActiveItem,
	active,
}) {
	return (
		<>
			<button
				onClick={() => handleSetActiveItem(item.key)}
				key={item.key}
				className={`${
					active === item.key ? 'reg_card_active' : 'reg_card'
				} flex flex-col items-start w-auto lg:w-[23.5rem] flex-shrink-0 flex-grow lg:flex-grow-0 py-[1.81rem] pl-[2.31rem] pr-[1.5rem]`}>
				<div className=' w-full flex justify-between items-start'>
					{item.icon}

					{active !== item.key ? (
						<svg
							className='mr-[1.5rem]'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<circle
								cx='12'
								cy='12'
								r='11'
								stroke='#1B47B4'
								strokeWidth='2'
							/>
						</svg>
					) : (
						<svg
							className='mr-[1.5rem]'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'>
							<circle cx='12' cy='12' r='12' fill='#1B47B4' />
							<circle cx='12' cy='12' r='6' fill='white' />
						</svg>
					)}
				</div>
				<span className='heading_heading_4 mt-[1.25rem] !text-left w-full'>
					{item.title}
				</span>

				<div className='mt-[.94rem] pb-[1.31rem]'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='32'
						height='32'
						viewBox='0 0 32 32'
						fill='none'>
						<path
							d='M5 16H27'
							stroke='#212121'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M18 7L27 16L18 25'
							stroke='#212121'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</div>
			</button>
		</>
	);
}
