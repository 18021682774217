import { useNavigate } from 'react-router-dom';

export default function LandingPartFour() {
	const navigate = useNavigate();

	return (
		<>
			<div className='w-full pl-0 lg:pl-[1rem] pr-0 lg:pr-[2rem] flex mt-[2.12rem]'>
				<div className='bg-[#C9DAFF] pl-[.75rem] lg:pl-[5.62rem] pr-[.75rem] lg:pr-[3.06rem] rounded-none lg:rounded-[0.9375rem] w-full flex flex-col lg:flex-row'>
					<div className='flex flex-col w-full'>
						<span className='mt-[3.83rem] label_label_hire !text-[#322D60]'>
							For Private Employment Agents
						</span>
						<span className='mt-[.36rem] heading_heading_hire !text-[#282828]'>
							Are you a verified <br className='hidden lg:block' />
							employment agent?
						</span>
						<span className='mt-[1.35rem] paragraph_paragraph_1 !text-[#000]'>
							We ensure that only verified employment agents can interact with{' '}
							<br className='hidden lg:block' />
							candidates on this platform. Get your licensed to begin{' '}
							<br className='hidden lg:block' />
							managing workers.
						</span>

						<div className='flex flex-col xl:flex-row items-center w-fit mt-[2.32rem] gap-[1rem]'>
							<button
								onClick={() => navigate('/registration-agency')}
								className='get-licensed-btn'>
								Get licensed
							</button>
							<button
								onClick={() => navigate('/home/agencies')}
								className='verify-license-btn'>
								Verify employment agency
							</button>
						</div>
					</div>
					<div
						style={{
							background:
								'linear-gradient(336deg, #5398C2 -50.98%, #171D20 11.82%)',
						}}
						className='w-full lg:w-[39.875rem] mb-[6.62rem] mt-[3.83rem] px-3 xl:px-[4.25rem] rounded-[0.9375rem] flex flex-col'>
						<span className='mt-5 xl:mt-[5.88rem] label_label_hire_license'>
							Get <span className='text-[#B8FF97]'>licensed</span> to{' '}
							<br className='hidden lg:block' />
							manage local and <br className='hidden lg:block' />
							migrant workers.
						</span>

						<div className='w-full flex justify-end'>
							<div className='flex flex-col mt-[8.12rem]'>
								<div className='w-full flex justify-end'>
									<div
										className='flex justify-center items-center'
										style={{
											width: '3.375rem',
											height: '3.375rem',
											flexShrink: 0,
											borderRadius: '3.125rem',
											border: '2px solid #1B47B4',
											background: '#13141B',
										}}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='30'
											height='30'
											viewBox='0 0 30 30'
											fill='none'>
											<path
												d='M26.5162 9.27742L12.0162 23.7774C11.932 23.8617 11.8321 23.9285 11.7221 23.9741C11.612 24.0197 11.4941 24.0432 11.375 24.0432C11.2559 24.0432 11.138 24.0197 11.028 23.9741C10.918 23.9285 10.818 23.8617 10.7338 23.7774L4.39009 17.4337C4.22004 17.2636 4.12451 17.033 4.12451 16.7925C4.12451 16.552 4.22004 16.3214 4.39009 16.1513C4.56014 15.9813 4.79078 15.8857 5.03127 15.8857C5.27175 15.8857 5.50239 15.9813 5.67244 16.1513L11.375 21.855L25.2338 7.99507C25.4039 7.82502 25.6345 7.72949 25.875 7.72949C26.1155 7.72949 26.3461 7.82502 26.5162 7.99507C26.6862 8.16512 26.7818 8.39576 26.7818 8.63625C26.7818 8.87673 26.6862 9.10737 26.5162 9.27742Z'
												fill='#B8FF97'
											/>
										</svg>
									</div>
								</div>
								<span className='mt-[0.75rem] mb-[2.5rem] label_label_hire_verification'>
									Verification is your reputation.
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
