import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppliedJobs } from '../../../features/job/jobSlice';
import EmptyApplicationState from '../EmptyApplicationState';
import DeclinedCard from '../job_cards/DeclinedCard';

export default function DeclinedApplications() {
	const dispatch = useDispatch();

	const { declined } = useSelector((state) => state.job);
	const { user } = useSelector((state) => state.auth);

	const [jobList, setjobList] = useState(declined);

	const usrObj = {
		japUsrId: user?.usrId,
		japProgressStatus: '',
		position: '',
	};

	async function handleFetchJobs() {
		await dispatch(fetchAppliedJobs(usrObj));
	}

	useEffect(() => {}, [jobList]);

	useEffect(() => {
		setjobList(declined);
	}, [declined]);

	useEffect(() => {
		handleFetchJobs();
	}, []);

	return (
		<>
			<div className='mt-[1.2rem]'>
				<span className='heading_heading_5'>
					{jobList?.length
						? `Not to worry ${user?.usrFirstName}, other opportunities await.`
						: 'Declined applications'}
				</span>
			</div>

			<div className='mt-[1rem] mb-[5rem]'>
				{jobList?.length ? (
					jobList?.map((item, index) => {
						return <DeclinedCard key={index} item={item} />;
					})
				) : (
					<EmptyApplicationState info='Declined applications will show up here' />
				)}
			</div>
		</>
	);
}
