import { useNavigate } from 'react-router-dom';
import home10 from '../../../../assets/landing/home10.png';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { LongNumberFormatter } from '../../../../utils';

export default function LandingPartThree() {
	const navigate = useNavigate();

	const { homePageCountObj } = useSelector((state) => state.global);

	useEffect(() => {}, [homePageCountObj]);

	const cardInfo = [
		{
			key: 1,
			title: (
				<span className='label_label_hire_one'>
					{LongNumberFormatter(homePageCountObj?.employersCount)}+
				</span>
			),
			text: <span className='label_label_hire_two'>Total Employers</span>,
		},
		{
			key: 2,
			title: (
				<span className='label_label_hire_one'>
					{LongNumberFormatter(homePageCountObj?.employerJobsCount)}+
				</span>
			),
			text: <span className='label_label_hire_two'>Total Jobs Offer</span>,
		},
		{
			key: 3,
			title: (
				<span className='label_label_hire_one'>
					{LongNumberFormatter(homePageCountObj?.jobSeekersCount)}+
				</span>
			),
			text: <span className='label_label_hire_two'>Candidates</span>,
		},
	];

	return (
		<>
			<div className='w-full pl-[.5rem] lg:pl-[1rem] pr-[.5rem] lg:pr-[2rem] flex'>
				<div className='bg-[#1A1C28] pl-[.75rem] lg:pl-[5.62rem] rounded-[0.9375rem] -mt-[3.81rem] w-full flex'>
					<div className='flex flex-col w-full'>
						<span className='mt-[3.83rem] label_label_hire'>For Employers</span>
						<span className='mt-[.36rem] heading_heading_hire'>
							Hire your next <br />
							<span className='!text-[#B5CEFF]'>candidate.</span>
						</span>
						<span className='mt-[1.35rem] paragraph_paragraph_1 !text-white'>
							Search and connect with top candidates faster. Get{' '}
							<br className='hidden lg:block' />
							access to a wide pool of credible talent.
						</span>

						<button
							onClick={() => navigate('/registration-employer')}
							className='mt-[2.32rem] mb-[1rem] lg:mb-[2.56rem] hire-talent-btn'>
							Create account
						</button>

						<div className='pt-[2.56rem] pb-[2.46rem] hidden lg:flex items-center gap-[3.81rem] w-[32.12838rem] max-w-full mt-[2.96rem] mb-[.5rem] hire-bg'>
							{cardInfo.map((item, index) => {
								return (
									<div key={index} className='w-full'>
										<div
											className='flex flex-col justify-center items-center'
											key={item?.key}
											type='button'>
											<div className='flex flex-col items-center justify-center'>
												<div className=''>{item?.title}</div>
												<div className='mt-[.65rem]'>{item?.text}</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>

						<div className='pt-[1.56rem] pb-[2.46rem] flex lg:hidden items-center gap-[3.81rem] w-[32.12838rem] max-w-full mt-[2.96rem] mb-[.5rem] hire-bg'>
							{cardInfo.slice(0, 2).map((item, index) => {
								return (
									<div key={index} className='w-full'>
										<div
											className='flex flex-col justify-center items-center'
											key={item?.key}
											type='button'>
											<div className='flex flex-col items-center justify-center'>
												<div className=''>{item?.title}</div>
												<div className='mt-[.65rem]'>{item?.text}</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className='w-[90rem] hidden lg:flex'>
						<img src={home10} alt='Employer' className='employer-img' />
					</div>
				</div>
			</div>
		</>
	);
}
