export default function InAppNotificationBar({ message, color }) {
	return (
		<>
			<div
				style={{
					border: `1px solid ${color}`,
					borderLeft: `0.80038rem solid ${color}`,
				}}
				className='hidden xl:flex in_app_bar pr-3 xl:pr-[3.81rem] xl:w-[42rem]'>
				<svg
					className="hidden xl:flex xl:flex-shrink-0"
					xmlns='http://www.w3.org/2000/svg'
					width='28'
					height='28'
					viewBox='0 0 28 28'
					fill='none'>
					<path
						d='M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z'
						stroke='#212121'
						strokeWidth='2'
						strokeMiterlimit='10'
					/>
					<path
						d='M14 14.875V8.75'
						stroke='#212121'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					<path
						d='M14 20.125C14.7249 20.125 15.3125 19.5374 15.3125 18.8125C15.3125 18.0876 14.7249 17.5 14 17.5C13.2751 17.5 12.6875 18.0876 12.6875 18.8125C12.6875 19.5374 13.2751 20.125 14 20.125Z'
						fill='#212121'
					/>
				</svg>

				<span className='flex heading_heading_5 !text-[#212121]'>
					{message}
				</span>
			</div>
		</>
	);
}
