import { Splide, SplideSlide } from '@splidejs/react-splide';
import home5 from '../../../../assets/landing/home5.svg';
import home7 from '../../../../assets/landing/home7.png';
import home8 from '../../../../assets/landing/home8.png';
import { useNavigate } from 'react-router-dom';

export default function LandingPage() {
	const navigate = useNavigate();

	const cardInfo = [
		{
			key: 2,
			title: (
				<span className='label_label_landing_card'>
					Apply for Jobs{' '}
				</span>
			),
			icon: (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='80'
					height='87'
					viewBox='0 0 80 87'
					fill='none'>
					<path
						d='M71.1282 20.9689L80 13.4407L77.4926 13.4193L73.8925 13.9483L72.2842 13.1226L71.1282 11.2655L69.7515 10.6418L65.283 11.169L64.2753 11.7642L62.7991 11.6695L60.7965 12.3755L59.8539 13.489L57.37 16.9653L55.4614 17.1119H54.0901L52.3661 16.5524L49.8171 16.8974L47.7438 16.0734H44.3608L42.8177 15.623L41.298 14.0073L38.901 12.606L34.9264 9.16899L33.1499 8.19133L28.4969 8.43976L26.1016 7.89463L23.9416 8.55237L22.1252 8.2378L20.4717 6.58988V1.45667L19.0118 1.24577L17.0851 1.55319L16.1154 0L12.1987 3.90889L9.83062 7.11714L8.90979 6.80078L7.13869 8.32538L6.19615 9.44424L5.47975 9.71413L4.37439 11.0064V11.035L4.47027 10.9581L5.3911 11.9644L5.98268 14.1664L6.93426 14.6775L6.74793 16.1503L8.03781 16.3809L8.68004 18.2593V19.9931L10.869 21.8608L12.063 24.4757L13.3023 24.9743L14.4583 25.7L13.6333 26.767L15.0083 27.8394L15.6306 29.6375L15.3809 31.4391L13.1684 33.7269V34.9637L12.3453 37.0048H9.02739L9.14498 37.7609L8.10656 38.642L7.36844 40.2113L5.8271 41.5071L4.74164 43.4928L2.89817 45.0335L2.53092 47.4L0 55.7235L2.71545 55.5895L37.7251 73.8542L39.6662 76.7086L39.2302 77.5379L39.5703 78.6103L40.3736 79.5093L52.2611 86.9714L53.0861 87L55.1123 86.2922L58.564 81.7899L59.9516 79.0607V77.1518L61.5111 75.4485L63.0723 73.2626L62.4807 72.8229L62.5495 71.8059L63.9099 70.3904L64.0926 69.7523L65.283 69.4127H68.0943L69.7985 67.275L73.0893 66.2312L72.4977 65.3858L73.6446 64.3152L74.3791 64.0829L77.5089 60.6762L77.6771 60.1418L70.9437 54.6976L71.1282 20.9689Z'
						fill='#81A4FF'
					/>
				</svg>
			),
		},

		{
			key: 5,
			title: (
				<span className='label_label_landing_card'>
					Migrant <br /> Workers
				</span>
			),
			icon: <img src={home5} alt='homepage icon' />,
		},
		{
			key: 1,
			title: (
				<span className='label_label_landing_card'>
					Distress <br /> Reporting
				</span>
			),
			icon: (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='113'
					height='90'
					viewBox='0 0 113 90'
					fill='none'>
					<g clipPath='url(#clip0_345_66)'>
						<path
							d='M37.0601 80.52H75.2501V52.92C75.2501 42.71 66.9701 34.43 56.7601 34.43H55.5501C45.3401 34.43 37.0601 42.71 37.0601 52.92V80.52Z'
							fill='#B42800'
						/>
						<path
							d='M75.25 80.52V52.92C75.25 42.71 66.97 34.43 56.76 34.43H55.55V80.52H75.25Z'
							fill='#C92800'
						/>
						<path
							d='M82.4801 79.19H27.8101V88.3H82.4801V79.19Z'
							fill='#FCFAFF'
						/>
						<path
							d='M83.29 87.11C83.29 88.2146 82.3946 89.11 81.29 89.11H29.01C27.9054 89.11 27.01 88.2146 27.01 87.11V80.39C27.01 79.2854 27.9054 78.39 29.01 78.39H81.29C82.3946 78.39 83.29 79.2854 83.29 80.39V87.11ZM28.62 85.5C28.62 86.6046 29.5154 87.5 30.62 87.5H79.68C80.7846 87.5 81.68 86.6046 81.68 85.5V82C81.68 80.8954 80.7846 80 79.68 80H30.62C29.5154 80 28.62 80.8954 28.62 82V85.5Z'
							fill='#FCFAFF'
						/>
						<path
							d='M54.55 35.91H55.15C65.36 35.91 73.64 43.92 73.64 53.81V77.31H70.32V53.81C70.32 44.82 63.46 37.4 54.54 36.13V35.91H54.55Z'
							fill='white'
						/>
						<path
							d='M56.1501 23.45C56.6001 23.45 56.9501 23.09 56.9501 22.65V0.8C56.9501 0.36 56.5901 0 56.1501 0C55.7101 0 55.3501 0.36 55.3501 0.8V22.64C55.3501 23.09 55.7101 23.45 56.1501 23.45Z'
							fill='white'
						/>
						<path
							d='M70.4901 26.31C70.5901 26.35 70.7001 26.37 70.8001 26.37C71.1101 26.37 71.4101 26.18 71.5401 25.88L79.9301 5.71998C80.1001 5.30998 79.9101 4.83998 79.5001 4.66998C79.1001 4.49998 78.6201 4.68998 78.4601 5.09998L70.0601 25.26C69.8901 25.67 70.0901 26.15 70.4901 26.31Z'
							fill='white'
						/>
						<path
							d='M40.89 25.83C41.02 26.14 41.32 26.33 41.63 26.33C41.73 26.33 41.84 26.31 41.94 26.27C42.35 26.1 42.55 25.63 42.38 25.22L34.06 5.02002C33.89 4.61002 33.42 4.42002 33.01 4.58002C32.6 4.75002 32.4 5.22002 32.57 5.63002L40.89 25.83Z'
							fill='white'
						/>
						<path
							d='M21.27 45.37L1.10999 36.97C0.709994 36.8 0.229994 37 0.0599938 37.4C-0.110006 37.81 0.0799938 38.28 0.489994 38.45L20.65 46.85C20.75 46.89 20.86 46.91 20.96 46.91C21.27 46.91 21.57 46.72 21.7 46.41C21.88 46.01 21.69 45.54 21.27 45.37Z'
							fill='white'
						/>
						<path
							d='M112.33 37.61C112.16 37.2 111.69 37.01 111.28 37.17L91.09 45.5C90.68 45.67 90.48 46.14 90.65 46.55C90.78 46.86 91.08 47.05 91.4 47.05C91.5 47.05 91.6 47.03 91.7 46.99L111.9 38.67C112.3 38.49 112.5 38.02 112.33 37.61Z'
							fill='white'
						/>
						<path
							d='M99.5101 18.19C99.2001 17.87 98.6901 17.87 98.3701 18.18L82.8101 33.51C82.4901 33.82 82.4901 34.33 82.8001 34.65C82.9601 34.81 83.1701 34.89 83.3701 34.89C83.5701 34.89 83.7801 34.81 83.9401 34.66L99.5001 19.33C99.8101 19.02 99.8201 18.51 99.5101 18.19Z'
							fill='white'
						/>
						<path
							d='M28.24 34.65C28.4 34.8 28.6 34.88 28.8 34.88C29.01 34.88 29.21 34.8 29.37 34.64C29.68 34.32 29.67 33.81 29.36 33.5L13.81 18.18C13.49 17.87 12.98 17.87 12.67 18.19C12.36 18.51 12.36 19.02 12.68 19.33L28.24 34.65Z'
							fill='white'
						/>
					</g>
					<defs>
						<clipPath id='clip0_345_66'>
							<rect
								width='112.39'
								height='89.11'
								rx='13'
								fill='white'
							/>
						</clipPath>
					</defs>
				</svg>
			),
		},
		{
			key: 6,
			title: (
				<span className='label_label_landing_card'>
					Counselling <br /> Services
				</span>
			),
			// icon: <img src={home6} alt='homepage icon' />,
			icon: (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='121'
					height='90'
					viewBox='0 0 121 90'
					fill='none'>
					<path
						d='M38.3957 8.42358H15.0381V72.8508H38.3957V8.42358Z'
						fill='#F5F5F5'
						fillOpacity='0.17'
					/>
					<path
						d='M36.1413 10.8829H17.2915V70.3941H36.1413V10.8829Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M48.4085 46.5607L103.509 46.5607V5.62151L48.4085 5.62151L48.4085 46.5607Z'
						fill='#E6E6E6'
						fillOpacity='0.1'
					/>
					<path
						d='M47.5564 46.5607L102.656 46.5607V5.62151L47.5564 5.62151L47.5564 46.5607Z'
						fill='#F5F5F5'
						fillOpacity='0.17'
					/>
					<path
						d='M101.125 44.843V7.34143L49.0854 7.34143V44.843H101.125Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M49.728 44.8402V7.33862H49.083V44.8402H49.728Z'
						fill='#E6E6E6'
						fillOpacity='0.1'
					/>
					<path
						d='M48.1863 36.8301L102.021 36.8301V34.5206L48.1863 34.5206V36.8301Z'
						fill='#F5F5F5'
						fillOpacity='0.17'
					/>
					<g opacity='0.4'>
						<path
							d='M51.0463 3.81226H45.8452V16.9707H51.0463V3.81226Z'
							fill='#E6E6E6'
							fillOpacity='0.1'
						/>
						<path
							d='M51.0462 15.9125H53.6262V3.81215H51.0462V15.9125Z'
							fill='#F5F5F5'
							fillOpacity='0.17'
						/>
						<path
							d='M58.8273 3.81226H53.6262V16.9707H58.8273V3.81226Z'
							fill='#E6E6E6'
							fillOpacity='0.1'
						/>
						<path
							d='M58.8275 15.9125H61.4075V3.81215H58.8275V15.9125Z'
							fill='#F5F5F5'
							fillOpacity='0.17'
						/>
						<path
							d='M66.6085 3.81226H61.4075V16.9707H66.6085V3.81226Z'
							fill='#E6E6E6'
							fillOpacity='0.1'
						/>
						<path
							d='M66.6085 15.9125H69.1885V3.81215H66.6085V15.9125Z'
							fill='#F5F5F5'
							fillOpacity='0.17'
						/>
						<path
							d='M74.3895 3.81226H69.1885V16.9707H74.3895V3.81226Z'
							fill='#E6E6E6'
							fillOpacity='0.1'
						/>
						<path
							d='M74.3895 15.9125H76.9695V3.81215H74.3895V15.9125Z'
							fill='#F5F5F5'
							fillOpacity='0.17'
						/>
						<path
							d='M82.1705 3.81226H76.9695V16.9707H82.1705V3.81226Z'
							fill='#E6E6E6'
							fillOpacity='0.1'
						/>
						<path
							d='M82.1707 15.9125H84.7507V3.81215H82.1707V15.9125Z'
							fill='#F5F5F5'
							fillOpacity='0.17'
						/>
						<path
							d='M89.9518 3.81226H84.7507V16.9707H89.9518V3.81226Z'
							fill='#E6E6E6'
							fillOpacity='0.1'
						/>
						<path
							d='M89.9515 15.9125H92.5315V3.81215H89.9515V15.9125Z'
							fill='#F5F5F5'
							fillOpacity='0.17'
						/>
						<path
							d='M97.7325 3.81226H92.5315V16.9707H97.7325V3.81226Z'
							fill='#E6E6E6'
							fillOpacity='0.1'
						/>
						<path
							d='M97.7328 15.9125H100.313V3.81215H97.7328V15.9125Z'
							fill='#F5F5F5'
							fillOpacity='0.17'
						/>
						<path
							d='M105.514 3.81226H100.313V16.9707H105.514V3.81226Z'
							fill='#E6E6E6'
							fillOpacity='0.1'
						/>
					</g>
					<path
						d='M49.259 79.0906H55.7065V55.0784H49.259V79.0906Z'
						fill='#E6E6E6'
						fillOpacity='0.1'
					/>
					<path
						d='M50.3341 79.0908H49.2615V76.9287H51.4598L50.3341 79.0908Z'
						fill='#F0F0F0'
						fillOpacity='0.08'
					/>
					<path
						d='M95.653 79.0906H102.101V55.0784H95.653V79.0906Z'
						fill='#E6E6E6'
						fillOpacity='0.1'
					/>
					<path
						d='M49.2613 78.2595H96.5876V55.0783L49.2613 55.0783V78.2595Z'
						fill='#F0F0F0'
						fillOpacity='0.08'
					/>
					<path
						d='M50.0947 62.4633L95.7566 62.4633V56.1051L50.0947 56.1051V62.4633Z'
						fill='#E6E6E6'
						fillOpacity='0.1'
					/>
					<path
						d='M61.649 58.081H84.2094C85.2554 58.081 86.2579 57.2959 86.9778 55.9069H58.8757C59.5956 57.2959 60.5957 58.081 61.649 58.081Z'
						fill='#F0F0F0'
						fillOpacity='0.08'
					/>
					<path
						d='M95.5154 79.0908H96.588V76.9287H94.3896L95.5154 79.0908Z'
						fill='#F0F0F0'
						fillOpacity='0.08'
					/>
					<path
						d='M50.0947 69.8479H95.7566V63.4897L50.0947 63.4897V69.8479Z'
						fill='#E6E6E6'
						fillOpacity='0.1'
					/>
					<path
						d='M61.649 65.466H84.2094C85.2554 65.466 86.2579 64.6833 86.9778 63.2919H58.8757C59.5956 64.6833 60.5957 65.466 61.649 65.466Z'
						fill='#F0F0F0'
						fillOpacity='0.08'
					/>
					<path
						d='M50.0947 77.233H95.7566V70.8749H50.0947V77.233Z'
						fill='#E6E6E6'
						fillOpacity='0.1'
					/>
					<path
						d='M61.649 72.8508H84.2094C85.2554 72.8508 86.2579 72.0681 86.9778 70.6766H58.8757C59.5956 72.0681 60.5957 72.8508 61.649 72.8508Z'
						fill='#F0F0F0'
						fillOpacity='0.08'
					/>
					<path
						d='M60.4411 89.9999C86.3092 89.9999 107.279 88.7756 107.279 87.2653C107.279 85.7551 86.3092 84.5308 60.4411 84.5308C34.573 84.5308 13.6028 85.7551 13.6028 87.2653C13.6028 88.7756 34.573 89.9999 60.4411 89.9999Z'
						fill='#F5F5F5'
						fillOpacity='0.17'
					/>
					<path
						d='M91.271 85.7717H90.9654C90.9016 85.7669 90.8418 85.7391 90.7971 85.6933C90.7524 85.6476 90.7259 85.5871 90.7226 85.5233L88.8435 72.4379H90.2714L91.5166 85.4376C91.5235 85.4758 91.5225 85.515 91.5137 85.5528C91.505 85.5906 91.4886 85.6262 91.4656 85.6575C91.4426 85.6887 91.4135 85.715 91.3801 85.7347C91.3466 85.7543 91.3095 85.7669 91.271 85.7717Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M91.271 85.7717H90.9654C90.9016 85.7669 90.8418 85.7391 90.7971 85.6933C90.7524 85.6476 90.7259 85.5871 90.7226 85.5233L88.8435 72.4379H90.2714L91.5166 85.4376C91.5235 85.4758 91.5225 85.515 91.5137 85.5528C91.505 85.5906 91.4886 85.6262 91.4656 85.6575C91.4426 85.6887 91.4135 85.715 91.3801 85.7347C91.3466 85.7543 91.3095 85.7669 91.271 85.7717Z'
						fill='black'
					/>
					<path
						d='M95.1887 85.7717H94.8831C94.8193 85.7669 94.7595 85.7391 94.7148 85.6933C94.6701 85.6476 94.6437 85.5871 94.6404 85.5233L92.7612 72.4379H94.1891L95.4371 85.4376C95.444 85.476 95.443 85.5154 95.434 85.5534C95.4251 85.5914 95.4085 85.6271 95.3852 85.6584C95.3619 85.6897 95.3325 85.7159 95.2987 85.7354C95.2649 85.7549 95.2274 85.7673 95.1887 85.7717Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M95.1887 85.7717H94.8831C94.8193 85.7669 94.7595 85.7391 94.7148 85.6933C94.6701 85.6476 94.6437 85.5871 94.6404 85.5233L92.7612 72.4379H94.1891L95.4371 85.4376C95.444 85.476 95.443 85.5154 95.434 85.5534C95.4251 85.5914 95.4085 85.6271 95.3852 85.6584C95.3619 85.6897 95.3325 85.7159 95.2987 85.7354C95.2649 85.7549 95.2274 85.7673 95.1887 85.7717Z'
						fill='black'
					/>
					<path
						d='M73.3593 85.7717H73.6649C73.7286 85.7669 73.7885 85.7391 73.8331 85.6933C73.8778 85.6476 73.9043 85.5871 73.9076 85.5233L75.7867 72.4379H74.3588L73.1137 85.4376C73.1068 85.4758 73.1077 85.515 73.1165 85.5528C73.1253 85.5906 73.1416 85.6262 73.1646 85.6575C73.1876 85.6887 73.2167 85.715 73.2502 85.7347C73.2837 85.7543 73.3208 85.7669 73.3593 85.7717Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M73.3593 85.7717H73.6649C73.7286 85.7669 73.7885 85.7391 73.8331 85.6933C73.8778 85.6476 73.9043 85.5871 73.9076 85.5233L75.7867 72.4379H74.3588L73.1137 85.4376C73.1068 85.4758 73.1077 85.515 73.1165 85.5528C73.1253 85.5906 73.1416 85.6262 73.1646 85.6575C73.1876 85.6887 73.2167 85.715 73.2502 85.7347C73.2837 85.7543 73.3208 85.7669 73.3593 85.7717Z'
						fill='black'
					/>
					<path
						d='M69.4403 85.7717H69.7459C69.8097 85.7669 69.8695 85.7391 69.9142 85.6933C69.9589 85.6476 69.9854 85.5871 69.9886 85.5233L71.8678 72.4379H70.4399L69.1947 85.4376C69.1878 85.4758 69.1888 85.515 69.1976 85.5528C69.2063 85.5906 69.2227 85.6262 69.2457 85.6575C69.2686 85.6887 69.2978 85.715 69.3312 85.7347C69.3647 85.7543 69.4018 85.7669 69.4403 85.7717Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M69.4403 85.7717H69.7459C69.8097 85.7669 69.8695 85.7391 69.9142 85.6933C69.9589 85.6476 69.9854 85.5871 69.9886 85.5233L71.8678 72.4379H70.4399L69.1947 85.4376C69.1878 85.4758 69.1888 85.515 69.1976 85.5528C69.2063 85.5906 69.2227 85.6262 69.2457 85.6575C69.2686 85.6887 69.2978 85.715 69.3312 85.7347C69.3647 85.7543 69.4018 85.7669 69.4403 85.7717Z'
						fill='black'
					/>
					<path
						d='M64.3625 56.9225H73.9809C75.6944 50.9966 81.8287 46.3217 88.1172 46.3217H91.2415C97.8099 46.3217 102.071 51.4422 100.729 57.7621C100.478 58.9247 100.065 60.0465 99.5034 61.0948L97.987 70.2877C97.7928 71.4758 96.8447 72.4382 95.8737 72.4382H66.9213C65.9503 72.4382 65.0022 71.4758 64.808 70.2877L62.9574 59.0615C62.7604 57.8849 63.3915 56.9225 64.3625 56.9225Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.5'
						d='M64.3625 56.9225H73.9809C75.6944 50.9966 81.8287 46.3217 88.1172 46.3217H91.2415C97.8099 46.3217 102.071 51.4422 100.729 57.7621C100.478 58.9247 100.065 60.0465 99.5034 61.0948L97.987 70.2877C97.7928 71.4758 96.8447 72.4382 95.8737 72.4382H66.9213C65.9503 72.4382 65.0022 71.4758 64.808 70.2877L62.9574 59.0615C62.7604 57.8849 63.3915 56.9225 64.3625 56.9225Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M61.9552 82.3873L64.337 83.4783C64.3775 83.4953 64.4098 83.5276 64.4269 83.5682C64.4439 83.6087 64.4445 83.6544 64.4283 83.6953L63.6773 85.6344C63.6324 85.7335 63.5501 85.8107 63.4484 85.8492C63.3467 85.8878 63.2339 85.8845 63.1346 85.84C62.3122 85.4459 61.9381 85.2146 60.89 84.7348C59.7821 84.1874 58.7117 83.5673 57.6857 82.8785C56.909 82.3074 57.4801 81.5363 57.8657 81.7077C59.6077 82.4759 60.7215 82.5444 61.5297 82.3502C61.6716 82.3132 61.8219 82.3264 61.9552 82.3873Z'
						fill='#263238'
					/>
					<path
						d='M78.8476 53.1869C78.8476 53.1869 63.6175 53.3754 63.3805 59.3783C63.0435 68.0086 61.7698 82.3363 61.7698 82.3363L64.34 83.4786C64.34 83.4786 68.8408 69.1024 69.0007 62.3627C73.2845 61.3003 84.2994 62.3627 85.5931 58.8614C86.2271 57.0404 86.395 55.0895 86.0814 53.1869H78.8476Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.2'
						d='M78.8476 53.1869C78.8476 53.1869 63.6175 53.3754 63.3805 59.3783C63.0435 68.0086 61.7698 82.3363 61.7698 82.3363L64.34 83.4786C64.34 83.4786 68.8408 69.1024 69.0007 62.3627C73.2845 61.3003 84.2994 62.3627 85.5931 58.8614C86.2271 57.0404 86.395 55.0895 86.0814 53.1869H78.8476Z'
						fill='black'
					/>
					<path
						d='M82.0886 37.391C81.9544 37.945 81.8202 38.4448 81.6688 38.9646C81.5175 39.4843 81.3604 39.9898 81.1947 40.4982C80.8606 41.5148 80.4922 42.5258 80.0695 43.5253C79.6462 44.5471 79.1549 45.5393 78.5988 46.4954C78.3068 47.0009 77.9844 47.4882 77.6335 47.9547C77.2469 48.4713 76.8012 48.9409 76.3056 49.3541L76.2627 49.3912L76.1713 49.4597C75.883 49.648 75.5542 49.7654 75.2118 49.8024C74.9965 49.8288 74.7788 49.8288 74.5635 49.8024C74.2642 49.7657 73.97 49.6948 73.6868 49.5911C73.2476 49.4328 72.8209 49.2419 72.4102 49.0199C71.6773 48.6123 70.9688 48.1622 70.2883 47.672C69.6258 47.1951 68.9861 46.701 68.3635 46.187C67.7409 45.6729 67.1441 45.1474 66.5529 44.582C66.4013 44.4347 66.3082 44.2375 66.2908 44.0268C66.2734 43.8162 66.333 43.6064 66.4585 43.4363C66.584 43.2662 66.7669 43.1473 66.9732 43.1017C67.1796 43.0562 67.3956 43.0869 67.581 43.1883H67.5953C68.2693 43.5625 68.949 43.9594 69.6315 44.3307C70.314 44.7019 70.988 45.076 71.6649 45.4245C72.3417 45.7729 73.0214 46.1041 73.6639 46.3726C73.9588 46.4983 74.2635 46.5995 74.5749 46.6753C74.6675 46.7029 74.764 46.7155 74.8605 46.7124C74.8805 46.7124 74.8605 46.7124 74.8005 46.7124C74.6551 46.7291 74.5159 46.7811 74.395 46.8638L74.2579 46.9666C74.5392 46.6928 74.7875 46.3871 74.9976 46.0556C75.2483 45.6792 75.478 45.2892 75.6859 44.8876C76.1237 44.0255 76.5155 43.1409 76.8596 42.2373C77.2109 41.3178 77.5393 40.3753 77.8449 39.4215C78.1504 38.4677 78.436 37.4881 78.7016 36.5657V36.5314C78.8228 36.0886 79.1135 35.7112 79.5107 35.4809C79.9079 35.2506 80.3798 35.1858 80.8243 35.3006C81.2689 35.4154 81.6504 35.7006 81.8865 36.0944C82.1225 36.4882 82.1941 36.9591 82.0858 37.4053L82.0886 37.391Z'
						fill='#263238'
					/>
					<path
						d='M75.9149 83.6217H78.7708C78.7946 83.6205 78.8185 83.6241 78.8409 83.6323C78.8634 83.6405 78.884 83.653 78.9015 83.6693C78.9191 83.6855 78.9332 83.705 78.9431 83.7268C78.9531 83.7485 78.9585 83.772 78.9593 83.7959L79.0992 86.0548C79.0992 86.1738 79.0526 86.288 78.9692 86.3729C78.8859 86.4578 78.7726 86.5067 78.6537 86.5089C77.6599 86.4918 77.1829 86.4318 75.9264 86.4318C75.1553 86.4318 73.596 86.5118 72.5279 86.5118C71.4599 86.5118 71.4913 85.458 71.9425 85.3694C73.973 84.9411 74.7583 84.3499 75.4752 83.7845C75.6001 83.6837 75.7545 83.6266 75.9149 83.6217Z'
						fill='#263238'
					/>
					<path
						d='M82.9524 53.1871C82.9524 53.1871 69.1387 54.3522 69.4786 60.3523C69.8498 66.9207 75.9156 83.6273 75.9156 83.6273H78.7086C78.7086 83.6273 76.3183 68.3286 75.4758 61.6374C79.3712 61.6374 88.4013 62.3685 89.6978 58.8587C90.3319 57.0376 90.4988 55.0865 90.1833 53.1842L82.9524 53.1871Z'
						fill='#1B47B4'
					/>
					<path
						d='M67.7962 43.4689C67.5807 43.1179 67.3195 42.7971 67.0194 42.515C66.4482 43.0462 66.137 44.5912 66.137 44.5912L66.9166 44.6597C66.9166 44.6597 67.8133 44.3684 67.7962 43.4689Z'
						fill='#1B47B4'
					/>
					<path
						d='M64.9287 42.0809L64.0405 41.6954C63.9252 41.6455 63.7956 41.6391 63.6759 41.6775C63.5562 41.7158 63.4544 41.7962 63.3894 41.9038L62.9668 42.6006C62.9084 42.6988 62.8838 42.8134 62.8967 42.9268C62.9095 43.0403 62.9592 43.1464 63.0381 43.2289L64.2576 44.4998L66.2909 44.417C66.4058 44.4131 66.5176 44.3784 66.6145 44.3166C66.7115 44.2548 66.7901 44.1682 66.8421 44.0657L67.0763 43.6059C67.15 43.4675 67.1701 43.3068 67.1327 43.1545C67.0953 43.0022 67.003 42.869 66.8735 42.7806C66.2138 42.3465 64.9287 42.0809 64.9287 42.0809Z'
						fill='#7F3E3B'
					/>
					<path
						d='M78.7163 36.806C78.3108 39.3763 77.7824 44.9251 78.8591 53.2927L90.1824 53.187C90.1139 47.3869 91.022 41.9437 91.9102 36.6233C91.9454 36.4143 91.9384 36.2005 91.8894 35.9944C91.8404 35.7882 91.7505 35.594 91.6251 35.4233C91.4996 35.2526 91.3411 35.1088 91.1591 35.0004C90.977 34.8921 90.775 34.8215 90.5651 34.7927C87.3177 34.4129 84.037 34.4129 80.7896 34.7927C80.2744 34.863 79.7952 35.0964 79.4221 35.4587C79.0491 35.8209 78.8017 36.2931 78.7163 36.806Z'
						fill='#263238'
					/>
					<path
						d='M92.013 35.9008C92.3471 36.7261 92.6127 37.5172 92.8869 38.3339C93.161 39.1507 93.3952 39.9703 93.6208 40.8014C93.8464 41.6324 94.0435 42.4806 94.192 43.3459C94.3648 44.2295 94.4802 45.1233 94.5375 46.0218C94.5547 46.2531 94.5575 46.493 94.5604 46.7329V47.0985C94.5563 47.4737 94.4581 47.8419 94.2748 48.1694C94.1528 48.3821 93.992 48.57 93.8007 48.7234C93.7115 48.7949 93.6159 48.858 93.5151 48.9119C93.4239 48.9623 93.3283 49.0043 93.2296 49.0376C92.961 49.1374 92.6765 49.1877 92.3899 49.1861C92.0513 49.1783 91.7151 49.1255 91.3904 49.029C90.9152 48.8861 90.4529 48.7037 90.0082 48.4835C89.5941 48.2779 89.2057 48.0637 88.8259 47.841C87.3494 46.9593 85.9481 45.9573 84.6364 44.8452C84.4714 44.7099 84.3619 44.5186 84.3286 44.3078C84.2952 44.0971 84.3405 43.8814 84.4557 43.7017C84.5708 43.5221 84.748 43.391 84.9534 43.3333C85.1589 43.2756 85.3784 43.2953 85.5702 43.3887H85.5902C87.0667 44.0913 88.5689 44.8167 90.031 45.4192C90.3966 45.5677 90.7593 45.7048 91.1162 45.8276C91.4326 45.9439 91.7585 46.0328 92.0901 46.0932C92.2214 46.1161 92.3385 46.1132 92.3243 46.0932C92.2468 46.086 92.1689 46.0998 92.0986 46.1332C92.0537 46.1505 92.0098 46.1705 91.9673 46.1932C91.9127 46.2224 91.861 46.2568 91.8131 46.296C91.7001 46.3841 91.6034 46.4914 91.5275 46.613C91.4418 46.7552 91.3909 46.9157 91.379 47.0813L91.3647 46.8215C91.3476 46.6444 91.3333 46.4702 91.3076 46.2874C91.0696 44.7391 90.7125 43.2115 90.2395 41.7181C89.7826 40.1845 89.2571 38.6195 88.7231 37.1316V37.1174C88.5755 36.6885 88.6004 36.219 88.7926 35.8082C88.9847 35.3974 89.329 35.0773 89.7528 34.9157C90.1766 34.754 90.6466 34.7634 91.0635 34.9419C91.4805 35.1204 91.8117 35.454 91.9873 35.8722L92.013 35.9008Z'
						fill='#263238'
					/>
					<path
						d='M87.487 29.0494L86.1191 30.1918L84.2514 31.7425C84.309 31.9897 84.3491 32.2407 84.3713 32.4936C84.4599 33.396 84.2628 34.2242 83.169 34.644C82.961 34.7121 82.7715 34.8271 82.615 34.9801C82.4585 35.1331 82.3393 35.32 82.2666 35.5264C82.1181 36.0034 82.2923 36.5374 83.7945 36.5374C86.002 36.5374 87.1529 35.555 87.587 35.0724C87.6453 35.0053 87.679 34.9205 87.6826 34.8317C87.6863 34.743 87.6596 34.6556 87.607 34.584C86.736 33.3389 87.1301 30.5973 87.487 29.0494Z'
						fill='#7F3E3B'
					/>
					<path
						d='M83.74 36.0461C83.836 36.1571 83.9019 36.2908 83.9313 36.4345C84.0446 36.281 84.1849 36.1493 84.3454 36.0461C84.5354 36.1354 84.7041 36.2641 84.8405 36.4236C84.9769 36.5832 85.0778 36.7699 85.1365 36.9714C84.7204 36.8862 84.3198 36.7378 83.9485 36.5316C83.7036 36.6674 83.4333 36.751 83.1545 36.7772C83.1717 36.2832 83.74 36.0461 83.74 36.0461Z'
						fill='#1B47B4'
					/>
					<path
						d='M84.3447 36.046C84.7412 36.5234 85.0026 37.0983 85.1015 37.711C85.1015 37.711 88.757 36.7971 88.5856 34.4125C88.2643 34.2607 87.9229 34.1559 87.5718 34.1012C87.5718 34.1012 86.7865 35.5291 84.3447 36.046Z'
						fill='#1B47B4'
					/>
					<path
						d='M83.7402 36.0462C83.3402 36.5273 83.0638 37.0989 82.9349 37.7111C82.9349 37.7111 81.0614 35.9976 82.8863 34.4326C83.2013 34.3324 83.5257 34.2645 83.8544 34.2299C83.8544 34.2299 82.6407 35.2151 83.7402 36.0462Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.2'
						d='M84.251 31.7336C84.3084 31.9819 84.3484 32.2338 84.3709 32.4876C85.0677 32.3477 85.9959 31.608 86.0844 30.8969C86.1188 30.6615 86.1274 30.4231 86.1101 30.1858L84.251 31.7336Z'
						fill='black'
					/>
					<path
						d='M82.252 24.1319C80.5385 25.3513 81.4923 28.21 82.9602 27.1162C84.4281 26.0224 83.6513 23.1381 82.252 24.1319Z'
						fill='#263238'
					/>
					<path
						d='M88.0516 26.6793C87.5747 28.8669 87.4091 30.1691 86.1154 31.1686C85.6986 31.4978 85.1999 31.7067 84.673 31.7729C84.1462 31.839 83.6112 31.7599 83.126 31.5441C82.6409 31.3283 82.2239 30.9839 81.9203 30.5483C81.6167 30.1127 81.4379 29.6024 81.4033 29.0725C81.2319 27.0334 82.0401 23.7949 84.3419 23.1752C84.8374 23.0275 85.3631 23.0127 85.8661 23.1324C86.3691 23.2521 86.8317 23.502 87.2076 23.8571C87.5835 24.2121 87.8594 24.6598 88.0075 25.1552C88.1557 25.6505 88.1709 26.1762 88.0516 26.6793Z'
						fill='#7F3E3B'
					/>
					<path
						d='M87.0382 25.0542C85.8244 24.72 82.6944 25.5168 81.5835 24.3316C80.3926 23.0608 82.129 22.4411 82.643 22.8837C81.9948 22.1412 82.2347 21.3473 83.1542 21.4187C83.8254 21.4701 84.0367 22.2126 84.0367 22.2126C84.0367 22.2126 83.9253 20.9132 85.0791 21.0046C86.1243 21.0846 86.1271 22.4953 86.1271 22.4953C86.1271 22.4953 88.6231 21.79 89.457 24.1717C91.0277 24.1374 90.9935 27.4445 87.8321 28.6211C86.5498 27.9871 86.4898 25.731 87.0382 25.0542Z'
						fill='#263238'
					/>
					<path
						d='M89.1204 24.6484C89.0381 24.6534 88.9571 24.6261 88.8946 24.5724C88.832 24.5187 88.7929 24.4427 88.7855 24.3606C88.778 24.2784 88.8029 24.1967 88.8548 24.1326C88.9066 24.0685 88.9814 24.0271 89.0633 24.0173C89.1492 23.9629 89.2145 23.8815 89.2489 23.7859C89.2949 23.6945 89.3217 23.5947 89.3276 23.4925C89.3335 23.3903 89.3184 23.288 89.2832 23.1919C89.2592 23.1537 89.2437 23.1109 89.2376 23.0662C89.2315 23.0216 89.235 22.9761 89.2479 22.9329C89.2608 22.8897 89.2827 22.8497 89.3122 22.8157C89.3418 22.7816 89.3783 22.7543 89.4192 22.7354C89.4602 22.7166 89.5047 22.7067 89.5497 22.7064C89.5948 22.7062 89.6395 22.7155 89.6806 22.7338C89.7218 22.7522 89.7586 22.7791 89.7886 22.8128C89.8185 22.8465 89.841 22.8861 89.8544 22.9292C89.932 23.1204 89.9661 23.3264 89.9542 23.5324C89.9424 23.7383 89.8849 23.9391 89.7858 24.1201C89.7299 24.2481 89.6455 24.3617 89.5391 24.4523C89.4328 24.5428 89.3071 24.6079 89.1718 24.6427C89.155 24.647 89.1377 24.6489 89.1204 24.6484Z'
						fill='#263238'
					/>
					<path
						d='M88.4088 28.7097C88.1118 29.0953 87.6843 29.3594 87.2065 29.4522C86.5868 29.5636 86.3983 28.9925 86.6725 28.4413C86.9181 27.9415 87.5292 27.2847 88.1004 27.4275C88.6716 27.5703 88.7744 28.2185 88.4088 28.7097Z'
						fill='#7F3E3B'
					/>
					<path
						d='M84.3021 26.8792C84.2736 27.0591 84.1565 27.1933 84.0394 27.179C83.9223 27.1647 83.848 27.0077 83.8737 26.8278C83.8994 26.6478 84.0194 26.5136 84.1393 26.5422C84.2593 26.5707 84.3278 26.6992 84.3021 26.8792Z'
						fill='#263238'
					/>
					<path
						d='M82.2638 26.6362C82.2352 26.8162 82.1181 26.9504 82.0011 26.9218C81.884 26.8933 81.8097 26.7505 81.8354 26.5706C81.8611 26.3906 81.9811 26.2564 82.101 26.2707C82.2209 26.285 82.2923 26.4563 82.2638 26.6362Z'
						fill='#263238'
					/>
					<path
						d='M82.8865 26.7074C82.6593 27.1711 82.3712 27.6023 82.0298 27.9897C82.1309 28.0705 82.2486 28.128 82.3744 28.1581C82.5003 28.1882 82.6313 28.1902 82.758 28.1639L82.8865 26.7074Z'
						fill='#7F3E3B'
					/>
					<path
						d='M83.5947 29.0896C83.8068 29.0924 84.0173 29.0524 84.2136 28.9719C84.4099 28.8914 84.5879 28.7722 84.737 28.6213C84.744 28.6104 84.7469 28.5974 84.7452 28.5846C84.7434 28.5718 84.7372 28.56 84.7275 28.5514C84.7179 28.5428 84.7055 28.5379 84.6926 28.5376C84.6796 28.5373 84.667 28.5417 84.657 28.5499C84.494 28.711 84.2963 28.8326 84.0789 28.9054C83.8616 28.9782 83.6305 29.0002 83.4033 28.9697C83.3895 28.9687 83.3758 28.9728 83.3647 28.9812C83.3537 28.9896 83.3461 29.0018 83.3433 29.0154C83.3426 29.0225 83.3432 29.0297 83.3452 29.0366C83.3472 29.0435 83.3506 29.0499 83.3551 29.0555C83.3596 29.0611 83.3652 29.0657 83.3715 29.0691C83.3778 29.0725 83.3848 29.0746 83.3919 29.0753C83.4592 29.0839 83.5269 29.0887 83.5947 29.0896Z'
						fill='#263238'
					/>
					<path
						d='M85.1224 25.8425C85.1394 25.839 85.1551 25.8311 85.1681 25.8197C85.1782 25.8099 85.1862 25.7981 85.1917 25.7851C85.1972 25.7721 85.2 25.7581 85.2 25.744C85.2 25.7299 85.1972 25.7159 85.1917 25.7029C85.1862 25.6899 85.1782 25.6782 85.1681 25.6683C85.0597 25.5403 84.9215 25.4411 84.7655 25.3793C84.6096 25.3175 84.4409 25.2952 84.2742 25.3142C84.2604 25.3165 84.2471 25.3214 84.2351 25.3288C84.2231 25.3362 84.2128 25.3459 84.2045 25.3573C84.1963 25.3687 84.1904 25.3816 84.1872 25.3953C84.184 25.409 84.1835 25.4231 84.1857 25.437C84.188 25.4509 84.1929 25.4642 84.2003 25.4761C84.2077 25.4881 84.2174 25.4985 84.2288 25.5067C84.2402 25.5149 84.2531 25.5208 84.2668 25.5241C84.2805 25.5273 84.2946 25.5278 84.3085 25.5255C84.44 25.5128 84.5726 25.5321 84.6949 25.5819C84.8173 25.6316 84.9258 25.7103 85.011 25.8111C85.0245 25.8266 85.0423 25.8379 85.062 25.8434C85.0818 25.849 85.1028 25.8487 85.1224 25.8425Z'
						fill='#263238'
					/>
					<path
						d='M81.5354 25.7251C81.552 25.7298 81.5695 25.7304 81.5864 25.7269C81.6033 25.7234 81.6191 25.716 81.6325 25.7052C81.7349 25.614 81.8571 25.548 81.9895 25.5123C82.1218 25.4766 82.2607 25.4722 82.395 25.4995C82.4222 25.5066 82.4512 25.5028 82.4757 25.489C82.5002 25.4751 82.5184 25.4523 82.5264 25.4253C82.5303 25.4118 82.5314 25.3976 82.5298 25.3837C82.5281 25.3697 82.5237 25.3562 82.5168 25.344C82.5099 25.3318 82.5006 25.321 82.4895 25.3124C82.4784 25.3038 82.4657 25.2975 82.4521 25.2939C82.2848 25.2578 82.1113 25.2615 81.9456 25.3046C81.7799 25.3478 81.6267 25.4292 81.4983 25.5424C81.4767 25.5602 81.4631 25.5858 81.4604 25.6137C81.4578 25.6415 81.4662 25.6693 81.484 25.6909C81.4972 25.7073 81.5152 25.7193 81.5354 25.7251Z'
						fill='#263238'
					/>
					<path
						d='M85.2539 41.9092L84.003 41.1324C83.914 41.0768 83.8148 41.0395 83.7112 41.0226C83.6076 41.0057 83.5017 41.0096 83.3996 41.0341C83.2976 41.0586 83.2014 41.1031 83.1167 41.1651C83.032 41.227 82.9605 41.3053 82.9064 41.3952L82.7607 41.6408C82.6737 41.7839 82.6345 41.951 82.6489 42.1179C82.6633 42.2848 82.7305 42.4428 82.8407 42.5689L83.6974 43.5599C83.6974 43.5599 84.4485 45.3105 85.3195 44.8479L85.9821 43.7055L85.2539 41.9092Z'
						fill='#7F3E3B'
					/>
					<path
						d='M86.5417 44.1482C86.3704 43.7336 86.1478 43.3422 85.8791 42.983C85.2509 43.5199 84.7368 45.2334 84.7368 45.2334L85.505 45.3991C85.7845 45.3206 86.0334 45.1589 86.2186 44.9354C86.4038 44.712 86.5165 44.4374 86.5417 44.1482Z'
						fill='#1B47B4'
					/>
					<path
						d='M96.112 56.9224H81.2845C80.3135 56.9224 79.6853 57.8848 79.8795 59.0728L81.73 70.2876C81.9271 71.4756 82.8724 72.4381 83.8462 72.4381H94.2757L96.112 56.9224Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.5'
						d='M96.112 56.9224H81.2845C80.3135 56.9224 79.6853 57.8848 79.8795 59.0728L81.73 70.2876C81.9271 71.4756 82.8724 72.4381 83.8462 72.4381H94.2757L96.112 56.9224Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M35.6266 86.7001H35.989C36.053 86.7001 36.1145 86.6747 36.1598 86.6294C36.2051 86.5841 36.2305 86.5226 36.2305 86.4586L38.2163 56.5278H36.7209L35.3923 86.4247C35.3875 86.4586 35.3899 86.4931 35.3994 86.5259C35.409 86.5587 35.4253 86.5891 35.4475 86.6152C35.4696 86.6412 35.497 86.6622 35.5279 86.6769C35.5588 86.6915 35.5924 86.6994 35.6266 86.7001Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M35.6266 86.7001H35.989C36.053 86.7001 36.1145 86.6747 36.1598 86.6294C36.2051 86.5841 36.2305 86.5226 36.2305 86.4586L38.2163 56.5278H36.7209L35.3923 86.4247C35.3875 86.4586 35.3899 86.4931 35.3994 86.5259C35.409 86.5587 35.4253 86.5891 35.4475 86.6152C35.4696 86.6412 35.497 86.6622 35.5279 86.6769C35.5588 86.6915 35.5924 86.6994 35.6266 86.7001Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M31.5197 86.7001H31.882C31.9461 86.7001 32.0075 86.6747 32.0528 86.6294C32.0981 86.5841 32.1236 86.5226 32.1236 86.4586L34.1093 56.5278H32.6116L31.2853 86.4247C31.2806 86.4586 31.283 86.4931 31.2925 86.5259C31.302 86.5587 31.3184 86.5891 31.3405 86.6152C31.3627 86.6412 31.3901 86.6622 31.421 86.6769C31.4519 86.6915 31.4855 86.6994 31.5197 86.7001Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M31.5197 86.7001H31.882C31.9461 86.7001 32.0075 86.6747 32.0528 86.6294C32.0981 86.5841 32.1236 86.5226 32.1236 86.4586L34.1093 56.5278H32.6116L31.2853 86.4247C31.2806 86.4586 31.283 86.4931 31.2925 86.5259C31.302 86.5587 31.3184 86.5891 31.3405 86.6152C31.3627 86.6412 31.3901 86.6622 31.421 86.6769C31.4519 86.6915 31.4855 86.6994 31.5197 86.7001Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M82.1656 86.7001H81.8032C81.7392 86.7001 81.6777 86.6747 81.6324 86.6294C81.5871 86.5841 81.5616 86.5226 81.5616 86.4586L79.5759 56.5278H81.0713L82.3999 86.4247C82.4047 86.4586 82.4023 86.4931 82.3927 86.5259C82.3832 86.5587 82.3668 86.5891 82.3447 86.6152C82.3226 86.6412 82.2951 86.6622 82.2643 86.6769C82.2334 86.6915 82.1997 86.6994 82.1656 86.7001Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M82.1656 86.7001H81.8032C81.7392 86.7001 81.6777 86.6747 81.6324 86.6294C81.5871 86.5841 81.5616 86.5226 81.5616 86.4586L79.5759 56.5278H81.0713L82.3999 86.4247C82.4047 86.4586 82.4023 86.4931 82.3927 86.5259C82.3832 86.5587 82.3668 86.5891 82.3447 86.6152C82.3226 86.6412 82.2951 86.6622 82.2643 86.6769C82.2334 86.6915 82.1997 86.6994 82.1656 86.7001Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M86.2718 86.7001H85.9094C85.8454 86.7001 85.7839 86.6747 85.7386 86.6294C85.6933 86.5841 85.6678 86.5226 85.6678 86.4586L83.6821 56.5278H85.1799L86.5061 86.4247C86.5109 86.4586 86.5085 86.4931 86.4989 86.5259C86.4894 86.5587 86.473 86.5891 86.4509 86.6152C86.4288 86.6412 86.4013 86.6622 86.3705 86.6769C86.3396 86.6915 86.3059 86.6994 86.2718 86.7001Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M86.2718 86.7001H85.9094C85.8454 86.7001 85.7839 86.6747 85.7386 86.6294C85.6933 86.5841 85.6678 86.5226 85.6678 86.4586L83.6821 56.5278H85.1799L86.5061 86.4247C86.5109 86.4586 86.5085 86.4931 86.4989 86.5259C86.4894 86.5587 86.473 86.5891 86.4509 86.6152C86.4288 86.6412 86.4013 86.6622 86.3705 86.6769C86.3396 86.6915 86.3059 86.6994 86.2718 86.7001Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M87.9995 55.9192H29.7083V56.5352H87.9995V55.9192Z'
						fill='#1B47B4'
					/>
					<path
						d='M26.9618 40.7241L26.3358 45.3288C26.1381 46.8609 25.9322 48.3986 25.7866 49.9087C25.7674 50.0982 25.7537 50.2822 25.7372 50.4579L25.7152 50.7325C25.7198 50.6868 25.7198 50.6408 25.7152 50.5952C25.71 50.5512 25.6999 50.5079 25.685 50.4661C25.6738 50.4331 25.6601 50.4009 25.6438 50.37C25.6329 50.3479 25.62 50.3268 25.6054 50.3069C25.556 50.2437 25.5505 50.252 25.5642 50.2657C25.5923 50.2956 25.6236 50.3223 25.6576 50.3453C25.7731 50.4245 25.8942 50.4951 26.02 50.5567C26.6735 50.8556 27.348 51.1061 28.0382 51.3063C29.4769 51.7429 30.9899 52.1301 32.4836 52.4898L32.3133 53.7995C30.7164 53.7515 29.1252 53.5872 27.5522 53.308C26.7187 53.1679 25.8998 52.9519 25.1057 52.6627C24.8724 52.5728 24.6457 52.4663 24.4275 52.3442C24.2927 52.2674 24.1642 52.1802 24.0431 52.0834C23.8751 51.9504 23.7279 51.7931 23.6065 51.6166C23.519 51.4862 23.4489 51.345 23.3978 51.1965C23.3697 51.1066 23.3477 51.0148 23.3319 50.9219C23.3218 50.8307 23.3163 50.7391 23.3154 50.6474V50.3426C23.3154 50.1394 23.3154 49.9362 23.3319 49.7358C23.3978 48.1322 23.5433 46.5671 23.7136 45.002C23.8838 43.4369 24.0815 41.8856 24.3259 40.3342L26.9618 40.7241Z'
						fill='#7F3E3B'
					/>
					<path
						d='M35.9269 53.9038L34.9192 54.8237C34.804 54.9284 34.6601 54.9963 34.506 55.0188C34.352 55.0413 34.1947 55.0173 34.0543 54.95L32.9423 54.4145C32.9423 54.4145 31.2948 54.3459 31.0559 53.3382L31.4842 52.5556L34.0406 52.2811C34.3029 52.2528 34.5673 52.3117 34.7929 52.4486L35.8473 53.0938C35.9136 53.1358 35.9694 53.1924 36.0106 53.2592C36.0517 53.326 36.0771 53.4013 36.0848 53.4794C36.0924 53.5575 36.0822 53.6363 36.0549 53.7098C36.0275 53.7834 35.9838 53.8497 35.9269 53.9038Z'
						fill='#7F3E3B'
					/>
					<path
						d='M29.8975 31.2678C30.1721 32.8494 30.3808 35.7352 29.2495 36.7594C29.2495 36.7594 31.9953 38.2036 33.8157 40.7243C35.7378 39.1921 34.1315 36.9049 34.1315 36.9049C32.3907 36.4354 32.484 35.1366 32.8163 33.9257L29.8975 31.2678Z'
						fill='#7F3E3B'
					/>
					<path
						d='M35.0564 37.8658C35.0564 37.8658 34.9109 36.5258 34.1805 35.8641C33.5682 35.3149 30.3365 35.617 29.3288 35.7295C29.2352 35.7408 29.1459 35.7753 29.0692 35.83C28.9924 35.8847 28.9306 35.9578 28.8894 36.0426L28.1536 37.5802L35.0564 37.8658Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.8'
						d='M35.0564 37.8658C35.0564 37.8658 34.9109 36.5258 34.1805 35.8641C33.5682 35.3149 30.3365 35.617 29.3288 35.7295C29.2352 35.7408 29.1459 35.7753 29.0692 35.83C28.9924 35.8847 28.9306 35.9578 28.8894 36.0426L28.1536 37.5802L35.0564 37.8658Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M26.1305 37.1548C24.1425 37.8028 23.7142 40.469 23.8954 42.542L27.907 45.0132C27.907 45.0132 28.8817 41.9929 28.8735 40.3674C28.8652 38.6485 27.4814 36.721 26.1305 37.1548Z'
						fill='#1B47B4'
					/>
					<path
						d='M26.908 44.412C26.8574 44.4131 26.8071 44.4042 26.76 44.3858C26.7129 44.3674 26.6699 44.3399 26.6334 44.3049C26.617 44.287 26.6023 44.2677 26.5895 44.2472C26.5744 44.2272 26.5623 44.205 26.5538 44.1813C26.5448 44.159 26.5375 44.1361 26.5318 44.1127C26.5291 44.089 26.5291 44.065 26.5318 44.0413C26.5306 43.9905 26.5397 43.94 26.5586 43.8928C26.5775 43.8456 26.6057 43.8027 26.6416 43.7667C26.713 43.6995 26.8072 43.6621 26.9052 43.6621C27.0032 43.6621 27.0975 43.6995 27.1688 43.7667C27.2047 43.8027 27.233 43.8456 27.2519 43.8928C27.2707 43.94 27.2799 43.9905 27.2787 44.0413C27.2814 44.065 27.2814 44.089 27.2787 44.1127C27.2744 44.1364 27.267 44.1595 27.2567 44.1813C27.2491 44.2048 27.238 44.227 27.2237 44.2472L27.1771 44.3049C27.1416 44.3398 27.0995 44.3672 27.0533 44.3856C27.0071 44.404 26.9577 44.4129 26.908 44.412Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M26.1353 44.1592C26.0671 44.1329 26.0081 44.0871 25.9656 44.0275C25.9231 43.968 25.8991 43.8973 25.8965 43.8242C25.8426 43.873 25.7756 43.9047 25.7038 43.9155C25.632 43.9262 25.5586 43.9155 25.4928 43.8846C25.4272 43.8539 25.3718 43.805 25.3333 43.7436C25.2949 43.6822 25.2749 43.611 25.2759 43.5386C25.2194 43.5843 25.1506 43.6121 25.0782 43.6185C25.0058 43.6249 24.9331 43.6095 24.8695 43.5743C24.8058 43.5403 24.7534 43.4884 24.7187 43.425C24.6841 43.3615 24.6687 43.2894 24.6746 43.2174C24.6151 43.2592 24.5447 43.2828 24.4721 43.2853C24.3994 43.2877 24.3276 43.2689 24.2655 43.2311C24.1813 43.1799 24.1208 43.0974 24.0971 43.0017C24.0734 42.9061 24.0886 42.8049 24.1392 42.7204C24.1909 42.636 24.2738 42.5754 24.37 42.5518C24.4661 42.5281 24.5676 42.5433 24.6526 42.5941C24.7109 42.6309 24.7582 42.6826 24.7896 42.744C24.821 42.8053 24.8353 42.874 24.8311 42.9428C24.8872 42.9025 24.9535 42.8788 25.0225 42.8744C25.0914 42.87 25.1602 42.8852 25.221 42.9181C25.2818 42.9508 25.3323 42.9996 25.3672 43.0592C25.4021 43.1188 25.4199 43.1868 25.4187 43.2558C25.4725 43.2128 25.5372 43.1857 25.6056 43.1774C25.674 43.1692 25.7433 43.1802 25.8058 43.2091C25.8683 43.2379 25.9217 43.2834 25.9599 43.3406C25.9982 43.3977 26.02 43.4644 26.0228 43.5331C26.0746 43.4879 26.1377 43.4576 26.2054 43.4455C26.2732 43.4334 26.3429 43.4399 26.4072 43.4645C26.4987 43.5012 26.572 43.5725 26.6111 43.6631C26.6502 43.7536 26.6519 43.8559 26.6158 43.9477C26.5887 44.0174 26.5412 44.0772 26.4794 44.1193C26.4177 44.1615 26.3446 44.184 26.2699 44.1839C26.2239 44.1833 26.1784 44.175 26.1353 44.1592Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M23.8949 42.921C23.8703 42.9237 23.8454 42.9237 23.8208 42.921C23.797 42.9168 23.7739 42.9094 23.7521 42.8991C23.7286 42.8914 23.7064 42.8803 23.6862 42.8661L23.6313 42.8194C23.6135 42.8031 23.5979 42.7847 23.5846 42.7645C23.5846 42.7426 23.5599 42.7206 23.5489 42.6986C23.5404 42.6762 23.533 42.6533 23.527 42.63C23.5256 42.6053 23.5256 42.5805 23.527 42.5559C23.5255 42.5321 23.5255 42.5082 23.527 42.4845C23.533 42.4603 23.5404 42.4365 23.5489 42.4131C23.558 42.3905 23.57 42.3693 23.5846 42.3499C23.5985 42.3294 23.6141 42.3101 23.6313 42.2923L23.6862 42.2456C23.707 42.2324 23.7291 42.2214 23.7521 42.2126L23.8208 42.1907C23.8807 42.1799 23.9423 42.1835 24.0005 42.2011C24.0587 42.2188 24.112 42.25 24.1558 42.2923C24.1729 42.3101 24.1886 42.3294 24.2024 42.3499C24.2159 42.37 24.2279 42.3911 24.2381 42.4131C24.2471 42.4361 24.2535 42.46 24.2574 42.4845C24.2602 42.5082 24.2602 42.5321 24.2574 42.5559C24.2601 42.5805 24.2601 42.6053 24.2574 42.63C24.2535 42.6535 24.2471 42.6765 24.2381 42.6986C24.2381 42.7206 24.2162 42.7426 24.2024 42.7645C24.1892 42.7847 24.1735 42.8031 24.1558 42.8194C24.0851 42.8856 23.9917 42.922 23.8949 42.921Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M38.772 41.0346C38.6045 43.3191 38.0966 47.1631 36.85 53.6377L26.103 53.3192C26.3776 49.3515 25.9575 46.3943 24.9855 39.4503C24.9441 39.1498 24.9636 38.8441 25.0429 38.5514C25.1221 38.2586 25.2595 37.9848 25.4468 37.7463C25.6341 37.5077 25.8675 37.3094 26.133 37.1629C26.3986 37.0165 26.691 36.925 26.9927 36.8939C27.6819 36.8226 28.4671 36.7649 29.2552 36.7484C30.8839 36.6809 32.5154 36.7296 34.1372 36.8939C34.6589 36.9571 35.1998 37.0422 35.716 37.1301C36.6198 37.284 37.4338 37.7694 37.9989 38.4914C38.5641 39.2134 38.8397 40.1202 38.772 41.0346Z'
						fill='#1B47B4'
					/>
					<path
						d='M35.4571 28.3683C35.4818 28.5797 35.6163 28.739 35.7536 28.7198C35.8909 28.7005 35.9843 28.5193 35.9596 28.3079C35.9349 28.0965 35.8003 27.9372 35.663 27.9537C35.5257 27.9702 35.4324 28.1486 35.4571 28.3683Z'
						fill='#263238'
					/>
					<path
						d='M35.6038 27.9673L36.0953 27.7587C36.0953 27.7587 35.8811 28.187 35.6038 27.9673Z'
						fill='#263238'
					/>
					<path
						d='M35.5405 28.6893C35.7871 29.1502 36.0957 29.5752 36.4576 29.9524C36.3591 30.043 36.2419 30.1108 36.1142 30.1512C35.9866 30.1915 35.8517 30.2033 35.719 30.1858L35.5405 28.6893Z'
						fill='#630F0F'
					/>
					<path
						d='M35.4825 26.504C35.4933 26.4848 35.499 26.463 35.499 26.4409C35.499 26.4188 35.4933 26.397 35.4825 26.3777C35.4677 26.3481 35.4419 26.3254 35.4105 26.3146C35.3792 26.3038 35.3449 26.3058 35.315 26.3201C35.1329 26.401 34.9729 26.5246 34.8486 26.6804C34.7244 26.8362 34.6395 27.0196 34.6011 27.2152C34.5988 27.2314 34.5997 27.248 34.6039 27.2639C34.608 27.2797 34.6153 27.2946 34.6253 27.3077C34.6352 27.3207 34.6477 27.3316 34.6619 27.3398C34.6762 27.348 34.6919 27.3532 34.7082 27.3552C34.7244 27.3575 34.741 27.3566 34.7568 27.3524C34.7727 27.3483 34.7876 27.341 34.8006 27.3311C34.8137 27.3211 34.8246 27.3086 34.8328 27.2944C34.8409 27.2801 34.8462 27.2644 34.8482 27.2481C34.8816 27.094 34.951 26.95 35.0508 26.8278C35.1505 26.7056 35.2777 26.6088 35.4221 26.5452C35.4465 26.5392 35.468 26.5246 35.4825 26.504Z'
						fill='#263238'
					/>
					<path
						d='M28.1438 28.3137C28.616 30.8974 28.7616 32.0012 30.236 33.1819C32.4546 34.9557 35.5272 33.8107 35.8374 31.1363C36.112 28.7283 35.2883 24.9062 32.6249 24.1758C32.0362 24.0121 31.4154 24.0015 30.8215 24.1451C30.2276 24.2886 29.6802 24.5816 29.2313 24.9961C28.7824 25.4106 28.4469 25.933 28.2566 26.5136C28.0662 27.0942 28.0274 27.7138 28.1438 28.3137Z'
						fill='#7F3E3B'
					/>
					<path
						d='M33.8376 29.0383C34.2714 30.4579 35.3093 33.8901 33.0138 34.6561C31.2209 35.2547 25.6003 35.7325 24.955 32.4403C24.2878 29.0383 27.3219 26.6138 27.3219 26.6138C27.3219 26.6138 24.9166 24.6423 26.4569 22.4951C28.4421 19.7274 33.1978 21.2925 34.6064 23.2475C35.982 25.1503 33.8376 29.0383 33.8376 29.0383Z'
						fill='#263238'
					/>
					<path
						d='M29.5 35.1284C28.4643 35.1273 27.4387 34.9259 26.4796 34.5354C25.2056 33.9862 24.4615 33.1378 24.3269 32.0806C23.8766 28.4919 27.2155 26.5204 27.2512 26.5012L27.383 26.7291C27.35 26.7483 24.1622 28.6347 24.5905 32.0477C24.7443 33.2586 25.7602 33.9368 26.5867 34.291C28.2177 34.9939 30.3237 34.9884 30.8344 34.615L30.9909 34.8291C30.7328 35.0241 30.1727 35.1284 29.5 35.1284Z'
						fill='#263238'
					/>
					<path
						d='M26.2899 26.1003C26.2597 26.5588 25.8808 27.1986 27.4706 27.4265C29.0604 27.6544 31.2735 27.6407 31.7293 27.259C32.1851 26.8774 32.6299 25.9603 31.6799 26.0426C30.7298 26.125 26.3421 25.315 26.2899 26.1003Z'
						fill='#1B47B4'
					/>
					<path
						d='M32.968 29.442C33.2275 29.9216 33.6655 30.2797 34.1871 30.4387C34.8791 30.6419 35.236 30.0241 35.0521 29.3651C34.8846 28.772 34.3217 27.951 33.6243 28.0361C32.9268 28.1213 32.6577 28.8516 32.968 29.442Z'
						fill='#7F3E3B'
					/>
					<path
						d='M49.7192 83.712L52.2673 83.6626L51.0344 77.3033L48.4863 77.35L49.7192 83.712Z'
						fill='#7F3E3B'
					/>
					<path
						d='M51.6302 80.9413C52.3029 83.4811 54.7824 86.1583 54.7824 86.1583L51.2458 85.2576L49.7 83.061L49.9745 82.4789L51.6302 80.9413Z'
						fill='#7F3E3B'
					/>
					<path
						d='M49.1173 82.1223C49.1101 82.4198 49.1276 82.7173 49.1695 83.0119C49.3205 84.0608 49.7818 86.856 49.7818 86.856H50.0234C49.9142 86.0051 49.8491 85.1491 49.8284 84.2914C49.8614 83.6023 50.3062 84.8214 50.8444 85.5133C51.3826 86.2052 52.3518 86.834 53.9197 86.8862C55.7538 86.9439 56.4869 86.867 55.8994 86.4084C55.3118 85.9499 54.0597 85.6533 54.0597 85.6533C53.0497 85.2208 52.19 84.4996 51.5885 83.5803C50.9951 82.7542 50.2152 82.0797 49.3122 81.6116C49.1585 81.6198 49.1283 81.9109 49.1173 82.1223Z'
						fill='#263238'
					/>
					<path
						d='M58.7449 75.0714L61.9959 75.173L58.3577 69.7638L56.1062 71.6886L58.7449 75.0714Z'
						fill='#7F3E3B'
					/>
					<path
						d='M59.5159 72.7756C61.88 73.8739 65.6636 73.6598 65.6636 73.6598L62.5884 76.131L59.9497 75.9333L59.6751 75.3484L59.5159 72.7756Z'
						fill='#7F3E3B'
					/>
					<path
						d='M59.0165 75.5792C59.2295 75.7861 59.4591 75.9753 59.7029 76.1448C60.5651 76.7461 62.8963 78.2865 62.8963 78.2865L63.0445 78.097C62.3502 77.5982 61.6821 77.0639 61.0429 76.4963C60.5541 75.9965 61.7266 76.4825 62.5668 76.5319C63.407 76.5814 64.4668 76.2574 65.469 75.0492C66.647 73.6461 67.0396 73.0174 66.3422 73.1656C65.6448 73.3139 64.6508 74.0937 64.6508 74.0937C63.7173 74.5935 62.6479 74.7802 61.6003 74.6264C60.6271 74.5274 59.6449 74.6796 58.7474 75.0684C58.6733 75.2057 58.8572 75.4199 59.0165 75.5792Z'
						fill='#263238'
					/>
					<path
						d='M33.0962 53.4507C33.0962 53.4507 50.4934 50.8834 52.2891 55.337C55.4248 63.0966 59.8482 71.9874 59.8482 71.9874L57.712 73.7557C57.712 73.7557 50.1255 66.732 47.4291 58.9285C44.2056 59.9719 33.9501 63.3217 30.1939 63.3217C26.1823 63.3217 24.5431 59.9033 25.9929 54.2223C26.0564 53.9789 26.1984 53.7632 26.397 53.6088C26.5956 53.4544 26.8396 53.3699 27.0912 53.3683C29.6365 53.393 33.0962 53.4507 33.0962 53.4507Z'
						fill='#263238'
					/>
					<path
						d='M34.7627 55.716C34.7627 55.716 49.0407 55.2877 49.1643 60.3454C49.362 68.7118 51.7206 80.6998 51.7206 80.6998L49.1148 80.9414C49.1148 80.9414 44.8561 71.4054 43.7304 63.2395C38.6452 63.5689 33.9554 63.341 30.1992 63.341C25.9899 63.341 25.6412 58.7199 29.7379 53.3245C32.2448 53.319 34.7627 55.716 34.7627 55.716Z'
						fill='#263238'
					/>
					<path
						d='M25.9548 52.682L25.6555 53.6293C25.6143 53.7035 25.6994 53.7804 25.8257 53.7858L36.8857 54.1126C36.9818 54.1126 37.0642 54.0714 37.0724 54.011L37.1987 53.0527C37.1987 52.9841 37.1246 52.9264 37.0175 52.9237L26.136 52.5997C26.1012 52.5959 26.066 52.6015 26.0341 52.616C26.0022 52.6305 25.9748 52.6533 25.9548 52.682Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M27.3791 53.9426H27.088C27.0304 53.9426 26.9864 53.9096 26.9919 53.8739L27.1649 52.6274C27.1649 52.5917 27.2226 52.5642 27.2802 52.5642H27.5713C27.6289 52.5642 27.6729 52.5972 27.6674 52.6329L27.4944 53.8767C27.4889 53.9179 27.4367 53.9453 27.3791 53.9426Z'
						fill='#263238'
					/>
					<path
						d='M36.0597 54.203H35.7687C35.711 54.203 35.6671 54.17 35.6726 54.1343L35.8483 52.8877C35.8483 52.852 35.9032 52.8246 35.9609 52.8246H36.2519C36.3123 52.8246 36.3535 52.8575 36.3508 52.8932L36.1751 54.1398C36.1696 54.1782 36.1201 54.203 36.0597 54.203Z'
						fill='#263238'
					/>
					<path
						d='M31.7209 54.0715H31.4298C31.3694 54.0715 31.3283 54.0385 31.3337 54.0028L31.5067 52.7563C31.5067 52.7206 31.5644 52.6931 31.622 52.6931H31.9131C31.9708 52.6931 32.0147 52.7261 32.0092 52.7618L31.8362 54.0083C31.8307 54.0468 31.7786 54.0742 31.7209 54.0715Z'
						fill='#263238'
					/>
					<path
						d='M44.9662 41.9899H51.9103C51.9619 41.9901 52.0128 42.0022 52.0589 42.0253C52.1051 42.0484 52.1453 42.0818 52.1764 42.123C52.2075 42.1642 52.2287 42.2121 52.2382 42.2628C52.2478 42.3135 52.2455 42.3658 52.2315 42.4155L49.634 51.3255C49.6143 51.3946 49.5725 51.4554 49.5149 51.4985C49.4574 51.5416 49.3874 51.5648 49.3155 51.5644H42.3687C42.317 51.5649 42.2658 51.5532 42.2194 51.5303C42.173 51.5073 42.1326 51.4738 42.1016 51.4323C42.0706 51.3909 42.0497 51.3427 42.0408 51.2917C42.0319 51.2407 42.0351 51.1883 42.0502 51.1388L44.645 42.2288C44.6653 42.1594 44.7076 42.0986 44.7656 42.0555C44.8236 42.0124 44.894 41.9894 44.9662 41.9899Z'
						fill='#1B47B4'
					/>
					<path
						d='M45.0919 42.3167L51.8438 42.4759L49.1776 50.8807L42.4258 50.7187L45.0919 42.3167Z'
						fill='#E0E0E0'
						fillOpacity='0.13'
					/>
					<path
						d='M44.8808 42.2344L51.6326 42.2618L49.1312 50.7161L42.3794 50.6886L44.8808 42.2344Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M38.7907 42.4104C38.7907 42.685 38.8319 43.009 38.8539 43.3138C38.8758 43.6185 38.917 43.937 38.9582 44.2473C39.0351 44.8679 39.1376 45.4793 39.2657 46.0815C39.3889 46.6686 39.5522 47.2466 39.7545 47.8113C39.8561 48.0859 39.9687 48.3605 40.0895 48.6021C40.1499 48.7284 40.2158 48.8465 40.2817 48.9673L40.3833 49.1348C40.5093 49.2495 40.6581 49.3365 40.8198 49.3902C41.0769 49.4899 41.3413 49.5697 41.6106 49.629C42.2169 49.757 42.8303 49.8487 43.4475 49.9036C44.7161 50.0272 46.0423 50.0876 47.3383 50.115L47.4701 51.4303C46.1206 51.698 44.7513 51.8532 43.3762 51.8943C42.662 51.9164 41.9472 51.887 41.2372 51.8064C40.8482 51.7588 40.4635 51.6808 40.0867 51.573C39.605 51.4455 39.157 51.214 38.7742 50.8948C38.6461 50.7839 38.5301 50.6597 38.4283 50.5242L38.2525 50.277C38.14 50.1123 38.0301 49.9475 37.9368 49.7801C37.7411 49.4535 37.5677 49.1141 37.4178 48.7641C37.1247 48.093 36.8877 47.3987 36.7094 46.6883C36.5317 45.9978 36.395 45.2973 36.3003 44.5905C36.2536 44.2418 36.2179 43.8904 36.1877 43.5362C36.1575 43.182 36.1383 42.8442 36.1328 42.4379L38.7907 42.4104Z'
						fill='#7F3E3B'
					/>
					<path
						d='M37.8879 38.3601C39.6177 39.9883 39.2004 44.2305 39.2004 44.2305L35.889 44.8099C35.889 44.8099 33.8434 41.7676 34.5628 40.4935C35.3096 39.1646 36.9543 37.4787 37.8879 38.3601Z'
						fill='#1B47B4'
					/>
					<path
						d='M39.2008 44.6038C39.15 44.6047 39.0996 44.5955 39.0524 44.5766C39.0053 44.5577 38.9623 44.5297 38.9262 44.494C38.9085 44.4766 38.8928 44.4573 38.8795 44.4363C38.8669 44.4162 38.8559 44.3951 38.8466 44.3732C38.841 44.347 38.8373 44.3203 38.8356 44.2936C38.8341 44.2698 38.8341 44.2459 38.8356 44.2222C38.8339 44.1715 38.8425 44.1211 38.8609 44.0739C38.8793 44.0267 38.9071 43.9837 38.9427 43.9476C38.9606 43.9306 38.9799 43.915 39.0003 43.9009C39.0199 43.8872 39.0411 43.8762 39.0635 43.868C39.0864 43.8582 39.1104 43.8508 39.1349 43.846C39.1888 43.8355 39.2444 43.837 39.2977 43.8505C39.351 43.864 39.4007 43.889 39.4432 43.9239C39.4857 43.9588 39.52 44.0026 39.5436 44.0523C39.5671 44.102 39.5795 44.1562 39.5797 44.2112C39.5825 44.2349 39.5825 44.2589 39.5797 44.2826C39.5753 44.3072 39.5679 44.3312 39.5577 44.354C39.5495 44.3764 39.5384 44.3976 39.5248 44.4171C39.5114 44.438 39.4958 44.4574 39.4781 44.4748C39.4435 44.5145 39.4009 44.5465 39.3531 44.5688C39.3054 44.591 39.2534 44.6029 39.2008 44.6038Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M36.1913 44.8017C36.1829 44.703 36.2141 44.6051 36.2779 44.5294C36.3418 44.4537 36.4331 44.4065 36.5318 44.3981C36.6005 44.3917 36.6697 44.4048 36.7313 44.4359C36.793 44.4669 36.8447 44.5147 36.8805 44.5738C36.9026 44.5084 36.9422 44.4504 36.9949 44.4058C37.0477 44.3613 37.1116 44.332 37.1798 44.3212C37.2483 44.312 37.318 44.3217 37.3814 44.3493C37.4448 44.3769 37.4995 44.4213 37.5395 44.4777C37.5551 44.4109 37.5891 44.3498 37.6376 44.3012C37.6861 44.2527 37.7472 44.2187 37.814 44.2031C37.8818 44.1889 37.9522 44.194 38.0172 44.2178C38.0823 44.2416 38.1393 44.2831 38.182 44.3377C38.1981 44.2711 38.2323 44.2103 38.2808 44.1619C38.3292 44.1135 38.39 44.0793 38.4565 44.0631C38.5045 44.0504 38.5546 44.0475 38.6037 44.0545C38.6528 44.0615 38.7 44.0783 38.7425 44.1039C38.785 44.1296 38.822 44.1634 38.8511 44.2036C38.8803 44.2437 38.9011 44.2893 38.9123 44.3377C38.9246 44.3856 38.9273 44.4356 38.9201 44.4846C38.9129 44.5335 38.896 44.5806 38.8705 44.623C38.8449 44.6655 38.8112 44.7024 38.7712 44.7316C38.7313 44.7609 38.6859 44.7819 38.6378 44.7935C38.5677 44.8115 38.4938 44.8089 38.4252 44.786C38.3565 44.7631 38.2959 44.7209 38.2506 44.6644C38.2351 44.7355 38.1994 44.8005 38.1478 44.8517C38.0961 44.9028 38.0307 44.9379 37.9596 44.9527C37.8883 44.966 37.8147 44.9586 37.7476 44.9313C37.6804 44.9041 37.6225 44.8581 37.5806 44.799C37.5625 44.8696 37.5239 44.9334 37.4697 44.9822C37.4155 45.0311 37.348 45.0628 37.2759 45.0735C37.2039 45.0839 37.1306 45.073 37.0647 45.0423C36.9989 45.0116 36.9434 44.9623 36.9052 44.9005C36.8817 44.9691 36.8388 45.0295 36.7818 45.0743C36.7249 45.1191 36.6561 45.1465 36.5839 45.1532H36.5537C36.4605 45.15 36.3718 45.1122 36.3048 45.0473C36.2378 44.9823 36.1974 44.8948 36.1913 44.8017Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M35.8891 45.1835C35.8644 45.1862 35.8396 45.1862 35.8149 45.1835L35.7463 45.1615C35.7235 45.1522 35.7015 45.1412 35.6804 45.1286L35.6255 45.0819C35.607 45.0652 35.5912 45.0458 35.5788 45.0242C35.5653 45.0041 35.5533 44.983 35.5431 44.9611C35.5341 44.938 35.5277 44.9141 35.5239 44.8897C35.521 44.866 35.521 44.842 35.5239 44.8183C35.5212 44.7937 35.5212 44.7688 35.5239 44.7442C35.5277 44.7206 35.5341 44.6976 35.5431 44.6755C35.5431 44.6536 35.5651 44.6316 35.5788 44.6096C35.592 44.5894 35.6077 44.571 35.6255 44.5547L35.6804 44.508C35.7009 44.4943 35.723 44.4833 35.7463 44.4751C35.7681 44.4648 35.7912 44.4574 35.8149 44.4531C35.8748 44.4409 35.9368 44.4438 35.9953 44.4615C36.0537 44.4793 36.1069 44.5113 36.1499 44.5547C36.1677 44.571 36.1834 44.5894 36.1966 44.6096C36.2103 44.6316 36.2213 44.6536 36.2323 44.6755C36.2408 44.698 36.2482 44.7209 36.2542 44.7442C36.2556 44.7689 36.2556 44.7936 36.2542 44.8183C36.2557 44.8421 36.2557 44.8659 36.2542 44.8897C36.2482 44.9139 36.2408 44.9377 36.2323 44.9611C36.222 44.983 36.2101 45.0041 36.1966 45.0242C36.1834 45.0453 36.1677 45.0646 36.1499 45.0819C36.0793 45.1481 35.9858 45.1844 35.8891 45.1835Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M50.4227 48.7472L49.275 48.6703C48.9892 48.6502 48.7049 48.7265 48.4677 48.8872L46.3617 50.3013L46.3452 51.1936C47.0207 51.9789 48.5144 51.276 48.5144 51.276L49.901 51.2293C50.0115 51.2265 50.1186 51.1904 50.2082 51.1257C50.2979 51.0611 50.3659 50.9709 50.4035 50.8669L50.9059 49.494C50.9373 49.4131 50.949 49.3258 50.9402 49.2395C50.9313 49.1531 50.9021 49.0701 50.8549 48.9972C50.8078 48.9243 50.744 48.8637 50.6689 48.8202C50.5937 48.7767 50.5094 48.7517 50.4227 48.7472Z'
						fill='#7F3E3B'
					/>
					<path
						d='M26.641 87.7897H26.9513C27.0156 87.7834 27.0757 87.7546 27.1209 87.7084C27.1661 87.6622 27.1935 87.6016 27.1984 87.5371L29.1204 74.2366H27.6679L26.4021 87.4492C26.3946 87.4874 26.3949 87.5266 26.403 87.5646C26.4111 87.6026 26.4267 87.6386 26.449 87.6704C26.4713 87.7022 26.4998 87.7291 26.5328 87.7496C26.5658 87.7701 26.6026 87.7838 26.641 87.7897Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M26.641 87.7897H26.9513C27.0156 87.7834 27.0757 87.7546 27.1209 87.7084C27.1661 87.6622 27.1935 87.6016 27.1984 87.5371L29.1204 74.2366H27.6679L26.4021 87.4492C26.3946 87.4874 26.3949 87.5266 26.403 87.5646C26.4111 87.6026 26.4267 87.6386 26.449 87.6704C26.4713 87.7022 26.4998 87.7291 26.5328 87.7496C26.5658 87.7701 26.6026 87.7838 26.641 87.7897Z'
						fill='black'
					/>
					<path
						d='M22.6598 87.7897H22.9701C23.0344 87.7834 23.0945 87.7546 23.1397 87.7084C23.1849 87.6622 23.2123 87.6016 23.2172 87.5371L25.1392 74.2366H23.6867L22.4209 87.4492C22.4134 87.4874 22.4137 87.5266 22.4218 87.5646C22.4299 87.6026 22.4455 87.6386 22.4678 87.6704C22.4901 87.7022 22.5186 87.7291 22.5516 87.7496C22.5846 87.7701 22.6214 87.7838 22.6598 87.7897Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M22.6598 87.7897H22.9701C23.0344 87.7834 23.0945 87.7546 23.1397 87.7084C23.1849 87.6622 23.2123 87.6016 23.2172 87.5371L25.1392 74.2366H23.6867L22.4209 87.4492C22.4134 87.4874 22.4137 87.5266 22.4218 87.5646C22.4299 87.6026 22.4455 87.6386 22.4678 87.6704C22.4901 87.7022 22.5186 87.7291 22.5516 87.7496C22.5846 87.7701 22.6214 87.7838 22.6598 87.7897Z'
						fill='black'
					/>
					<path
						d='M44.8454 87.7897H44.5324C44.4685 87.7828 44.4091 87.7538 44.3645 87.7076C44.3198 87.6615 44.2928 87.6011 44.288 87.5371L42.366 74.2366H43.8157L45.0815 87.4492C45.089 87.4872 45.0888 87.5262 45.081 87.564C45.0731 87.6019 45.0577 87.6377 45.0356 87.6695C45.0136 87.7013 44.9854 87.7283 44.9527 87.7489C44.92 87.7696 44.8835 87.7834 44.8454 87.7897Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M44.8454 87.7897H44.5324C44.4685 87.7828 44.4091 87.7538 44.3645 87.7076C44.3198 87.6615 44.2928 87.6011 44.288 87.5371L42.366 74.2366H43.8157L45.0815 87.4492C45.089 87.4872 45.0888 87.5262 45.081 87.564C45.0731 87.6019 45.0577 87.6377 45.0356 87.6695C45.0136 87.7013 44.9854 87.7283 44.9527 87.7489C44.92 87.7696 44.8835 87.7834 44.8454 87.7897Z'
						fill='black'
					/>
					<path
						d='M48.8268 87.7897H48.5138C48.45 87.7828 48.3906 87.7538 48.3459 87.7076C48.3013 87.6615 48.2742 87.6011 48.2695 87.5371L46.3474 74.2366H47.7972L49.063 87.4492C49.0705 87.4872 49.0703 87.5262 49.0624 87.564C49.0545 87.6019 49.0391 87.6377 49.0171 87.6695C48.995 87.7013 48.9668 87.7283 48.9342 87.7489C48.9015 87.7696 48.865 87.7834 48.8268 87.7897Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.3'
						d='M48.8268 87.7897H48.5138C48.45 87.7828 48.3906 87.7538 48.3459 87.7076C48.3013 87.6615 48.2742 87.6011 48.2695 87.5371L46.3474 74.2366H47.7972L49.063 87.4492C49.0705 87.4872 49.0703 87.5262 49.0624 87.564C49.0545 87.6019 49.0391 87.6377 49.0171 87.6695C48.995 87.7013 48.9668 87.7283 48.9342 87.7489C48.9015 87.7696 48.865 87.7834 48.8268 87.7897Z'
						fill='black'
					/>
					<path
						d='M53.986 58.4701H44.211C42.4757 52.4459 36.2483 47.6957 29.8451 47.6957H26.671C19.9823 47.6957 15.666 52.9126 17.0279 59.3213C17.2857 60.5022 17.7042 61.6423 18.2717 62.7096L19.8148 72.0452C20.0125 73.2506 20.9763 74.2281 21.962 74.2281H51.3857C52.3714 74.2281 53.3352 73.2506 53.5329 72.0452L55.4138 60.6447C55.6142 59.4476 54.9744 58.4701 53.986 58.4701Z'
						fill='#1B47B4'
					/>
					<path
						opacity='0.5'
						d='M53.986 58.4701H44.211C42.4757 52.4459 36.2483 47.6957 29.8451 47.6957H26.671C19.9823 47.6957 15.666 52.9126 17.0279 59.3213C17.2857 60.5022 17.7042 61.6423 18.2717 62.7096L19.8148 72.0452C20.0125 73.2506 20.9763 74.2281 21.962 74.2281H51.3857C52.3714 74.2281 53.3352 73.2506 53.5329 72.0452L55.4138 60.6447C55.6142 59.4476 54.9744 58.4701 53.986 58.4701Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M51.3919 23.4685H49.4182V23.7101H51.3919V23.4685Z'
						fill='#1B47B4'
					/>
					<path
						d='M42.1593 26.3143H41.9177V25.1234C41.9184 24.6836 42.0935 24.2621 42.4047 23.9514C42.7158 23.6407 43.1376 23.4662 43.5773 23.4662H47.9812V23.7078H43.5773C43.2017 23.7078 42.8413 23.8568 42.5755 24.1222C42.3096 24.3876 42.1599 24.7477 42.1593 25.1234V26.3143Z'
						fill='#1B47B4'
					/>
					<path
						d='M43.5775 24.3141H65.6016C65.8169 24.3141 66.0233 24.3996 66.1756 24.5518C66.3278 24.704 66.4133 24.9105 66.4133 25.1258V33.762C66.4133 33.9779 66.3275 34.1849 66.1748 34.3376C66.0222 34.4903 65.8151 34.5761 65.5992 34.5761H43.5775C43.3616 34.5761 43.1545 34.4903 43.0019 34.3376C42.8492 34.1849 42.7634 33.9779 42.7634 33.762V25.1234C42.7637 25.0168 42.7851 24.9113 42.8261 24.8129C42.8672 24.7146 42.9273 24.6253 43.0029 24.5501C43.0785 24.475 43.1681 24.4154 43.2667 24.375C43.3653 24.3345 43.4709 24.3138 43.5775 24.3141Z'
						fill='#1B47B4'
					/>
					<path
						d='M64.5595 29.9913H44.6177C44.4729 29.9913 44.3341 29.9338 44.2317 29.8314C44.1293 29.729 44.0718 29.5902 44.0718 29.4454C44.0718 29.3006 44.1293 29.1617 44.2317 29.0593C44.3341 28.9569 44.4729 28.8994 44.6177 28.8994H64.5595C64.7043 28.8994 64.8431 28.9569 64.9455 29.0593C65.0479 29.1617 65.1054 29.3006 65.1054 29.4454C65.1054 29.5902 65.0479 29.729 64.9455 29.8314C64.8431 29.9338 64.7043 29.9913 64.5595 29.9913Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M60.2909 32.2593H44.6177C44.473 32.2593 44.3341 32.2018 44.2317 32.0994C44.1293 31.997 44.0718 31.8582 44.0718 31.7134C44.0712 31.6413 44.0848 31.5698 44.112 31.503C44.1391 31.4362 44.1792 31.3754 44.23 31.3242C44.2807 31.273 44.3411 31.2324 44.4077 31.2046C44.4743 31.1769 44.5456 31.1626 44.6177 31.1626H60.2909C60.4357 31.1626 60.5746 31.2201 60.677 31.3225C60.7794 31.4249 60.8369 31.5638 60.8369 31.7085C60.8375 31.7806 60.8239 31.8522 60.7967 31.919C60.7696 31.9858 60.7294 32.0465 60.6787 32.0977C60.6279 32.1489 60.5675 32.1896 60.501 32.2173C60.4344 32.2451 60.363 32.2593 60.2909 32.2593Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M51.3431 27.7229H44.6177C44.4729 27.7229 44.3341 27.6654 44.2317 27.563C44.1293 27.4606 44.0718 27.3217 44.0718 27.1769C44.0718 27.0321 44.1293 26.8933 44.2317 26.7909C44.3341 26.6885 44.4729 26.631 44.6177 26.631H51.3431C51.4879 26.631 51.6267 26.6885 51.7291 26.7909C51.8315 26.8933 51.889 27.0321 51.889 27.1769C51.889 27.3217 51.8315 27.4606 51.7291 27.563C51.6267 27.6654 51.4879 27.7229 51.3431 27.7229Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M60.2579 27.7229H53.5325C53.3877 27.7229 53.2489 27.6654 53.1465 27.563C53.0441 27.4606 52.9866 27.3217 52.9866 27.1769C52.9866 27.0321 53.0441 26.8933 53.1465 26.7909C53.2489 26.6885 53.3877 26.631 53.5325 26.631H60.2579C60.4027 26.631 60.5415 26.6885 60.6439 26.7909C60.7463 26.8933 60.8038 27.0321 60.8038 27.1769C60.8038 27.3217 60.7463 27.4606 60.6439 27.563C60.5415 27.6654 60.4027 27.7229 60.2579 27.7229Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M44.0718 33.9215V36.5377C44.0718 36.6499 44.103 36.7598 44.162 36.8552C44.2209 36.9506 44.3053 37.0277 44.4056 37.0779C44.5059 37.128 44.6182 37.1493 44.7299 37.1392C44.8416 37.1291 44.9483 37.0881 45.0381 37.0208L49.0482 33.9191L44.0718 33.9215Z'
						fill='#1B47B4'
					/>
					<path
						d='M78.0301 11.3541H56.006C55.8991 11.3541 55.7932 11.3752 55.6944 11.4161C55.5957 11.457 55.5059 11.517 55.4303 11.5926C55.3547 11.6682 55.2948 11.7579 55.2539 11.8567C55.2129 11.9555 55.1919 12.0613 55.1919 12.1682V20.8044C55.1919 21.0203 55.2777 21.2274 55.4303 21.3801C55.583 21.5327 55.7901 21.6185 56.006 21.6185H73.3992L76.5541 24.0584C76.6438 24.1257 76.7505 24.1667 76.8622 24.1767C76.9739 24.1868 77.0862 24.1656 77.1865 24.1154C77.2868 24.0652 77.3712 23.9881 77.4302 23.8927C77.4891 23.7973 77.5204 23.6874 77.5204 23.5752V21.6137H78.0156C78.2315 21.6137 78.4386 21.5279 78.5912 21.3752C78.7439 21.2226 78.8297 21.0155 78.8297 20.7996V12.1682C78.8297 11.9548 78.7459 11.7499 78.5964 11.5976C78.4468 11.4454 78.2435 11.3579 78.0301 11.3541Z'
						fill='white'
						fillOpacity='0.3'
					/>
					<path
						d='M76.9289 24.3071C76.7682 24.3061 76.6121 24.2527 76.4844 24.1549L73.3633 21.7392H56.0064C55.7587 21.7386 55.5212 21.6399 55.3461 21.4647C55.1709 21.2895 55.0722 21.0521 55.0715 20.8043V12.1682C55.0722 11.9204 55.1709 11.683 55.3461 11.5078C55.5212 11.3326 55.7587 11.2339 56.0064 11.2333H78.0305C78.2783 11.2339 78.5157 11.3326 78.6909 11.5078C78.866 11.683 78.9647 11.9204 78.9654 12.1682V20.8043C78.9647 21.0521 78.866 21.2895 78.6909 21.4647C78.5157 21.6399 78.2783 21.7386 78.0305 21.7392H77.6561V23.58C77.6561 23.7722 77.5797 23.9565 77.4438 24.0925C77.3079 24.2284 77.1236 24.3047 76.9314 24.3047L76.9289 24.3071ZM56.0064 11.4748C55.8227 11.4755 55.6468 11.5487 55.5169 11.6786C55.387 11.8085 55.3137 11.9845 55.3131 12.1682V20.8043C55.3137 20.988 55.387 21.164 55.5169 21.2939C55.6468 21.4238 55.8227 21.497 56.0064 21.4977H73.4479L76.627 23.9617C76.6984 24.0181 76.7842 24.0532 76.8747 24.063C76.9651 24.0727 77.0565 24.0568 77.1383 24.0169C77.2201 23.977 77.2889 23.9148 77.3369 23.8375C77.385 23.7603 77.4102 23.671 77.4097 23.58V21.5001H78.0257C78.2094 21.4994 78.3853 21.4262 78.5152 21.2963C78.6451 21.1664 78.7183 20.9904 78.719 20.8068V12.1682C78.7183 11.9845 78.6451 11.8085 78.5152 11.6786C78.3853 11.5487 78.2094 11.4755 78.0257 11.4748H56.0064Z'
						fill='#1B47B4'
					/>
					<path
						d='M66.5486 17.0335H76.9894C77.0611 17.0335 77.1321 17.0193 77.1983 16.9919C77.2645 16.9645 77.3247 16.9242 77.3754 16.8736C77.4261 16.8229 77.4663 16.7627 77.4938 16.6964C77.5212 16.6302 77.5353 16.5592 77.5353 16.4875C77.5341 16.3433 77.476 16.2054 77.3739 16.1037C77.2717 16.002 77.1336 15.9446 76.9894 15.944H66.5486C66.4036 15.9446 66.2648 16.0027 66.1624 16.1054C66.0601 16.2082 66.0027 16.3473 66.0027 16.4923C66.004 16.6363 66.062 16.7739 66.1643 16.8753C66.2665 16.9766 66.4047 17.0335 66.5486 17.0335Z'
						fill='#1B47B4'
					/>
					<path
						d='M59.2579 17.0335H64.4275C64.4992 17.0335 64.5702 17.0193 64.6364 16.9919C64.7027 16.9645 64.7628 16.9242 64.8135 16.8736C64.8642 16.8229 64.9045 16.7627 64.9319 16.6964C64.9593 16.6302 64.9735 16.5592 64.9735 16.4875C64.9722 16.3433 64.9142 16.2054 64.812 16.1037C64.7098 16.002 64.5717 15.9446 64.4275 15.944H59.2579C59.1129 15.9446 58.974 16.0027 58.8717 16.1054C58.7694 16.2082 58.7119 16.3473 58.7119 16.4923C58.7132 16.6363 58.7713 16.7739 58.8735 16.8753C58.9758 16.9766 59.1139 17.0335 59.2579 17.0335Z'
						fill='#1B47B4'
					/>
					<path
						d='M61.3139 19.3019L76.9894 19.3019C77.291 19.3019 77.5354 19.0574 77.5354 18.7559C77.5354 18.4544 77.291 18.21 76.9894 18.21L61.3139 18.21C61.0123 18.21 60.7679 18.4544 60.7679 18.7559C60.7679 19.0574 61.0123 19.3019 61.3139 19.3019Z'
						fill='#1B47B4'
					/>
					<path
						d='M57.0591 14.7647H76.9888C77.1336 14.7647 77.2725 14.7072 77.3748 14.6048C77.4772 14.5024 77.5348 14.3636 77.5348 14.2188C77.5348 14.0738 77.4773 13.9346 77.375 13.8319C77.2727 13.7291 77.1338 13.671 76.9888 13.6704H57.0591C56.9141 13.671 56.7753 13.7291 56.6729 13.8319C56.5706 13.9346 56.5132 14.0738 56.5132 14.2188C56.5132 14.3636 56.5707 14.5024 56.6731 14.6048C56.7755 14.7072 56.9143 14.7647 57.0591 14.7647Z'
						fill='#1B47B4'
					/>
				</svg>
			),
		},
	];

	return (
		<>
			<div className='bg-[#052B85] w-full flex flex-col justify-center items-center py-[4rem] lg:py-[6.31rem]'>
				<span className='text-center p-[.75rem] lg:p-0 heading_heading_main'>
					The National Employment Authority Job Portal{' '}
					<br className='hidden xl:block' /> for Job Seekers,
					Employment Agencies & Employers.
				</span>

				<div className='mt-8 xl:mt-[4.56rem] w-full p-[.75rem] lg:p-0'>
					<div className='flex justify-center gap-[1.5rem] pb-5 px-[1rem] xl:px-[5.06rem] xl:min-w-[81.75rem] w-full max-w-full'>
						<Splide
							options={{
								rewind: true,
								gap: '1.5rem',
								type: 'slide',
								perPage: 4,
								rewindByDrag: true,
								width: '100%',
								pagination: true,
								drag: true,
								direction: 'ltr',
								releaseWheel: false,
								perMove: 1,
								keyboard: 'global',
								breakpoints: {
									1440: {
										perPage: 3,
									},
									1023: {
										perPage: 3,
									},
									991: {
										perPage: 3,
									},
									990: {
										perPage: 2,
									},
									640: {
										perPage: 1,
									},
								},
								trimSpace: true,
							}}
							aria-label='Landing page cards'>
							{cardInfo.map((item, index) => {
								return (
									<SplideSlide key={index}>
										<button
											onClick={() => {
												if (item?.key === 1) {
													navigate('/distress-type');
												} else if (item?.key === 2) {
													navigate(
														'/home/jobs?type=LOCAL'
													);
												} else if (item?.key === 3) {
													navigate(
														'/home/jobs?type=ABROAD'
													);
												} else if (item?.key === 4) {
													navigate(
														'/home/jobs?type=GOVERNMENT'
													);
												} else if (item?.key === 5) {
													navigate('/migrant-worker');
												} else {
													navigate('/find-counselor');
												}
											}}
											className='dashboard_card'
											key={item?.key}
											type='button'>
											<div className='w-full flex flex-col items-center justify-center gap-y-[.63rem]'>
												<div className='h-[7.1875rem] '>
													{item.icon}
												</div>
												<div className='flex items-center justify-center w-full'>
													<div className='flex items-center'>
														<span className='dashboard_card_title flex justify-center items-center w-full flex-shrink-0'>
															{item?.title}
														</span>
														<div className=''>
															<div className='dashboard_card_svg'>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='15'
																	height='15'
																	viewBox='0 0 15 15'
																	fill='none'>
																	<path
																		d='M5.29419 2.64709L9.70595 7.05886L5.29419 11.4706'
																		stroke='white'
																		strokeWidth='2'
																		strokeLinecap='round'
																		strokeLinejoin='round'
																	/>
																</svg>
															</div>
														</div>
													</div>
												</div>
											</div>
										</button>
									</SplideSlide>
								);
							})}
						</Splide>
					</div>
				</div>

				<div className='flex items-center mt-[2.97rem] px-[1rem] xl:px-0'>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://play.google.com/store/apps?hl=en_US'>
						<img
							src={home7}
							className='w-[10.75rem] h-[3.125rem] object-none'
							alt='store icon'
						/>
					</a>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://play.google.com/store/apps?hl=en_US'>
						<img
							src={home8}
							className='w-[10.75rem] h-[3.125rem] object-none ml-[.5rem]'
							alt='store icon'
						/>
					</a>
				</div>
			</div>
		</>
	);
}
