import { useDispatch } from 'react-redux';
import {
	handleNextStep,
	handlePrevStep,
	handleRegNextStep,
	handleRegPrevStep,
} from '../../../../features/profile/profileSlice';
import SkillsForm from './skills_form.js/SkillsForm';

export default function SkillInfo({ buildprofile }) {
	const dispatch = useDispatch();

	function handleGoNext() {
		dispatch(handleRegNextStep());
	}

	function handleProfileGoNext() {
		dispatch(handleNextStep());
	}

	function handleGoBack() {
		dispatch(handleRegPrevStep());
	}

	function handleProfileGoBack() {
		dispatch(handlePrevStep());
	}

	return (
		<>
			<div className='flex justify-center items-center w-full'>
				<div
					style={{
						borderRadius: '0.5rem',
						border: '1px solid #147CBC',
					}}
					className='mt-10 p-3 xl:p-[2.75rem] !w-full xl:!w-[80.875rem] bg-white'>
					<div className='flex justify-center items-center pt-[3.56rem]'>
						<div
							style={{
								background:
									'linear-gradient(180deg, rgba(0, 0, 0, 0.00) -50%, #1844B5 50%)',
							}}
							className='w-[6.25rem] h-[6.25rem] rounded-full p-[1.81rem]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='42'
								height='42'
								viewBox='0 0 42 42'
								fill='none'>
								<path
									d='M41.2355 18.3749L30.1678 7.23174C27.212 4.28178 23.2065 2.625 19.0305 2.625C14.8544 2.625 10.8489 4.28178 7.89307 7.23174L7.8783 7.2465L5.61916 9.58111C5.38514 9.83261 5.25905 10.1658 5.26796 10.5093C5.27687 10.8527 5.42008 11.1789 5.66683 11.4179C5.91358 11.657 6.24421 11.7897 6.58774 11.7877C6.93127 11.7857 7.26032 11.649 7.50424 11.4071L9.75682 9.08072C10.6631 8.17464 11.6973 7.40631 12.8264 6.80025L20.4553 14.4374L4.70534 30.1874C4.46152 30.4311 4.26811 30.7205 4.13615 31.039C4.0042 31.3576 3.93628 31.699 3.93628 32.0437C3.93628 32.3885 4.0042 32.7299 4.13615 33.0484C4.26811 33.3669 4.46152 33.6563 4.70534 33.9001L8.09979 37.2945C8.34355 37.5384 8.63296 37.7318 8.95147 37.8637C9.26999 37.9957 9.61139 38.0636 9.95616 38.0636C10.3009 38.0636 10.6423 37.9957 10.9608 37.8637C11.2794 37.7318 11.5688 37.5384 11.8125 37.2945L27.5625 21.5445L29.9152 23.8972L32.8125 26.7913C33.0563 27.0351 33.3457 27.2285 33.6642 27.3604C33.9827 27.4924 34.3241 27.5603 34.6689 27.5603C35.0137 27.5603 35.3551 27.4924 35.6736 27.3604C35.9921 27.2285 36.2815 27.0351 36.5253 26.7913L41.2322 22.0843C41.7241 21.5926 42.0007 20.9258 42.0013 20.2303C42.0019 19.5348 41.7265 18.8675 41.2355 18.3749ZM9.95534 35.4374L6.56252 32.0429L17.7188 20.8867L21.1116 24.2811L9.95534 35.4374ZM26.6339 18.7555L22.9688 22.4223L19.5743 19.0311L23.2411 15.3643C23.3631 15.2424 23.46 15.0977 23.526 14.9383C23.5921 14.779 23.6261 14.6082 23.6261 14.4357C23.6261 14.2632 23.5921 14.0924 23.526 13.9331C23.46 13.7738 23.3631 13.629 23.2411 13.5071L15.4744 5.74205C17.7183 5.11007 20.0901 5.08716 22.3458 5.67568C24.6016 6.26419 26.6598 7.44288 28.309 9.09056L35.5639 16.393L30.8438 21.1081L28.4911 18.7555C28.3692 18.6335 28.2245 18.5366 28.0651 18.4706C27.9058 18.4045 27.735 18.3705 27.5625 18.3705C27.39 18.3705 27.2192 18.4045 27.0599 18.4706C26.9006 18.5366 26.7558 18.6335 26.6339 18.7555ZM34.673 24.9341L32.7042 22.9653L37.4063 18.2518L39.375 20.2206L34.673 24.9341Z'
									fill='white'
								/>
							</svg>
						</div>
					</div>
					<h3 className='heading_heading_2 w-full text-center mt-[2rem]'>
						<span>Skills</span>
					</h3>

					<SkillsForm />

					<div className='mt-[20rem]'></div>

					<div className='flex flex-col justify-center items-center w-full'>
						<button
							onClick={buildprofile ? handleProfileGoNext : handleGoNext}
							className='bg-blueDark rounded-[35px] text-white w-[15rem] p-3'
							type='button'>
							Next
						</button>
						<button
							onClick={buildprofile ? handleProfileGoBack : handleGoBack}
							className='bg-white rounded-[35px] text-blueDark border border-blueDark w-[15rem] p-2 my-3'
							type='button'>
							Back
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
