import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import backArrow from '../../assets/Back.svg';
import circleSvg from '../../assets/CheckCircle.svg';
import PasswordChecklist from 'react-password-checklist';
import {
	cleanSignupEmployerDetails,
	clearUserDetails,
	handleChooseAccount,
	handleLoginSession,
	handleUserToken,
	login,
	UpdateUserAdminPassword,
} from '../../features/auth/authSlice';
import { customToast } from '../../notifications';
import { fetchPasswordSetups } from '../../features/global/globalSlice';
import Header from '../components/signup/Header';

function PasswordAgency() {
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { authLoading, userDetails, institution } = useSelector((state) => state.auth);
	const { pwdSetup } = useSelector((state) => state.global);

	const [checkValue, setcheckValue] = useState(false);

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	let pswrdRules = [];

	let pwdMsg = {};

	pwdSetup?.forEach((item) => {
		pswrdRules.push(item?.pwdDesc);
		pwdMsg[item.pwdDesc] = item?.pwdName;
	});

	async function handleFetchPwdSetup() {
		await dispatch(fetchPasswordSetups());
	}

	function handlePasswordCheck(values) {
		setcheckValue(values);
	}

	const onFinish = async () => {
		let usrObj = {
			usrId: userDetails?.usrId,
			usrNationalId: userDetails?.usrNationalId,
			usrInstId: institution?.jsonData?.instId,
			usrEncryptedPassword: password,
		};		

		const res = await dispatch(UpdateUserAdminPassword(usrObj));

		if (res?.payload?.success) {
			const resp = await dispatch(
				login({
					usrUsername: res?.payload?.jsonData?.usrNationalId,
					usrEncryptedPassword: password,
				})
			);

			if (resp?.payload?.success) {
				await customToast({
					content: 'Account created successfully',
					bdColor: 'success',
				});
				await dispatch(handleChooseAccount(true))
				await dispatch(handleLoginSession(true))
				await dispatch(handleUserToken(''));
				await dispatch(cleanSignupEmployerDetails());
				await dispatch(clearUserDetails())
				await navigate('/accounts');
			} else {
				await customToast({
					content: resp?.payload?.messages?.message,
					bdColor: 'error',
				});
			}
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
			});
		}
	};

	const handleBack = () => {
		navigate(-1);
	};

	useEffect(() => {}, [pwdSetup]);

	useEffect(() => {
		handleFetchPwdSetup();
	}, []);

	return (
		<>
			<div className='sticky top-0 w-full z-[1000]'>
				<Header />
			</div>
			<div className=''>
				<div className='lg:w-[1718px] w-auto h-[15px] bg-blueDark'></div>
				<div
					className='cursor-pointer mt-[30px] lg:pl-[190px] pl-12 text-neutral-800 text-[22px] font-bold font-dmSans leading-relaxed 
        flex items-center'>
					<div className='flex items-center'>
						<button
							style={{
								height: '40px',
								width: '40px',
								background: '#fff',
								borderRadius: '50%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							type='button'
							onClick={handleBack}>
							<img
								loading='lazy'
								src={backArrow}
								className='bg-[#EDF8FF] rounded-full p-1'
								style={{ height: '40px', width: '40px' }}
								alt='backarror'
							/>

							<span className='ml-3'>Previous</span>
						</button>
					</div>
				</div>
			</div>

			<div className='text-center text-black1 text-4xl font-bold font-dmSans'>
				Lastly, let’s keep your account secure.
			</div>
			<div className='text-center text-neutral-800 text-[22px] font-normal font-dmSans leading-relaxed'>
				Create a password to your account 
			</div>

			<div
				className='lg:p-0 p-3 mt-10'
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: '100%',
					}}
					form={form}>
					<div className='lg:w-[670px] w-auto grid grid-cols-1'>
						<Form.Item
							name='password'
							label='Password'
							rules={[
								{
									required: true,
									message: 'Please add password',
								},
							]}>
							<Input.Password
								className='input'
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Form.Item>

						<Form.Item
							name='confirmPassword'
							label='Confirm Password'
							rules={[
								{
									required: true,
									message: 'Please add confirm password',
								},
							]}>
							<Input.Password
								className='input'
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
						</Form.Item>

						<PasswordChecklist
							onChange={(val) => handlePasswordCheck(val)}
							className='text-blueDark'
							rules={pswrdRules}
							minLength={8}
							value={password}
							valueAgain={confirmPassword}
							messages={{
								minLength: 'A minimum of 8 characters',
								specialChar: 'At least one symbol (~!@$&)',
								number: 'At least one number (0-9)',
								capital: 'At least one upper case letter (A-Z)',
								match: 'Confirm password to match with the password',
							}}
							iconComponents={{
								ValidIcon: (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth='1.5'
										stroke='currentColor'
										className='w-6 h-6 text-blueDark'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
										/>
									</svg>
								),
								InvalidIcon: <img loading='lazy' src={circleSvg} />,
							}}
							validColor='#147CBC'
							iconSize={2}
						/>

						<div className='lg:w-[638px] w-auto mt-[54px]'>
							<span className='text-neutral-800 text-[22px] font-normal font-dmSans leading-relaxed'>
								By clicking “Create account”, and creating an account, you agree
								to NEA’s{' '}
							</span>
							<Link
								to='/nea-privacy-policy'
								className='text-neutral-800 text-[22px] font-bold font-dmSans underline leading-relaxed'>
								Privacy Policy
							</Link>
							<span className='text-neutral-800 text-[22px] font-normal font-dmSans leading-relaxed'>
								{' '}
								and{' '}
							</span>
							<Link
								to='/agency/terms-of-service'
								className='text-neutral-800 text-[22px] font-bold font-dmSans underline leading-relaxed'>
								Terms of Service
							</Link>
						</div>
					</div>

					<div className='text-center mt-[40px] mb-14'>
						<button
							type='submit'
							disabled={authLoading === true || checkValue === false}
							className='text-white text-[18px] font-bold font-dmSans
                w-[299px] h-[53px] px-16 py-[19px] bg-blueDark rounded-[38px] 
                justify-center items-center gap-2.5 inline-flex'>
							{authLoading ? <Spin /> : 'Create account'}
						</button>
					</div>
				</Form>
			</div>
		</>
	);
}

export default PasswordAgency;
