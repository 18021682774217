import { Spin } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const url = process.env.REACT_APP_API_BASE_URL;

export default function AttestationResultsDescription({ foreignJobActive }) {
	const { user } = useSelector((state) => state.auth);

	const [loading, setloading] = useState(true);

	return (
		<>
			<div className='bg-white px-3 lg:px-[2.5rem] py-[2rem] w-full'>
				<div className='flex justify-between items-center'>
					<span className='heading_heading_4'>Attestation Results </span>
				</div>

				<div
					className='mt-[1.5rem]'
					style={{
						width: '100%',
						height: '0.0625rem',
						background: 'rgba(216, 216, 216, 0.54)',
					}}></div>

				<div className='mt-[2rem] grid grid-cols-1 gap-[1.5rem]'>
					{foreignJobActive?.japProgressStatus === 'PASSED_ATTESTATION' ||
					foreignJobActive?.japProgressStatus === 'CLEAR' ? (
						<>
                        <div className='flex flex-col'>
								<span className='label_label_1'>Attestation Passed</span>
								<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
                                Attestation clearance certificate provided below.
								</span>
							</div>

							<div className='flex justify-center flex-col items-center w-full mt-14'>
								{/* <img src={attImg} alt='Attestation results' /> */}
								<>
									<iframe
										onLoad={() => setloading(false)}
										title='Generated invoice'
										width={'100%'}
										height={'700px'}
										src={`${url}/nea/directReport.action?P_JOB_ID=${foreignJobActive?.japJobId}&P1_FORMAT=PDF&reportName=ATTESTATION_CERTIFICATE&usrId=${user?.usrId}&P_USR_ID=${user?.usrId}`}></iframe>
								</>

								{loading ? (
									<>
										<button
											className='bg-darkBlue text-white w-[300px] p-3 flex justify-center mt-10'
											disabled
											type='button'>
											<Spin />
											<span className='ml-2'>
												Loading license... Please wait
											</span>
										</button>
									</>
								) : null}
							</div>
						</>
					) : (
						<>
							<div className='flex flex-col'>
								<span className='label_label_1'>Attestation Failed</span>
								<span className='paragraph_paragraph_1 mt-[0.3rem] truncate'>
									Your documents and job application were reviewed and have not
									been accepted for this job.
								</span>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
