import emblem from '../assets/emblem.png';
import flagDivider from '../assets/flag_divider.svg';
import neaLogo from '../assets/landing/home20.png';
import home28 from '../assets/landing/home28.svg';
import { Link } from 'react-router-dom';
import { Button, Space } from 'antd';

export default function AuthHeader({ hide }) {
	return (
		<>
			<div className='w-full h-auto bg-white flex flex-col justify-center lg:flex-row lg:items-center lg:justify-between p-1'>
				<div className='flex items-center justify-between w-full lg:w-auto mb-[.5rem] lg:mb-0'>
					<Link to='/' className='flex items-center'>
						<img
							loading='lazy'
							src={emblem}
							className='ml-0 lg:ml-[1.97rem] object-contain w-[3.9375rem] h-[3.49rem]'
							alt='kenya Emplem'
						/>

						<img
							loading='lazy'
							className='h-10 ml-3'
							src={flagDivider}
							alt='kenya flag colors'
						/>

						<img
							loading='lazy'
							src={neaLogo}
							className='ml-[1.06rem] object-cover w-[9.3125rem] h-[2.73813rem]'
							alt='NEAIMIS Logo'
						/>
					</Link>
				</div>

				{hide ? null : (
					<div className='flex items-center ml-[.5rem] lg:ml-0'>
						<Button
							className='hidden lg:flex bg-transparent nav-items'
							type='button'>
							<Space>
								<div className='flex items-center'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='26'
										height='25'
										viewBox='0 0 26 25'
										fill='none'>
										<path
											d='M13 2.34375C10.9913 2.34375 9.02768 2.9394 7.35749 4.05539C5.68731 5.17137 4.38556 6.75756 3.61685 8.61337C2.84815 10.4692 2.64702 12.5113 3.0389 14.4814C3.43079 16.4515 4.39807 18.2612 5.81845 19.6816C7.23883 21.1019 9.0485 22.0692 11.0186 22.4611C12.9887 22.853 15.0308 22.6519 16.8866 21.8832C18.7424 21.1144 20.3286 19.8127 21.4446 18.1425C22.5606 16.4723 23.1563 14.5087 23.1563 12.5C23.1534 9.80727 22.0825 7.22564 20.1784 5.32159C18.2744 3.41754 15.6927 2.34659 13 2.34375ZM10.4248 16.4062H15.5752C15.0508 18.1973 14.1719 19.8115 13 21.083C11.8281 19.8115 10.9492 18.1973 10.4248 16.4062ZM10.0703 14.8438C9.8112 13.292 9.8112 11.708 10.0703 10.1562H15.9297C16.1888 11.708 16.1888 13.292 15.9297 14.8438H10.0703ZM4.40625 12.5C4.40557 11.7075 4.51502 10.9187 4.73145 10.1562H8.48731C8.25424 11.7101 8.25424 13.2899 8.48731 14.8438H4.73145C4.51502 14.0813 4.40557 13.2925 4.40625 12.5ZM15.5752 8.59375H10.4248C10.9492 6.80273 11.8281 5.18848 13 3.91699C14.1719 5.18848 15.0508 6.80273 15.5752 8.59375ZM17.5127 10.1562H21.2686C21.7022 11.6887 21.7022 13.3113 21.2686 14.8438H17.5127C17.7458 13.2899 17.7458 11.7101 17.5127 10.1562ZM20.6533 8.59375H17.1934C16.7947 7.02491 16.1251 5.53774 15.2148 4.19922C16.3778 4.51174 17.4624 5.06418 18.3991 5.82102C19.3357 6.57787 20.1036 7.52235 20.6533 8.59375ZM10.7852 4.19922C9.8749 5.53774 9.20534 7.02491 8.80664 8.59375H5.34668C5.89642 7.52235 6.66429 6.57787 7.60093 5.82102C8.53756 5.06418 9.62222 4.51174 10.7852 4.19922ZM5.34668 16.4062H8.80664C9.20534 17.9751 9.8749 19.4623 10.7852 20.8008C9.62222 20.4883 8.53756 19.9358 7.60093 19.179C6.66429 18.4221 5.89642 17.4776 5.34668 16.4062ZM15.2148 20.8008C16.1251 19.4623 16.7947 17.9751 17.1934 16.4062H20.6533C20.1036 17.4776 19.3357 18.4221 18.3991 19.179C17.4624 19.9358 16.3778 20.4883 15.2148 20.8008Z'
											fill='#4B85FE'
										/>
									</svg>
									<span className='ml-[.5rem]'>EN</span>
								</div>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='14'
									height='14'
									viewBox='0 0 14 14'
									fill='none'>
									<path
										d='M3.5 5.5L7.5 9.5L11.5 5.5'
										stroke='#AAAAAA'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</Space>
						</Button>

						<Button
							className='hidden lg:flex bg-transparent nav-items'
							type='button'>
							<img
								loading='lazy'
								className=' h-[3rem] -mt-3 object-contain'
								src={home28}
								alt='Accessibility'
							/>
						</Button>
					</div>
				)}
			</div>
		</>
	);
}
