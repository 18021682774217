export default function ProcessCompleteStep({ email, password }) {
	return (
		<>
			<div className='w-full mt-[2.5rem] flex flex-col'>
				<span className='heading_heading_3'></span>
			</div>

			<div className='flex flex-col justify-center items-center w-full h-full mt-[2.5rem]'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='32'
					height='32'
					viewBox='0 0 32 32'
					fill='none'>
					<path
						d='M11 17L14 20L21 13'
						stroke='#388E3C'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					<path
						d='M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z'
						stroke='#388E3C'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>

				<span className='label_label_1 mt-[.75rem] !text-[#616161]'>
					{password ? 'Password' : email ? 'Email address' : 'Phone number'}{' '}
					updated
				</span>
				<span className='paragraph_paragraph_2 mt-[.75rem] !text-[#616161]'>
					You have successfully changed your{' '}
					{password ? 'password' : email ? 'email address' : 'phone number'}
				</span>
			</div>
		</>
	);
}
