import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, Form, Input, Popover, Select, message } from 'antd';
import { countries } from '../../../countries';
import { languages } from '../../../languages';
import { eduLevelOptions } from '../../../utils';
import {
	clearActiveEdu,
	fetchCVEducations,
	fetchEducations,
	saveEducation,
	setActiveEdu,
	setEduData,
} from '../../../features/edu/eduSlice';
import moment from 'moment';
import eduImg from '../../../assets/edit_edu.png';
import {
	fetchAwards,
	fetchCourses,
	fetchInstitutionTypes,
	fetchProfessionalBodies,
	fetchStudyAreas,
} from '../../../features/dropDowns/dropDownSlice';
import Dragger from 'antd/es/upload/Dragger';
import toast from 'react-hot-toast';

const { TextArea } = Input;

const url = process.env.REACT_APP_API_BASE_URL;

let languageOptions = [];

let countryOptions = [];

countries.forEach((item) => {
	countryOptions.push({
		label: item?.name,
		value: item?.name,
	});
});

languages.forEach((item) => {
	languageOptions.push({
		label: item?.name,
		value: item?.name,
	});
});

export default function CreateEduTab({ handleCurrent, form }) {
	const dispatch = useDispatch();
	const [formEdu] = Form.useForm();
	const [formEduEdit] = Form.useForm();
	const formRef = useRef(null);

	const { cvData } = useSelector((state) => state.cv);
	const { user, token } = useSelector((state) => state.auth);
	const { eduCV, eduData } = useSelector((state) => state.edu);
	const { dropDownLoading, instTypes, studyAreas, awards } = useSelector(
		(state) => state.dropDown
	);

	const [addEducation, setaddEducation] = useState(false);
	const [educationList, seteducationList] = useState(eduCV);
	const [editedItem, seteditedItem] = useState({});
	const [editing, setediting] = useState(false);
	const [data, setdata] = useState({});
	const [minDate, setminDate] = useState();
	const [fileUrl, setfileUrl] = useState('');
	const [fileName, setfileName] = useState('');

	const usrObj = {
		usrId: user?.usrId,
		eduCvlId: cvData?.cvId,
	};

	const props = {
		name: 'file',
		multiple: false,
		showUploadList: false,
		accept: 'image/png, image/jpeg, .pdf',
		action: `${url}/nea/saveFile.action`,
		onChange(info) {
			const { status } = info.file;
			if (status !== 'uploading') {
			}
			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`);
				if (info?.file?.response?.success) {
					setfileUrl(info?.file?.response?.jsonData);
					setfileName(info?.file?.name ? info?.file?.name : 'Attachment');
					seteditedItem((prevData) => ({
						...prevData,
						eduFileUrl: info?.file?.response?.jsonData,
						eduFileName: info?.file?.name,
					}));
				} else {
					setfileUrl('');
					setfileName('');
				}
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onDrop(e) {},
	};

	const handleChange = (e) => {
		seteditedItem((prevData) => ({
			...prevData,
			[e.target.name]: e.target.value,
		}));
	};

	function handleEduLevelChange(value) {
		seteditedItem((prevData) => ({
			...prevData,
			eduLevel: value,
		}));
	}

	function onMonthChange(value) {
		seteditedItem((prevData) => ({
			...prevData,
			eduStartDate: value,
		}));
	}

	function onEndMonthChange(value) {
		seteditedItem((prevData) => ({
			...prevData,
			endEndDate: value,
		}));
	}

	async function handleCancel(isFinish) {
		await seteditedItem({});
		await formEdu.resetFields();
		await setediting(false);
		await setaddEducation(false);
		await setdata({});

		if (isFinish) {
			return;
		} else {
			await dispatch(clearActiveEdu());
		}
	}

	async function handleaddNew() {
		await seteditedItem({});
		await setediting(false);
		await setaddEducation(true);
	}

	async function handleUpdatePreview(item) {
		await dispatch(setActiveEdu(item));
	}

	async function handleValuesChange(values) {
		setdata((prevData) => ({
			...prevData,
			...values,
		}));
	}

	async function handleEditingChange(values) {
		let eduArr = eduData?.map((item) =>
			item?.eduId === values?.eduId ? { ...values } : item
		);
		await dispatch(setEduData(eduArr));
	}

	const onFinish = async (values) => {
		values.eduStartMonth = moment(values?.month?.$d).format('MMMM');
		values.eduStartYear = values?.year?.$y;

		values.eduEndMonth = moment(values?.end_month?.$d).format('MMMM');
		values.eduEndYear = values?.end_year?.$y;

		values.eduUsrId = user?.usrId;

		values.eduFileUrl = fileUrl;
		values.eduFileName = fileName;

		const res = await dispatch(saveEducation(values));

		if (res?.payload?.success) {

			const resp = await dispatch(fetchEducations(usrObj));

			if (resp?.payload?.success) {
				await seteducationList(resp?.payload?.data?.result);
				await dispatch(clearActiveEdu())

				await dispatch(setEduData([res?.payload?.jsonData, ...eduData]));
			
	
				await handleCancel(true);
				formEdu.resetFields();
			}else{
				toast.error(resp?.payload?.messages?.message)
			}

			
		}else{
			toast.error(res?.payload?.messages?.message)
		}
	};

	const onFinish2 = async () => {
		//not working
		await dispatch(saveEducation(editedItem));
		const resp = await dispatch(fetchCVEducations(usrObj));

		if (resp?.payload?.success) {
			await seteducationList(resp?.payload?.data?.result);
		}

		await setaddEducation(false);
	};

	async function handleFetchEducations() {
		const resp = await dispatch(fetchCVEducations(usrObj));

		if (resp?.payload?.success) {
			await seteducationList(resp?.payload?.data?.result);
		}
	}

	function handleMinDateChange(date) {
		setminDate(date);
	}

	async function handleUpdateList(e, value) {
		e.preventDefault();
		if (eduData?.find((item) => item?.eduId === value?.eduId)) {
			let newArr = eduData?.filter((item) => item?.eduId !== value?.eduId);
			await dispatch(setEduData(newArr));
		} else {
			await dispatch(setEduData([value, ...eduData]));
		}
	}

	async function handleDropdownOptions() {
		await dispatch(fetchInstitutionTypes());
		await dispatch(fetchAwards());
		await dispatch(fetchStudyAreas());
		await dispatch(fetchProfessionalBodies());
	}

	async function handleFetchCourses(name) {
		let ardObj = awards?.find(
			(item) =>
				String(item?.awdName).toUpperCase() === String(name).toUpperCase()
		);
		await dispatch(fetchCourses(ardObj?.awdId));
	}

	useEffect(() => {
		handleUpdatePreview(data);
	}, [data]);

	useEffect(() => {
		handleEditingChange(editedItem);
	}, [editedItem]);

	useEffect(() => {}, [educationList]);

	useEffect(() => {
		seteducationList(eduCV);
	}, [eduCV]);

	useEffect(() => {
		handleFetchEducations();
		handleDropdownOptions();
	}, []);

	return (
		<>
			<div
				className='py-[2.88rem] px-[2.5rem]'
				style={{
					borderRadius: '0.875rem',
					border: '1px solid var(--Border-secondary, #E0E0E0)',
					background: '#FFF',
				}}>
				<h3 className='heading_heading_4'>Education experience</h3>
				<p className='paragraph_paragraph_2 mt-[1rem] !text-black333'>
					Tell us about your education history
				</p>
				<div className='flex flex-col mt-[.81rem] w-full'>
					{educationList?.length && !addEducation ? (
						<>
							{educationList?.map((item, index) => {
								return (
								
										<div key={index}>
											<div className='bg-white p-3 mt-3'>
												<div className='w-full flex flex-col'>
													<div className='flex justify-between items-center'>
														<h3 className='text-darkBlue text-[19px] font-bold'>
															{item?.eduInstName} ({item?.eduAward})
														</h3>

														<div className='flex'>
															{eduData?.filter((x) => x?.eduId === item?.eduId)
																.length === 0 ? (
																<Popover title='Add to CV'>
																	<button
																		onClick={(e) => handleUpdateList(e, item)}
																		type='button'>
																		<div className='border-2 border-[#02A548] rounded-full p-1'>
																			<svg
																				xmlns='http://www.w3.org/2000/svg'
																				width='24'
																				height='24'
																				viewBox='0 0 32 32'
																				fill='none'>
																				<path
																					fillRule='evenodd'
																					clipRule='evenodd'
																					d='M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z'
																					fill='#02A548'
																				/>
																				<path
																					fillRule='evenodd'
																					clipRule='evenodd'
																					d='M16 4C16.5523 4 17 4.44772 17 5V27C17 27.5523 16.5523 28 16 28C15.4477 28 15 27.5523 15 27V5C15 4.44772 15.4477 4 16 4Z'
																					fill='#02A548'
																				/>
																			</svg>
																		</div>
																	</button>
																</Popover>
															) : null}

															{eduData?.filter((x) => x?.eduId === item?.eduId)
																.length > 0 ? (
																<Popover title='Remove from CV'>
																	<button
																		onClick={(e) => handleUpdateList(e, item)}
																		type='button'>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='20'
																			height='20'
																			viewBox='0 0 12 12'
																			fill='none'>
																			<path
																				d='M7.40799 5.99628L11.708 1.70628C11.8963 1.51798 12.0021 1.26258 12.0021 0.996279C12.0021 0.729978 11.8963 0.474583 11.708 0.286279C11.5197 0.0979755 11.2643 -0.0078125 10.998 -0.0078125C10.7317 -0.0078125 10.4763 0.0979755 10.288 0.286279L5.99799 4.58628L1.70799 0.286279C1.51968 0.0979755 1.26429 -0.0078125 0.997986 -0.0078125C0.731684 -0.0078125 0.47629 0.0979755 0.287986 0.286279C0.0996821 0.474583 -0.0061059 0.729978 -0.0061059 0.996279C-0.0061059 1.26258 0.0996821 1.51798 0.287986 1.70628L4.58799 5.99628L0.287986 10.2863C0.194257 10.3792 0.119863 10.4898 0.0690947 10.6117C0.018326 10.7336 -0.0078125 10.8643 -0.0078125 10.9963C-0.0078125 11.1283 0.018326 11.259 0.0690947 11.3809C0.119863 11.5027 0.194257 11.6133 0.287986 11.7063C0.380949 11.8 0.49155 11.8744 0.613409 11.9252C0.735268 11.9759 0.865974 12.0021 0.997986 12.0021C1.13 12.0021 1.2607 11.9759 1.38256 11.9252C1.50442 11.8744 1.61502 11.8 1.70799 11.7063L5.99799 7.40628L10.288 11.7063C10.3809 11.8 10.4915 11.8744 10.6134 11.9252C10.7353 11.9759 10.866 12.0021 10.998 12.0021C11.13 12.0021 11.2607 11.9759 11.3826 11.9252C11.5044 11.8744 11.615 11.8 11.708 11.7063C11.8017 11.6133 11.8761 11.5027 11.9269 11.3809C11.9776 11.259 12.0038 11.1283 12.0038 10.9963C12.0038 10.8643 11.9776 10.7336 11.9269 10.6117C11.8761 10.4898 11.8017 10.3792 11.708 10.2863L7.40799 5.99628Z'
																				fill='#C8001B'
																			/>
																		</svg>
																	</button>
																</Popover>
															) : null}
														</div>
													</div>

													<span className='text-black333 font-medium text-[18px]'>
														{item?.eduCourse}
													</span>
													<span className='text-black333 font-normal text-[15px]'>
														{moment(item?.eduStartDate).format('MMMM YYYY')} -{' '}
														{moment(item?.endEndDate).format('MMMM YYYY')}
													</span>

													<span className='text-black2 font-normal text-[18px] text-justify mt-2'>
														{item?.eduDesc}
													</span>
												</div>
											</div>
										</div>
								
								);
							})}

							<div className='mt-10'></div>
						</>
					) : !addEducation && !educationList?.length ? (
						<>
							<div className='flex items-center justify-center flex-col'>
								<img
									loading='lazy'
									className='object-contain w-[550px]'
									src={eduImg}
									alt='education'
								/>
								<p className='edit-step-desc my-10'>
									Don’t have education experience? You can skip this part
								</p>
							</div>
						</>
					) : editing ? (
						<Form
							layout='vertical'
							ref={formRef}
							name='control-ref'
							onFinish={onFinish2}
							style={{
								maxWidth: '100%',
								marginTop: 20,
							}}
							form={formEduEdit}>
							<div className='grid grid-cols-1'>
								<div className='flex flex-col'>
									<label className='mb-2'>Education Level</label>
									<Select
										suffixIcon={
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'>
												<path
													d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
													fill='#212121'
												/>
											</svg>
										}
										style={{
											width: '100%',
											height: '44px',
										}}
										value={editedItem?.eduLevel}
										options={eduLevelOptions}
										onChange={(value) => {
											handleEduLevelChange(value);
										}}
										onDropdownVisibleChange={() => {
											//trigger something
										}}
									/>
								</div>

								<div className='flex flex-col'>
									<label className='mb-2'>Name of School</label>
									<Input
										onChange={handleChange}
										name='eduSchool'
										value={editedItem?.eduSchool}
										className='input'
									/>
								</div>

								<div className='mt-4'>
									<p className='mb-3 font-medium'>Start of Education</p>
									<DatePicker
										format={'MMMM - YYYY'}
										placeholder={
											editedItem?.eduStartDate
												? moment(editedItem?.eduStartDate).format('MMMM - YYYY')
												: 'Select start date'
										}
										className='w-full'
										onChange={onMonthChange}
										picker='month'
									/>
								</div>

								<div className='mt-4'>
									<p className='mb-3 font-medium'>End of Education</p>
									<DatePicker
										format={'MMMM - YYYY'}
										placeholder={
											editedItem?.endEndDate
												? moment(editedItem?.endEndDate).format('MMMM - YYYY')
												: 'Select start date'
										}
										className='w-full'
										onChange={onEndMonthChange}
										picker='month'
									/>
								</div>
							</div>

							<div className='mt-4'>
								<p className='mb-1 font-medium text-[15px]'>Brief Summary</p>
								<p className='label-info'>
									Give a brief summary of key things you learned or projects you
									did. Each experience is valuable!
								</p>
								<div className='w-full mt-3'>
									<TextArea
										value={editedItem?.eduDesc}
										name='eduDesc'
										onChange={handleChange}
										rows={6}
									/>
								</div>
							</div>

							<div className='flex items-center justify-end mt-10 mb-14 w-full'>
								<button
									onClick={handleCancel}
									className='bg-white rounded-[35px] text-darkBlue border-2 border-darkBlue w-[300px] p-2 my-3'
									type='button'>
									Cancel
								</button>
								<button
									className='bg-darkBlue rounded-[35px] text-white w-[300px] p-2 ml-4'
									type='button'
									onClick={() => formEduEdit.submit()}>
									Save
								</button>
							</div>
						</Form>
					) : (
						<Form
							layout='vertical'
							ref={formRef}
							name='control-ref'
							onFinish={onFinish}
							style={{
								maxWidth: '100%',
								marginTop: 20,
							}}
							form={formEdu}
							onValuesChange={(values) => {
								handleValuesChange(values);
							}}>
							<div className='grid grid-cols-1 w-full'>
								<Form.Item label='Institution Type' name='eduInstType'>
									<Select
										suffixIcon={
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'>
												<path
													d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
													fill='#212121'
												/>
											</svg>
										}
										showSearch
										loading={dropDownLoading}
										placeholder='Search to Select'
										optionFilterProp='children'
										filterOption={(input, option) =>
											(option?.label?.toLocaleLowerCase() ?? '').includes(
												input?.toLocaleLowerCase()
											)
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										options={
											instTypes?.length > 0 &&
											instTypes?.map((item) => ({
												value: item.instpName,
												label: item.instpName,
											}))
										}
									/>
								</Form.Item>

								<Form.Item
									label='Institution Name'
									name='eduInstName'
									rules={[
										{
											required: true,
											message: 'Field is required',
										},
										{
											message: 'Enter a valid name',
											validator: (_, value) => {
												if (/^[0-9]*$/.test(value)) {
													return Promise.reject();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}>
									<Input required className='input  ' />
								</Form.Item>

								<Form.Item label='Area of Study' name='eduAreaOfStudy'>
									<Select
										suffixIcon={
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'>
												<path
													d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
													fill='#212121'
												/>
											</svg>
										}
										showSearch
										loading={dropDownLoading}
										placeholder='Search to Select'
										optionFilterProp='children'
										filterOption={(input, option) =>
											(option?.label?.toLocaleLowerCase() ?? '').includes(
												input?.toLocaleLowerCase()
											)
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										options={
											studyAreas?.length > 0 &&
											studyAreas?.map((item) => ({
												value: item.stdyName,
												label: item.stdyName,
											}))
										}
									/>
								</Form.Item>

								<Form.Item label='Award' name='eduAward'>
									<Select
										suffixIcon={
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'>
												<path
													d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
													fill='#212121'
												/>
											</svg>
										}
										showSearch
										loading={dropDownLoading}
										placeholder='Search to Select'
										optionFilterProp='children'
										filterOption={(input, option) =>
											(option?.label?.toLocaleLowerCase() ?? '').includes(
												input?.toLocaleLowerCase()
											)
										}
										onChange={(val) => {
											handleFetchCourses(val);
										}}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										options={
											awards?.length > 0 &&
											awards?.map((item) => ({
												value: item.awdName,
												label: item.awdName,
											}))
										}
									/>
								</Form.Item>

								<Form.Item label='Course' name='eduCourse'>
									<Input className='input' />
								</Form.Item>

								<Form.Item label='Education Cert No' name='eduCertNo'>
									<Input className='input  ' />
								</Form.Item>

								<Form.Item
									label='Start Date'
									name='eduStartDate'
									rules={[
										{
											required: true,
											message: 'Start date is required',
										},
									]}
									className='mr-6 w-full'>
									<DatePicker
										onChange={handleMinDateChange}
										placeholder='Select Date'
										className='mr-3'
										format={'MMMM - YYYY'}
										picker='month'
									/>
								</Form.Item>

								<Form.Item
									label='End Date'
									name='endEndDate'
									rules={[
										{
											required: true,
											message: 'Start date is required',
										},
									]}
									className='mr-6 w-full'>
									<DatePicker
										placeholder='Select Date'
										className='mr-3'
										format={'MMMM - YYYY'}
										picker='month'
										minDate={minDate}
									/>
								</Form.Item>
							</div>

							<div className='grid grid-cols-1 gap-5'>
								<Form.Item
									name='eduFileUrl'
									label='Certificate'
									rules={[
										{
											required: false,
											message: 'Please upload a file',
										},
									]}>
									<Dragger {...props}>
										<div className='flex justify-center'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='29'
												height='29'
												viewBox='0 0 29 29'
												fill='none'>
												<path
													d='M25.375 18.125V22.9583C25.375 23.5993 25.1204 24.214 24.6672 24.6672C24.214 25.1204 23.5993 25.375 22.9583 25.375H6.04167C5.40073 25.375 4.78604 25.1204 4.33283 24.6672C3.87961 24.214 3.625 23.5993 3.625 22.9583V18.125'
													stroke='#147CBC'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
												<path
													d='M20.5423 9.66667L14.5007 3.625L8.45898 9.66667'
													stroke='#147CBC'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
												<path
													d='M14.5 3.625V18.125'
													stroke='#147CBC'
													strokeWidth='2'
													strokeLinecap='round'
													strokeLinejoin='round'
												/>
											</svg>
											<p className='ant-upload-text !ml-2'>
												{fileName ? fileName : 'Upload pdf or jpeg'}
											</p>
										</div>
									</Dragger>
								</Form.Item>
							</div>

							<div>
								<p className='mb-1 font-medium text-[15px]'>Brief Summary</p>
								<p className='label-info'>
									Give a brief summary of key things you learned or projects you
									did. Each experience is valuable!
								</p>
								<div className='w-full mt-5'>
									<Form.Item
										name='eduDesc'
										rules={[
											{
												required: true,
												message: 'Field is required',
											},
										]}
										className='w-full'>
										<TextArea rows={6} />
									</Form.Item>
								</div>
							</div>

							<div className='flex items-center justify-end mt-10 mb-14 w-full'>
								<button
									onClick={handleCancel}
									className='bg-white rounded-[35px] text-blueDark border-2 border-blueDark w-[300px] p-2 my-3'
									type='button'>
									Cancel
								</button>
								<button
									onClick={() => formEdu.submit()}
									className='bg-blueDark rounded-[35px] text-white w-[300px] p-2 ml-4'
									type='button'>
									Save
								</button>
							</div>
						</Form>
					)}
				</div>

				<div className='flex justify-center w-full'>
					{addEducation ? null : (
						<button onClick={handleaddNew} className='blue-outline-btn'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='25'
								height='24'
								viewBox='0 0 25 24'
								fill='none'>
								<path
									d='M11.75 18.75V12.75H5.75V11.25H11.75V5.25H13.25V11.25H19.25V12.75H13.25V18.75H11.75Z'
									fill='#1B47B4'
								/>
							</svg>
							<span className='ml-[.5rem]'>Add more education</span>
						</button>
					)}
				</div>
			</div>
		</>
	);
}
