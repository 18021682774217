import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import circleSvg from '../../assets/CheckCircle.svg';
import PasswordChecklist from 'react-password-checklist';
import Header from '../components/signup/Header';
import {
	handleChooseAccount,
	handleLoginSession,
	handleUserToken,
	login,
	updateUser,
} from '../../features/auth/authSlice';
import { customToast } from '../../notifications';
import { fetchPasswordSetups } from '../../features/global/globalSlice';

function Step3() {
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { authLoading, user } = useSelector((state) => state.auth);
	const { pwdSetup } = useSelector((state) => state.global);

	const [checkValue, setcheckValue] = useState(false);

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	let pswrdRules = [];

	let pwdMsg = {};

	pwdSetup?.forEach((item) => {
		pswrdRules.push(item?.pwdDesc);
		pwdMsg[item.pwdDesc] = item?.pwdName;
	});

	async function handleFetchPwdSetup() {
		await dispatch(fetchPasswordSetups());
	}

	function handlePasswordCheck(values) {
		setcheckValue(values);
	}

	const onFinish = async () => {

		let profObj = {
			usrId: user?.usrId,
			usrEncryptedPassword: password,
			usrNationalId: user?.usrNationalId,
		};

		const res = await dispatch(updateUser(profObj));

		if (res?.payload?.success) {
			await customToast({
				content: 'Account created successfully',
				bdColor: 'success',
				header: 'Success',
			});

			const resp = await dispatch(
				login({
					usrEncryptedPassword: password,
					usrUsername: res?.payload?.jsonData?.usrUsername,
				})
			);

			if (resp?.payload?.success) {
				if (resp?.payload?.data?.myInstitution?.length > 0) {
					await dispatch(handleLoginSession(true));
					await dispatch(handleUserToken(''));
					await dispatch(handleChooseAccount(true));
					await navigate('/accounts');
				} else {
					await dispatch(handleLoginSession(true));
					await dispatch(handleUserToken(resp?.payload?.jsonData?.usrResetHash));
					await dispatch(handleChooseAccount(false));
					await navigate('/home');
				}
			} else {
				await customToast({
					content: resp?.payload?.messages?.message,
					bdColor: 'error',
					header: 'Error',
				});
			}
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	};

	useEffect(() => {}, [pwdSetup]);

	useEffect(() => {
		handleFetchPwdSetup();
	}, []);

	return (
		<>
			<div className='sticky bg-white top-0 w-full z-[1000]'>
				<Header />
			</div>

			<div className='text-center text-black1 text-4xl font-bold font-dmSans mt-[4rem]'>
				Lastly, let’s keep your account secure.
			</div>
			<div className='text-center text-neutral-800 text-[22px] font-normal font-dmSans leading-relaxed mb-[3.63rem] mt-[0.75rem]'>
				Create a password to your account
			</div>

			<div
				className='lg:p-0 p-3'
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: '100%',
					}}
					form={form}>
					<div className='lg:w-[670px] w-auto grid grid-cols-1'>
						<Form.Item
							name='password'
							label='Password'
							rules={[
								{
									required: true,
									message: 'Please add password',
								},
							]}>
							<Input.Password
								className='input'
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Form.Item>

						<Form.Item
							name='confirmPassword'
							label='Confirm Password'
							rules={[
								{
									required: true,
									message: 'Please add confirm password',
								},
							]}>
							<Input.Password
								className='input'
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
						</Form.Item>

						<PasswordChecklist
							onChange={(val) => handlePasswordCheck(val)}
							className='text-darkBlue'
							rules={pswrdRules}
							minLength={8}
							value={password}
							valueAgain={confirmPassword}
							messages={{
								minLength: (
									<span className='paragraph_paragraph_b_4_1125'>
										A minimum of 8 characters
									</span>
								),
								specialChar: (
									<span className='paragraph_paragraph_b_4_1125'>
										At least one symbol (~!@$&)
									</span>
								),
								number: (
									<span className='paragraph_paragraph_b_4_1125'>
										At least one number (0-9)
									</span>
								),
								capital: (
									<span className='paragraph_paragraph_b_4_1125'>
										At least one upper case letter (A-Z)
									</span>
								),
								match: (
									<span className='paragraph_paragraph_b_4_1125'>
										Passwords should match
									</span>
								),
							}}
							iconComponents={{
								ValidIcon: (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth='1.5'
										stroke='currentColor'
										className='w-6 h-6 text-darkBlue'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
										/>
									</svg>
								),
								InvalidIcon: <img loading='lazy' src={circleSvg} />,
							}}
							validColor='#147CBC'
							iconSize={2}
						/>

						<div className='lg:w-[638px] w-auto mt-[54px]'>
							<span className='text-neutral-800 text-[22px] font-normal font-dmSans leading-relaxed'>
								By clicking “Submit”, and creating an account, you agree
								to NEA’s{' '}
							</span>
							<Link
								to='/nea-privacy-policy'
								className='text-neutral-800 text-[22px] font-bold font-dmSans underline leading-relaxed'>
								Privacy Policy
							</Link>
							<span className='text-neutral-800 text-[22px] font-normal font-dmSans leading-relaxed'>
								{' '}
								and{' '}
							</span>
							<Link
								to='/terms-of-service'
								className='text-neutral-800 text-[22px] font-bold font-dmSans underline leading-relaxed'>
								Terms of Service
							</Link>
						</div>
					</div>

					<div className='text-center mt-[40px] mb-14'>
						<button
							type='submit'
							disabled={authLoading === true || checkValue === false}
							className='text-white text-[18px] font-bold font-dmSans
                w-[299px] h-[53px] px-16 py-[19px] bg-darkBlue rounded-[38px] 
                justify-center items-center gap-2.5 inline-flex'>
							{authLoading ? <Spin /> : 'Submit'}
						</button>
					</div>
				</Form>
			</div>
		</>
	);
}

export default Step3;
