import home11 from '../../../../assets/landing/home11.png';
import home12 from '../../../../assets/landing/home12.png';
import home13 from '../../../../assets/landing/home13.png';
import home14 from '../../../../assets/landing/home14.png';
import home15 from '../../../../assets/landing/home15.png';
import home16 from '../../../../assets/landing/home16.png';
import home17 from '../../../../assets/landing/home17.png';
import home18 from '../../../../assets/landing/home18.png';

export default function LandingPartFive() {

	const cardInfo = [
		{
			key: 1,
			icon: <img src={home11} alt='homepage icon' />,
		},
		{
			key: 2,
			icon: <img src={home12} alt='homepage icon' />,
		},
		{
			key: 3,
			icon: <img src={home13} alt='homepage icon' />,
		},
		{
			key: 4,
			icon: <img src={home14} alt='homepage icon' />,
		},
		{
			key: 5,
			icon: <img src={home15} alt='homepage icon' />,
		},
		{
			key: 6,
			icon: <img src={home16} alt='homepage icon' />,
		},
		{
			key: 7,
			icon: <img src={home17} alt='homepage icon' />,
		},
		{
			key: 8,
			icon: <img src={home18} alt='homepage icon' />,
		},
	];

	return (
		<>
			<div className='w-full flex flex-col justify-center items-center py-[6.31rem]'>
				<span className='heading_heading_main !text-[#091D50]'>
					Our Partners
				</span>

				<div className='mt-[4.56rem] hidden lg:block'>
					<div className='flex justify-center items-center gap-x-[4.31rem] pb-5 min-w-[81.75rem] w-full max-w-full'>
						{cardInfo.slice(0, 4).map((item, index) => {
							return (
								<div key={index} className='w-full'>
									<div className='' key={item?.key} type='button'>
										<div className='flex flex-col items-center justify-center'>
											<div className='h-[7.75rem]'>{item.icon}</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>

                    <div className='flex justify-center items-center gap-x-[4.31rem] pb-5 min-w-[81.75rem] w-full max-w-full'>
						{cardInfo.slice(4, 8).map((item, index) => {
							return (
								<div key={index} className='w-full'>
									<div className='' key={item?.key} type='button'>
										<div className='flex flex-col items-center justify-center'>
											<div className='h-[7.75rem]'>{item.icon}</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<div className='mt-[4.56rem] p-[.75rem] flex lg:hidden'>
                    <div className='grid grid-cols-2 gap-5 pb-5 w-full max-w-full'>
						{cardInfo.map((item, index) => {
							return (
								<div key={index} className='w-full'>
									<div className='' key={item?.key} type='button'>
										<div className='flex flex-col items-center justify-center'>
											<div className='h-[7.75rem]'>{item.icon}</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
}
